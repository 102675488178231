// Purpose: Wrap some of the error-reporting stuff, so we can migrate later

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import { createBrowserRouter as baseCreateBrowserRouter } from "react-router-dom";

export const enableErrorReporting = import.meta.env.MODE === "production";

const sentryConfig = {
  dsn: "https://aabe81f3063b46408f58d290519264a8@o84452.ingest.sentry.io/4504439522721792",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: enableErrorReporting ? 0.2 : 0,
};

export function initErrorReporting() {
  if (!enableErrorReporting) {
    return;
  }
  Sentry.init(sentryConfig);
}

export const createBrowserRouter = enableErrorReporting
  ? Sentry.wrapCreateBrowserRouter(baseCreateBrowserRouter)
  : baseCreateBrowserRouter;

export function setTags(
  tags: Record<string, number | string | boolean | bigint | symbol | null | undefined>
) {
  if (!enableErrorReporting) return;
  Sentry.setTags(tags);
}

export function setUser(user: Sentry.User) {
  if (!enableErrorReporting) return;
  Sentry.setUser(user);
}

export function showDialog({ name, email }: { name?: string; email?: string }) {
  Sentry.showReportDialog({
    user: {
      name,
      email,
    },
  });
}

// Somewhat hacky way to report feedback back to Sentry. Impossible to avoid though.
// - GH issue: https://github.com/getsentry/sentry-javascript/issues/3111
export async function sendFeedback({
  name,
  email,
  feedback,
  eventId,
}: {
  name: string;
  email: string;
  feedback: string;
  eventId: string;
}) {
  try {
    const dsn = sentryConfig.dsn;
    const query = Object.entries({ dsn, eventId })
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join("&");
    const body = new FormData();
    body.append("name", name);
    body.append("email", email);
    body.append("comments", feedback);
    await fetch(`https://sentry.io/api/embed/error-page/?${query}`, { method: "POST", body });
  } catch (err) {
    console.error("Unable to send error feedback");
  }
}

/**
 * Capture an exception, and return eventId
 * @param e Error
 * @returns eventId string
 */
export function captureException(e: unknown): string {
  if (!enableErrorReporting) return "";
  return Sentry.captureException(e);
}

export function captureMessage(msg: string) {
  if (!enableErrorReporting || !msg) return;
  return Sentry.captureMessage(msg);
}

export function getLastEventId(): string | undefined {
  return Sentry.lastEventId();
}
