import { useState } from "react";
import { FormTemplateModelWithJsonFormsData, FormTemplatesListRequest } from "@apacta/sdk";
import { useQuery, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";

const DefaultFormTemplateRequestOptions: FormTemplatesListRequest = {
  sort: "created",
  direction: "desc",
  limit: 20,
};
/**
 * Infinite query hook for fetching form templates
 */
export default function useInfiniteFormTemplates(props?: {
  includedFormTemplateIds?: Array<string>;
  opts?: FormTemplatesListRequest;
}) {
  const { includedFormTemplateIds, opts } = props ?? {};
  const api = useAPI();

  const [query, setQuery] = useState<string>("");
  const [options, setOptions] = useState<FormTemplatesListRequest>({
    ...DefaultFormTemplateRequestOptions,
    ...opts,
  });

  const formTemplatesQ = useSuspenseInfiniteQuery({
    queryKey: ["formTemplates", query, options],
    queryFn: async ({ pageParam = 1 }) => {
      return await api.formTemplatesList({
        q: query,
        page: pageParam,
        ...options,
      });
    },
    initialPageParam: 1,
    refetchOnMount: true,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.pagination.hasNextPage) return undefined;
      return lastPage.pagination.currentPage + 1;
    },
  });

  // If formTemplatesQ.data.pages is undefined, return empty array, otherwise merge data from all pages into single array
  const formTemplateData: Array<FormTemplateModelWithJsonFormsData> = formTemplatesQ.data?.pages
    ? formTemplatesQ.data.pages.reduce(
        (acc, val) => [...acc, ...val.data],
        [] as Array<FormTemplateModelWithJsonFormsData>
      )
    : [];

  const notIncludedFormTemplateIds = [...(includedFormTemplateIds ?? [])].filter(
    (id) => !formTemplateData.some((p) => p.id === id)
  );

  const includedFormTemplatesQDisabled =
    !includedFormTemplateIds ||
    includedFormTemplateIds.length === 0 ||
    !notIncludedFormTemplateIds.length;

  const includedFormTemplatesQ = useQuery({
    queryKey: ["formTemplates", notIncludedFormTemplateIds],
    queryFn: async () => await api.formTemplatesList({ ids: notIncludedFormTemplateIds }),
    enabled: !includedFormTemplatesQDisabled,
  });

  const includedFormTemplates = (includedFormTemplatesQ?.data?.data ??
    []) as Array<FormTemplateModelWithJsonFormsData>; // coerce to array of form templates

  // Merge included form templates with fetched form templates and sort them by name
  const formTemplates = [...formTemplateData, ...includedFormTemplates].sort((a, b) => {
    return b.name! > a.name! ? -1 : 1;
  });

  return {
    formTemplates,
    query,
    setQuery,
    setOptions,
    hasNextPage: formTemplatesQ.hasNextPage,
    fetchNextPage: formTemplatesQ.fetchNextPage,
    isFetching: formTemplatesQ.isFetching,
  };
}
