import { User } from "@apacta/sdk";
import Avatar from "./avatar";

/**
 *
 * @param user Used to infer avatar image and background color
 * @param className Used to override default styles
 * @param initials Used to override initials, or set it if no user is supplied
 * @param backgroundColor Used to override the background color, or set it if no user is supplied
 * @constructor
 */
export function UserAvatar({ user, className }: { user?: User; className?: string }) {
  return <Avatar className={className} name={user?.fullName || "N/A"} image={user?.imageUrl} />;
}
