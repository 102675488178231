import { ControlProps } from "@jsonforms/core";
import { useMount } from "~/lib/lifecycle-helpers";

export function useDefaultFormValues(props: ControlProps) {
  // If default value, call the handleChange function, once
  useMount(() => {
    if (props.schema.default === undefined) return;
    if (props.data !== undefined) return;

    const ref = setTimeout(() => {
      props.handleChange(props.path, props.schema.default);
    }, 1);
    return () => clearTimeout(ref);
  });
}
