import {
  MediaCarousel,
  MediaCarouselMimeType,
  MediaPreview,
  MediaPreviewLink,
} from "~/lib/ui/media/";
import { BaseFile } from "@apacta/sdk";
import { Fragment, useId } from "react";

export default function CardFooter({ files = [] }: { files?: Array<BaseFile> }) {
  const footerId = useId();
  if (files.length) {
    const carouselItems = files.filter(
      (f) => f.mimeType?.startsWith("image/") || f.mimeType?.startsWith("video/")
    );

    // Because linting cannot handle grouped conditions...
    const mediaCheck = (length: number, index: number) => {
      if (length <= 4) return true;
      return length > 4 && index < 3;
    };

    return (
      <div className="border-t px-4 py-3">
        <div className="flex w-full">
          {files.map((file, idx) => (
            <Fragment key={`${file.id}-file-${idx}-${footerId}`}>
              {mediaCheck(files.length, idx) && (
                <div key={`${file.id}-file-${idx}`} className="flex basis-1/4 justify-center">
                  <div className="p-2">
                    <>
                      {carouselItems.map((f) => f.id).includes(file.id) ? (
                        <MediaCarousel
                          focusedId={file.id}
                          items={files.map((f) => {
                            return {
                              id: f.id,
                              previewUrl: f.fileUrl ?? "",
                              downloadUrl: f.downloadUrl ?? "",
                              title: f.originalFilename ?? "",
                              mimeType: f.mimeType! as MediaCarouselMimeType,
                            };
                          })}
                        >
                          <MediaPreview
                            height="h-16"
                            href={file.fileUrl ?? ""}
                            mimeType={file.mimeType ?? ""}
                          ></MediaPreview>
                        </MediaCarousel>
                      ) : (
                        <MediaPreviewLink
                          href={file.fileUrl ?? ""}
                          title={file.originalFilename ?? ""}
                          mimeType={file.mimeType ?? ""}
                          height="h-16"
                        />
                      )}
                    </>
                  </div>
                </div>
              )}
              {files.length > 4 && idx === 3 && (
                <div className="flex basis-1/4 items-center justify-center">
                  <div className="p-2">
                    <MediaCarousel
                      focusedId={files[3].id}
                      items={files.map((f) => {
                        return {
                          id: f.id,
                          previewUrl: f.fileUrl ?? "",
                          downloadUrl: f.downloadUrl ?? "",
                          title: f.originalFilename ?? "",
                          mimeType: f.mimeType! as MediaCarouselMimeType,
                        };
                      })}
                    >
                      <div className="flex h-16 w-16 items-center justify-center rounded-full border p-2 font-semibold hover:bg-shade-200">
                        + {files.length - 3}
                      </div>
                    </MediaCarousel>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  return <div className="border-t px-4 py-3"></div>;
}
