import { useTranslation } from "react-i18next";
import { KPICardWrapper } from "../_cmp/kpi-card-wrapper";
import { FormatValue } from "../_cmp/FormatValue";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { PercentBar } from "../_cmp/percent-bar";
import Tooltip from "~/lib/ui/tooltip";

export function StatusCardFixed({
  salesPriceCurrent = 0,
  estimatedSalesPrice = 0,
  estimatedCostPrice = 0,
  costPriceCurrent = 0,
  completionPercent = 0,
}: {
  salesPriceCurrent?: number;
  estimatedSalesPrice?: number;
  costPriceCurrent?: number;
  estimatedCostPrice?: number;
  completionPercent?: number;
}) {
  const { t } = useTranslation();

  const leftPercent = completionPercent;

  return (
    <KPICardWrapper label={t("common:status")} Icon={CurrencyDollarIcon}>
      <div className="flex flex-row justify-between">
        <div className="flex-1 text-left">
          <Tooltip
            arrowClassName="fill-hover"
            triggerClassname="cursor-text"
            triggerAsChild={false}
            trigger={
              <KeyValueView label={t("finance:delivered_sales_price")} value={salesPriceCurrent} />
            }
            className="text-sm"
          >
            {t("finance:delivered_sales_price_tooltip")}
          </Tooltip>

          <KeyValueView label={t("finance:delivered_cost_price")} value={costPriceCurrent} />
        </div>
        <div className="flex-1 text-right">
          <KeyValueView
            label={t("finance:sales_price_project_current")}
            value={estimatedSalesPrice}
          />
          <KeyValueView
            label={t("finance:cost_price_project_current")}
            value={estimatedCostPrice}
          />
        </div>
      </div>
      <div className={twMerge("w-full", leftPercent < 100 && "gap-1")}>
        <PercentBar percentage={leftPercent} primaryClassName="bg-green-600" />
      </div>
    </KPICardWrapper>
  );
}

function KeyValueView({ label, value = 0 }: { label: string; value?: number }) {
  return (
    <div className="items-center">
      <span className="mr-4 text-shade-400">{label}</span>
      <span className="text-xl font-bold">
        <FormatValue value={value} unit="currency" />
      </span>
    </div>
  );
}
