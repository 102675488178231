import DayControls from "./day-controls";
import WeekControls from "./week-controls";
import { useTranslation } from "react-i18next";
import { PlanningView, PlanningViewMap } from "~/pages/planning/_cmp/types";
import { usePlanning } from "~/lib/planning/use-planning";
import { Dropdown } from "~/lib/ui/dropdown";
import MonthControls from "./month-control";
import { Button, Icon } from "~/lib/ui";
import { twMerge } from "tailwind-merge";

export default function Controls({
  selectedDate,
  shownDates,
  planningView,
  onDateChange,
  onViewChange,
  onShowUnplanned,
}: {
  selectedDate: Date;
  planningView: PlanningView;
  shownDates: Array<Date>;
  onDateChange: (date: Date) => void;
  onViewChange: (view: PlanningView) => void;
  onShowUnplanned: () => void;
}) {
  const { modalCreate } = usePlanning();

  const { t } = useTranslation();
  const { cardStateViews } = usePlanning();

  const dropdownMap: PlanningViewMap = {
    day: t("common:day"),
    week: t("common:week"),
    fortnight: t("common:fortnight"),
    month: t("common:month"),
  };

  const handleShowCreate = async () => {
    await modalCreate();
  };

  const handleViewChange = (view: PlanningView) => {
    //setPlanningView(view);
    onViewChange(view);
  };

  return (
    <div className="flex w-full max-w-[85.5rem] items-center gap-14 py-2 pl-8">
      <div className="flex gap-8">
        <Dropdown
          options={{
            align: {
              align: "start",
              alignOffset: 0,
              side: "bottom",
            },
            trigger: {
              asChild: true,
            },
          }}
          trigger={
            <Button variant="secondary" className="text-sm">
              <div className="flex items-center justify-center gap-2 text-sm">
                <div>{dropdownMap[planningView]}</div>
                <Icon name="chevronDown" className="h-3 w-3" />
              </div>
            </Button>
          }
        >
          <Dropdown.Item className="text-sm" onSelect={() => handleViewChange("day")}>
            {t("common:day")}
          </Dropdown.Item>
          <Dropdown.Item className="text-sm" onSelect={() => handleViewChange("week")}>
            {t("common:week")}
          </Dropdown.Item>
          <Dropdown.Item className="text-sm" onSelect={() => handleViewChange("fortnight")}>
            {t("common:fortnight")}
          </Dropdown.Item>
          <Dropdown.Item className="text-sm" onSelect={() => handleViewChange("month")}>
            {t("common:month")}
          </Dropdown.Item>
        </Dropdown>
        <div>
          {planningView === "day" && (
            <DayControls onDateChange={onDateChange} selectedDate={selectedDate} />
          )}
          {(planningView === "week" || planningView === "fortnight") && (
            <WeekControls
              onDateChange={onDateChange}
              selectedDate={selectedDate}
              shownDates={shownDates}
              planningView={planningView}
            />
          )}
          {planningView === "month" && (
            <MonthControls onDateChange={onDateChange} selectedDate={selectedDate} />
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <Button
          variant="secondary"
          className="relative overflow-visible text-sm"
          onClick={() => onShowUnplanned()}
        >
          <div className="flex flex-shrink-0 flex-grow-0 items-center justify-center gap-2">
            <span>{t("planning:side_panel.not_planned")}</span>
            <div
              className={twMerge(
                "absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full p-0.5 text-xs",
                cardStateViews.unplanned.length
                  ? "bg-hover text-white"
                  : "bg-gray-300 text-zinc-700"
              )}
            >
              {cardStateViews.unplanned.length}
            </div>
          </div>
        </Button>
        <Button variant="tertiary" className="text-sm" onClick={() => handleShowCreate()}>
          <div className="flex flex-shrink-0 flex-grow-0 items-center justify-center gap-2">
            <span>{t("planning:create")}</span>
            <Icon name="add" className="h-5 w-5" />
          </div>
        </Button>
      </div>
    </div>
  );
}
