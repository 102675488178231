import { UsersProductsUsage200ResponseDataInner, UsersProductsUsageEntity } from "@apacta/sdk";
import { EmployeeHeader } from "~/pages/employees/_cmp/employee-header";
import { Fragment, useMemo, useState } from "react";
import { Button, Icon } from "~/lib/ui";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { RenderProductUsageRow } from "~/pages/employees/_cmp/render-product-usage-row";

export function RenderProductUsageTable({
  data,
}: {
  data: Array<UsersProductsUsage200ResponseDataInner>;
}) {
  const { t } = useTranslation();
  const iconClass = "inline ml-2 h-5 w-5";
  const [sortField, setSortField] = useState<sortableFields>("productNumber");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const sortedData = useMemo(() => {
    if (!data) return [];

    return data.map((userData: UsersProductsUsage200ResponseDataInner) => ({
      ...userData,
      products: [...userData.products].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
        return 0;
      }),
    }));
  }, [data, sortField, sortDirection]);

  // Sorting
  type sortableFields = keyof Pick<
    UsersProductsUsageEntity,
    "productNumber" | "productName" | "amount" | "buyingPrice" | "sellingPrice"
  >;

  const handleSortClick = (field: sortableFields) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const renderSortIcon = (field: sortableFields) => {
    if (sortField !== field) return null;
    return sortDirection === "asc" ? (
      <Icon name="chevronUp" className={iconClass} />
    ) : (
      <Icon name="chevronDown" className={iconClass} />
    );
  };

  const columnHeaders: Array<sortableFields> = [
    "productNumber",
    "productName",
    "amount",
    "buyingPrice",
    "sellingPrice",
  ];
  const renderTableHeaders = () => {
    return columnHeaders.map((columnKey) => {
      const columnName = _.snakeCase(columnKey);
      const btnClass = "flex items-center whitespace-nowrap text-sm";
      return (
        <th key={columnKey} scope="col" className="w-32 min-w-32 text-left">
          <Button className={btnClass} onClick={() => handleSortClick(columnKey)}>
            {t(`common:${columnName}`)} {renderSortIcon(columnKey)}
          </Button>
        </th>
      );
    });
  };
  return (
    <>
      {sortedData?.map((productUsage: UsersProductsUsage200ResponseDataInner) => {
        return (
          <div className="mt-5" key={productUsage.user.id}>
            <EmployeeHeader user={productUsage.user} />
            <div className="mt-2 table">
              <table className="mt-6 w-full  text-left">
                <thead className="border-b border-white/10 text-sm leading-6 text-white">
                  <tr>{renderTableHeaders()}</tr>
                </thead>
                <tbody className="divide-y divide-white/5">
                  {productUsage.products.map((product) => (
                    <Fragment key={`${product.productId}-${productUsage.user.id}-row`}>
                      <RenderProductUsageRow product={product} />
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div></div>
          </div>
        );
      })}
    </>
  );
}
