import {
  ColumnFiltersState,
  ExpandedState,
  PaginationState,
  SortingState,
  Updater,
  functionalUpdate,
} from "@tanstack/react-table";
import { useCallback, useReducer } from "react";

// Stored in state. Doesn't survive a refresh
export type DataTableState = {
  columnVisibility: Record<string, boolean>;
  expanded: ExpandedState; // should be state
  pagination: PaginationState;
  columnFilters: ColumnFiltersState;
  sorting: SortingState;
  search: string;
};

const DEFAULT_PAGE_SIZE = 100; // Don't override this for no reason

/**
 * State handler for Table State. Nothing fancy, and you don't need to use it.
 * @returns
 */
export function useDataTableState(defaults?: Partial<DataTableState>) {
  const stateReducer = <K extends keyof DataTableState>(
    s: DataTableState,
    action: {
      field: K;
      value: DataTableState[K];
    }
  ) => {
    if (action.value === s[action.field]) return s; // no-op
    return { ...s, [action.field]: action.value };
  };

  const [state, dispatch] = useReducer(stateReducer, defaults, createInitialState);

  const handleTableState = useCallback(
    <K extends keyof DataTableState>(field: K) => {
      function updater(upFn: Updater<DataTableState[K]>) {
        const newValue = functionalUpdate(upFn, state[field]);
        return dispatch({ field, value: newValue });
      }
      return updater;
    },
    [state]
  );

  return {
    state,
    handleTableState,
    pageNumber: state.pagination.pageIndex + 1,
    sortingDirection:
      state.sorting.length === 0 ? undefined : state.sorting[0].desc ? "desc" : "asc",
    sortBy: state.sorting.length === 0 ? undefined : state.sorting[0].id,
    dispatch: dispatch,
    queryKeys: [state.pagination, state.search, state.sorting],
  } as const;
}

function createInitialState(defaults?: Partial<DataTableState>): DataTableState {
  return {
    columnVisibility: defaults?.columnVisibility ?? {},
    columnFilters: defaults?.columnFilters ?? [],
    expanded: defaults?.expanded ?? {},
    pagination: defaults?.pagination ?? { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
    sorting: defaults?.sorting ?? [],
    search: defaults?.search ?? "",
  };
}
