import { useRef } from "react";
import MimeIcon from "../icons/mime-icon";
import { PlayIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

export function MediaPreview({
  href,
  mimeType,
  filename,
  height = "h-32",
}: {
  href: string;
  mimeType: string;
  filename?: string;
  height?: `h-${number}`;
}) {
  const imgRef = useRef<HTMLImageElement>(null);
  if (!mimeType) {
    mimeType = "application/octet-stream";
    console.warn("MediaPreview received no mimeType. It is required", filename, href);
  }

  if (mimeType.startsWith("image/")) {
    return (
      <img
        ref={imgRef}
        className={twMerge("w-auto object-contain", height)}
        src={href}
        alt={filename}
        onError={(e) => {
          if (imgRef.current) {
            // We can safely do this, as the image we're setting it to is a placeholder that we control
            // eslint-disable-next-line react-compiler/react-compiler
            imgRef.current.src = "/image_not_found.jpg";
          }
        }}
      />
    );
  }
  if (mimeType.startsWith("video/")) {
    return (
      <div className={twMerge("relative", height)} title={filename}>
        <video className="h-full w-full" controls={false}>
          <source src={href} type={mimeType} />
        </video>

        <PlayIcon className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-gray-600 p-2 text-white" />
      </div>
    );
  }
  return (
    <div className={twMerge("relative", height)} title={filename}>
      <MimeIcon mime={mimeType} className="h-full w-full" />
    </div>
  );
}
