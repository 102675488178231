// Purpose: Render a standard confirmation dialog

import { useTranslation } from "react-i18next";
import { DialogFooter } from "../dialog-footer";
import { IconProp } from "~/lib/ui/types";
import { DialogHeader } from "../dialog-header";
import { getIcon } from "~/lib/ui";
import { MouseEvent } from "react";

export type ConfirmationDialogProps = {
  Icon?: IconProp;
  title?: string;
  description?: string;
  submitLabel?: string;
  isLoading?: boolean;
  onClose?: () => void;
  onSubmit: (e: MouseEvent<HTMLButtonElement>) => Promise<unknown> | unknown;
  variant?: "success" | "warning" | "alert";
};

export type ConfirmOption = Omit<ConfirmationDialogProps, "onSubmit" | "isLoading" | "onClose">;

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { t } = useTranslation();

  const {
    Icon,
    variant = "warning",
    title,
    description,
    submitLabel,
    onClose,
    onSubmit,
    isLoading,
  } = props;

  return (
    <>
      <DialogHeader
        title={title ?? t("ui:confirm.title")}
        variant={variant}
        Icon={Icon ?? getIcon("unknownType")}
      />
      <div className="mb-12">
        <div className="mt-3 sm:mt-5">
          <div className="mt-2 whitespace-pre-line text-base text-shade-900">
            {description ?? t("ui:confirm.description")}
          </div>
        </div>
      </div>
      <DialogFooter
        onClose={() => onClose?.()}
        primary={{
          loading: isLoading,
          disabled: isLoading,
          label: submitLabel ?? t("common:confirm"),
          onClick: async (e) => {
            await onSubmit(e);
            onClose?.();
          },
        }}
      />
    </>
  );
}
