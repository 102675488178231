import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ToastContext } from "~/lib/toast/context";
import { ToastNotification } from "~/lib/ui/toast";
import { getIcon } from "../ui";

export const useToasts = () => {
  const toastCtx = useContext(ToastContext);
  const { t } = useTranslation();

  const templates = {
    CHANGES_SAVED: {
      title: t("common:changes_saved"),
      description: t("common:changes_saved_description"),
      Icon: getIcon("successCircle"),
      timeout: 5000,
    } satisfies ToastNotification,
    OPERATION_FAILED: {
      Icon: getIcon("errorCircle"),
      title: t("error.operation.title"),
      description: t("error.operation.subtitle"),
      variant: "error",
    } satisfies ToastNotification,
    UNEXPECTED_ERROR: {
      Icon: getIcon("errorCircle"),
      title: t("error.unexpected.title"),
      description: t("error.unexpected.subtitle"),
      variant: "error",
    } satisfies ToastNotification,
    CREATED: {
      title: t("common:created", "Created"),
      description: t("common:operation_complete", "Operation completed successfully"),
      variant: "success",
      Icon: getIcon("successCircle"),
    },
    DELETED: {
      title: t("common:deleted", "Deleted"),
      description: t("common:operation_complete", "Operation completed successfully"),
      variant: "success",
      Icon: getIcon("successCircle"),
    },
    MESSAGE_SENT: {
      title: t("common:message_sent", "Message sent"),
      description: t("common:operation_complete", "Operation completed successfully"),
      variant: "success",
      Icon: getIcon("successCircle"),
    },
  } as const;

  if (!toastCtx) {
    throw new Error("Expecting toast context, but couldn't find it.");
  }

  const show = useCallback((ToastItem: ToastNotification) => {
    toastCtx.add(ToastItem);
  }, []);

  type Overrides = Partial<(typeof templates)[keyof typeof templates]>;
  const showTemplate = useCallback(
    (templateName: keyof typeof templates, overrides?: Overrides) => {
      if (!templates[templateName]) throw new Error("Invalid template");
      let temp = templates[templateName];
      if (overrides) {
        temp = { ...temp, ...overrides };
      }
      show(temp);
    },
    []
  );

  return { show, showTemplate };
};
