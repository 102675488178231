import { Layout, LayoutProps, and, or, rankWith, uiTypeIs } from "@jsonforms/core";
import { renderChildren } from "./renderChildren";
import { isEntityOf } from "./testers";
import { Label } from "~/lib/ui";
import { getLabelForControl } from "../getLabelForControl";

export const HorizontalLayoutTightRenderer = (props: LayoutProps) => {
  const layout = props.uischema as Layout;
  const label = getLabelForControl(props);
  return (
    <>
      {label && <Label>{label}</Label>}
      <div className="flex w-full flex-row flex-wrap gap-4">
        {renderChildren({
          schema: props.schema,
          path: props.path,
          enabled: props.enabled,
          elements: layout.elements,
          uischema: props.uischema,
        })}
      </div>
    </>
  );
};
export const HorizontalLayoutTightTester = rankWith(
  3,
  or(and(uiTypeIs("HorizontalLayout"), isEntityOf("timepicker")), uiTypeIs("HorizontalLayoutTight"))
);
