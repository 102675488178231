import { availableRules } from "~/pages/offers/_cmp/offer-lines-rule-selector";
import { DefaultApiInterface, Offer, OfferLine } from "@apacta/sdk";
import {
  offerLinesToOrderLinesTransformer,
  orderLinesToOfferLinesTransformer,
} from "~/lib/ui/order-lines/lib/transformers";
import { OrderLinesBuilderRef } from "~/lib/ui/order-lines/lib/types";
import { RefObject } from "react";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";

export const runOfferLineRule = async (
  offer: Offer,
  orderLineBuilderRef: RefObject<OrderLinesBuilderRef>,
  formState: OfferFormState,
  api: DefaultApiInterface
): Promise<void> => {
  return new Promise(async (resolve) => {
    if (orderLineBuilderRef.current) {
      const lines = orderLineBuilderRef.current.getLines();
      const ruleId = formState.getValue("offerLinesRule");
      const rules = availableRules();
      const rule = rules.find((r) => r.id === ruleId);

      if (!rule || rule.id === "show_all") {
        // We need to wait for the next tick to make sure the lifecycle for showing the finance lines is done
        await new Promise<void>((resolveLines) =>
          setTimeout(() => {
            orderLineBuilderRef?.current?.setViewLines(lines);
            resolveLines();
          }, 1)
        );
        resolve();
      } else {
        let offerLinesResponseRaw: { data: Array<OfferLine> } = { data: [] };
        switch (rule.id) {
          case "gather_offer_lines":
            offerLinesResponseRaw = await api.gatherOfferLines({
              offerId: offer.id ?? "",
              offerLine: orderLinesToOfferLinesTransformer(lines, {
                offerId: offer.id,
              }),
            });
            break;
          case "distribution_hours_over_products":
            offerLinesResponseRaw = await api.distributionHoursOverProducts({
              offerId: offer.id ?? "",
              offerLine: orderLinesToOfferLinesTransformer(lines, {
                offerId: offer.id,
              }),
            });
            break;
          case "show_only_product_bundles":
            offerLinesResponseRaw = await api.showOnlyProductBundles({
              offerId: offer.id ?? "",
              offerLine: orderLinesToOfferLinesTransformer(lines, {
                offerId: offer.id,
              }),
            });
            break;
          case "group_by_product_and_hours":
            offerLinesResponseRaw = await api.groupByProductAndHours({
              offerId: offer.id ?? "",
              offerLine: orderLinesToOfferLinesTransformer(lines, {
                offerId: offer.id,
              }),
            });
            break;
          case "total_price_line":
            offerLinesResponseRaw = await api.addTotalPriceLine({
              offerId: offer.id ?? "",
              offerLine: orderLinesToOfferLinesTransformer(lines, {
                offerId: offer.id,
              }),
            });
            break;
        }

        orderLineBuilderRef.current.setViewLines(
          offerLinesToOrderLinesTransformer(offerLinesResponseRaw.data)
        );

        resolve();
      }
    }
    resolve();
  });
};
