import { RichTextEditor, RichTextEditorRef, ToolbarAction } from "~/lib/ui/rich-text-editor";
import TemplateModal from "~/pages/offers/_cmp/template-modal";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "~/lib/ui";
import { OfferFormState } from "./state/use-offer-form-state";

export default function DescriptionSection({
  editMode = false,
  formState,
  onValidStateChange,
}: {
  editMode: boolean;
  formState: OfferFormState;
  onValidStateChange?: (valid: boolean) => void;
}) {
  const { t } = useTranslation();
  const editorRef = useRef<RichTextEditorRef>(null);
  const [editorValidState, setEditorValidState] = useState<boolean>(true);

  const handleEditorChange = async (validState: boolean) => {
    if (editorRef.current) {
      formState.setValues({ description: await editorRef.current.getEditorMarkdown() });
      if (editorValidState !== validState) {
        setEditorValidState(validState);
        onValidStateChange?.(validState);
      }
    }
  };

  const [showModal, setShowModal] = useState<boolean>(false);

  const toolbarActions: Array<ToolbarAction> = [
    {
      render: () => <span>{t("common:template", { count: 2 })}</span>,
      onClick: () => setShowModal(true),
    },
  ];

  useEffect(() => {
    if (!editMode) {
      editorRef.current?.setEditorMarkdown(formState.getValue("description"), true);
    }
  }, [formState.getValue("description")]);

  return (
    <>
      <Dialog
        open={showModal}
        onOpenChange={setShowModal}
        render={({ onClose }) => (
          <TemplateModal
            onClose={() => {
              setShowModal(false);
              onClose();
            }}
            onSubmit={(data) => {
              editorRef.current?.setEditorMarkdown(data, true);
              setShowModal(false);
              onClose();
            }}
          />
        )}
      />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <RichTextEditor
            ref={editorRef}
            initialData={formState.getValue("description")}
            onChange={(text, html, valid) => handleEditorChange(valid)}
            toolbarActions={toolbarActions}
            preview={!editMode}
            characterLimit={8000}
          />
        </div>
      </div>
    </>
  );
}
