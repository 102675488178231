export function isValidUrl(string: string) {
  const urlRegex =
    /^(https):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,})(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?([-a-z\d_]*)?$/;
  return urlRegex.test(string);
}

export function isValidEmail(string: string) {
  const emailRegex = /^[\w.+-]+@([\w-]+\.)+[\w-]{2,}$/;
  return emailRegex.test(string);
}

export function isValidPhone(string: string) {
  const phoneRegex = /^((\(?\+[0-9]{1,3}\)?)?)(\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2})$/;
  return phoneRegex.test(string);
}
