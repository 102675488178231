import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { Popover } from "~/lib/ui/popover/popover";
import { FilterList } from "~/lib/ui/form-elements/filter-list";
import TextInput from "~/lib/ui/form-elements/text-input";
import { twMerge } from "tailwind-merge";
import { CheckIcon } from "@heroicons/react/24/outline";
import { OfferStatusItem, useOfferStatus } from "~/lib/offers/use-offer-status";

export default function OfferStatusFilter({
  triggerRender,
  initialSelectedStatusId,
  onOpenChange,
  onSelect,
}: {
  triggerRender: () => ReactNode;
  initialSelectedStatusId?: string;
  onOpenChange?: (open: boolean) => void;
  onSelect?: (item: OfferStatusItem) => void;
}) {
  const { t } = useTranslation();

  const offerStatus = useOfferStatus(initialSelectedStatusId);

  const [statusSearchInput, setStatusSearchInput] = useState<string>("");

  const handleSelectStatus = (item: OfferStatusItem, closeCallback: () => void) => {
    onSelect?.(item);
    closeCallback();
  };

  useDebounce(
    () => {
      offerStatus.setOfferStatusSearchQuery(statusSearchInput);
    },
    [statusSearchInput],
    500
  );

  const handleOpenChange = (to: boolean) => {
    setStatusSearchInput("");
    onOpenChange?.(to);
  };

  return (
    <Popover
      onOpenChange={handleOpenChange}
      config={{ side: "right", align: "start" }}
      triggerRender={triggerRender}
    >
      {(close) => (
        <div className="flex h-96 min-w-[18em] flex-shrink-0 flex-col gap-2 overflow-hidden rounded-md border bg-white p-2 shadow-sm">
          <FilterList
            isLoading={offerStatus.offerStatusLoading}
            filterRender={() => (
              <div className="flex">
                <TextInput
                  id="employee-search"
                  label=""
                  placeholder={`${t("common:search")} ${t("common:status").toLowerCase()}`}
                  onChange={(value) => setStatusSearchInput(value)}
                />
              </div>
            )}
            itemRender={({ item, isSelected }) => (
              <div
                key={`user-${item.id}`}
                className={twMerge(
                  "flex cursor-pointer items-center justify-between rounded-md px-2 py-2 hover:bg-gray-100"
                )}
                onClick={() => handleSelectStatus(item, close)}
              >
                <div className="flex gap-2">
                  <span>{offerStatus.offerStatusLabel(item)}</span>
                </div>
                <div className="ml-4">
                  <CheckIcon className={twMerge("h-5 w-5", !isSelected && "invisible")} />
                </div>
              </div>
            )}
            buttonRender={() => (
              <div className="flex items-center justify-end gap-6">
                <span
                  className="cursor-pointer justify-center underline hover:text-hover"
                  onClick={() => close()}
                >
                  {t("common:cancel")}
                </span>
              </div>
            )}
            items={offerStatus.filteredOfferStatuses}
            initialSelected={
              offerStatus.offerStatuses.filter((item) => item.id === initialSelectedStatusId) ?? []
            }
          />
        </div>
      )}
    </Popover>
  );
}
