import {
  ChartBarIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import Tooltip from "~/lib/ui/tooltip";
import { IconProp } from "~/lib/ui/types";

export type KPICardWrapperProps = {
  children?: React.ReactNode;
  label?: string;
  description?: string;
  href?: string;
  Icon?: IconProp;
  containerClass?: string;
  warning?: string;
};

export function KPICardWrapper({
  children = null,
  label = "Missing Label",
  description,
  href,
  Icon = ChartBarIcon,
  containerClass,
  warning,
}: KPICardWrapperProps) {
  const { t } = useTranslation();
  return (
    <div className="flex grow flex-col justify-between rounded-lg border bg-white">
      <div className={twMerge("flex flex-col gap-4 p-6", containerClass)}>
        <div className="flex items-center gap-3">
          <div className="flex h-7 w-7 items-center justify-center overflow-hidden rounded-md bg-primary p-0">
            <Icon className="h-5 w-5 text-white" aria-hidden="true" />
          </div>
          <div className="text-md flex flex-row items-center gap-x-1 truncate font-medium text-primary">
            {label}
            {description && (
              <>
                <Tooltip
                  trigger={<InformationCircleIcon className="inline h-5 w-5" />}
                  className="text-sm"
                >
                  {description}
                </Tooltip>
              </>
            )}
          </div>
        </div>
        {children}
      </div>
      <div>
        {href && (
          <Link
            to={{
              pathname: href,
              search: window.location.search,
            }}
            className="font-medium text-primary hover:text-hover"
          >
            <div className="flex w-full items-center justify-between self-end rounded-b-lg border-t border-gray-200 px-4 py-4 hover:underline sm:px-6">
              <div>
                {warning && (
                  <Tooltip
                    trigger={<ExclamationTriangleIcon className="inline h-5 w-5 text-red-600" />}
                  >
                    {warning}
                  </Tooltip>
                )}
              </div>
              <div className="">
                <div className="flex items-center gap-1 text-sm">
                  {t("common:show")}
                  <ChevronRightIcon className="inline h-4 w-4" />
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}
