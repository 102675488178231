import { ComponentPropsWithRef, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const baseClass =
  "mr-3 flex h-5  w-5 items-center justify-center rounded-md bg-white text-white border border-shade-400";
const focusClass = "focus:ring-tertiary focus:ring";
const checkedClass = "checked:text-tertiary";
const hoverClass = "hover:text-hover";
const disabledClass = "disabled:bg-gray-200 disabled:border-gray-300";
// Checkbox with initial styling and ref forwarding
export const Checkbox = forwardRef<HTMLInputElement, ComponentPropsWithRef<"input">>(
  ({ className, ...props }, ref) => (
    <input
      type="checkbox"
      className={twMerge(baseClass, checkedClass, hoverClass, focusClass, disabledClass, className)}
      ref={ref}
      {...props}
    />
  )
);
Checkbox.displayName = "Checkbox";
