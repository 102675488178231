import { Project } from "@apacta/sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { formatCurrency } from "~/lib/utils/number";
import { CACHE_OFFERS } from "~/pages/offers";

import { CACHE_PRODUCTS } from "~/pages/products";

export default function ProductsOfferTab() {
  const api = useAPI();
  const { t } = useTranslation();
  const { entity: project } = useOutletContext<{ entity: Project }>();

  const dataQ = useSuspenseQuery({
    // Cache hirarchy. Products clears it, product clears it offers and this specific query
    queryKey: [CACHE_PRODUCTS, project.id, CACHE_OFFERS],
    queryFn: () =>
      api.projectGetKpiCardProductsPreCalculationSubPage({
        projectId: project.id as string,
      }),
  });

  if (!dataQ.data) {
    return null;
  }

  const { preCalculations: rows, totalCost } = dataQ.data.data;

  return (
    <div>
      <div className="table">
        <table className="text-left">
          <thead>
            <tr>
              <th>{t("common:name")}</th>
              <th>{t("common:quantity")}</th>
              <th>{t("common:cost_price")}</th>
              <th>{t("common:total_cost_price")}</th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((item, idx) => {
              return (
                <Fragment key={idx}>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>{formatCurrency(item.costPrice)}</td>
                    <td>{formatCurrency(item.totalCostPrice)}</td>
                  </tr>
                </Fragment>
              );
            })}
            <tr className="bg-slate-50 font-bold">
              <td>{t("common:total")}</td>
              <td></td>
              <td></td>
              <td>{formatCurrency(totalCost)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
