import { IGetProject200Response } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router";
import { getIcon } from "~/lib/ui";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";

export default function ProjectFinanceProductsPage() {
  const context = useOutletContext<{ entity: IGetProject200Response["data"] }>();
  const { t } = useTranslation();

  const tabs: Array<TabHeaderItem> = [
    {
      id: "delivered",
      label: t("projects:index.finance.delivered_product_spend"),
      to: "./",
      Icon: getIcon("product"),
    },
  ];

  if (context.entity?.isOffer) {
    tabs.push({
      id: "estimated",
      label: t("projects:index.finance.estimated_product_spend"),
      to: "offer",
      Icon: getIcon("offer"),
    });
  }

  tabs.push({
    id: "expenses",
    label: t("common:vendor_invoice", { count: 2 }),
    to: "expenses",
    Icon: getIcon("expense"),
  });

  return (
    <>
      <PageTabs tabs={tabs} preserveSearchParams />
      <Outlet context={context} />
    </>
  );
}
