import { Vendor } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { FilterGroup } from "./filter-group";
import { useVendors } from "~/lib/products/use-vendors";

export function FilterGroupVendors({
  onConfirmSelection,
  value,
  onClear,
  allowMultiple = true,
}: {
  value: Parameters<typeof FilterGroup>[0]["value"];
  onClear: Parameters<typeof FilterGroup>[0]["onClear"];
  onConfirmSelection: (selection: Array<Vendor>) => void;
  allowMultiple?: boolean;
}) {
  const { vendors, isFetching } = useVendors();
  const { t } = useTranslation();
  return (
    <FilterGroup
      name={t("common:vendor", { count: allowMultiple ? 2 : 1 })}
      isLoading={isFetching}
      value={value}
      selection={{
        items: vendors,
        valueFn: (item) => item.id,
        filterFn: (item, query) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        },
        allowMultiple,
        renderSelectionItem: (item) => <span>{item.name}</span>,
        onConfirmSelection: (selected) => {
          onConfirmSelection(selected);
        },
      }}
      onClear={onClear}
    />
  );
}
