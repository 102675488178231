import { FilterList } from "~/lib/ui/form-elements/filter-list";
import TextInput from "~/lib/ui/form-elements/text-input";
import ButtonLegacy from "~/lib/ui/buttons/button-legacy";
import { Popover } from "~/lib/ui/popover/popover";
import { UsersPriceGroup } from "@apacta/sdk";
import { ReactNode, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { formatCurrency } from "~/lib/utils/number";
import { twMerge } from "tailwind-merge";

type EmployeePopoverOptions = {
  multiselect: boolean;
};

export default function PriceGroupPopover({
  triggerRender,
  initialSelectedPriceGroups,
  onOpenChange,
  onSelect,
  options,
}: {
  triggerRender: () => ReactNode;
  initialSelectedPriceGroups?: Array<UsersPriceGroup>;
  onOpenChange?: (open: boolean) => void;
  onSelect?: (users: Array<UsersPriceGroup>) => void;
  options?: EmployeePopoverOptions;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const defaultEmployeePopoverOptions: EmployeePopoverOptions = {
    multiselect: false,
  };

  const opts = { ...defaultEmployeePopoverOptions, ...options };

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [groupSearchInput, setGroupSearchInput] = useState<string>("");

  const handleSetPriceGroups = (groups: Array<UsersPriceGroup>, closeCallback: () => void) => {
    onSelect?.(groups);
    closeCallback();
  };

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ["priceGroups", searchQuery],
    queryFn: () => api.usersPriceGroupsList({ q: searchQuery }),
  });

  useDebounce(
    () => {
      setSearchQuery(groupSearchInput);
    },
    [groupSearchInput],
    500
  );

  const priceGroups = data?.data || [];

  const handleOpenChange = (to: boolean) => {
    setGroupSearchInput("");
    onOpenChange?.(to);
  };

  return (
    <Popover
      onOpenChange={handleOpenChange}
      config={{ side: "right" }}
      triggerAsChild={true}
      triggerRender={() => triggerRender()}
    >
      {(close) => (
        <div className="flex h-96 min-w-[18em] flex-shrink-0 flex-col gap-2 overflow-hidden rounded-md border bg-white p-2 shadow-sm">
          <FilterList
            isLoading={isFetching}
            filterRender={() => (
              <div className="flex">
                <TextInput
                  id="employee-search"
                  label=""
                  placeholder={`${t("common:search")} ${t("common:price_group", {
                    count: 2,
                  }).toLowerCase()}`}
                  onChange={(value) => setGroupSearchInput(value)}
                />
              </div>
            )}
            itemRender={({ item, isSelected, triggerSelect }) => (
              <div
                key={`group-${item.id}`}
                className={twMerge(
                  "flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-2 hover:bg-gray-100"
                )}
                onClick={() =>
                  opts.multiselect ? triggerSelect() : handleSetPriceGroups([item], close)
                }
              >
                <div className="flex justify-between">
                  <span>{item.name}</span>
                </div>
                <div className="ml-4">
                  <span>{formatCurrency(item.costPrice ?? 0, { currency: "DKK" })}</span>
                </div>
              </div>
            )}
            buttonRender={(userList) => (
              <div className="flex items-center justify-end gap-6">
                <span
                  className="cursor-pointer justify-center underline hover:text-hover"
                  onClick={() => close()}
                >
                  {t("common:cancel")}
                </span>
                {opts.multiselect && (
                  <ButtonLegacy
                    variant="primary"
                    className="justify-center"
                    onClick={() => handleSetPriceGroups(userList, close)}
                  >
                    {t("common:confirm")}
                  </ButtonLegacy>
                )}
              </div>
            )}
            items={priceGroups}
            initialSelected={initialSelectedPriceGroups}
          />
        </div>
      )}
    </Popover>
  );
}
