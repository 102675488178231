import { JsonForms } from "@jsonforms/react";
import { ComponentProps, createContext, useContext } from "react";
import { renderers } from "../renderers";
import { vanillaCells } from "@jsonforms/vanilla-renderers";
import { createAjv } from "@jsonforms/core";
import { DEV } from "~/lib/auth/config";

type FormsContext = {
  mode: "create" | "edit";
};

type FormRendererProps = Pick<
  ComponentProps<typeof JsonForms>,
  "schema" | "uischema" | "data" | "onChange" | "additionalErrors"
> &
  FormsContext;

const ajv = createAjv({ useDefaults: true });
const FormsContext = createContext<FormsContext>({ mode: "create" });

// Wraps the JsonForms component with our renderers and defaults
// Note: Do not nest this component inside another FormRenderer
export function FormRenderer({
  schema,
  uischema,
  data,
  onChange,
  mode,
  additionalErrors,
}: FormRendererProps) {
  if (!schema) {
    console.warn("FormRenderer called without schema");
    return null;
  }

  // Ideally, we should warn the user when they have an invalid form.
  // But for now we'll warn the developers
  const isValidSchema = ajv.validate(schema, data);
  const hasAdditionalErrors = additionalErrors && additionalErrors.length > 0;
  const isValid = isValidSchema && !hasAdditionalErrors;

  return (
    <>
      {DEV && !isValid && (
        <div
          className="relative mb-4 rounded border border-orange-400 px-4 py-3 text-orange-700"
          role="alert"
        >
          <div className="mr-4 font-bold">Debug: Schema violations</div>
          <div className="mb-4 text-xs text-shade-600">
            Form will still be saved. This is only to help developers pin-point cases where API
            sends the incorrect data format.
          </div>

          {!isValidSchema && (
            <>
              <div>Schema errors</div>
              <ol className="list-decimal pl-4">
                {ajv.errors?.map((err, idx) => (
                  <li className="" key={idx}>
                    {`${err.instancePath}${err.schemaPath}`} &nbsp; "{err.message}"
                  </li>
                ))}
              </ol>
            </>
          )}
          {additionalErrors && additionalErrors.length > 0 && (
            <>
              <div>Additional Errors (backend)</div>
              <ol className="list-decimal pl-4">
                {additionalErrors?.map((err, idx) => (
                  <li className="" key={idx}>
                    {`${err.instancePath}${err.schemaPath}`} &nbsp; "{err.message}"
                  </li>
                ))}
              </ol>
            </>
          )}
        </div>
      )}
      <FormsContext.Provider value={{ mode }}>
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={renderers}
          cells={vanillaCells}
          onChange={onChange}
          ajv={ajv}
          additionalErrors={additionalErrors}
        />
      </FormsContext.Provider>
    </>
  );
}

export function useFormsContext() {
  const ctx = useContext(FormsContext);
  if (!ctx) {
    throw new Error("useFormsContext must be used within a FormRenderer");
  }
  return ctx;
}
