import { Feature, useFeatureFlags } from "../../lib/feature-flags";

const featureList = ["banana", "calendar"];

// Demoing how Feature flags work
export default function FeaturePage() {
  const features = useFeatureFlags();

  return (
    <div className="p-10">
      <h1>Feature Flag Demo</h1>
      <p>&nbsp;</p>
      <fieldset>
        <label>Feature test</label>
        <br />
        {features.has("calendar") ? (
          <p>I'm allowed to see calendar</p>
        ) : (
          <p>I'm not allowed to see calendar</p>
        )}
      </fieldset>
      <p>&nbsp;</p>
      <div className="grid grid-cols-2 gap-4">
        {featureList.map((feature) => (
          <div key={feature} className="rounded-md bg-white p-2">
            <h3>{feature} </h3>
            <Feature name={feature}>✅</Feature>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}
