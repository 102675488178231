import { ReactNode, Suspense } from "react";
import { Outlet } from "react-router";
import { ScrollRestoration } from "react-router-dom";
import { NavigationLayout } from "../../navigation/navigation-layout/navigation-layout";
import { PageSpinner } from "../page-spinner";

import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useApactaIntercom } from "~/lib/intercom/use-apacta-intercom";
import { useSegment } from "~/lib/segment/use-segment";
import { InterceptNavigation } from "./intercept-navigation";
import { DineroWarning } from "./dinero-warning";
import { UnsupportedBrowserWarning } from "./unsupported-browser-warning";
import { linkToCompanyFrontpage, linkToFormCreate, linkToProjects } from "~/lib/utils";

const ESCAPE_LAYOUT = ["/login", "/p/", "/fix-payment"];

export function Layout({ children }: { children?: ReactNode }) {
  useApactaIntercom(ESCAPE_LAYOUT);
  usePageTitle();
  useSegment();

  const location = window.location;

  if (ESCAPE_LAYOUT.some((l) => location.pathname.startsWith(l))) {
    return <InnerLayout>{children}</InnerLayout>;
  }

  return (
    <InterceptNavigation
      intercept={(ses) => {
        // Always allow logout
        if (window.location.pathname === "/logout") return null;
        // If not logged in, redirect to login
        if (ses.isLoggedIn === false) return "/login";
        // If missing payment, redirect to fix payment
        if (ses.me?.subscription?.missingPaymentMethod) return "/fix-payment";
        // If subscription isn't active, invite them to fix it
        if (ses.me?.subscription?.isActive === false) return "/companies/subscriptions/update";
        // Offers/Products only company: https://github.com/Apacta/issues/issues/540
        if (
          window.location.pathname === "/" &&
          ses.features.has("cp3.hack.redirect_frontpage_to_offers")
        )
          return "/offers";

        if (window.location.pathname === "/" && ses.me?.user.adminAccess === "access_to_admin") {
          return linkToCompanyFrontpage();
        }

        if (
          window.location.pathname === "/" &&
          ses.me?.user.adminAccess === "limited_access_to_admin"
        ) {
          return linkToProjects();
        }

        if (window.location.pathname === "/" && ses.me?.user.adminAccess === "no_access_to_admin") {
          return linkToFormCreate();
        }

        return null; // Allow navigation
      }}
    >
      <NavigationLayout>
        <DineroWarning />
        <UnsupportedBrowserWarning />
        <InnerLayout>
          <Suspense fallback={<PageSpinner />}>{children}</Suspense>
        </InnerLayout>
      </NavigationLayout>
    </InterceptNavigation>
  );
}

// Note: I don't fully understand WHY we need both outlet and children, but this is the only way this works.
// If we switch routers at some point. Take special care here.
function InnerLayout({ children }: { children?: ReactNode }) {
  return (
    <>
      <Outlet />
      {children}
      <ScrollRestoration />
    </>
  );
}
