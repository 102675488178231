// Purpose: Commonly used validation types, with strings
import { z } from "zod";

export function dkZip() {
  return z
    .string()
    .regex(/^\d{4}$/, { message: "Invalid zip code" })
    .optional()
    .or(z.literal(""));
}

export function ean() {
  return z
    .string()
    .min(13)
    .max(20)
    .regex(/^\d+$/, {
      message: "EAN numbers are numeric and between 13 and 20 characters long",
    })
    .or(z.literal(""))
    .optional();
}
