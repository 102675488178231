import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { TabHeader, TabHeaderItem, TabSize } from "~/lib/ui/tabs/tab-header";

export default function ViewTabs({
  tabs,
  children,
  defaultIndex = 0,
  shownIndex,
  showNavigation = true,
  onChange,
  tabSize = "medium",
  containerClass,
  tabClass,
}: {
  tabs: Array<TabHeaderItem>;
  children: JSX.Element | Array<JSX.Element>;
  defaultIndex?: number;
  shownIndex?: number | undefined;
  showNavigation?: boolean;
  onChange?: (tabIndex: number) => void;
  tabSize?: TabSize;
  containerClass?: string;
  tabClass?: string;
}) {
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);

  const childElements: Array<JSX.Element> = Array.isArray(children) ? children : [children];

  useEffect(() => {
    if (shownIndex !== undefined) {
      setSelectedIndex(shownIndex);
      onChange?.(shownIndex);
    }
  }, [shownIndex]);

  const handleSelectTab = (tab: TabHeaderItem) => {
    const idx = tabs.findIndex((t) => t.id === tab.id);
    setSelectedIndex(idx);
    onChange?.(idx);
  };

  return (
    <>
      {showNavigation && (
        <TabHeader
          selectedTab={tabs[selectedIndex]}
          tabSize={tabSize}
          tabs={tabs}
          onSelect={handleSelectTab}
        />
      )}
      <div className={twMerge(showNavigation && "mt-8", containerClass)}>
        {childElements.map((child, idx) => (
          <div
            key={`tab-${idx}`}
            className={twMerge(selectedIndex === idx ? "block" : "hidden", tabClass)}
          >
            {child}
          </div>
        ))}
      </div>
    </>
  );
}
