import { ReactNode } from "react";
import { Button, getIcon } from "~/lib/ui";
import { PageLayout } from "~/lib/ui/page-layout";

type ButtonProps = Parameters<typeof Button>[0];

const variants: Array<ButtonProps["variant"]> = [undefined, "primary", "secondary", "warning"];
const sizes = Array<ButtonProps["size"]>(undefined, "small", "medium");

export default function ButtonPlayground() {
  return (
    <PageLayout title="Button Playground">
      <div className="grid grid-cols-3 gap-4">
        {variants.map((variant) => (
          <Card key={variant}>
            <div>
              <h2 className="text-lg font-bold">{variant ?? "Default"}</h2>
              <div className="flex gap-2">
                {sizes.map((size) => (
                  <Button key={`normal-${size}`} variant={variant} size={size}>
                    Button
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-lg font-bold">{variant ?? "Default"}: disabled</h2>
              <div className="flex gap-2">
                {sizes.map((size) => (
                  <Button key={`disabled-${size}`} variant={variant} size={size} disabled>
                    Button
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-lg font-bold">{variant ?? "Default"}: icons</h2>
              <div className="flex gap-2">
                {sizes.map((size) => (
                  <Button
                    key={`icon-${size}`}
                    variant={variant}
                    size={size}
                    Icon={getIcon("offer")}
                  >
                    Button
                  </Button>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </div>
    </PageLayout>
  );
}

function Card({ children }: { children: ReactNode }) {
  return <div className="flex flex-col gap-2 rounded-md bg-white p-8 shadow-md">{children}</div>;
}
