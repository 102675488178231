export const getInitials = (v: string | undefined): string => {
  if (!v) return "";
  // cleanup non alphanumerics
  const cleanNames = v.replace(/[^\w\s]/gi, "");
  const names = cleanNames.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
