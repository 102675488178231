import { IGetProject200Response } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router";
import { getIcon } from "~/lib/ui";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";

export default function ProjectFinanceHoursPage() {
  const context = useOutletContext<{ entity: IGetProject200Response["data"] }>();
  const { t } = useTranslation();

  const tabs: Array<TabHeaderItem> = [
    {
      id: "specified",
      label: t("projects:index.finance.specified", "Specified"),
      to: "./",
      Icon: getIcon("details"),
    },
    {
      id: "sum",
      label: t("projects:index.finance.summarised", "Summarised"),
      to: "sum",
      Icon: getIcon("entityOverview"),
    },
  ];

  if (context.entity?.isOffer) {
    tabs.push({
      id: "estimated",
      label: t("projects:index.finance.estimated_hour_spend", "Hours from offer"),
      to: "offer",
      Icon: getIcon("offer"),
    });
  }

  return (
    <>
      <PageTabs tabs={tabs} preserveSearchParams />
      <Outlet context={context} />
    </>
  );
}
