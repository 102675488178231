import { Categorization, LayoutProps, RankedTester, rankWith, uiTypeIs } from "@jsonforms/core";
import { renderChildren } from "./renderChildren";

type BaseProps = Pick<LayoutProps, Exclude<keyof LayoutProps, "uischema">>;
interface Props extends BaseProps {
  uischema: Categorization;
}

export const CategoryRenderer = (props: Props) => {
  return (
    <div className="flex flex-col gap-4 rounded-md bg-white p-6 shadow-sm">
      {renderChildren({
        schema: props.schema,
        path: props.path,
        enabled: props.enabled,
        elements: props.uischema.elements,
      })}
    </div>
  );
};
export const CategoryTester: RankedTester = rankWith(100, uiTypeIs("Category"));
