import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Popover } from "~/lib/ui/popover/popover";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export type DataViewType = "contribution" | "delivered";

export default function DataViewChange({
  defaultView,
  onViewChange,
}: {
  defaultView?: DataViewType;
  onViewChange: (view: DataViewType) => void;
}) {
  const [view, setView] = useState<DataViewType>(defaultView || "contribution");
  const { t } = useTranslation();

  const handleViewChange = (v: DataViewType) => {
    setView(v);
    onViewChange(v);
  };

  return (
    <Popover
      config={{ align: "start" }}
      triggerRender={() => (
        <div className="flex w-fit items-center justify-between gap-4 rounded-md border border-gray-300 bg-white px-2 py-1 text-lg text-gray-700 shadow-sm transition-colors duration-200 hover:border-hover hover:text-hover">
          <span>{t(`frontpage:view.label.${view}`)}</span>
          <ChevronDownIcon className="h-5 w-5" />
        </div>
      )}
    >
      {(close) => (
        <div className="min-w-[10em] rounded-lg border bg-white p-2 text-base shadow-md">
          <ul>
            <li
              className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
              onClick={() => {
                handleViewChange("contribution");
                close();
              }}
            >
              {t("frontpage:view.label.contribution")}
            </li>
            <li
              className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
              onClick={() => {
                handleViewChange("delivered");
                close();
              }}
            >
              {t("frontpage:view.label.delivered")}
            </li>
          </ul>
        </div>
      )}
    </Popover>
  );
}
