import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { Contact, ContactPerson } from "@apacta/sdk";
import { useState } from "react";

export function useContacts(
  defaultContact?: Contact | null,
  defaultContactPerson?: ContactPerson | null
) {
  const api = useAPI();

  const [contactQuery, setContactQuery] = useState<string>("");
  const [contactPersonQuery, setContactPersonQuery] = useState<string>("");

  const [contact, setContact] = useState<Contact | null | undefined>(defaultContact || null);
  const [contactPerson, setContactPerson] = useState<ContactPerson | null | undefined>(
    defaultContactPerson || null
  );

  const contactQ = useQuery({
    queryKey: ["getContacts", contactQuery],
    queryFn: () => api.getContacts({ q: contactQuery }),
  });

  const contactPersonQ = useQuery({
    queryKey: ["getContactPersons", contact, contactPersonQuery],
    queryFn: () => contact && api.getContactPersonsList({ contactId: contact.id as string }),
    enabled: !!contact,
  });

  const getContactById = (id: string): Promise<Contact | null> => {
    return api.getContact({ contactId: id }).then((res) => res.data);
  };

  return {
    contact,
    contactPerson,

    contacts: contactQ.data?.data || [],
    contactPersons: contactPersonQ.data?.data || [],

    contactQuery,
    contactPersonQuery,

    setContactQuery,
    setContactPersonQuery,
    setContact,
    setContactPerson,
    getContactById,

    isFetchingContacts: contactQ.isFetching,
    isFetchingContactPersons: contactPersonQ.isFetching,
  };
}
