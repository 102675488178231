import { formatCurrency } from "~/lib/utils/number";
import needle from "./needle.svg";
import { twMerge } from "tailwind-merge";
import { ReactNode } from "react";

type SupportedUnits = "currency" | "percent";

type GaugePercentProps = {
  value: number;
  unit: "percent";
  max?: never;
};

type GaugeFlexibleValueProps = {
  unit: "currency";
  max: number;
};

export type GaugeProps = (GaugeFlexibleValueProps | GaugePercentProps) & {
  value: number;
  renderValue?: (value: number) => ReactNode;
  areas?: Array<{ className: string; value: number }>;
};

// Simple SVG gauge component
export function GaugeSVG({ value, renderValue, unit = "percent", max, areas = [] }: GaugeProps) {
  const valueLabel = value;
  if (unit === "currency") {
    if (max === undefined) {
      throw new Error("Missing max");
    }
    if (value > max) value = max;
  }
  const radius = 48;
  const strokeWidth = 14;
  const strokeWidthOutline = 15;
  const innerRadius = radius - strokeWidth / 2;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * 0.5;
  const dashArray = `${arc} ${circumference}`;
  const cx = 50;
  const cy = 50;
  const transform = `rotate(180, ${cx}, ${cy})`;
  const vr = valueRatio({ value, unit, max });
  const needleTransform = `rotate(${percentToDegrees(vr * 100)}, 50, 50)`;

  const sortedAreas = areas.sort((a, b) => b.value - a.value);

  return (
    <div className="flex flex-col items-center gap-1">
      <svg viewBox="0 0 100 55" className="relative w-full">
        <circle
          cx={cx}
          cy={cy}
          fill="transparent"
          r={innerRadius}
          className="stroke-gray-200"
          strokeWidth={strokeWidthOutline}
          strokeDasharray={dashArray}
          transform={transform}
        />

        {sortedAreas.map((area, idx) => {
          const vRatio = valueRatio({ unit, value: area.value, max });
          const dashStart = arc * vRatio;
          const dashStop = circumference;

          const areaDashArray = `${dashStart} ${dashStop}`;
          const dashOffset = 0; // Stroke areas overlap, but we sort to work around it.

          return (
            <g key={idx}>
              <circle
                key={idx}
                cx={cx}
                cy={cy}
                fill="transparent"
                className={twMerge("transition ease-in-out", area.className)}
                r={innerRadius}
                strokeDasharray={areaDashArray}
                strokeDashoffset={dashOffset}
                strokeWidth={strokeWidth}
                transform={transform}
              ></circle>
            </g>
          );
        })}
        <image
          href={needle}
          y={cy - 34}
          x={cx - 17.5}
          transform={needleTransform}
          style={{
            width: 35,
            height: 35,
          }}
        />
      </svg>
      <span className="text-gray-400">
        {renderValue ? renderValue(valueLabel) : <FormatValue unit={unit} value={valueLabel} />}
      </span>
    </div>
  );
}
function percentToDegrees(percent: number) {
  const degrees = -90;
  const inc = 1.8;
  return degrees + percent * inc;
}

function FormatValue({ value, unit }: { value: number; unit: SupportedUnits }) {
  switch (unit) {
    case "currency":
      return <>{formatCurrency(value, { maximumFractionDigits: 0 })}</>;
    case "percent":
      return <>{`${Math.round(value)}%`}</>;
  }
  return <>{value}</>;
}

function valueRatio({ value, unit, max }: { value: number; unit: SupportedUnits; max?: number }) {
  if (unit === "percent") {
    return value / 100;
  }
  if (unit === "currency" && max !== undefined) {
    if (value > max) return max;
    return value / max;
  }
  throw new Error("Unsupported unit");
}
