import { useTranslation } from "react-i18next";
import { formatCurrency } from "~/lib/utils/number";
import { SupportedUnits } from "./kpi-data-card";
import { useLocale } from "~/lib/utils/date";

export function FormatValue({
  value,
  unit,
  decimals = 0,
}: {
  value?: number;
  unit?: SupportedUnits;
  decimals?: number;
}) {
  const { t } = useTranslation();
  const { formatHours } = useLocale();

  if (value === undefined || value === null) return <span>"N/A"</span>;
  switch (unit) {
    case "distance":
      return <span>{`${value.toFixed(1)} ${t("common:km", { count: 2 })}`}</span>;
    case "hours":
      return <span>{formatHours(value)}</span>;
    case "fancy_hours":
      return <span>{formatHours(value, { localeStringFormat: true })}</span>;
    case "number":
      return <>{`${value}`}</>;
    case "currency":
      return <span>{formatCurrency(value, { maximumFractionDigits: decimals })}</span>;
    case "percent":
      return <span>{`${value.toFixed(1)}%`}</span>;
    default:
      console.warn(`Unsupported unit: ${unit}`);
      return <></>;
  }
}
