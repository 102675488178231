import { Outlet } from "react-router";
import { PageLayout } from "~/lib/ui/page-layout";
import PageTabs from "~/lib/ui/tabs/page-tabs";

export default function PlaygroundTables() {
  return (
    <PageLayout title="Table playground">
      <PageTabs
        tabs={[
          { label: "CustomTable", id: "index", to: "./" },
          { label: "Client only", id: "client", to: "client" },
        ]}
      />
      <Outlet />
    </PageLayout>
  );
}
