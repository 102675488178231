export const deepEqual = (
  object1: { [key: string]: unknown },
  object2: { [key: string]: unknown },
  ignoreOrder = false
): boolean => {
  if (!isObject(object1) || !isObject(object2)) return false;
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  if (ignoreOrder) {
    keys1.sort();
    keys2.sort();
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    const objVal1: { [key: string]: unknown } = val1 as { [key: string]: unknown };
    const objVal2: { [key: string]: unknown } = val2 as { [key: string]: unknown };
    if ((areObjects && !deepEqual(objVal1, objVal2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};
export function isObject(object: unknown): boolean {
  return object != null && typeof object === "object";
}
