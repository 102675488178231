import { forwardRef, Ref } from "react";
import { twMerge } from "tailwind-merge";

export type InputProps = React.HTMLProps<HTMLInputElement>;

export const baseClasses = [
  "text-sm w-full border h-10 rounded-md shadow-sm border-gray-300 text-gray-700",
  "focus:border-hover focus:outline-none transition-colors focus:ring-transparent",
  "disabled:bg-gray-100",
  "invalid:text-error invalid:outline-1 invalid:outline-error invalid:outline",
];

export const Input = forwardRef(function InputInner(props: InputProps, ref: Ref<HTMLInputElement>) {
  const { className, ...restProps } = props;
  return <input {...restProps} className={twMerge(baseClasses, className)} ref={ref} />;
});
