import { Spinner } from "~/lib/ui/spinner";
import { formatCurrency } from "~/lib/utils/number";
import { useAPI } from "~/lib/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Product } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { Dialog } from "~/lib/ui";
import { CreateProductDialog } from "~/pages/products/_cmp/create-product-dialog";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function ProductSection({
  searchQuery,
  onSelect,
  showBuyingPrice = false,
  showSellingPrice = false,
  showGrossPrice = false,
}: {
  searchQuery: string;
  onSelect: (item: Product) => void;
  showBuyingPrice?: boolean;
  showSellingPrice?: boolean;
  showGrossPrice?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const productListQuery = useQuery({
    queryKey: ["productList", searchQuery],
    queryFn: () =>
      api.productsList({
        q: searchQuery,
      }),
    placeholderData: keepPreviousData,
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });

  if (productListQuery.isFetching) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2 divide-y pr-1">
      {productListQuery.data?.data.map((product) => (
        <div key={`${product.id}`} onClick={() => onSelect(product)} className="pt-2">
          <div className="flex cursor-pointer flex-col gap-2 rounded-lg p-2 hover:bg-shade-100">
            <span>{product.name}</span>
            <div className="flex w-full justify-between text-sm text-shade-400">
              {showBuyingPrice && (
                <div>
                  {t("common:cost_price")}: {formatCurrency(product.buyingPrice ?? 0)}
                </div>
              )}
              {showSellingPrice && (
                <div className="text-center">
                  {t("common:sales_price")}: {formatCurrency(product.sellingPrice ?? 0)}
                </div>
              )}
              {showGrossPrice && (
                <div className="text-right">
                  {t("common:gross_price")}: {formatCurrency(product.grossPrice ?? 0)}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <Dialog
        trigger={
          <div className="pt-2">
            <div className="flex cursor-pointer items-center gap-2 rounded-lg p-2 hover:bg-shade-100">
              <PlusIcon className="h-5 w-5" />
              <span>
                {t("common:create", { entity: t("common:product", { count: 1 }).toLowerCase() })}
              </span>
              {searchQuery && (
                <span>
                  "<span className="font-semibold">{searchQuery}</span>"
                </span>
              )}
            </div>
          </div>
        }
        render={({ onClose }) => (
          <CreateProductDialog
            initialName={searchQuery}
            skipNavigate={true}
            onProductCreated={async () => {
              await productListQuery.refetch();
              onClose();
            }}
          />
        )}
      />
    </div>
  );
}
