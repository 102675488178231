import { DefaultApiInterface, GetMe200Response, GetMe200ResponseFeaturesEnum } from "@apacta/sdk";
import { createContext, useContext } from "react";
import { LanguageDefinition } from "../i18n/i18n";

export const SessionContext = createContext<SessionContextState | undefined>(undefined);

export type FeaturesType = GetMe200ResponseFeaturesEnum;

export interface SessionContextState {
  api: DefaultApiInterface;
  apiKey?: string;
  scope?: string;
  isLoggedIn: boolean | null;
  me?: GetMe200Response;
  features: Set<FeaturesType>;
  // Methods
  loginWithAPIKey: (newAPIKey: string) => void;
  logout: () => void;
  setLanguage: (lang: LanguageDefinition) => Promise<void>;
}
// Note that ANY changes to the context state will result in a complete re-render of the application!
// - Try not to add anything unnecessary in here

export function useSession() {
  const state = useContext(SessionContext);
  if (state === undefined) {
    throw new Error(
      "SessionContext undefined. Did you remember to wrap the app in SessionContextProvider?"
    );
  }
  return state;
}
