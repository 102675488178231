import { Activity } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { FilterGroup } from "./filter-group";
import { useActivities } from "~/lib/activities/use-activities";

export function FilterGroupActivities({
  onConfirmSelection,
  value,
  onClear,
}: {
  value: Parameters<typeof FilterGroup>[0]["value"];
  onClear: Parameters<typeof FilterGroup>[0]["onClear"];
  onConfirmSelection: (selection: Array<Activity>) => void;
}) {
  const { t } = useTranslation();
  const { statuses, isFetching } = useActivities();
  return (
    <FilterGroup
      name={t("common:activity", { count: 2 })}
      value={value}
      isLoading={isFetching}
      onClear={onClear}
      selection={{
        items: statuses,
        valueFn: (item) => item.id,
        allowMultiple: true,
        renderSelectionItem: (item) => item.name,
        onConfirmSelection: (selected) => onConfirmSelection(selected),
      }}
    />
  );
}
