export function formatCurrency(value: number, options?: Intl.NumberFormatOptions): string {
  const defaultOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "DKK",
    currencyDisplay: "narrowSymbol",
  };
  try {
    return new Intl.NumberFormat("da-DK", { ...defaultOptions, ...options }).format(value);
  } catch (err) {
    // Safari <14.1 doesn't support narrowSymbol
    return new Intl.NumberFormat("da-DK", {
      ...defaultOptions,
      currencyDisplay: "symbol",
      ...options,
    }).format(value);
  }
}

export function formatDecimals(value: number, decimals = 2, locale?: string) {
  return new Intl.NumberFormat(locale ?? "da-DK", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
}

export function roundToDecimal(value: number, decimals = 2) {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}
