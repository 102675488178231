import { FilterGroup } from "./filter-group";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { Customer } from "~/lib/customer/types";
import { useDebouncedValue } from "~/lib/debounce/use-debounce";

export function FilterGroupCustomers(props: {
  value: Parameters<typeof FilterGroup>[0]["value"];
  onClear: Parameters<typeof FilterGroup>[0]["onClear"];
  onConfirmSelection: (selection: Array<Customer>) => void;
  allowMultiple?: boolean;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const [query, { setValue: setQuery, isPending: queryPending }] = useDebouncedValue("");
  //const [query, setQuery] = useState("");

  const q = useQuery({
    queryKey: ["customersFilter", query],
    queryFn: () =>
      api.getContacts({
        q: query,
      }),
    enabled: !queryPending,
  });

  const items = q.data?.data ?? [];

  function handleClear() {
    setQuery("");
    props.onClear?.();
  }

  return (
    <>
      <FilterGroup
        name={t("common:customer", { count: 2 })}
        isLoading={q.isPending}
        value={props.value}
        selection={{
          items: items,
          valueFn: (item) => item.id,
          allowMultiple: props.allowMultiple,
          renderSelectionItem: (item) => <span>{item.name}</span>,
          onConfirmSelection: (selected) => {
            props.onConfirmSelection(selected);
          },
          onQueryChange: (queryStr) => {
            setQuery(queryStr);
          },
        }}
        onClear={handleClear}
      />
    </>
  );
}
