import { IntegrationSetting } from "@apacta/sdk";
import React, { useEffect, useState } from "react";
import TextInput from "~/lib/ui/form-elements/text-input";

export function IntegrationSettingInput({
  onChange,
  integrationSetting,
  initialIntegrationValue,
}: {
  onChange: (value: string | null) => void;
  integrationSetting: IntegrationSetting;
  initialIntegrationValue?: string;
}) {
  const [settingValue, setSettingValue] = useState<string | null>("");
  const integrationSettingId = integrationSetting?.users?.[0]?.joinData?.integrationSettingId;
  useEffect(() => {
    onChange?.(settingValue);
  }, [settingValue]);

  return (
    <>
      {integrationSetting !== undefined && (
        <>
          <TextInput
            onChange={(value) => setSettingValue(value)}
            key={integrationSettingId}
            label={integrationSetting?.name}
            id={integrationSettingId}
            defaultValue={initialIntegrationValue}
          />
          <div className="text-sm-left">{integrationSetting.description}</div>
        </>
      )}
    </>
  );
}
