import { ReactNode } from "react";
import { PageLayout } from "~/lib/ui/page-layout";
import { SkeletonLoader, templates } from "~/lib/ui/skeleton";

export default function SkeletonLoaders() {
  return (
    <PageLayout title="Skeleton Loaders">
      <div className="flex flex-col flex-wrap gap-4">
        {Object.keys(templates).map((template) => (
          <Wrapper key={template} title={template}>
            <SkeletonLoader template={template as keyof typeof templates} className="" />
          </Wrapper>
        ))}
      </div>
    </PageLayout>
  );
}

function Wrapper(props: { title: string; children: ReactNode }) {
  return (
    <div className="border p-4">
      <h3>{props.title}</h3>
      {props.children}
    </div>
  );
}
