import { KPIBetaCard } from "../_cmp/kpi-beta-card";
import { useTranslation } from "react-i18next";
import { getIcon } from "~/lib/ui";
import { useLocale } from "~/lib/utils/date";

export function HourConsumptionCardVariable({
  costPrice = 0,
  salesPrice = 0,
  hoursDelivered = 0,
}: {
  costPrice?: number;
  salesPrice?: number;
  hoursDelivered?: number;
}) {
  const { t } = useTranslation();
  const {} = useLocale();
  return (
    <>
      <KPIBetaCard
        Icon={getIcon("hours")}
        label={t("common:work_hour_usage", "Hours")}
        href="./finance/hours"
        unit="currency"
        estimated={{
          label: t("finance:sales_price"),
          value: salesPrice,
        }}
        current={{
          label: t("finance:cost_price"),
          className: "bg-gray-300 text-black",
          value: costPrice,
        }}
        remainder={{
          label: t("finance:contribution", "Contribution"),
          className: "bg-green-600 text-white",
        }}
        barClasses={{
          primary: "bg-gray-300",
          remainder: "bg-green-600",
        }}
        extraUnits={[
          {
            unit: "hours",
            rightLabel: t("finance:hours_delivered", "Hours delivered"),
            rightValue: hoursDelivered,
          },
        ]}
      />
    </>
  );
}
