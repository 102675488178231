import { EffectCallback, useEffect } from "react";

type Destructor = Exclude<ReturnType<EffectCallback>, void>;
type UseMountCallback = (signal: AbortController["signal"]) => Destructor | void;
/**
 * An explicit way of adding a on-mount callback. More readable than empty dependency arrays.
 *
 * Longer explanation. This functions like useEffect with an empty dependency array with one notable exception.
 * It generates an AbortController and passes the signal to your callback.
 *
 * React 18 has this annoying "feature" that runs useEffect twice before rendering. This allows us to cancel the API calls on abort.
 *
 * Example:
 *
 * useMount((signal) => {
 *   api.getFeatures({ signal }).then(res => {
 *     setFeatures(res.data.data);
 *   })
 * })
 *
 * @param fn Effect Callback
 */
export function useMount(fn: UseMountCallback) {
  useEffect(() => {
    const abortController = new AbortController();
    const cleanup = fn(abortController.signal);
    return () => {
      cleanup?.();
      abortController.abort();
    };
  }, []);
}
