import { ApexOptions } from "apexcharts";
import colors from "tailwindcss/colors";
import { renderToString } from "react-dom/server";

/**
 * The options for the total formatter
 */
export type TotalFormatterOptions = {
  dataPointIndex: number;
  seriesIndex: number;
  globals: {
    seriesNames: Array<string>;
    labels: Array<string>;
    seriesLog: Array<Array<number>>;
  };
};

/**
 * The data format for the total formatter
 */
export type TotalFormatter = {
  dataPoint: string;
  dataSeries: Array<{ [key: string]: number }>;
};

/**
 * Method to create the options for a stacked bar chart
 * @param XAxisLabels - The x-axis labels
 * @param barColors - The colors of the bars in the same order as the data
 * @param yAxisFormatter - The formatter for the y-axis labels
 * @param legendFormatter - The formatter for the legend labels
 * @param totalFormatter - The formatter for the total label
 * @param hasNegativeValues - Whether the chart has negative values, used to add an annotation to the y-axis at y = 0
 */
export const stackedBarChartOptions = (
  XAxisLabels: Array<string>,
  barColors: Array<string>,
  yAxisFormatter?: (value: number) => string,
  legendFormatter?: (value: string) => string,
  totalFormatter?: (values: TotalFormatter) => string,
  hasNegativeValues?: boolean
): Partial<ApexOptions> => {
  const handleFormatTotal = (opts: TotalFormatterOptions): string => {
    const seriesData = opts.globals.seriesNames.map((name, index) => {
      return {
        [name]: opts.globals.seriesLog[index][opts.dataPointIndex],
      };
    });

    const cbData = {
      dataPoint: opts.globals.labels[opts.dataPointIndex],
      dataSeries: seriesData,
    };

    return totalFormatter ? totalFormatter(cbData) : "";
  };

  /**
   * If the chart has negative values, we need to add an annotation to the y-axis
   * to accentuate the zero line in the chart
   */
  const annotations = hasNegativeValues
    ? {
        yaxis: [
          {
            width: "100%",
            borderColor: colors["zinc"][700],
            y: 0,
            borderWidth: 1,
            strokeDashArray: 0,
          },
        ],
      }
    : undefined;

  return {
    chart: {
      // ApexCharts options
      type: "bar",
      height: "100%",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    annotations,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: "50%", // relative to available space, see responsive options
        dataLabels: {
          position: "top",
          total: {
            enabled: true,
            formatter(val: string, opts: TotalFormatterOptions): string {
              return handleFormatTotal(opts) ?? val ?? "";
            },
          },
        },
      },
    },
    xaxis: {
      type: "category",
      categories: [...new Set(XAxisLabels)], // Set() removes duplicates
    },
    tooltip: {
      // custom tooltip, as the default one is not very good
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const x = w.globals.labels[dataPointIndex];
        const s = w.config.series[seriesIndex].name;
        const c = w.globals.colors[seriesIndex];
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return renderToString(
          <div>
            <div className="bg-gray-200 px-2 py-1 font-semibold">{x}</div>
            <div className="flex items-center gap-2 p-2">
              <div>{legendFormatter?.(s) ?? s}</div>
              <div className="rounded-lg px-2 py-1 text-white" style={{ backgroundColor: c }}>
                {yAxisFormatter ? yAxisFormatter(data) : data}
              </div>
            </div>
          </div>
        );
      },
    },
    yaxis: {
      labels: {
        formatter: yAxisFormatter,
      },
    },
    colors: barColors,
    legend: {
      position: "top",
      inverseOrder: true,
      itemMargin: {
        horizontal: 20,
      },
      formatter(legendName: string, opts?: any): string {
        return legendFormatter ? legendFormatter(legendName) : legendName;
      },
      offsetY: 10,
    },
    dataLabels: {
      // hide the data labels on the individual bars
      enabled: false,
    },
  };
};
