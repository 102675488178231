import { HTMLProps, Ref, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

type LabelProps = HTMLProps<HTMLLabelElement> & { required?: boolean };

/**
 * DOM label with an optional requirement indicator
 */
export const Label = forwardRef(function LabelInner(props: LabelProps, ref: Ref<HTMLLabelElement>) {
  const { children, required, className, ...restProps } = props;
  const BASE_CLASS = "text-left block text-sm font-medium text-gray-700 mb-1";

  return (
    <label className={twMerge(BASE_CLASS, className)} {...restProps} ref={ref}>
      {children} {required && <span className="text-error">*</span>}
    </label>
  );
});
