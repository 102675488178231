import { useContext, useState } from "react";
import { Toast } from "../ui/toast";
import { ToastContext } from "./context";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export function ToastView() {
  const { t } = useTranslation();
  const { items, remove } = useContext(ToastContext);
  const [pause, setPause] = useState<boolean>(false);

  // Pause all toasts to avoid losing hover state if previous toast times out
  const handlePause = (v: boolean): void => {
    if (v) {
      setPause(true);
      return;
    }
    setPause(false);
    return;
  };

  return (
    <div
      aria-live="assertive"
      className={twMerge(
        "pointer-events-none fixed bottom-8 z-50 flex w-full flex-col items-end gap-4 sm:right-8 sm:top-8 sm:items-start",
        items.length === 0 && "hidden" // array can exist, but be empty
      )}
    >
      {items.map((item) => (
        <Toast
          key={item.id}
          title={item.title}
          description={item.description}
          Icon={item.Icon}
          variant={item.variant}
          renderActions={item.renderActions}
          paused={pause}
          onDestroy={() => remove(item)}
          onPause={(v: boolean) => handlePause(v)}
          timeout={item.timeout}
        />
      ))}
    </div>
  );
}
