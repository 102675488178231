import { useContext } from "react";
import { OrderLinesBuilderContext } from "~/lib/ui/order-lines/order-lines-builder-context";

export function useOrderLinesBuilder() {
  const ctx = useContext(OrderLinesBuilderContext);
  if (!ctx) {
    throw new Error("useFinanceBuilder must be used within a FinanceBuilderContextProvider");
  }

  return useContext(OrderLinesBuilderContext);
}
