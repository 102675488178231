import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

/**
 * A component that renders a divider with text or ReactNode in the middle
 * @param lineColor
 * @param children
 */
export function HorizontalDivider({
  children,
  lineClassName,
  lineRightWrapperClassName,
  lineLeftWrapperClassName,
}: {
  children: ReactNode;
  lineClassName: string;
  lineRightWrapperClassName?: string;
  lineLeftWrapperClassName?: string;
}) {
  return (
    <div className="flex items-center gap-4">
      <div className={twMerge("w-full", lineLeftWrapperClassName)}>
        <hr className={twMerge("border-t-1", lineClassName)} />
      </div>
      {children}
      <div className={twMerge("w-full", lineRightWrapperClassName)}>
        <hr className={twMerge("border-t-1", lineClassName)} />
      </div>
    </div>
  );
}
