import { twMerge } from "tailwind-merge";
import { IconProp } from "../types";
import { baseItemClass, labelClass } from "./shared";
import * as RadixDropdown from "@radix-ui/react-dropdown-menu";

type PrimitiveProps = Parameters<typeof RadixDropdown.RadioGroup>[0];
type RadioItemType = { label: string; value: string };

type Props = {
  label?: string;
  value?: string; // If this matches an item value, it's selected
  className?: string;
  Icon?: IconProp;
  items: Array<RadioItemType>;
  primitive?: PrimitiveProps;
  defaultIndex?: number;
  preventClosing?: boolean;
  onValueChange: (newValue: string) => void;
};

export function DropdownItemRadio(props: Props) {
  return (
    <RadixDropdown.RadioGroup
      value={props.value}
      {...props.primitive}
      onValueChange={props.onValueChange}
    >
      {props.label ? (
        <RadixDropdown.Label className={labelClass}>{props.label}</RadixDropdown.Label>
      ) : null}
      {props.items.map((item, idx) => (
        <RadixDropdown.RadioItem
          key={idx}
          value={item.value}
          className={twMerge(baseItemClass, props.className)}
          asChild={false}
          onSelect={(e) => props.preventClosing && e.preventDefault()}
        >
          <RadioItemView
            className=""
            label={item.label}
            value={item.value}
            selected={item.value === props.value}
          />
        </RadixDropdown.RadioItem>
      ))}
    </RadixDropdown.RadioGroup>
  );
}

function RadioItemView({
  label,
  className,
  selected,
}: RadioItemType & { className?: string; selected: boolean }) {
  return (
    <div className={className}>
      <div className="flex flex-row items-center justify-between gap-2">
        {label}
        <div className="mx-3 flex h-5 w-5 items-center justify-center rounded-full border border-zinc-700 sm:group-hover:border-white">
          {selected ? (
            <div className="h-3 w-3 rounded-full bg-zinc-700 sm:group-hover:bg-white" />
          ) : null}
        </div>
      </div>
    </div>
  );
}
