import { useMemo } from "react";
import { useSession } from "./session";

export function useRoles() {
  const { me } = useSession();

  // This cannot change, unless the page is reloaded
  const roles = useMemo(() => {
    let roleSet = new Set<string>();
    if (me?.user.roles) {
      roleSet = new Set(me.user.roles.map((role) => role.identifier));
    }
    return roleSet;
  }, []);

  return roles;
}
