import { LabelEntity } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { FilterGroup } from "./filter-group";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import { LabelColor } from "~/lib/utils/colors";
import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";

export function FilterGroupLabels({
  onConfirmSelection,
  value,
  onClear,
}: {
  value: Parameters<typeof FilterGroup>[0]["value"];
  onClear: Parameters<typeof FilterGroup>[0]["onClear"];
  onConfirmSelection: (selection: Array<LabelEntity>) => void;
}) {
  const { t } = useTranslation();
  const { labels, isLoadingLabels } = useCategoryLabels("employee");
  return (
    <FilterGroup
      name={t("common:label", { count: 2 })}
      value={value}
      isLoading={isLoadingLabels}
      onClear={onClear}
      selection={{
        items: labels,
        valueFn: (item: LabelEntity) => item.id,
        allowMultiple: true,
        renderSelectionItem: (label: LabelEntity) => (
          <UICategoryLabel
            id={label.id}
            key={label.id}
            readonly
            text={label.text ?? ""}
            bgColor={label.bgColor as LabelColor}
          />
        ),
        onConfirmSelection: (selected) => onConfirmSelection(selected),
      }}
    />
  );
}
