import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function MobileNavigationTopBar({ openSideBar }: { openSideBar: () => void }) {
  const { t } = useTranslation();

  return (
    <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow md:hidden print:hidden">
      <button
        type="button"
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        onClick={() => openSideBar()}
      >
        <span className="sr-only">{t("ui:navigation-topbar.sr-open-sidebar")}</span>
        <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
}
