import { Dropdown } from "~/lib/ui/dropdown";
import { Button, Icon } from "~/lib/ui";
import { useLocale } from "~/lib/utils/date";
import { useId, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Radio } from "~/lib/ui/form-elements";
import { Popover } from "~/lib/ui/popover/popover";
import Calendar from "~/lib/ui/calendar/calendar";
import { useTranslation } from "react-i18next";

export type RepetitionType = "day" | "week" | "month" | "year";

export default function RepetitionDialog({
  onSubmit,
  onClose,
  defaultRepeat,
  defaultInterval,
  defaultEndTime,
}: {
  onSubmit: ({
    repeat,
    interval,
    endTime,
  }: {
    repeat?: RepetitionType;
    interval?: number;
    endTime?: Date;
  }) => void;
  onClose: () => void;
  defaultRepeat?: RepetitionType;
  defaultInterval?: number;
  defaultEndTime?: Date | null;
}) {
  const { t } = useTranslation();
  const { dayName, format, stringToDate } = useLocale();

  const [selectedEndDate, setSelectedEndDate] = useState<Date>(defaultEndTime ?? new Date());
  const [endDateError, setEndDateError] = useState<boolean>(false);

  const [interval, setInterval] = useState<number | undefined>(defaultInterval);
  const [selectedRepetition, setSelectedRepetition] = useState<RepetitionType>(
    defaultRepeat ?? "week"
  );

  const endDateRef = useRef<HTMLInputElement | null>(null);
  const untilRef = useRef<HTMLInputElement | null>(null);

  const handleDateChange = (value: Date | string) => {
    let date: Date | null;
    if (typeof value === "string") {
      date = stringToDate(value, { shortDate: true, excludeTime: true });
    } else {
      date = new Date(value);
    }

    if (date && !isNaN(date.getTime())) {
      setEndDateError(false);
      setSelectedEndDate(date);
      if (endDateRef.current) {
        endDateRef.current.value = format(date, { shortDate: true, excludeTime: true });
      }
    } else {
      setEndDateError(true);
    }
  };

  const handleSubmit = () => {
    if (untilRef.current && untilRef.current.checked && !endDateError) {
      onSubmit({ repeat: selectedRepetition, interval, endTime: selectedEndDate });
    } else {
      onSubmit({ repeat: selectedRepetition, interval, endTime: undefined });
    }
  };

  const countInputId = useId();
  const neverRepeatId = useId();

  const handleDateInputFocus = () => {
    if (untilRef.current) {
      untilRef.current.checked = true;
    }
  };

  return (
    <div>
      <div className="text-sm font-semibold">{t("planning:custom_repeat")}</div>
      <div className="flex flex-col gap-8 py-4 ">
        <div className="flex w-fit cursor-pointer items-center border-b-2 border-b-transparent text-sm focus-within:cursor-auto focus-within:border-b-hover focus-within:bg-shade-100">
          <label htmlFor={countInputId} className="cursor-pointer px-1">
            {t("planning:repeat_every")}
          </label>
          <input
            id={countInputId}
            type="number"
            placeholder="0"
            min={0}
            defaultValue={interval}
            onChange={(e) => setInterval(Number(e.currentTarget.value))}
            className="hide-arrow w-20 cursor-pointer border-0 bg-transparent px-2 text-right text-sm font-semibold focus:cursor-auto focus:outline-none focus:ring-transparent"
          />
          <Dropdown
            options={{
              align: {
                align: "start",
                alignOffset: 0,
              },
            }}
            trigger={
              <div className="flex cursor-pointer items-center gap-2 px-2 py-1 text-sm">
                <span>{t(`common:${selectedRepetition}`, { count: interval }).toLowerCase()}</span>
                <Icon name="chevronDown" className="h-3 w-3" />
              </div>
            }
          >
            <Dropdown.Item onSelect={() => setSelectedRepetition("day")} className="text-sm">
              {t(`common:day`, { count: interval }).toLowerCase()}
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => setSelectedRepetition("week")} className="text-sm">
              {t(`common:week`, { count: interval }).toLowerCase()}
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => setSelectedRepetition("month")} className="text-sm">
              {t(`common:month`, { count: interval }).toLowerCase()}
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => setSelectedRepetition("year")} className="text-sm">
              {t(`common:year`, { count: interval }).toLowerCase()}
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div className="flex flex-col gap-2 px-1 text-sm">
          <div className="">{t("planning:ends")}</div>
          <div className="flex items-center gap-1">
            <div>
              <Radio
                name="rule-end"
                id={neverRepeatId}
                className="cursor-pointer"
                defaultChecked={true}
              />
            </div>
            <label htmlFor={neverRepeatId} className="py-2">
              {t("common:never")}
            </label>
          </div>
          <div className="">
            <div className="flex items-center gap-1">
              <div>
                <Radio
                  name="rule-end"
                  defaultChecked={!!defaultEndTime}
                  ref={untilRef}
                  className="mr-1 cursor-pointer"
                />
              </div>

              <label
                className={twMerge(
                  "flex cursor-pointer items-center border-b-2 px-2 focus-within:bg-shade-100",
                  endDateError
                    ? "border-b-red-500"
                    : "border-b-transparent focus-within:border-b-hover"
                )}
              >
                <div className="w-20 cursor-pointer">{t("common:until")}</div>
                <input
                  ref={endDateRef}
                  onFocus={handleDateInputFocus}
                  defaultValue={format(selectedEndDate, {
                    shortDate: true,
                    excludeTime: true,
                  })}
                  onChange={(e) => handleDateChange(e.currentTarget.value)}
                  className="w-20 cursor-pointer border-0 bg-transparent px-1 text-center text-sm focus:outline-none focus:ring-transparent"
                />
                <Popover
                  triggerAsChild={true}
                  config={{ align: "center", side: "right" }}
                  onOpenChange={(open) =>
                    untilRef.current ? (untilRef.current.checked = true) : null
                  }
                  triggerRender={() => (
                    <button className="flex grow items-center justify-center py-2 pl-1 focus:bg-shade-200">
                      <Icon name="calendarSolid" size="small" />
                    </button>
                  )}
                >
                  {(close) => (
                    <Calendar
                      defaultDate={selectedEndDate}
                      onSelect={(d) => {
                        handleDateChange(d);
                        close();
                      }}
                    />
                  )}
                </Popover>
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex items-center justify-end gap-4 pt-4 text-sm">
        <Button className="hover:underline" onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          className="rounded-lg bg-tertiary px-3 py-2 text-white hover:bg-tertiary-hover"
          onClick={() => handleSubmit()}
        >
          {t("common:approve")}
        </Button>
      </div>
    </div>
  );
}
