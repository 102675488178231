import { ReactNode, useEffect, useState } from "react";
import { featureEnabled } from "../../feature-helper";
import { useLocation } from "react-router";
import MobileNavigationTopBar from "./topbar/mobile-navigation-top-bar";

import { twMerge } from "tailwind-merge";
import { getScreenSizeType, ScreenSizeType } from "../../utils/tailwind/screen-sizes";
import { SidebarDesktop } from "~/lib/navigation/navigation-layout/sidebar/sidebar-desktop";
import { useMount } from "~/lib/lifecycle-helpers";
import { SidebarContextProvider } from "./sidebar-context";
import { SidebarMobile } from "./sidebar/sidebar-mobile";

export function NavigationLayout({ children }: { children?: ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showTopBar, setShowTopBar] = useState(false);
  const [sidebarType, setSidebarType] = useState<ScreenSizeType>(getScreenSizeType());
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(sidebarType === "laptop");
  const location = useLocation();

  /**
   * Attach event listener to resize event to update the sidebar type
   */
  useMount(() => {
    const handleResize = () => {
      const type = getScreenSizeType();
      setSidebarType(type);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    setShowTopBar(featureEnabled(location));
    if (sidebarType === "laptop") {
      setSidebarCollapsed(true);
    }
  }, [location]);

  function handleSidebarOpen() {
    setSidebarOpen(true);
  }

  return (
    <SidebarContextProvider
      value={{
        sidebarType,
        sidebarOpen,
        sidebarCollapsed,
        onVisibilityChange: setSidebarOpen,
        onSidebarChange: setSidebarType,
        onSidebarCollapse: setSidebarCollapsed,
      }}
    >
      <>
        {sidebarType === "mobile" ? <SidebarMobile /> : <SidebarDesktop />}
        <div
          className={twMerge(
            "flex h-full flex-1 flex-col",
            sidebarType === "laptop" && "pl-20",
            sidebarType === "desktop" && sidebarCollapsed && "pl-20",
            sidebarType === "desktop" && !sidebarCollapsed && "pl-64"
          )}
        >
          <MobileNavigationTopBar openSideBar={handleSidebarOpen} />
          <main className="h-screen">{children}</main>
        </div>
      </>
    </SidebarContextProvider>
  );
}
