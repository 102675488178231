import { ControlPanelApiProjectStatusResponse } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useProjectStatus } from "~/pages/projects/_cmp/use-project-status";
import { FilterGroup } from "./filter-group";

export function FilterGroupProjectStatus({
  onConfirmSelection,
  value,
  onClear,
}: {
  value: Parameters<typeof FilterGroup>[0]["value"];
  onClear: Parameters<typeof FilterGroup>[0]["onClear"];
  onConfirmSelection: (selection: Array<ControlPanelApiProjectStatusResponse>) => void;
}) {
  const { t } = useTranslation();
  const { statuses, isFetching } = useProjectStatus();
  return (
    <FilterGroup
      name={t("common:status")}
      value={value}
      isLoading={isFetching}
      onClear={onClear}
      selection={{
        items: statuses,
        valueFn: (item) => item.id,
        allowMultiple: false,
        renderSelectionItem: (item) => item.name,
        onConfirmSelection: (selected) => onConfirmSelection(selected),
      }}
    />
  );
}
