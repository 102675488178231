import { KPICardWrapper, KPICardWrapperProps } from "./kpi-card-wrapper";
import { SupportedUnits } from "./kpi-data-card";
import { twMerge } from "tailwind-merge";
import { FormatValue } from "./FormatValue";
import { useTranslation } from "react-i18next";

const BAR_CLASS = "w-18 bg-gray-300 rounded-t-md text-white relative";
const VALUE_CLASS =
  "flex h-full w-full rotate-180 items-center justify-center text-sm [text-orientation:sideways] [writing-mode:vertical-rl]";
const BOTTOM_LABEL_CLASS = "m-x-auto absolute -bottom-6 left-0 right-0 text-center text-slate-400 ";
const TOP_LABEL_CLASS = "m-x-auto absolute -top-6 left-0 right-0 text-center text-slate-400 ";

export function ComparisonBarChartCard({
  estimated,
  actual,
  unit,
  ...kpiProps
}: {
  estimated: {
    label: string;
    value: number;
  };
  actual: {
    label: string;
    value: number;
  };
  unit: SupportedUnits;
} & KPICardWrapperProps) {
  const { t } = useTranslation();
  const hasNoData = estimated.value === 0 && actual.value === 0;

  const estimatePercent = Math.round(
    (Math.abs(estimated.value) / Math.abs(actual.value)) * 100
  ).clamp(0, 100);
  const actualPercent = Math.round(
    (Math.abs(actual.value) / Math.abs(estimated.value)) * 100
  ).clamp(0, 100);
  let differencePercent = actualPercent - estimatePercent;
  if (unit === "percent") {
    differencePercent = actual.value - estimated.value;
  }
  let actualColor = "bg-green-600";

  if (actual.value < estimated.value) {
    actualColor = "bg-red-600";
  }

  if (hasNoData) {
    return (
      <KPICardWrapper {...kpiProps} containerClass="h-full">
        <div className="flex h-full items-center justify-center text-shade-400">
          {t("common:not_available")}
        </div>
      </KPICardWrapper>
    );
  }

  return (
    <KPICardWrapper {...kpiProps} containerClass="h-full">
      <div className="h-full max-h-[200px] w-full px-4 pb-2">
        <div className="flex h-full w-full flex-row items-end justify-center gap-x-10 border-b border-b-slate-300">
          <div
            className={twMerge(BAR_CLASS, "bg-gray-500")}
            title={`${estimated.value} kr`}
            style={{ height: `${estimatePercent}%` }}
          >
            <div className={VALUE_CLASS}>
              <FormatValue unit={unit} value={estimated.value} />
            </div>
            <div className={BOTTOM_LABEL_CLASS}>{estimated.label}</div>
          </div>
          <div
            className={twMerge(BAR_CLASS, actualColor)}
            style={{ height: `${actualPercent}%` }}
            title={unit === "currency" ? `${actual.value} kr` : `${actual.value}%`}
          >
            <div className={VALUE_CLASS}>
              <FormatValue unit={unit} value={actual.value} />
            </div>
            <div className={BOTTOM_LABEL_CLASS}>{actual.label}</div>
            {differencePercent && (
              <div className={TOP_LABEL_CLASS}>
                {differencePercent < 0 ? (
                  <FormatValue value={differencePercent} unit="percent" />
                ) : (
                  <span>
                    +<FormatValue value={differencePercent} unit="percent" />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </KPICardWrapper>
  );
}
