// Purpose: Use the SDK PaginationDetails API. Use pageCount if available, but otherwise keep it simple.

import { PaginationDetails } from "@apacta/sdk";
import { usePagination } from "../utils";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Icon } from "../ui";

const BUTTON_CLASS =
  "border bg-white enabled:hover:bg-gray-50 enabled:focus:z-20 enabled:focus:outline-offset-0 disabled:text-gray-300 enabled:hover:text-hover  ring-gray-300";
const CHEVRON_CLASS = "h-full inline-flex items-center  bg-white w-10 justify-center";
const PAGE_CLASS = "inline-flex items-center border w-10 text-sm font-medium justify-center h-full";
const CURRENT_CLASS = "border-primary bg-primary text-primary";

/**
 * @deprecated Delete when the final pages have stopped using it
 */
export function PaginationBar({
  paginationDetails: p,
}: {
  paginationDetails?: PaginationDetails; // We're depending on the SDK here on purpose
}) {
  const [, setPagination] = usePagination();
  const { t } = useTranslation();

  if (!p) {
    console.warn("PaginationBar received an empty pagination object, nothing will be shown");
    return null;
  }

  const currentPage = Number(p.currentPage); // Retarted, but hard to change atm
  const pageCount = Number(p.pageCount);

  const availablePages = Array.from({ length: pageCount }, (_, i) => i + 1); // Array w. page numbers
  let leftPages: Array<number | null> = availablePages.filter((page) => page < currentPage);
  let rightPages: Array<number | null> = availablePages.filter((page) => page > currentPage);

  // When there is no room to show all the pages, we'll try to make it fit by removing some pages
  if (leftPages.length > 3) {
    leftPages = [
      leftPages[0],
      null,
      leftPages[leftPages.length - 2],
      leftPages[leftPages.length - 1],
    ];
  }
  if (rightPages.length > 3) {
    rightPages = [rightPages[0], rightPages[1], null, rightPages[rightPages.length - 1]];
  }

  return (
    <nav className="flex h-10 items-center justify-center self-end text-sm text-gray-600">
      <button
        className={twMerge(BUTTON_CLASS, CHEVRON_CLASS, "rounded-l-md")}
        onClick={() => setPagination("page", currentPage - 1)}
        aria-label={t("ui:navigation.back", "Back")}
        disabled={currentPage === 1 || !p.hasPrevPage}
      >
        <Icon name="chevronLeft" className="h-5 w-5" />
      </button>
      {leftPages.map((pnr) => (
        <button
          key={`page-button-${pnr}`}
          className={twMerge(BUTTON_CLASS, PAGE_CLASS)}
          disabled={!pnr}
          onClick={pnr ? () => setPagination("page", pnr) : undefined}
        >
          {!!pnr ? pnr : "..."}
        </button>
      ))}
      <button disabled className={twMerge(PAGE_CLASS, BUTTON_CLASS, CURRENT_CLASS)}>
        {p.currentPage}
      </button>
      {rightPages.map((pnr) => (
        <button
          key={`page-button-${pnr}`}
          className={twMerge(BUTTON_CLASS, PAGE_CLASS)}
          disabled={!pnr}
          onClick={pnr ? () => setPagination("page", pnr) : undefined}
        >
          {!!pnr ? pnr : "..."}
        </button>
      ))}

      <button
        className={twMerge(BUTTON_CLASS, CHEVRON_CLASS, "rounded-r-md")}
        onClick={() => setPagination("page", currentPage + 1)}
        aria-label={t("ui:navigation.next", "Next")}
        disabled={!p.hasNextPage}
      >
        <Icon name="chevronRight" className="h-5 w-5" />
      </button>
    </nav>
  );
}
