import { Invoice } from "@apacta/sdk";
import { useDataColumns } from "~/lib/ui/data-table";
import { InvoiceImage } from "./invoice-image";
import { linkToInvoiceV3 } from "~/lib/utils";
import { getInvoiceEntity, getInvoiceType } from "./get-invoice-type";
import { useTranslation } from "react-i18next";
import { getIcon } from "~/lib/ui";
import { formatCurrency } from "~/lib/utils/number";
import * as Popover from "@radix-ui/react-popover";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { InvoiceActivityLogContent } from "./activity-log-content";
import { useState } from "react";
import { useAPI } from "~/lib/api";
import { useQueryClient } from "@tanstack/react-query";
import { CACHE_INVOICES } from "..";
import { useNavigate } from "react-router";
import { ProjectCell } from "~/lib/ui/table/cells/project-cell";
import { CustomerCell } from "~/lib/ui/table/cells/customer-cell";
import { DateCell } from "~/lib/ui/table/cells/date-cell";

export function useInvoiceTableColumns(opts: { onSendInvoice: (invoice: Invoice) => void }) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [selectedInvoiceForActivityLog, setSelectedInvoiceForActivityLog] = useState<string>();

  async function handleDelete(id: string) {
    await api.deleteInvoice({ invoiceId: id });
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, "index"] });
  }

  async function handleCreditNote(id: string) {
    const res = await api.duplicateInvoice({ invoiceId: id, asCreditNote: true });
    if (!res.data?.id) {
      console.error("Didn't receive an ID for the duplicated invoice. Pending API changes?");
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, "index"] });
    navigate(linkToInvoiceV3(res.data.id));
  }

  const columns = useDataColumns<Invoice>(
    (columnHelper) => [
      columnHelper.accessor("invoiceNumber", {
        id: "invoice_number",
        header: t("invoices:invoice_number", "Invoice number"),
        cell: ({ row }) => (
          <div className="flex min-w-3 items-center justify-between gap-2">
            <div className="text-right">{row.original.invoiceNumber ?? "-"}</div>
            <InvoiceImage invoice={row.original} />
          </div>
        ),
        meta: {
          className: "w-1/24 group",
          href: (invoice) => linkToInvoiceV3(invoice.id),
        },
        enableSorting: true,
      }),
      columnHelper.accessor("created", {
        id: "issued_date",
        header: t("invoices:issued_date", "Issued date"),
        cell: ({ row }) => <DateCell date={row.original.issuedDate} />,
        enableSorting: true,
        meta: {
          href: (invoice) => linkToInvoiceV3(invoice.id),
        },
      }),
      columnHelper.display({
        id: "status",
        cell: ({ row }) => getInvoiceType({ invoice: row.original, t }),
        meta: {
          href: (invoice) => linkToInvoiceV3(invoice.id),
        },
      }),
      columnHelper.accessor("project.name", {
        id: "project",
        header: t("common:project", { count: 1 }),
        cell: ({ row }) => <ProjectCell project={row.original.project} />,
      }),
      columnHelper.accessor("grossPayment", {
        header: t("finance:total_with_vat", "Total w. VAT"),
        cell: ({ row }) =>
          row.original.netPayment ? formatCurrency(row.original.netPayment) : formatCurrency(0),
        meta: {
          className: "text-right",
        },
      }),
      columnHelper.accessor("contact.name", {
        id: "customer",
        header: t("common:customer", { count: 1 }),
        cell: ({ row }) => <CustomerCell customer={row.original.contact} />,
        meta: {
          skeletonType: "avatar",
        },
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          className: "text-right",
        },
        cell: ({ row }) => (
          <>
            <Popover.Root
              open={selectedInvoiceForActivityLog === row.original.id}
              onOpenChange={() => {
                setSelectedInvoiceForActivityLog(undefined);
              }}
            >
              <Popover.Anchor></Popover.Anchor>
              <Popover.Portal>
                <Popover.Content>
                  <InvoiceActivityLogContent invoiceId={row.original.id} />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <ActionButtons
              size="small"
              actions={[
                {
                  Icon: getIcon("edit"),
                  label: t("common:edit"),
                  href: `./${row.original.id}`,
                  isHidden: !row.original.isDraft || row.original.isLocked, // show for drafts only
                },
                {
                  Icon: getIcon("view"),
                  label: t("common:view"),
                  href: `./${row.original.id}`,
                  isHidden: row.original.isDraft || row.original.isLocked, // show for non-drafts only
                },
                {
                  Icon: getIcon("send"),
                  label: t("common:send"),
                  onClick: () => {
                    opts.onSendInvoice(row.original);
                  },
                  isHidden: row.original.isDraft,
                },
                {
                  Icon: getIcon("log"),
                  label: t("common:activity_log", "Activity log"),
                  onClick: () => {
                    setSelectedInvoiceForActivityLog(row.original.id);
                  },
                  isHidden: row.original.isLocked,
                },
                {
                  Icon: getIcon("delete"),
                  label: t("common:delete"),
                  confirm: {
                    entity: getInvoiceEntity(row.original),
                    action: "delete",
                  },
                  isHidden: !row.original.isDraft || row.original.isLocked, // only show for drafts
                  onClick: () => handleDelete(row.original.id),
                },
                {
                  Icon: getIcon("credit"),
                  label: t("invoices:create_credit_note"),
                  isHidden: row.original.isDraft, // show for non-drafts only
                  onClick: () => handleCreditNote(row.original.id),
                },
              ]}
            />
          </>
        ),
      }),
    ],
    [selectedInvoiceForActivityLog]
  );
  return columns;
}
