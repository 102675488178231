import { twMerge } from "tailwind-merge";
import Switch from "~/lib/ui/switch";
import Tooltip from "~/lib/ui/tooltip";
import { formatDecimals } from "~/lib/utils/number";
import FormEntryCardPopover from "~/pages/_cmp/weekly-registration-table/form-entry-card-popover";
import { MouseEvent, useEffect, useState } from "react";
import { useDevice } from "~/lib/utils/device/use-device";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";
import { UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner } from "../../../../apacta-sdk";

export type WeekSectionProjectForm =
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner;

export function FormEntryCard({
  form,
  onToggle,
  onClick,
  showApprovedState,
}: {
  form: WeekSectionProjectForm;
  onToggle?: ({ formId, state }: { formId: string; state: boolean }) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  showApprovedState?: boolean;
}) {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState<boolean>(!!form.approved);
  const { isTouchDevice } = useDevice();
  const { isBreakpoint } = useBreakpoints();

  // useEffect to update the toggled state when the form is changed
  // This is solely to provide a better user experience when the form is approved
  // It avoids waiting for the backend to update the form and then update the UI
  useEffect(() => {
    setIsToggled(!!form.approved);
  }, [form]);

  const totalHours = form.entries.reduce(
    (acc, entry) => acc + (["km", "daily"].includes(entry.timeUnit) ? 0 : entry.total),
    0
  );

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    if (isTouchDevice && !isBreakpoint("sm")) {
      setPopoverOpen(!popoverOpen);
    } else {
      onClick?.(e);
    }
  };

  return (
    <Tooltip
      open={isTouchDevice ? popoverOpen : undefined}
      onOpenChange={isTouchDevice ? setPopoverOpen : undefined}
      side="right"
      delay={100}
      trigger={
        <div
          onClick={handleOnClick}
          className={twMerge(
            "flex h-16 w-16 select-none flex-col items-center justify-center gap-0.5 border border-gray-300 bg-gray-100 text-sm font-semibold text-gray-700"
          )}
        >
          <div>
            {formatDecimals(totalHours)}
            &nbsp;t
          </div>
          {onToggle && (
            <div>
              <Switch
                controlled
                size="small"
                onCheckedChange={(state) => {
                  setIsToggled(state);
                  onToggle?.({ formId: form.id, state });
                }}
                checked={isToggled}
              />
            </div>
          )}
        </div>
      }
    >
      <FormEntryCardPopover form={form} showApprovedState={showApprovedState} />
    </Tooltip>
  );
}
