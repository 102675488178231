import { Fragment, ReactNode } from "react";
import { Spinner } from "~/lib/ui/spinner";

/**
 * A simple container for metadata. Allows you to render a list of generic items
 * and add a generic add button. In simple terms, it's a container.
 * Supports readonly mode to hide the addRender.
 * @param items list of items to be render
 * @param render render function to determine how the items will look in the list
 * @param readonly whether or not addRender should be rendered
 * @param addRender render function to determine what the add button should look like
 * @param loading whether or not the container should display a spinner
 */
export function MetaContainer<EntityType extends { id: string }>({
  items = [],
  render,
  readonly = false,
  addRender,
  loading,
}: {
  items?: Array<EntityType>;
  render: (data: EntityType) => ReactNode;
  readonly?: boolean;
  addRender?: () => ReactNode;
  loading?: boolean;
}) {
  if (loading) {
    return (
      <div className="flex flex-wrap gap-2 pt-2">
        <Spinner className="h-6 w-6" />
      </div>
    );
  }

  return (
    <div className="flex min-h-[2rem] flex-wrap items-center gap-2">
      {items.map((item) => (
        <Fragment key={item.id}>{render(item)}</Fragment>
      ))}

      {!readonly && addRender && addRender()}
    </div>
  );
}
