import { ControlProps, rankWith, uiTypeIs } from "@jsonforms/core";

export function FallbackControlRenderer(props: ControlProps) {
  const type = props.uischema?.type;
  const entity = props.uischema?.options?.["x-entity"];
  const dataType = props.schema.type;

  return (
    <p className="text-error">
      {type} renderer for <strong>{entity}</strong> ({dataType}) missing.
    </p>
  );
}
export const FallbackControlTester = rankWith(1, uiTypeIs("Control"));
