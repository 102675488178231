import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { GetLabelsDirectionEnum, LabelEntity } from "@apacta/sdk";

export type UseCategoryLabelsEntity = "employee" | "task";

export function useCategoryLabels(entity?: UseCategoryLabelsEntity) {
  const api = useAPI();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const labels = useQuery({
    queryKey: ["category-labels", entity],
    queryFn: () =>
      api.getLabels({
        entity,
        q: searchQuery,
        sort: "text",
        direction: GetLabelsDirectionEnum.Asc,
      }),
  });

  const data = labels.data?.data ?? [];

  const employeeLabels = data.filter((label: LabelEntity) => label.entity === "employee");
  const taskLabels = data.filter((label: LabelEntity) => label.entity === "task");

  return {
    labels: labels.data?.data ?? [],
    employeeLabels,
    taskLabels,
    setSearchQuery,
    isLoadingLabels: labels.isLoading,
    refetchLabels: async () => labels.refetch(),
  };
}
