import { Expense } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useDataColumns } from "~/lib/ui/data-table";
import { ProjectCell } from "~/lib/ui/table/cells/project-cell";
import { VendorCell } from "~/lib/ui/table/cells/vendor-cell";
import { useLocale } from "~/lib/utils/date";
import { formatCurrency } from "~/lib/utils/number";

export function useExpensesTableColumns() {
  const { t } = useTranslation();
  const locale = useLocale();
  const columns = useDataColumns<Expense>((columnHelper) => [
    columnHelper.accessor("deliveryDate", {
      header: t("common:date"),
      cell: ({ row }) =>
        row.original.deliveryDate
          ? locale.format(row.original.deliveryDate, {
              excludeTime: true,
              shortDate: true,
            })
          : "-",
    }),
    columnHelper.accessor("orderNumber", {
      enableSorting: true,
      header: t("common:nr"),
      cell: ({ row }) =>
        row.original.supplierInvoiceNumber ? (
          <span className="font-semibold">{row.original.supplierInvoiceNumber}</span>
        ) : (
          "-"
        ),
      meta: {
        className: "text-right w-12",
      },
    }),
    columnHelper.accessor("project.name", {
      id: "project",
      header: t("common:project", { count: 1 }),
      cell: ({ row }) => <ProjectCell project={row.original.project} />,
    }),
    columnHelper.accessor("vendor.name", {
      header: t("common:vendor", { count: 1 }),
      cell: ({ row }) => <VendorCell vendor={row.original.vendor} />,
    }),

    columnHelper.accessor("totalBuyingPrice", {
      header: t("finance:total_cost"),
      cell: ({ row }) => formatCurrency(row.original.totalBuyingPrice ?? 0),
      meta: {
        className: "text-right",
      },
    }),
    columnHelper.accessor("totalSellingPrice", {
      header: t("finance:total_sales_price"),
      cell: ({ row }) => formatCurrency(row.original.totalSellingPrice ?? 0),
      meta: {
        className: "text-right",
      },
    }),
  ]);
  return columns;
}
