import { Configuration, DefaultApi } from "@apacta/sdk";
import { BACKEND_URL } from "../auth/config";

export function createApiInstance(apiKey?: string) {
  const config = new Configuration({
    apiKey,
    basePath: `${BACKEND_URL}`,
    headers: { Accept: "application/json" },
  });
  return new DefaultApi(config);
}
/**
 * Used to wrap API calls if we want to test slower connections
 * @param fn e.g. api.getContacts()
 * @param timeout timeout in ms
 * @returns returns the same type of promise that it receives
 */
export function slowify<PT extends Promise<unknown>>(fn: PT, timeout = 3000): PT {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fn.then((res) => resolve(res)).catch((err) => reject(err));
    }, timeout);
  }) as PT;
}
