import { JsonSchema, UISchemaElement } from "@jsonforms/core";
import { JsonFormsDispatch } from "@jsonforms/react";
import { childWithAncestor } from "./childWithAncestor";

export const renderChildren = ({
  schema,
  path,
  enabled,
  elements,
  uischema,
}: {
  schema: JsonSchema;
  path: string;
  enabled: boolean;
  elements?: Array<UISchemaElement>;
  uischema?: UISchemaElement;
}) => {
  if (!elements || elements.length === 0) {
    return [];
  }

  return elements.map((child, index) => {
    child = childWithAncestor(child, uischema);
    const key = `${path}-${index}`;
    return (
      <JsonFormsDispatch key={key} uischema={child} schema={schema} path={path} enabled={enabled} />
    );
  });
};
