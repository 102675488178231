import { HTMLFontFace } from "jspdf";

export const fontFaces: Array<HTMLFontFace> = [
  {
    family: "Roboto",
    style: "normal",
    weight: "100",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Thin.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "200",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Light.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "300",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Light.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "normal",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Regular.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "500",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Medium.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "600",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Medium.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "700",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Bold.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "800",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Bold.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "normal",
    weight: "900",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Bold.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "100",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-ThinItalic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "200",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-LightItalic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "300",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-LightItalic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "normal",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-Italic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "500",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-MediumItalic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "600",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-MediumItalic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "700",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-BoldItalic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "800",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-BoldItalic.ttf", format: "truetype" }],
  },
  {
    family: "Roboto",
    style: "italic",
    weight: "900",
    stretch: "normal",
    src: [{ url: "/fonts/Roboto-BoldItalic.ttf", format: "truetype" }],
  },
];
