import { ButtonVariant } from "./shared/button.shared";
import ButtonLegacy from "./button-legacy";
import { PlusIcon } from "@heroicons/react/24/outline";

import { ReactElement } from "react";
import { IconProp } from "../types";
import { twMerge } from "tailwind-merge";

/**
 * @deprecated Use Button instead. It also accepts an Icon prop.
 */
function IconButton({
  variant = "secondary",
  disabled = false,
  id,
  onClick = () => console.warn("Missing handler for onClick"),
  Icon = PlusIcon,
  iconClassName,
  buttonClassName,
  children,
}: {
  variant?: ButtonVariant;
  id?: string;
  disabled?: boolean;
  tabIndex?: number;
  onClick?: () => void;
  Icon?: IconProp;
  iconClassName?: string;
  buttonClassName?: string;
  children?: ReactElement | Array<ReactElement> | string;
  className?: string;
  loading?: boolean;
}) {
  return (
    <ButtonLegacy
      id={id}
      className={buttonClassName}
      variant={variant}
      onClick={() => onClick()}
      disabled={disabled}
    >
      <Icon className={twMerge(iconClassName, "mr-3 h-6 w-6")} />
      <span>{children}</span>
    </ButtonLegacy>
  );
}

IconButton.Left = function Left({
  variant = "secondary",
  disabled = false,
  id,
  onClick = () => console.warn("Missing handler for onClick"),
  Icon = PlusIcon,
  iconClassName,
  className,
  children,
}: {
  variant?: ButtonVariant;
  id?: string;
  disabled?: boolean;
  tabIndex?: number;
  onClick?: () => void;
  Icon?: IconProp;
  iconClassName?: string;
  children?: ReactElement | Array<ReactElement> | string;
  className?: string;
  loading?: boolean;
}) {
  return IconButton({
    variant,
    disabled,
    id,
    onClick,
    Icon: Icon,
    children,
    className,
    iconClassName,
  });
};

IconButton.Right = function Right({
  variant = "secondary",
  disabled = false,
  id,
  onClick = () => console.warn("Missing handler for onClick"),
  Icon = PlusIcon,
  iconClassName,
  className,
  children,
}: {
  variant?: ButtonVariant;
  id?: string;
  disabled?: boolean;
  tabIndex?: number;
  onClick?: () => void;
  Icon?: IconProp;
  iconClassName?: string;
  children?: ReactElement | Array<ReactElement> | string;
  className?: string;
  loading?: boolean;
}) {
  return (
    <ButtonLegacy
      id={id}
      variant={variant}
      onClick={() => onClick()}
      disabled={disabled}
      className={className}
    >
      <span>{children}</span>
      <Icon className={twMerge(iconClassName, "ml-3 h-6 w-6")} />
    </ButtonLegacy>
  );
};

export default IconButton;
