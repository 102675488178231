import { Popover, PopoverSide } from "~/lib/ui/popover/popover";
import { ExpenseLine, Product, Vendor, VendorProduct } from "@apacta/sdk";
import { ReactNode } from "react";
import { ProductSearchContent } from "./product-search-content";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";

type ProductTypeProduct = {
  type: "product";
  item: Product;
};

type ProductTypeExpenseLine = {
  type: "expense_line";
  item: ExpenseLine;
};

type ProductTypeVendorProduct = {
  type: "vendor_product";
  item: VendorProduct;
};

export type ProductItem = ProductTypeProduct | ProductTypeExpenseLine | ProductTypeVendorProduct;

export default function ProductPopover({
  defaultTab,
  defaultQuery,
  defaultVendor,
  triggerRender,
  onTabChange,
  onQueryChange,
  onVendorChange,
  onSelect,
  options,
}: {
  defaultTab?: TabHeaderItem;
  defaultQuery?: string;
  defaultVendor?: Vendor;
  triggerRender: () => ReactNode;
  onTabChange?: (tab: TabHeaderItem) => void;
  onQueryChange?: (query: string) => void;
  onVendorChange?: (vendor: Vendor | undefined) => void;
  onSelect?: (item: ProductItem) => void;
  options?: {
    allowedProductTypes?: Array<string>;
    side?: PopoverSide;
  };
}) {
  return (
    <Popover
      config={{ side: options?.side ?? "right" }}
      triggerAsChild={true}
      triggerRender={triggerRender}
    >
      {(onClose) => (
        <div className="flex h-[28em] w-[48rem] min-w-[18em] flex-shrink-0 flex-col gap-2 overflow-hidden rounded-lg border bg-white p-2 shadow-md">
          <ProductSearchContent
            defaultTab={defaultTab}
            defaultQuery={defaultQuery}
            defaultVendor={defaultVendor}
            onQueryChange={onQueryChange}
            onVendorChange={onVendorChange}
            onTabChange={onTabChange}
            onSelect={(item) => {
              onSelect?.(item);
              onClose(); // Closes popover
            }}
            onClose={() => {
              onClose(); // Closes popover
            }}
            options={options}
          />
        </div>
      )}
    </Popover>
  );
}
