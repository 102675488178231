import { ContributionMarginCard, ContributionRatioCard } from "~/pages/projects/_kpi-cards";
import { KPIBetaCard } from "~/pages/projects/_cmp/kpi-beta-card";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { useTranslation } from "react-i18next";
import { calculateKPIDataPoints } from "~/lib/ui/order-lines/lib/calculations";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";

type KpiDataPoints = {
  productCost: number;
  hoursCount: number;
  hoursCost: number;
  salesPrice: number;
  contributionMargin: number;
  contributionRatio: number;
  contributionMarginPerHour: number;
};

export default function KpiSection({ formState }: { formState: OfferFormState }) {
  const { t } = useTranslation();
  const { orderLines, vat } = useOrderLinesBuilder();

  const [kpiDataPoints, setKpiDataPoints] = useState<KpiDataPoints>({
    productCost: 0,
    hoursCount: 0,
    hoursCost: 0,
    salesPrice: 0,
    contributionMargin: 0,
    contributionRatio: 0,
    contributionMarginPerHour: 0,
  });

  useEffect(() => {
    const overwriteSalesPrice = formState.getValue("manualTotalSalesPrice");
    const shouldOverwrite =
      formState.getValue("offerLinesRule") === "gather_offer_lines" ||
      formState.getValue("offerLinesRule") === "show_only_product_bundles";

    const {
      productCost,
      hoursCount,
      hoursCost,
      salesPrice,
      contributionMargin,
      contributionRatio,
      contributionMarginPerHour,
    } = calculateKPIDataPoints(orderLines, shouldOverwrite ? overwriteSalesPrice ?? 0 : undefined);

    setKpiDataPoints({
      productCost,
      hoursCount,
      hoursCost,
      salesPrice,
      contributionMargin,
      contributionRatio,
      contributionMarginPerHour,
    });

    return () => undefined;
  }, [orderLines, formState]);

  return (
    <div className="flex gap-4">
      <KPIBetaCard
        label={t("finance:cost_price")}
        current={{
          label: t("common:work_hour_usage"),
          value: kpiDataPoints.hoursCost,
          className: "bg-gray-300 text-black",
        }}
        estimated={{
          label: t("finance:cost_price"),
          value: kpiDataPoints.hoursCost + kpiDataPoints.productCost,
        }}
        remainder={{
          label: t("common:product_usage"),
          className: "bg-green-600 text-white",
        }}
        unit="currency"
        Icon={CurrencyDollarIcon}
        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
        containerClass="w-72"
      />
      <KPIBetaCard
        label={t("finance:total")}
        current={{
          label: t("finance:cost_price"),
          value: kpiDataPoints.hoursCost + kpiDataPoints.productCost,
          className: "bg-gray-300 text-black",
        }}
        estimated={{
          label: t("finance:sales_price"),
          value: kpiDataPoints.salesPrice,
        }}
        remainder={{
          label: t("finance:contribution"),
          className: "bg-green-600 text-white",
        }}
        unit="currency"
        Icon={CurrencyDollarIcon}
        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
        containerClass="w-72"
      />

      <ContributionMarginCard
        label={t("finance:contribution_margin_per_hour")}
        paddingXClassname=""
        contributionMargin={kpiDataPoints.contributionMarginPerHour}
      />
      <ContributionRatioCard
        label={t("common:contribution_ratio")}
        paddingXClassname=""
        contributionRatio={kpiDataPoints.contributionRatio}
      />
    </div>
  );
}
