import { ControlPanelApiProjectViewOrIndexResponse } from "@apacta/sdk";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useAPI } from "~/lib/api";
import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useDataTable } from "~/lib/ui/data-table";
import { DataTable } from "~/lib/ui/data-table/data-table";
import { useDataColumns } from "~/lib/ui/data-table/use-data-columns";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { FilterGroupCustomers } from "~/lib/ui/filters/filter-group-customers";
import { FilterGroupProjectStatus } from "~/lib/ui/filters/filter-group-project-status";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { delayPromise } from "~/lib/utils/promise/delay-promise";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import ProjectStatusChange from "~/pages/projects/_cmp/project-status-change";

export default function PlaygroundTables() {
  const api = useAPI();

  const tableState = useDataTableState();
  const { pagination, sorting, search } = tableState.state;

  const [projectFilters, setProjectFilters] = useTypedSearchParams<{
    isOffer?: boolean;
    status?: string;
    employees?: Array<string>;
    activities?: Array<string>;
    customer?: string;
  }>();

  const pageQ = useQuery({
    queryKey: ["test2", pagination, sorting, search, projectFilters],
    queryFn: () =>
      delayPromise(
        api.iListProjects({
          page: tableState.pageNumber,
          limit: pagination.pageSize,
          sort: tableState.sortBy,
          direction: tableState.sortingDirection,
          activityIds: projectFilters.activities,
          projectStatusId: projectFilters.status,
          contactId: projectFilters.customer,
          q: search,
        }),
        1000
      ),
  });

  // Example of how to do totals without killing performance.
  const totalExample = useMemo(() => {
    // Rows that include "plan" in the name
    let sum = 0;
    pageQ.data?.data.forEach((row) => {
      if (row.name.includes("plan")) sum += 1;
    });
    return sum;
  }, [pageQ.data?.data]);

  const columns = useDataColumns<ControlPanelApiProjectViewOrIndexResponse>((columnHelper) => [
    columnHelper.accessor("projectNumber", {
      header: "#",
      enableSorting: true,
      id: "project_number",
      meta: {
        className: "w-[2rem]",
      },
      footer: () => <span title="Rows with 'plan' in them">{totalExample}</span>,
    }),
    columnHelper.accessor("name", {
      header: "Name",
      enableSorting: true,
      meta: {
        className: "text-left",
      },
    }),
    columnHelper.accessor("projectStatusId", {
      id: "project_status_id",
      header: "Status",
      cell: ({ row }) => (
        <ProjectStatusChange
          project={row.original}
          initialStatusId={row.original.projectStatusId}
        />
      ),
      enableSorting: true,
    }),
    columnHelper.display({
      id: "actions",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          collapseAt={4}
          size="small"
          actions={[
            {
              label: "test",
              onClick: () => alert("test" + row.original.name),
              Icon: getIcon("administration"),
            },
          ]}
        />
      ),
    }),
  ]);

  const table = useDataTable(
    {
      columns: columns,
      data: pageQ.data?.data,
      backendPagination: pageQ.data?.pagination,
      tableState,
      isLoading: pageQ.isLoading,
      mode: "server",
      getRowId: (row) => row.id,
    },
    {
      enableGlobalFilter: true,
      enableExpanding: true,
      getRowCanExpand: () => true,
      enableRowSelection: true,
    }
  );

  return (
    <>
      <TabHeading subtitle="Server table with filters and sorting">ServerTable</TabHeading>
      <DataTable
        table={table}
        renderExpandedRow={({ row }) => <p>Expanded: {row.original.name}</p>}
        selectionActions={(rows) => [
          {
            label: "test",
            onClick: () => {
              alert(rows.length);
            },
            Icon: getIcon("administration"),
          },
        ]}
        renderFilters={() => (
          <>
            <FilterGroupCustomers
              value={projectFilters.customer}
              onConfirmSelection={(selectedCustomers) => {
                setProjectFilters("customer", selectedCustomers[0].id);
              }}
              onClear={() => setProjectFilters("customer", undefined)}
            />
            <FilterGroupProjectStatus
              value={projectFilters.status}
              onConfirmSelection={(selection) => setProjectFilters("status", selection[0].id)}
              onClear={() => setProjectFilters("status", undefined)}
            />
          </>
        )}
      />
    </>
  );
}
