import { z } from "zod";
import { ean } from "~/lib/form-state/zod";

export const customerSchema = {
  name: z.string().min(1),
  address: z.string().optional(),
  cityName: z.string().optional(),
  email: z.string().email().optional().or(z.literal("")),
  phone: z.string().optional(),
  cvr: z.string().optional(),
  ean: ean(),
  zip_code: z.string().optional(),
  payment_term_id: z.string().nullish(),
  cityId: z.string().nullish(),
};
