import { Icon } from "~/lib/ui";
import { Popover } from "~/lib/ui/popover/popover";
import { debounce } from "lodash";
import { useState } from "react";
import { User } from "@apacta/sdk";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";
import { BadgeLabel } from "~/lib/ui/badge-label";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useAPI } from "~/lib/api";
import { useMount } from "~/lib/lifecycle-helpers";
import { useSuspenseQuery } from "@tanstack/react-query";

export function EmployeeSection({
  formState,
}: {
  defaultEmployeeIds?: Array<string>;
  formState: TaskFormState;
}) {
  const { t } = useTranslation();
  const [employeeSearchQuery, setEmployeeSearchQuery] = useState<string>("");
  const [selectedEmployees, setSelectedEmployees] = useState<Array<User>>([]);

  const api = useAPI();

  const userQ = useSuspenseQuery({
    queryKey: ["users"],
    queryFn: () => api.getUsers({ isActive: true }),
  });

  const users = userQ.data?.data ?? [];

  // Set selected employees from form state
  useMount(() => {
    setSelectedEmployees(() => {
      return [...users.filter((user) => formState.getValue("employeeIds")?.includes(user.id))];
    });
  });

  // Only allow selection of employees that are not already selected, otherwise filter by search query
  const availableEmployees = users.filter((employee) => {
    if (selectedEmployees.find((e) => e.id === employee.id)) {
      return false;
    } else {
      return employee.fullName.toLowerCase().includes(employeeSearchQuery.toLowerCase());
    }
  });

  const updateSearchQuery = debounce((value: string) => {
    setEmployeeSearchQuery(value);
  }, 500);

  const removeSelectedEmployee = (employee: User) => {
    setSelectedEmployees(selectedEmployees.filter((e) => e.id !== employee.id));
    formState.setValues({
      employeeIds: selectedEmployees.filter((e) => e.id !== employee.id).map((e) => e.id),
    });
  };

  const addSelectedEmployee = (employee: User) => {
    setSelectedEmployees([...selectedEmployees, employee]);
    formState.setValues({ employeeIds: [...selectedEmployees, employee].map((e) => e.id) });
  };

  const SHOW_EMPLOYEE_LOADER =
    selectedEmployees.length === 0 &&
    formState.getValue("employeeIds") &&
    formState.getValue("employeeIds").length > 0 &&
    userQ.isFetching;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex grow items-center gap-3">
        <div
          title={t("common:employee", { count: 2 })}
          className="flex h-8 w-8 shrink-0 items-center justify-center"
        >
          <Icon name="employeeSolid" size="small" />
        </div>
        <Popover
          config={{
            align: "start",
            side: "bottom",
            sideOffset: -40,
            usePortal: false,
            animate: false,
          }}
          triggerAsChild={true}
          onOpenChange={() => {
            setEmployeeSearchQuery("");
          }}
          triggerRender={({ open }) => (
            <button className="h-10 grow cursor-pointer text-sm text-shade-500 hover:bg-shade-100 focus:bg-shade-100 focus:outline-none focus:ring-0 focus-visible:bg-shade-100 focus-visible:outline-none focus-visible:ring-0">
              <div className="flex w-full cursor-pointer items-center gap-2 border-0 bg-transparent px-3 py-2 text-left text-sm ">
                {open ? (
                  <>&nbsp;</>
                ) : (
                  t("common:add_entity", {
                    entity: t("common:employee", { count: 1 }).toLowerCase(),
                  })
                )}
                <div>{open ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />}</div>
              </div>
            </button>
          )}
        >
          {(close) => (
            <div className="w-[--radix-popover-trigger-width]">
              <div>
                <input
                  placeholder={t("common:search_x", {
                    x: t("common:employee", { count: 2 }).toLowerCase(),
                  })}
                  type="text"
                  onFocus={(e) => e.currentTarget.select()}
                  onChange={(e) => updateSearchQuery(e.currentTarget.value)}
                  className="h-10 w-full cursor-pointer border-0 bg-transparent px-3 py-2 text-sm focus:cursor-auto focus:outline-none focus:ring-0 focus-visible:bg-shade-100 focus-visible:outline-none focus-visible:ring-0"
                />
              </div>
              <div className="planning-scrollbar max-h-32 overflow-y-auto border bg-white shadow-sm">
                {userQ.isFetching ? (
                  <div className="px-4 py-2">
                    <ContentLoader className={twMerge("h-6 w-full")}>
                      <rect x={0} y={0} width="80vw" height="100vh"></rect>
                    </ContentLoader>
                  </div>
                ) : availableEmployees.length ? (
                  <>
                    {availableEmployees.map((employee) => (
                      <div
                        key={`employee-${employee.id}`}
                        onClick={() => {
                          addSelectedEmployee(employee);
                          close();
                        }}
                        className="group flex cursor-pointer items-center gap-2 px-3 py-2 text-sm hover:bg-shade-100"
                      >
                        <UserAvatar
                          user={employee}
                          className="font-base mr-3 inline-flex h-6 w-6 text-xs"
                        />
                        <div>{employee.fullName}</div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={twMerge("px-4 py-2 text-shade-400")}>
                    {t("common:no_results")}
                  </div>
                )}
              </div>
            </div>
          )}
        </Popover>
      </div>
      <div className="ml-14 flex grow flex-wrap gap-4">
        {SHOW_EMPLOYEE_LOADER && (
          <ContentLoader className={twMerge("h-6 w-32")}>
            <rect x={0} y={0} width="80vw" height="100vh"></rect>
          </ContentLoader>
        )}
        {selectedEmployees.map((employee) => (
          <BadgeLabel
            key={`employee-${employee.id}`}
            className="py-0 pl-0 pr-1 text-xs font-semibold"
          >
            <div className="flex items-center">
              <UserAvatar user={employee} className="font-base mr-3 inline-flex h-6 w-6 text-xs" />
              <span className="mr-3">{employee.firstName}</span>

              <div
                className="cursor-pointer rounded-full p-0.5 hover:bg-gray-300"
                onClick={() => removeSelectedEmployee(employee)}
              >
                <Icon name="close" className="h-3 w-3" />
              </div>
            </div>
          </BadgeLabel>
        ))}
      </div>
    </div>
  );
}
