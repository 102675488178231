import { ControlProps, or, rankWith } from "@jsonforms/core";
import TextArea from "~/lib/ui/form-elements/textarea";
import { isControlEntity } from "./testers";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { useTranslation } from "react-i18next";

export function TextAreaControl(props: ControlProps) {
  const { t } = useTranslation();
  return (
    <>
      <TextArea
        initialValue={props.data}
        label={getLabelForControl(props)}
        placeholder={
          props.uischema.options?.["placeholder"] ??
          t("common:write_here_placeholder", "Write here...")
        }
        autoHeight
        onChange={(v) => props.handleChange(props.path, v)}
      />
    </>
  );
}
export const TextAreaControlTester = rankWith(
  5,
  or(isControlEntity("big_text"), isControlEntity("note"))
);
