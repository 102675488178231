import { useMount } from "~/lib/lifecycle-helpers";
import { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";

export function SidebarScroller({
  scrollRef,
  navRef,
}: {
  scrollRef: MutableRefObject<HTMLElement | null>;
  navRef: MutableRefObject<HTMLElement | null>;
}) {
  const [scrollableHeight, setScrollableHeight] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);

  const { t } = useTranslation();

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollableHeightUpdate: number =
        scrollRef.current?.scrollHeight - scrollRef.current?.clientHeight;
      const currentScrollUpdate: number = scrollRef.current?.scrollTop;

      // Avoid mutation if nothing new has happened i.e. scrolling down when already in the bottom
      if (scrollableHeightUpdate !== scrollableHeight) {
        setScrollableHeight(scrollableHeightUpdate);
      }
      // Avoid mutation if nothing new has happened i.e. scrolling down when already in the bottom
      if (currentScrollUpdate !== scrollY) {
        setScrollY(currentScrollUpdate);
      }
    }
  };

  useMount(() => {
    // Observer for detecting size changes when folding out element with children
    const ob = new ResizeObserver(handleScroll);
    if (scrollRef.current) {
      handleScroll();
    }

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }

    if (navRef.current) {
      ob.observe(navRef.current);
    }

    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
      if (navRef.current) {
        ob.unobserve(navRef.current);
      }
    };
  });

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ left: 0, top: scrollableHeight, behavior: "smooth" });
    }
  };
  return (
    <>
      <div
        onClick={() => scrollToTop()}
        className={twMerge(
          "group absolute top-0 z-10 h-12 w-full cursor-pointer items-center justify-center bg-gradient-to-b from-primary to-primary/50 text-white",
          (scrollableHeight === 0 || scrollY < 5) && "hidden",
          scrollY > 5 && "flex"
        )}
      >
        <div className="absolute whitespace-nowrap bg-primary p-1 text-xs opacity-0 transition-all duration-200 group-hover:translate-y-2 group-hover:opacity-100">
          {t("ui:navigation.scroll.scroll-top")}
        </div>
        <Icon
          name="chevronDoubleUp"
          className="h-5 w-5 transition-all duration-200 group-hover:-translate-y-2"
        />
      </div>
      <div
        onClick={() => scrollToBottom()}
        className={twMerge(
          "group absolute bottom-0 z-10 h-12 w-full cursor-pointer items-center justify-center bg-gradient-to-t from-primary to-primary/50 text-white",
          (scrollableHeight === 0 || scrollY === scrollableHeight) && "hidden",
          scrollY < scrollableHeight && "flex"
        )}
      >
        <div className="absolute whitespace-nowrap bg-primary p-1 text-xs opacity-0 transition-all duration-200 group-hover:-translate-y-2 group-hover:opacity-100">
          {t("ui:navigation.scroll.scroll-bottom")}
        </div>
        <Icon
          name="chevronDoubleDown"
          className="h-5 w-5 transition-all duration-200 group-hover:translate-y-2"
        />
      </div>
    </>
  );
}
