import { twMerge } from "tailwind-merge";
import { decimalToTimeString, getDateWithoutTime, Week } from "~/lib/utils/date/date-utils";
import { useTranslation } from "react-i18next";
import { WeekSectionProjectForm } from "~/pages/_cmp/weekly-registration-table/form-entry-card";
import { WeeklyProject } from "~/pages/employees/index/weekly";

type DayHourData = {
  [key: number]: number;
};

export default function WeekSectionFooter({
  week,
  projects,
}: {
  week: Week;
  projects: Array<WeeklyProject>;
}) {
  const { t } = useTranslation();

  const getHours = (form: WeekSectionProjectForm) => {
    return form.entries.reduce(
      (acc, entry) => acc + (["km", "daily"].includes(entry.timeUnit) ? 0 : entry.total),
      0
    );
  };

  // reducer function to get the total hours of each day based on the days timestamp, using the hours from each entry in each form in each project
  const dayHourData = projects.reduce((acc: DayHourData, project: WeeklyProject) => {
    const forms = project.forms.filter((form) => !!form.date);
    return forms.reduce((acc2: DayHourData, form: WeekSectionProjectForm) => {
      const formDate = getDateWithoutTime(form.date!);
      const totalHours = getHours(form);
      const dateTime = formDate.getTime();

      if (!acc2[dateTime]) {
        acc2[dateTime] = totalHours;
      } else {
        acc2[dateTime] += totalHours;
      }

      return acc2;
    }, acc);
  }, {});

  const totalHours = Object.values(dayHourData).reduce((acc, n) => {
    return acc + n;
  }, 0);

  return (
    <tfoot>
      <tr className="border-t-4 text-sm font-semibold">
        <td className="border-r px-3 py-3">
          <div className="flex flex-col justify-between gap-2 sm:flex-row sm:gap-8">
            <div className="text-nowrap">{t("users:sum_of_hours")}</div>
            <div
              className="text-shade-400"
              title={`${t("common:total", { count: 2 })} ${t("common:hour", { count: 2 }).toLowerCase()}`}
            >
              {decimalToTimeString(totalHours)}
            </div>
          </div>
        </td>
        {week.days.map((day, idx) => {
          const dayData = dayHourData[day.date.getTime()] ?? 0;
          return (
            <td
              key={day.date.getTime()}
              className={twMerge(
                "w-24 border-x p-1.5 text-center text-sm",
                day.isHoliday && "bg-yellow-50",
                day.isWeekend && "bg-shade-200/50",
                idx === week.days.length - 1 && "border-r-0"
              )}
            >
              {dayData === 0 && <span>-</span>}
              {dayData > 0 && (
                <>
                  <span>{decimalToTimeString(dayData)}</span>
                  {}
                </>
              )}
            </td>
          );
        })}
      </tr>
    </tfoot>
  );
}
