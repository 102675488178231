import { UsersProductsUsageEntity } from "@apacta/sdk";
import { Fragment, useState } from "react";
import { Button, getIcon } from "~/lib/ui";
import { formatCurrency } from "~/lib/utils/number";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { RenderProductUsageFormEntry } from "~/pages/employees/_cmp/render-product-usage-form-entry";

export function RenderProductUsageRow({ product }: { product: UsersProductsUsageEntity }) {
  {
    const { t } = useTranslation();
    const [selectedEntry, setSelectedEntry] = useState<string>();

    function handleSelectToggle(entryId?: string) {
      setSelectedEntry((prev) => {
        if (prev === entryId) return undefined;
        return entryId;
      });
    }
    const isSelected = selectedEntry === product.productId;
    return (
      <Fragment>
        <tr className="w-60 min-w-60 bg-shade-50 ">
          <td className=" py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
            <div className="flex gap-x-3">
              {product.entries ? (
                <Button
                  size="small"
                  title={t("common:details")}
                  Icon={isSelected ? getIcon("chevronUp") : getIcon("chevronDown")}
                  onClick={() => handleSelectToggle(product.productId)}
                />
              ) : null}
              <div className="text-sm font-semibold leading-6 ">{product.productNumber}</div>
            </div>
          </td>
          <td className="flex items-center text-left">{product.productName}</td>
          <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
            {product.amount}
          </td>
          <td className="hidden py-4 pl-0 pr-4 text-left text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
            {formatCurrency(product.buyingPrice)}
          </td>
          <td className="hidden py-4 pl-0 pr-4 text-left text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
            {formatCurrency(product.sellingPrice)}
          </td>
        </tr>
        {isSelected && (
          <tr className={twMerge("text-sm")} key={`${product.productId}-sub-row`}>
            <td colSpan={7} className="w-full">
              <table className="w-full text-left">
                <thead>
                  <tr>
                    <th>{t("common:date")}</th>
                    <th>{t("common:quantity")}</th>
                    <th>{t("common:origin", "Origin")}</th>
                  </tr>
                </thead>
                <tbody>
                  {product.entries &&
                    product.entries.map((entry) => (
                      <Fragment key={entry.formId}>
                        <RenderProductUsageFormEntry entry={entry} />
                      </Fragment>
                    ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </Fragment>
    );
  }
}
