/** A common interface for tables that are shared across pages */
export interface SharedTableBase {
  parentType: "kpi" | "tab" | "page";
  /** Search. On by default. Should be disabled for KPI pages */
  enableGlobalFilter?: boolean;
  /** Filters. On by default. Should be disabled for KPI pages and tabs */
  enableFilters?: boolean;
  /** Enables hiding columns. Should match `id` or accessor of the column */
  columnVisibility?: Record<string, boolean>;
}

/** Returns defaults based on the parentType */
export function getSharedTableDefaults(pageType: "kpi" | "tab" | "page") {
  return {
    enableGlobalFilter: pageType !== "kpi",
    enableFilters: pageType !== "kpi" && pageType !== "tab",
  } as const;
}
