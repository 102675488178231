import { ControlProps, rankWith } from "@jsonforms/core";
import { isEntityOf } from "./testers";
import { DEV } from "~/lib/auth/config";

export function DeprecatedRenderer(props: ControlProps) {
  if (DEV) {
    const entity = props.uischema.options?.["x-deprecated-entity"] ?? "unknown";
    return (
      <div className="border border-red-800 p-2 text-red-800">
        <strong>Deprecated:</strong> {entity}
        <div className="text-xs text-shade-400">Only shown in dev</div>
      </div>
    );
  }
  return null;
}
export const deprecatedTester = rankWith(3, isEntityOf("deprecated"));
