import { useTranslation } from "react-i18next";
import { formatDate } from "date-fns";
import { OfferAction } from "@apacta/sdk";
import { CreatedIcon, CreatedText } from "~/pages/offers/_cmp/activity-log/activity/created";
import { SentIcon, SentText } from "~/pages/offers/_cmp/activity-log/activity/sent";
import { OpenedIcon, OpenedText } from "~/pages/offers/_cmp/activity-log/activity/opened";
import { AcceptedIcon, AcceptedText } from "~/pages/offers/_cmp/activity-log/activity/accepted";
import { RejectedIcon, RejectedText } from "~/pages/offers/_cmp/activity-log/activity/rejected";
import { DeliveredIcon, DeliveredText } from "~/pages/offers/_cmp/activity-log/activity/delivered";
import { SendFailIcon, SendFailText } from "~/pages/offers/_cmp/activity-log/activity/send-failure";
import { ExpiredIcon, ExpiredText } from "~/pages/offers/_cmp/activity-log/activity/expired";
import { useLocale } from "~/lib/utils/date";

export function ActivityLogLine({ action }: { action: OfferAction }) {
  const getActivityIcon = () => {
    switch (action.action) {
      case "created":
        return CreatedIcon();
      case "sent":
        return SentIcon();
      case "email_delivered":
        return DeliveredIcon();
      case "email_sending_failed":
        return SendFailIcon();
      case "expired":
        return ExpiredIcon();
      case "public_url_accessed":
        return OpenedIcon();
      case "accepted":
        return AcceptedIcon();
      case "rejected":
        return RejectedIcon();
      default:
        return null;
    }
  };

  const getActivityText = () => {
    switch (action.action) {
      case "created":
        return CreatedText({ action });
      case "sent":
        return SentText({ action });
      case "email_delivered":
        return DeliveredText({ action });
      case "email_sending_failed":
        return SendFailText({ action });
      case "expired":
        return ExpiredText({ action });
      case "public_url_accessed":
        return OpenedText({ action });
      case "accepted":
        return AcceptedText({ action });
      case "rejected":
        return RejectedText({ action });
      default:
        return null;
    }
  };

  return (
    <div className="flex grow items-center justify-between gap-8">
      <div>
        <div className="flex items-center gap-6">
          {getActivityIcon()}
          <div className="flex flex-col gap-1">
            {getActivityText()}
            <div className="sm:hidden">
              <ActivityLogLineTimestamp action={action} />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:block">
        <ActivityLogLineTimestamp action={action} />
      </div>
    </div>
  );
}

const ActivityLogLineTimestamp = ({ action }: { action: OfferAction }) => {
  const { t } = useTranslation();
  const { format } = useLocale();

  return (
    <div className="rounded-lg border bg-shade-50 px-2 py-1 text-xs">
      {action.created ? (
        <time dateTime={formatDate(action.created, "yyyy-MM-dd HH:MM:ss")}>
          {format(action.created)}
        </time>
      ) : (
        t("common:unknown")
      )}
    </div>
  );
};
