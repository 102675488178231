/**
 * Hook containing device information
 */

export const useDevice = () => {
  function isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  return { isTouchDevice: isTouchDevice() };
};
