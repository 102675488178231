import { ReactNode, Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSession } from "~/lib/auth/session";
import { PageSpinner } from "../page-spinner";

/**
 * Allows you to intercept navigation and redirect to another page.
 * Automatically handles when session isn't ready yet
 */
export function InterceptNavigation({
  children,
  intercept,
}: {
  children: ReactNode;
  /** If returns string, it will navigate to it. If null it will return children */
  intercept: (session: ReturnType<typeof useSession>) => string | null;
}) {
  const navigate = useNavigate();
  const session = useSession();
  const location = useLocation();

  useEffect(() => {
    // Session isn't ready yet
    if (session.isLoggedIn === null) return;
    const res = intercept(session);
    if (res === null) return;
    navigate(res);
  }, [session, location.pathname]);

  if (session.isLoggedIn === null) return null;
  return <Suspense fallback={<PageSpinner />}>{children}</Suspense>;
}
