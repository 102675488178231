import { ControlProps, composePaths, or, rankWith } from "@jsonforms/core";
import { SignatureEditMode, SignatureViewMode } from "./SignatureRenderer";
import Switch from "~/lib/ui/switch";
import { isControlEntity } from "./testers";
import { Input } from "~/lib/ui/form-elements";
import { useFormsContext } from "../components/form-renderer";
import { getLabelForControl } from "../getLabelForControl";

export function CheckboxLayoutRenderer(props: ControlProps) {
  const isChecked = props.data?.checked ?? props.schema.properties?.["checked"].default ?? false;
  const checkboxPath = composePaths(props.path, "checked");
  const textPath = composePaths(props.path, "text");
  const entityType = (props.uischema?.options?.["x-entity"] as string) ?? "unknown";
  const ctx = useFormsContext();

  // This is custom and brittle, should the schema from the backend change.
  // We'd need more time to see if we can do this with ui-schema.
  return (
    <>
      <Switch
        label={getLabelForControl(props)}
        required={props.required}
        checked={isChecked}
        controlled
        onCheckedChange={(checked) => props.handleChange(checkboxPath, checked)}
      />
      {isChecked && (
        <div>
          {entityType === "checkbox_with_comment" && (
            <div className="my-2">
              <Input
                value={props.data?.text ?? ""}
                onChange={(e) => props.handleChange(textPath, e.currentTarget.value)}
                placeholder={props.uischema.options?.["x-comment-placeholder"] as string}
              />
            </div>
          )}
          {entityType === "checkbox_with_signature" && (
            <>
              {ctx.mode === "create" && (
                <SignatureEditMode
                  value={props.data?.text ?? ""}
                  onChange={(v) => props.handleChange(textPath, v)}
                />
              )}
              {ctx.mode === "edit" && <SignatureViewMode value={props.data?.text ?? ""} />}
            </>
          )}
        </div>
      )}
    </>
  );
}
export const checkboxLayoutTester = rankWith(
  4,
  or(isControlEntity("checkbox_with_comment"), isControlEntity("checkbox_with_signature"))
);
