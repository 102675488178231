import { ColumnDef, ColumnHelper, createColumnHelper, RowData } from "@tanstack/react-table";
import { DependencyList, useMemo } from "react";
import { useTranslation } from "react-i18next";

/** Creates a generic helper function and makes it available through the callback
 * The `any` could not be avoided.
 */
type HelperFn<TData extends RowData> = (
  helper: ColumnHelper<TData>
) => Array<ColumnDef<TData, any>>;

/**
 * It's a useMemo that re-renders on language change. Helper now built in.
 * @param factory
 * @returns
 */
export const useDataColumns = <TData extends RowData>(
  factory: HelperFn<TData>,
  deps: DependencyList = []
) => {
  const { i18n } = useTranslation();
  const helper = useMemo(() => createColumnHelper<TData>(), []);
  const columns = useMemo(() => {
    const allColumns = factory(helper);
    return allColumns;
  }, [i18n.resolvedLanguage, ...deps]);
  return columns;
};
