import { MouseEventHandler, ReactNode, useMemo, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PillBadge } from "../pill-badge";
import { useTranslation } from "react-i18next";
import { FilterGroupSelectionList } from "./filter-selection-list";

export type SupportedPrimitives = string | number | boolean | undefined;

export type FilterGroupProps<ItemType> = {
  name: string; // Shown when empty
  value?: SupportedPrimitives | Array<SupportedPrimitives>;
  hidden?: boolean;
  onClear?: () => void;
  isLoading?: boolean;

  // Only called if there is a value/selection
  selection?: FilterSelectionProp<ItemType>;
};

export type FilterSelectionProp<ItemType> = {
  items: Array<ItemType>;
  allowMultiple?: boolean;
  onConfirmSelection?: (selected: Array<ItemType>) => void;
  renderSelectionItem?: (item: ItemType) => ReactNode;
  valueFn: (item: ItemType) => SupportedPrimitives; // Used for selection. E.g. `item.id`
  filterFn?: (item: ItemType, query: string) => boolean; // Used for search inside dialog
  onQueryChange?: (query: string) => void;
};

export function FilterGroup<ItemType>(props: FilterGroupProps<ItemType>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  function handleConfirmSelection(selected: Array<ItemType>) {
    props.selection?.onConfirmSelection?.(selected);
    setOpen(false);
  }

  const handleClear: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    setOpen(false);
    props.onClear?.();
  };

  const displayValue = useMemo(() => {
    if (props.value === undefined) return undefined;
    // Single Value
    if (!Array.isArray(props.value)) {
      if (props.value === "") {
        return undefined;
      }

      if (props.selection) {
        const item = props.selection.items.find(
          (it) => props.selection?.valueFn(it) === props.value
        );
        if (item) {
          return props.selection?.renderSelectionItem?.(item);
        }
      }

      if (props.value === true) {
        return t("common:yes");
      }
      if (props.value === false) {
        return t("common:no");
      }
      return props.value;
    }
    // Array
    if (props.value.length === 0) return undefined;
    if (props.selection && props.value.length === 1) {
      const val = props.value[0];
      const item = props.selection.items.find((it) => props.selection?.valueFn(it) === val);
      if (item) {
        return props.selection?.renderSelectionItem?.(item);
      }
    }
    return props.value.length;
  }, [props.value, props.isLoading]);

  if (props.hidden) return null;

  return (
    <>
      <Popover.Root onOpenChange={setOpen} open={open}>
        <Popover.Trigger disabled={!props.selection} asChild>
          <div className="cursor-pointer">
            <PillBadge
              label={props.name}
              isLoading={props.isLoading}
              value={displayValue}
              onAction={displayValue === undefined ? () => setOpen(true) : handleClear}
              action={
                displayValue === undefined ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <XMarkIcon className="h-3 w-3" />
                )
              }
              className="outline-none hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover"
            />
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="min-w-[15rem] rounded-lg bg-white shadow-md"
            sideOffset={4}
            align="start"
          >
            {props.selection && (
              <FilterGroupSelectionList<ItemType>
                value={props.value}
                filterFn={props.selection.filterFn}
                items={props.selection.items}
                onConfirm={handleConfirmSelection}
                onClear={handleClear}
                allowMultiple={props.selection.allowMultiple}
                renderSelectionItem={props.selection.renderSelectionItem}
                valueFn={props.selection.valueFn}
                onQueryChange={props.selection.onQueryChange}
              />
            )}
            <Popover.Arrow className="fill-white" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
}
