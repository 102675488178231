import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSession } from "~/lib/auth/session";
import { LoginLayout } from "./login/layout";
import { Button } from "~/lib/ui";
import { useTranslation } from "react-i18next";

export default function FixPaymentPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { me, isLoggedIn, logout } = useSession();

  // Edge case if reloaded or loaded directly for some weird reason
  useEffect(() => {
    if (isLoggedIn === false) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  if (!me) {
    return null;
  }

  const { subscription } = me;
  if (!subscription) {
    console.error("Invalid payment page loaded, but no subscription");
    return null;
  }
  return (
    <LoginLayout>
      <h2>{t("errors:invalid_payment.title")}</h2>
      <div className="mb-8">{t("errors:invalid_payment.summary")}</div>
      <div className="flex flex-row justify-end gap-4">
        {subscription.paymentLink ? (
          <Button
            variant="primary"
            onClick={() => (window.location.href = subscription.paymentLink)}
          >
            {t("errors:invalid_payment.primary_button")}
          </Button>
        ) : null}
        <Button variant="secondary" onClick={() => logout()}>
          {t("common:logout")}
        </Button>
      </div>
    </LoginLayout>
  );
}
