// Inspired by: https://github.com/ShanikaNishadhi/React-Application-With-i18next/blob/main/src/translations/i18n.js

// Type Definitions are generated in "react-i18next.d.ts".

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { restoreApiKey as getSessionCookie, saveCookie, SessionCookie } from "~/lib/auth/cookie";

import I18NextLocizeBackend, { LocizeBackendOptions } from "i18next-locize-backend";

type i18nLanguageDefinition = {
  name: string;
  defaultLocale: string;
  flag: string;
};
export const LANGUAGE_STORE_KEY = "APACTA_LANGUAGE"; // Mostly used on login screen

export type LanguageDefinition = i18nLanguageDefinition & { id: string };

type i18nLanguage = {
  [key: string]: LanguageDefinition;
};

// Used for language selection (and iframes)
export const availableLanguages: i18nLanguage = {
  da: {
    id: "f29f1d42-b1d3-11e4-b2b9-f23c91842e2b",
    name: "Dansk",
    defaultLocale: "da-DK",
    flag: "dk",
  },
  en: {
    id: "febce647-b1d3-11e4-b2b9-f23c91842e2b",
    name: "English",
    defaultLocale: "en-US",
    flag: "gb",
  },
  de: {
    id: "ec34dab4-b1d3-11e4-b2b9-f23c91842e2b",
    name: "Deutsch",
    defaultLocale: "de-DE",
    flag: "de",
  },
};

export const availableLanguagesList = Object.entries(availableLanguages).sort((a, b) =>
  a[0].localeCompare(b[0])
);

export function getCurrentLanguage() {
  const l = i18n.resolvedLanguage ?? "da";
  return availableLanguages[l];
}

export function getResolvedLanguage() {
  return i18n.resolvedLanguage || "da";
}

export function getCurrentLocale() {
  return getCurrentLanguage().defaultLocale;
}

export async function initTranslations() {
  return new Promise((resolve) => {
    const cookie: SessionCookie | undefined = getSessionCookie();
    i18n
      .use(I18NextLocizeBackend)
      .use(initReactI18next)
      .init<LocizeBackendOptions>(
        {
          ns: [
            "common",
            "ui",
            "errors",
            "missing-namespace",
            "customers",
            "planning",
            "projects",
            "invoices",
            "navigation",
            "products",
            "expenses",
            "offers",
            "reports",
            "users",
            "settings",
            "finance",
            "forms",
            "frontpage",
          ],
          defaultNS: ["missing-namespace"], // Should you find anything here, move it!
          debug: false, // Set this to true to debug
          backend: {
            referenceLng: "en",
            projectId: "2fdeb689-2813-4a9e-ad65-48433fa74dab",
            allowedAddOrUpdateHosts: ["localhost"],
            apiKey: import.meta.env.VITE_LOCIZE,
          },
          returnEmptyString: false,
          lng: cookie?.language || "da",
          fallbackLng: "en",
          returnNull: false,
          saveMissing: true,
          parseMissingKeyHandler(key: string) {
            return `[${key}]`; // This is to make it obvious of we are missing a translation
          },
          interpolation: {
            escapeValue: false, // React escapes by default
          },
        },
        resolve
      );
  });
}

i18n.on("languageChanged", (lng) => {
  // Set document language
  document.querySelector("html")?.setAttribute("lang", lng);

  const cookie: SessionCookie | undefined = getSessionCookie();

  if (!cookie || cookie.language === lng) return;

  cookie.language = lng;
  saveCookie(cookie);
});

export { i18n };
