import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import TextInput from "~/lib/ui/form-elements/text-input";
import { useTranslation } from "react-i18next";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

export default function WorkAddressInput({
  formState,
  editMode,
}: {
  formState: OfferFormState;
  editMode: boolean;
}) {
  const { t } = useTranslation();

  const [showEditWorkAddress, setShowEditWorkAddress] = useState<boolean>(false);

  return (
    <div className="flex items-center gap-2">
      {formState.getValue("streetName") &&
        formState.getValue("cityId") &&
        !showEditWorkAddress &&
        editMode && (
          <div
            onClick={() => setShowEditWorkAddress(true)}
            className="-ml-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-tertiary p-1 text-white hover:bg-tertiary-hover"
          >
            <PencilIcon className="h-4 w-4" />
          </div>
        )}
      <div className={twMerge("flex w-full flex-col", editMode ? "pl-2" : "")}>
        {formState.getValue("streetName") &&
        formState.getValue("cityId") &&
        !showEditWorkAddress ? (
          <>
            <span className="font-bold">
              <span>{t("common:work_address")}: </span>
              <span className="font-normal">
                {formState.getValue("streetName")}, {formState.getValue("zipCode")}{" "}
                {formState.getValue("cityName")}
              </span>
            </span>
          </>
        ) : (
          <div className="w-3/4">
            {editMode && (
              <>
                {showEditWorkAddress ? (
                  <div className="flex w-full">
                    <div className="w-full rounded-lg border bg-white p-4">
                      <div className="flex flex-col gap-6">
                        <StreetAddressInput
                          {...formState.register("streetName")}
                          label={t("common:address")}
                          onSelect={(item) => {
                            formState.setValues({
                              streetName: `${item.streetName} ${item.streetNumber}`,
                              zipCode: item.zipCode,
                              cityName: item.cityName,
                              cityId: item.cityId,
                            });
                            setShowEditWorkAddress(false);
                          }}
                        />
                        <div className="flex gap-6">
                          <div>
                            <TextInput
                              {...formState.register("zipCode")}
                              disabled
                              label={t("common:zip_code")}
                            />
                          </div>
                          <TextInput
                            {...formState.register("cityName")}
                            disabled
                            label={t("common:city_name")}
                          />
                        </div>
                        <div className="flex items-center justify-end gap-6">
                          <span
                            onClick={() => setShowEditWorkAddress(false)}
                            className="cursor-pointer justify-center underline hover:text-hover"
                          >
                            {t("common:cancel")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div
                      onClick={() => setShowEditWorkAddress(true)}
                      className="cursor-pointer rounded-lg p-2 hover:bg-shade-200"
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex h-7 w-7 items-center justify-center rounded-full bg-tertiary text-white hover:bg-tertiary-hover">
                          <PlusIcon className="h-4 w-4" />
                        </div>
                        <div>
                          {t("common:add")} {t("projects:worksite_address").toLowerCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
