import { Icon } from "~/lib/ui";
import { OfferAction } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export const AcceptedIcon = () => {
  return (
    <div className="rounded-full bg-green-600 p-1 text-white">
      <Icon name="approve" size="small" />
    </div>
  );
};

export const AcceptedText = ({ action }: { action: OfferAction }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-sm">
        {t(`offers:activity_log.${action.action}`)}
        {` ${t("common:by").toLowerCase()} `}
        <span className="text-sm font-semibold">
          {action.user ? action.user.fullName : t("common:recipient", { count: 1 }).toLowerCase()}
        </span>
      </div>
    </div>
  );
};
