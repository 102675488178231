import { Outlet } from "react-router";

export default function GraphQLPlayground() {
  return (
    <div className="p-6">
      <h1>GraphQL</h1>
      <div className="flex gap-4 font-bold ">
        <a className="hover:underline" href="/playground/graphql/show-more/">
          Show more
        </a>
        <a className="hover:underline" href="/playground/graphql/entity-table/">
          Entity Table
        </a>
      </div>

      <Outlet />
    </div>
  );
}
