import { forwardRef, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { DraggableCard } from "~/pages/planning/_cmp/draggable-card";
import { Spinner } from "~/lib/ui/spinner";
import { DropZone } from "~/pages/planning/_cmp/drag/drop-zone";
import { randomId } from "~/lib/utils/string";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";
import { User } from "@apacta/sdk";
import { CardContextMenu } from "~/pages/planning/_cmp/context/card-context-menu";
import { DropzoneContextMenu } from "~/pages/planning/_cmp/context/dropzone-context-menu";
import { usePlanning } from "~/lib/planning/use-planning";
import { Card, CardTypeEnum } from "~/lib/planning";
import { AddCard } from "~/pages/planning/_cmp/add-card";
import { EmployeeCard } from "~/pages/planning/_cmp/employee-card";
import { twMerge } from "tailwind-merge";
import { matchDates } from "~/lib/utils/date/date-utils";
import { DropZoneOrientation } from "~/pages/planning/_cmp/drag/drag-drop.types";
import { Icon } from "~/lib/ui";
import CardTooltip from "~/pages/planning/_cmp/card-tooltip";

type PlanningSectionDayProps = {
  user?: User;
  cards: Array<Card>;
  loading: boolean;
  initialCollapsed?: boolean;
  isDragging: boolean;
};

type PlanningSectionDayRef = {
  collapse: () => void;
  expand: () => void;
};

type SlideDirection = "left" | "right";

const PlanningSectionDay = forwardRef<PlanningSectionDayRef, PlanningSectionDayProps>(
  (
    { user, cards, loading, initialCollapsed = false, isDragging }: PlanningSectionDayProps,
    ref
  ) => {
    const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);

    const { t } = useTranslation();

    const { sidePanelExpanded } = useOutletContext<OutletContext>();
    const {
      isLoadingCards,
      modalCreate,
      selectedDate,
      cardCanPaste,
      updateCard,
      cardGetTask,
      setSelectedCard,
    } = usePlanning();

    const handleDrop = useCallback(
      (card: Card, details: { index: number }) => {
        const cardUpdateDTO = {
          ...card,
          listIndex: details.index,
          userId: user?.id || null,
          date: selectedDate,
          type: CardTypeEnum.Task,
        };
        const taskUpdateDTO = {};

        updateCard(cardUpdateDTO, taskUpdateDTO, false);
      },
      [updateCard]
    );

    const listRef = useRef<HTMLDivElement | null>();
    const [scrollX, setScrollX] = useState<number>(0);
    const [scrollableX, setScrollableX] = useState<number>(0);

    useEffect(() => {
      if (!isDragging) {
        const list = listRef.current;
        const scrollWidth = list?.scrollWidth || 0;
        const clientWidth = list?.clientWidth || 0;
        setScrollableX(scrollWidth - clientWidth);
      }
    }, [cards, isDragging, sidePanelExpanded]);

    /**
     * @description Handles sliding when clicking on arrows
     * @param direction Determines the direction to slide towards
     */
    const handleSlide = (direction: SlideDirection): void => {
      if (listRef.current) {
        // The distance in pixels that should be slid, positive for right, negative for left
        const scrollAmount = direction === "right" ? 500 : -500;

        // Get the actual position to scroll to using existing scrollX value
        // Using .clamp to ensure that scroll values cannot exceed maximum scrollable distance of the element
        const scrollPosition = (scrollX + scrollAmount).clamp(0, scrollableX);

        // Handle the scrolling smoothly
        listRef.current?.scrollTo({ left: scrollPosition, behavior: "smooth" });

        // Update scrollX with new position value
        setScrollX(scrollPosition);
      }
    };

    const calculateEstimate = (): number => {
      return cards.reduce((sum: number, card) => (sum += card.estimate || 0), 0);
    };

    const getNextIndex = (d: Date): number => {
      return cards.filter(
        (card) => card.date && matchDates(card.date, d, { ignoreTimestamp: true })
      ).length;
    };

    // Currently showing project name as card name
    const getCardName = (card: Card) => {
      const task = cardGetTask({ card });
      if (task) {
        return task?.project ? `${task.project.name}` : t("planning:card.task_name_missing");
      }
      return t("planning:card.task_name_missing");
    };

    return (
      <div className={twMerge("relative flex gap-2 transition-height duration-500")}>
        <EmployeeCard
          user={user}
          collapsed={collapsed}
          allowCollapse={false}
          onTriggerCollapse={(val) => setCollapsed(val)}
          totalEstimates={calculateEstimate()}
        />

        <div
          ref={(e) => (listRef.current = e)}
          className={twMerge(
            "group relative flex h-16 max-h-full w-full items-start overflow-x-hidden border border-r-0 bg-white px-2 py-2"
          )}
        >
          {(isLoadingCards || loading) && (
            <div className="relative flex h-full w-full items-center justify-center">
              <Spinner className="h-8 w-8" />
            </div>
          )}
          {!isLoadingCards &&
            !loading &&
            cards.map((sectionCard, idx, cc) => (
              <div
                key={`card-${sectionCard.id}-${idx}`}
                className={twMerge("flex", idx === cc.length - 1 && isDragging && "grow")}
              >
                <CardContextMenu canPaste={cardCanPaste} card={sectionCard}>
                  <DraggableCard
                    id={sectionCard.id}
                    title={getCardName(sectionCard)}
                    tooltip={<CardTooltip card={sectionCard} />}
                    date={sectionCard.date}
                    startTime={sectionCard.startTime}
                    estimate={sectionCard.estimate}
                    listIndex={sectionCard.listIndex}
                    userId={sectionCard.userId}
                    isCollapsed={collapsed && !!user}
                    onClick={() => setSelectedCard(sectionCard)}
                    labels={cardGetTask({ card: sectionCard })?.labels}
                    onDrop={(card: Card, details: { index: number }) =>
                      handleDrop(card, { index: details.index })
                    }
                  />
                </CardContextMenu>

                {idx === cc.length - 1 && (
                  <DropZone
                    isDragging={isDragging}
                    index={cards.length}
                    isLast={true}
                    onDrop={handleDrop}
                  />
                )}
              </div>
            ))}
          {!isLoadingCards && !loading && (
            <AddCard
              isDragging={isDragging}
              onClick={() =>
                modalCreate({
                  userId: user?.id,
                  index: getNextIndex(selectedDate),
                  date: selectedDate,
                })
              }
              limitWidth={true}
            />
          )}
          {!isLoadingCards &&
            !loading &&
            cards.filter(
              (card) => card.date && matchDates(card.date, selectedDate, { ignoreTimestamp: true })
            ).length === 0 && (
              <Fragment>
                <DropzoneContextMenu
                  userId={user?.id || null}
                  index={0}
                  date={selectedDate}
                  canPaste={cardCanPaste} // if copied element exist
                  className="flex w-full flex-grow flex-col"
                >
                  <>
                    <DropZone
                      key={randomId()}
                      isDragging={isDragging}
                      index={0}
                      isLast={true}
                      onDrop={(card: Card, details: { index: number }) =>
                        handleDrop(card, { index: details.index })
                      }
                      orientation={DropZoneOrientation.VERTICAL}
                      isCollapsed={collapsed && !!user}
                    />
                  </>
                </DropzoneContextMenu>
              </Fragment>
            )}
        </div>
        <div
          onClick={() => handleSlide("left")}
          className={twMerge(
            scrollX < 3
              ? "hidden"
              : "absolute left-0 ml-62 h-full w-24 cursor-pointer bg-gradient-to-r from-white via-white hover:from-gray-200 hover:via-gray-200"
          )}
        >
          <div className="flex h-full w-full items-center justify-center ">
            <Icon name="chevronLeft" className="h-6 w-6" />
          </div>
        </div>
        <div
          onClick={() => handleSlide("right")}
          className={twMerge(
            (!scrollableX || scrollX === scrollableX) && "hidden",
            scrollableX &&
              "absolute right-0 h-full w-24 cursor-pointer bg-gradient-to-l from-white via-white hover:from-gray-200 hover:via-gray-200"
          )}
        >
          <div className="flex h-full w-full items-center justify-center ">
            <Icon name="chevronRight" className="h-6 w-6" />
          </div>
        </div>
      </div>
    );
  }
);

PlanningSectionDay.displayName = "PlanningSectionDay";

export { PlanningSectionDay };
