import { RowData, Table } from "@tanstack/react-table";
import noRowsSVG from "./no-rows.svg";
import { useTranslation } from "react-i18next";

export function TableEmptyPlaceHolder<TData extends RowData>({
  table,
  showLeftActionArea,
}: {
  table: Table<TData>;
  showLeftActionArea: boolean;
}) {
  const { t } = useTranslation();
  const visibleColumnsLength = showLeftActionArea
    ? table.getVisibleFlatColumns().length + 1
    : table.getVisibleFlatColumns().length;

  const rows = table.getRowModel().rows;
  if (rows.length > 0) return null;
  if (table.options?.meta?.isLoading) return null;

  return (
    <tr>
      <td colSpan={visibleColumnsLength} className="text-center">
        <div className="self-center p-4">
          <img className="inline h-48" src={noRowsSVG} />
          <p className="mt-4 italic text-gray-500">{t("common:no_results")}...</p>
        </div>
      </td>
    </tr>
  );
}
