import { Vendor } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export function VendorCell({ vendor }: { vendor?: Vendor }) {
  const { t } = useTranslation();
  if (!vendor) return t("common:not_available");
  return (
    <>
      {vendor.imageUrl ? <img src={vendor.imageUrl} width={60} alt={vendor.name} /> : vendor.name}
    </>
  );
}
