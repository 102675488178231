import { Invoice, InvoiceLine } from "@apacta/sdk";
import { z } from "zod";
import { useFormState } from "~/lib/form-state";

export function useInvoiceFormState(invoice: Invoice) {
  return useFormState({
    schema: {
      customerId: z.string().optional(),
      issueDate: z.date().optional(),
      reference: z.string().optional(),
      message: z.string().optional(),
      lines: z.array(z.custom<InvoiceLine>((line) => line)),
      paymentTermId: z.string().optional(),
    },
    initialValues: {
      customerId: invoice.contactId,
      issueDate: invoice.issuedDate,
      reference: invoice.reference,
      message: invoice.message,
      lines: invoice.invoiceLines ?? [],
      paymentTermId: invoice.paymentTermId,
    },
  });
}
