import { UISchemaElement } from "@jsonforms/core";

export function childWithAncestor(child: UISchemaElement, uischema?: UISchemaElement) {
  return {
    ...child,
    options: {
      ...child.options,
      "x-ancestor": uischema?.options?.["x-ancestor"] ?? uischema?.options?.["x-entity"],
    },
  };
}
