import { Fragment, Suspense } from "react";
import {
  and,
  ArrayLayoutProps,
  composePaths,
  findUISchema,
  isObjectArray,
  rankWith,
} from "@jsonforms/core";
import { Button, getIcon, Label } from "~/lib/ui";
import { JsonFormsDispatch } from "@jsonforms/react";
import { range } from "~/lib/utils/utils";
import { useTranslation } from "react-i18next";
import { childWithAncestor } from "./childWithAncestor";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { isEntityOf } from "./testers";
import ProductPopover from "~/lib/products/product-popover";
import { ProductItem } from "~/lib/products/product-search-content";
import { useAPI } from "~/lib/api";
import { Product } from "@apacta/sdk";

// Custom List renderer for Products
export function ProductLayoutRenderer(props: ArrayLayoutProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const {
    addItem,
    data,
    uischemas,
    schema,
    path,
    uischema,
    rootSchema,
    moveDown,
    moveUp,
    removeItems,
  } = props;

  //if (data === 0) return null;
  const dataArray = range(data);

  async function handleAddProduct(p: ProductItem) {
    let pId: string | undefined = undefined;
    let product: Product | undefined;

    // Create and convert
    // TODO: This behavior should be (imo) inside of ProductItemSearch
    // - but impossible do to the onSelect signature without fetching each created product
    switch (p.type) {
      case "product":
        const productRes = await api.productsView({
          productId: p.item.id,
        });
        pId = p.item.id;
        product = productRes?.data;
        break;
      case "expense_line":
        const expenseRes = await api.createProduct({
          createProductRequest: {
            expenseLineId: p.item.id,
          },
        });
        if (expenseRes.data.id) {
          pId = expenseRes.data.id;
          product = expenseRes.data as Product;
        }
        break;
      case "vendor_product":
        const vpRes = await api.createProduct({
          createProductRequest: {
            vendorProductId: p.item.id,
          },
        });
        if (vpRes.data.id) {
          pId = vpRes.data.id;
          product = vpRes.data as Product;
        }
        break;
    }
    if (!pId) {
      console.warn("Cannot add product. No product-id found");
      return;
    }

    const newProduct = { product_id: pId, quantity: 1, product_unit: product?.unit ?? null };
    addItem(path, newProduct)();
  }

  // TODO: Create SortableList component so we can drag the lines around
  // - OrderLineBuilder does some of it, but not in a re-usable way.

  return (
    <div>
      <Label>{props.label}</Label>
      <div className="flex flex-col gap-4">
        {dataArray.map((i) => {
          const childPath = composePaths(path, `${i}`);
          const ancestor = uischema.options?.["x-ancestor"];
          const listItemUiSchema = findUISchema(
            uischemas ?? [],
            schema,
            uischema.scope,
            path,
            "HorizontalLayout",
            //ancestor ? `${ancestor}-ObjectControl` : "HorizontalLayout", // TODO: Can we do this dynamically?
            uischema,
            rootSchema
          );

          return (
            <Fragment key={childPath}>
              <Suspense fallback={<SkeletonLoader template="box" className="h-16" />}>
                <div className="flex flex-row items-center justify-between gap-4">
                  <JsonFormsDispatch
                    uischema={childWithAncestor(listItemUiSchema, uischema)}
                    schema={schema}
                    path={composePaths(path, `${i}`)}
                    enabled={props.enabled}
                  />
                  <Button
                    onClick={removeItems?.(path, [i])}
                    Icon={getIcon("delete")}
                    size="small"
                  />
                </div>
              </Suspense>
            </Fragment>
          );
        })}
      </div>
      <div className="mt-4">
        <ProductPopover
          triggerRender={() => (
            <Button Icon={getIcon("add")} variant="secondary" size="small">
              {t("common:product", { count: 1 })}
            </Button>
          )}
          onSelect={(item) => handleAddProduct(item)}
        />
      </div>
    </div>
  );
}

export const productListTester = rankWith(4, and(isObjectArray, isEntityOf("products")));
