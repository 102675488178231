import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";

export function useVendors() {
  const api = useAPI();

  const vendorsQuery = useQuery({
    queryKey: ["vendors"],
    queryFn: () => api.getVendors({}),
  });

  const vendors = vendorsQuery.data?.data || [];

  return { vendors, isFetching: vendorsQuery.isFetching };
}
