import { BadgeLabel } from "~/lib/ui/badge-label";
import { LabelEntity } from "@apacta/sdk";
import { Icon } from "../icons/icon";
import colors from "tailwindcss/colors";

export type CategoryLabel = Partial<LabelEntity> & {
  id: string;
  readonly?: boolean;
  onRemove?: (id: string) => void;
};

/**
 * Generates a UI label for a category containing a text and a color.
 * Supports a remove button if the label is not readonly.
 * Automatically adjusts the text color to be readable on the background color.
 * @param text - Text to render
 * @param color - Background color of the label
 * @param readonly - Whether the label should be readonly
 * @param onRemove - Callback when the remove button is clicked
 */
export function UICategoryLabel({
  id,
  text,
  bgColor,
  textColor = colors["white"],
  readonly = false,
  onRemove,
}: CategoryLabel) {
  return (
    <BadgeLabel className="px-3 py-1 text-xs" textColor={textColor} backgroundColor={bgColor}>
      <div className="flex items-center">
        <span className={readonly ? "" : "mr-3"}>{text}</span>

        {!readonly && (
          <div
            className="cursor-pointer rounded-full p-0.5 hover:bg-black/20"
            onClick={() => onRemove?.(id)}
          >
            <Icon name="remove" className="h-3 w-3" />
          </div>
        )}
      </div>
    </BadgeLabel>
  );
}
