import { useMount } from "../lifecycle-helpers";

/**
 * To use this paste the following into every frame page
 * 

<script type="application/javascript">
if (parent) {
  window.addEventListener("load", handleNavigation);

  function handleNavigation(e) {
    parent.postMessage(
      {
        source: "apacta-iframe",
        locationChange: {
          path: location.pathname+location.search,
        },
      },
      "*"
    );
  }
}
</script>
 */

const HIDE_SEARCH_PARAMS = ["api_key", "hide_sidebar", "lang"];

export function useLocationFromFrame(options?: { disabled: boolean }) {
  useMount(() => {
    const handlePostMessage = (
      event: MessageEvent<{
        source: "apacta-iframe";
        locationChange: {
          path: string;
        };
      }>
    ) => {
      if (event?.data?.source === "apacta-iframe" && event.data.locationChange.path) {
        if (window.location.pathname === event.data.locationChange.path) {
          return; // ignore
        }
        if (options?.disabled) {
          // Manually disabled, ignore
          return;
        }

        // The first part of the URL isn't used and pointless to recreate it.
        const url = new URL(`http://example.com${event.data.locationChange.path}`);
        for (const p of HIDE_SEARCH_PARAMS) {
          url.searchParams.delete(p);
        }
        const newPath = url.pathname + url.search;
        window.history.replaceState(undefined, "", newPath);
      }
    };

    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  });
}
