import { ControlProps, isBooleanControl, rankWith } from "@jsonforms/core";
import Switch from "~/lib/ui/switch";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { useDefaultFormValues } from "./useDefaultFormValues";

export function SwitchRenderer(props: ControlProps) {
  useDefaultFormValues(props);
  return (
    <Switch
      label={getLabelForControl(props)}
      checked={!!props.data}
      required={props.required}
      controlled
      error={props.errors}
      onCheckedChange={(checked) => props.handleChange(props.path, checked)}
    />
  );
}
export const switchTester = rankWith(2, isBooleanControl);
