import { Dropdown } from "~/lib/ui/dropdown";
import { Dialog, Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { useLocale } from "~/lib/utils/date";
import { RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameRepeatEnum } from "@apacta/sdk";
import RepetitionDialog, { RepetitionType } from "./repetition-dialog";
import { useState } from "react";
import { TaskFormState } from "~/pages/planning/_cmp/types";

type RepetitionMap = {
  [key in RepetitionType]: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameRepeatEnum;
};

const repetitionTranslationMap: RepetitionMap = {
  day: "daily",
  week: "weekly",
  month: "monthly",
  year: "yearly",
};

const getRepetitionKey = (value: string): RepetitionType => {
  return Object.keys(repetitionTranslationMap).find(
    (key) => repetitionTranslationMap[key as RepetitionType] === value
  ) as RepetitionType;
};

export function RepetitionDropdown({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  const { format, dayName } = useLocale();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const getSuffix = (d: number) => {
    const lastNumber = Number(d.toString().charAt(d.toString().length - 1));
    return lastNumber === 1 ? "st" : lastNumber === 2 ? "nd" : lastNumber === 3 ? "rd" : "th";
  };

  const repetitionTranslation = (
    interval?: number,
    end?: Date | null,
    repeat?: "daily" | "weekly" | "monthly" | "yearly"
  ) => {
    const fromDate = formState.getValue("fromDate") ?? new Date();

    let translatedText = t("planning:no_repeat");
    if (interval && repeat) {
      if (interval === 1) {
        translatedText = t("planning:repeats_x", { x: t(`common:${repeat}`).toLowerCase() });
        if (repeat === "weekly") {
          translatedText += ` ${t("common:every").toLowerCase()} ${dayName(fromDate).toLowerCase()}`;
        }
        if (repeat === "monthly") {
          translatedText += ` ${t("common:date_specific", { date: fromDate.getDate(), suffix: getSuffix(fromDate.getDate()) }).toLowerCase()}`;
        }
        if (repeat === "yearly") {
          translatedText += ` ${t("common:date_the").toLowerCase()} ${format(fromDate, {
            excludeTime: true,
            excludeYear: true,
          })}`;
        }
      } else {
        translatedText = t("planning:repeats_x_every_y_z", {
          x: t(`common:${repeat}`).toLowerCase(),
          y: interval,
          z: t(`common:${getRepetitionKey(repeat)}`, { count: interval }).toLowerCase(),
        });
      }

      if (end) {
        translatedText += `, ${t("common:until").toLowerCase()} ${format(end, { shortDate: true, excludeTime: true })}`;
      }
    }
    return translatedText;
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        render={({ onClose }) => (
          <RepetitionDialog
            defaultRepeat={
              !!formState.getValue("repeat")
                ? getRepetitionKey(formState.getValue("repeat")!)
                : undefined
            }
            defaultInterval={formState.getValue("interval")}
            defaultEndTime={formState.getValue("endTime")}
            onSubmit={({ repeat, interval, endTime }) => {
              formState.setValues({
                repeat: repeat ? repetitionTranslationMap[repeat] : undefined,
                interval,
                endTime,
              });
              onClose();
            }}
            onClose={onClose}
          />
        )}
        className="md:max-w-xs"
      />
      <div className="flex items-center">
        <Dropdown
          options={{
            trigger: {
              asChild: true,
            },
            align: {
              align: "start",
              alignOffset: 0,
            },
          }}
          trigger={
            <button className="flex items-center gap-2 px-3 py-2 hover:bg-shade-100 focus:bg-shade-100">
              <span>
                {repetitionTranslation(
                  formState.getValue("interval"),
                  formState.getValue("endTime"),
                  formState.getValue("repeat")
                )}
              </span>
              <Icon name="chevronDown" className="h-3 w-3" />
            </button>
          }
        >
          <Dropdown.Item
            onSelect={() =>
              formState.setValues({
                repeat: undefined,
                interval: undefined,
                endTime: undefined,
              })
            }
            className="py-1 text-sm hover:bg-shade-100"
          >
            {repetitionTranslation()}
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() =>
              formState.setValues({
                repeat: "daily",
                interval: 1,
                endTime: undefined,
              })
            }
            className="py-1 text-sm"
          >
            {repetitionTranslation(1, undefined, "daily")}
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() =>
              formState.setValues({
                repeat: "weekly",
                interval: 1,
                endTime: undefined,
              })
            }
            className="py-1 text-sm"
          >
            {repetitionTranslation(1, undefined, "weekly")}
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() =>
              formState.setValues({
                repeat: "monthly",
                interval: 1,
                endTime: undefined,
              })
            }
            className="py-1 text-sm"
          >
            {repetitionTranslation(1, undefined, "monthly")}
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() =>
              formState.setValues({
                repeat: "yearly",
                interval: 1,
                endTime: undefined,
              })
            }
            className="py-1 text-sm"
          >
            {repetitionTranslation(1, undefined, "yearly")}
          </Dropdown.Item>
          <Dropdown.Item onSelect={() => setDialogOpen(true)} className="py-1 text-sm">
            {t("common:custom")}
          </Dropdown.Item>
        </Dropdown>
      </div>
    </>
  );
}
