import { and, ControlProps, rankWith } from "@jsonforms/core";

import { isProductUnitEntity } from "./testers";
import { Label } from "~/lib/ui";
import { useTranslation } from "react-i18next";

// Shows search input if no product_id, otherwise shows product name

export function ProductUnitRenderer(props: ControlProps) {
  return <div>{props.data ? <ProductUnitView unit={props.data} /> : null}</div>;
}
function ProductUnitView(props: { unit: string }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-row items-center gap-4">
      <div className="flex flex-col gap-1.5">
        <Label>{t("products:product_unit", { count: 1 })}</Label>
        <div>{props.unit}</div>
      </div>
      <div></div>
    </div>
  );
}

export const productUnitTester = rankWith(4, and(isProductUnitEntity()));
