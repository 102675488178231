import { useTranslation } from "react-i18next";
import { PageLayout } from "~/lib/ui/page-layout";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { useNavigate } from "react-router";
import { Suspense, useEffect, useState } from "react";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { toLocalDateString } from "~/lib/utils/date";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { getDateWithoutTime, getOffsetDate } from "~/lib/utils/date/date-utils";
import { CACHE_CHECKIN, CheckInDialog } from "~/pages/time-registration/_cmp/check-in-dialog";
import { useLocationIfAvailable } from "~/pages/time-registration/_cmp/use-location-if-available";
import Tooltip from "~/lib/ui/tooltip";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { Spinner } from "~/lib/ui/spinner";
import { SuspenseOutletDataWrapper } from "~/pages/time-registration/_cmp/suspense-outlet-data-wrapper";

export default function RegistrationsPage() {
  const [dateFrom, setDateFrom] = useState<Date>(getOffsetDate(new Date(), -28));
  const [dateTo, setDateTo] = useState<Date>(new Date());

  const location = useLocationIfAvailable(true);
  const api = useAPI();

  const [searchParams, setSearchParams] = useTypedSearchParams<{
    dateFrom?: string;
    dateTo?: string;
  }>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const userCheckedInQuery = useQuery({
    queryKey: [CACHE_CHECKIN],
    queryFn: () => api.isCheckedIn({}),
  });

  const isCheckedIn = userCheckedInQuery.data?.data?.checkedIn ?? false;
  const checkedInProjectId = userCheckedInQuery.data?.data?.projectId ?? undefined;

  /**
   * Update the date range if the search params change
   */
  useEffect(() => {
    if (searchParams.dateFrom && searchParams.dateTo) {
      // If searchParams contain dates, update the state
      const _dateFrom = new Date(searchParams.dateFrom);
      const _dateTo = new Date(searchParams.dateTo);

      // Avoid updating the state if the dates are the same
      if (getDateWithoutTime(_dateFrom) !== getDateWithoutTime(dateFrom)) {
        setDateFrom(new Date(searchParams.dateFrom));
      }

      // Avoid updating the state if the dates are the same
      if (getDateWithoutTime(_dateTo) !== getDateWithoutTime(dateTo)) {
        setDateTo(new Date(searchParams.dateTo));
      }
    } else {
      // If searchParams do not contain dates, update the search params
      // This only happens on the initial load of the page
      setSearchParams("dateFrom", toLocalDateString(dateFrom));
      setSearchParams("dateTo", toLocalDateString(dateTo));
    }
  }, [searchParams.dateFrom, searchParams.dateTo]);

  const pageTabs: Array<TabHeaderItem> = [
    {
      id: "overview",
      label: t("common:weekly_overview"),
      to: "",
      Icon: getIcon("entityOverview"),
    },
    {
      id: "specified",
      label: t("common:specified"),
      to: "specified",
      Icon: getIcon("wall"),
    },
  ];

  const handleDateChange = (dateRange: DateRange) => {
    setSearchParams("dateFrom", toLocalDateString(dateRange.from));
    setSearchParams("dateTo", toLocalDateString(dateRange.to));
  };

  return (
    <PageLayout
      title={t("common:registration", { count: 2 })}
      renderActions={() => (
        <div className="flex gap-4">
          <Tooltip
            disabled={!!location}
            trigger={
              <div>
                <Dialog
                  trigger={
                    <Button disabled={!location} Icon={getIcon("time")} variant="secondary">
                      {isCheckedIn ? t("common:check_out") : t("common:check_in")}
                    </Button>
                  }
                  render={({ onClose }) => (
                    <CheckInDialog
                      projectId={checkedInProjectId}
                      userCheckedIn={isCheckedIn}
                      onClose={onClose}
                    />
                  )}
                />
              </div>
            }
          >
            <div className="whitespace-pre-line">{t("forms:location_not_supported")}</div>
          </Tooltip>

          <Button Icon={getIcon("add")} variant="tertiary" onClick={() => navigate("new")}>
            {t("common:create", { entity: t("common:registration", { count: 1 }).toLowerCase() })}
          </Button>
        </div>
      )}
      renderDescription={() => (
        <DateRangePopover
          dateFrom={searchParams.dateFrom ? new Date(searchParams.dateFrom) : undefined}
          dateTo={searchParams.dateTo ? new Date(searchParams.dateTo) : undefined}
          onChange={handleDateChange}
        />
      )}
    >
      <PageTabs tabs={pageTabs} preserveSearchParams />
      <Suspense
        fallback={
          <div className="flex items-center justify-center py-12">
            <Spinner />
          </div>
        }
      >
        <SuspenseOutletDataWrapper dateFrom={dateFrom} dateTo={dateTo} />
      </Suspense>
    </PageLayout>
  );
}
