import { useAPI } from "~/lib/api";
import { useMount } from "~/lib/lifecycle-helpers";

// Example. Delete when we have a real one.
export default function HelloPage() {
  const api = useAPI();
  useMount(() => {
    api.getMe().then((me) => {
      console.log("hello me", me);
    });
  });
  return <div>Hello</div>;
}
