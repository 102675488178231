import { ColumnDef, RowData, Table } from "@tanstack/react-table";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";

/** Display loading status (skeleton rows) or a placeholder if table is empty */
export function TableSkeletonRows<TData extends RowData>(props: {
  table: Table<TData>;
  showLeftActionArea: boolean;
}) {
  const { table } = props;
  const numberOfSkeletonRows = table.options.meta?.skeletonRows ?? 0;
  const skeletonRows = Array(numberOfSkeletonRows).fill({});
  if (!table.options.meta?.isLoading) return null;

  return skeletonRows.map((_, i) => (
    <tr key={i}>
      {props.showLeftActionArea && (
        <td>
          <SkeletonColumn />
        </td>
      )}
      {table.getVisibleFlatColumns().map((column, j) => (
        <td
          key={j}
          className={twMerge(
            column.columnDef.meta?.className,
            column.columnDef.meta?.cellClassName
          )}
        >
          <SkeletonColumn columnDef={column.columnDef} />
        </td>
      ))}
    </tr>
  ));
}

export type TableRowSkeletonType = "default" | "avatar";

function SkeletonColumn<TData extends RowData>({ columnDef }: { columnDef?: ColumnDef<TData> }) {
  let type: TableRowSkeletonType = "default";
  if (columnDef?.id === "name") {
    type = "avatar";
  }
  if (columnDef?.meta?.skeletonType) {
    type = columnDef.meta.skeletonType;
  }

  const className = "h-12 w-full";
  if (type === "avatar") {
    // Primary field typically has an avatar
    return (
      <ContentLoader className={className}>
        <circle className="h-6 w-6" cx={18} cy={20} r={18}></circle>
        <rect className="h-4 w-full" x={45} y={0} rx={5} ry={5}></rect>
        <rect className="h-3 w-full" x={45} y={25} rx={5} ry={5}></rect>
      </ContentLoader>
    );
  }

  return (
    <ContentLoader className={className}>
      <rect className="h-4 w-full" x={0} y={0} rx={5} ry={5}></rect>
      <rect className="h-3 w-full" x={0} y={25}></rect>
    </ContentLoader>
  );
}
