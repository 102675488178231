// Function that takes a string with spaces and converts it to snake case
export const sentenceToSnakeCase = (str: string) => {
  return str.replace(/\s+/g, "_").toLowerCase();
};

// Function that takes a string with spaces and converts it to kebab case
export const sentenceToKebabCase = (str: string) => {
  return str.replace(/\s+/g, "-").toLowerCase();
};

// Function that takes a string with spaces and converts it to camel case
export const sentenceToCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

// Function that takes a string with spaces and converts it to pascal case
export const sentenceToPascalCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return word.toUpperCase();
    })
    .replace(/\s+/g, "");
};
