import { FormSection } from "~/pages/planning/_cmp/crud/sections/form-section";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";

export function FormsTab({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div className="px-3">{t("planning:form_section.description")}</div>
      <FormSection formState={formState} />
    </div>
  );
}
