import { useSession } from "~/lib/auth/session";
import { BACKEND_URL } from "../auth/config";
import { Configuration, DefaultApi } from "@apacta/sdk";

export function useCSVEndpoint() {
  const { apiKey } = useSession();
  const config = new Configuration({
    apiKey,
    basePath: `${BACKEND_URL}`,

    headers: { Accept: "text/csv", Authorization: `Bearer ${apiKey}` },
  });

  return new DefaultApi(config);
}
