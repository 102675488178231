import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { printPercent } from "~/lib/utils/utils";

const baseAreaClass = "flex items-center justify-center text-xs h-10 text-gray-100";

export function PercentBar({
  percentage,
  primaryClassName = "bg-primary text-white",
  overshootClassName = "bg-red-600 text-white",
  remainderClassName = "bg-gray-200 text-gray-600",
}: {
  percentage: number;
  primaryClassName?: string;
  overshootClassName?: string;
  remainderClassName?: string;
}) {
  const { t } = useTranslation();

  // If the percentage is 0 we show one bar with 0% label
  // If percentage is 100 or, higher than 100, we show one element, but with the overshoot inside of it
  if (percentage >= 100 || percentage === 0) {
    return (
      <div className={twMerge("w-full")}>
        <Bar
          percent={percentage}
          className={primaryClassName}
          overShootClassName={overshootClassName}
        />
        <div className="mt-1 w-full text-center text-xs text-gray-500">
          {printPercent(percentage)}
        </div>
      </div>
    );
  }

  // If percentage is lower than 100, then we show two elements, one with the percentage and one with the remainder
  if (percentage < 100) {
    return (
      <div>
        <div
          className={twMerge("flex w-full flex-row justify-between", percentage < 100 && "gap-1")}
        >
          <Bar percent={percentage} className={primaryClassName} />
          <Bar percent={100 - percentage} className={remainderClassName} />
        </div>
        <div className="mt-1 w-full text-center text-xs text-gray-500">
          {printPercent(percentage)}
        </div>
      </div>
    );
  }
  return (
    <div className={twMerge(baseAreaClass, "rounded-md", remainderClassName)}>
      {t("common:not_available")}
    </div>
  );
}

function Bar({
  percent,
  className,
  overShootClassName,
  remainderClassName,
}: {
  percent: number;
  className?: string;
  overShootClassName?: string;
  remainderClassName?: string;
}) {
  if (percent < 0) return null;

  // Overdrive should be red
  if (percent > 100) {
    const remainder = Math.round(percent - 100);
    const hundredPercent = Math.round((100 / percent) * 100);
    const remainderPercent = Math.round((remainder / percent) * 100);

    return (
      <div className="flex w-full">
        <div
          className={twMerge(baseAreaClass, "rounded-l-md", className)}
          style={{ minWidth: `${hundredPercent.clamp(2, 100)}%` }}
        ></div>
        <div
          className={twMerge(baseAreaClass, className, "rounded-r-md", overShootClassName)}
          style={{ width: `${remainderPercent.clamp(0, 100)}%` }}
        ></div>
      </div>
    );
  }

  return (
    <>
      <div
        className={twMerge(
          baseAreaClass,
          "rounded-md bg-gray-300 text-gray-600",
          percent === 0 ? remainderClassName : className
        )}
        style={{
          width: percent === 0 ? "100%" : `${percent.clamp(0, 100)}%`,
        }}
      ></div>
    </>
  );
}
