import { Button, Icon } from "../..";
import { Ref, forwardRef } from "react";
import { TriggerComponentProps } from "../combobox-types";
import { Spinner } from "../../spinner";

export const DefaultMultiSelectTrigger = forwardRef(function TriggerInner(
  {
    selectedItems,
    labelFn,
    valueFn,
    open,
    icon,
    loading,
    onRemoveItem,
    placeholder,
    readonly,
    // This is to prevent passing these props to the button
    ...props
  }: TriggerComponentProps,
  ref: Ref<HTMLButtonElement>
) {
  const renderedLabel = (idx: number) => {
    if (!selectedItems[idx]) return placeholder;
    return labelFn(selectedItems[idx]);
  };

  return (
    <div role="combobox" className="block w-full text-sm">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex min-h-[2rem] flex-wrap items-center gap-2">
          {selectedItems?.map((item, idx) => (
            <div
              key={valueFn(item)}
              className="inline-flex select-none items-center rounded-full bg-shade-300 px-3 py-1 text-xs font-semibold"
            >
              <span className="mr-2">{renderedLabel(idx)}</span>
              {!readonly && (
                <Button
                  onClick={() => onRemoveItem?.(valueFn(item))}
                  className="cursor-pointer rounded-md p-0.5 hover:bg-black/20"
                >
                  <Icon name="remove" className="h-3 w-3" />
                </Button>
              )}
            </div>
          ))}
          {readonly ? null : (
            <Button
              {...props}
              ref={ref}
              disabled={loading}
              className="flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-shade-300 p-1 hover:bg-hover hover:text-white"
            >
              {loading ? <Spinner className="h-4 w-4" /> : <Icon name="add" className="h-4 w-4" />}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});
