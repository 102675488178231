import { ReactNode } from "react";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useTranslation } from "react-i18next";
import { getIcon } from "~/lib/ui";

export function ArrayListItem({
  children,
  onRemove,
  onMoveDown,
  onMoveUp,
  onSelect,
  isFirst,
  isLast,
  hideArrows = false,
}: {
  onRemove?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onSelect?: () => void;
  children: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  hideArrows?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className="my-2 flex flex-row items-end justify-between gap-4">
      {children}
      <ActionButtons
        collapseAt={2}
        size="small"
        actions={[
          {
            label: t("common:edit"),
            onClick: () => onSelect?.(),
            Icon: getIcon("edit"),
            collapseBehavior: "never",
          },
          {
            label: t("common:move_up", "Move up"),
            onClick: () => onMoveUp?.(),
            Icon: getIcon("moveUp"),
            isHidden: isFirst || hideArrows,
          },
          {
            label: t("common:move_down", "Move down"),
            onClick: () => onMoveDown?.(),
            Icon: getIcon("moveDown"),
            isHidden: isLast || hideArrows,
          },
          {
            label: t("common:remove"),
            onClick: () => onRemove?.(),
            Icon: getIcon("delete"),
            collapseBehavior: "never",
          },
        ]}
      />
    </div>
  );
}
