import { useNavigate, useParams } from "react-router";
import { FormEditView } from "~/lib/json-forms/form-edit-view";

export default function RegistrationPage() {
  const { formId } = useParams<{
    formId: string;
  }>();

  const navigate = useNavigate();
  // TODO - Add onBackClick={() => navigate("/time-registration")} before release
  return (
    <FormEditView
      mode="stand-alone"
      formId={formId!}
      onBackClick={() => navigate("/time-registration")}
    />
  );
}
