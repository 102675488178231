import { User } from "@apacta/sdk";
import { UserAvatar } from "../../avatar";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { linkToEmployee } from "~/lib/utils";
import Avatar from "../../avatar/avatar";
import { AVATAR_CLASS } from "./settings";

type EmployeeCellProps =
  | {
      fullName: string;
      userId?: string;
      imageUrl?: string;
      user?: never;
    }
  | { user: User; fullName?: never; userId?: never; imageUrl?: never };

const CONTAINER_CLASS = "flex min-w-36 items-center";

export function EmployeeCell({ user, ...userDetails }: EmployeeCellProps) {
  if (user) {
    return (
      <div className={CONTAINER_CLASS}>
        <UserAvatar className={AVATAR_CLASS} user={user} />
        <OptionalLink to={linkToEmployee(user.id)} className="ml-2">
          {user.fullName}
        </OptionalLink>
      </div>
    );
  }

  const { fullName, userId, imageUrl } = userDetails;

  return (
    <div className={CONTAINER_CLASS}>
      <Avatar className={AVATAR_CLASS} name={fullName} image={imageUrl} />
      <OptionalLink to={userId ? linkToEmployee(userId) : undefined} className="ml-2">
        {fullName}
      </OptionalLink>
    </div>
  );
}
