import { CreateInvoiceOperationRequest } from "@apacta/sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useFormState } from "~/lib/form-state";
import { useGraphQL } from "~/lib/gql";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { CheckboxLine } from "~/lib/ui/form-elements/checkbox-line";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";
import { formatCurrency, roundToDecimal } from "~/lib/utils/number";

export function CreateInvoiceFixed({
  projectId,
  onSubmit,
  onClose,
}: {
  onSubmit: (request: CreateInvoiceOperationRequest) => void;
  onClose: () => void;
  projectId: string;
}) {
  const gql = useGraphQL();
  const { t } = useTranslation();

  const invoicedTotalQ = useSuspenseQuery({
    queryKey: ["queryForTotalInvoice", projectId],
    queryFn: () =>
      gql.createFixedInvoiceProjectDetails({
        projectId,
      }),
  });

  const formState = useFormState({
    schema: {
      totalSalesPrice: z.number().positive(),
      notInvoicedAmount: z.number(),
      isFinalInvoice: z.boolean().optional(),
      hideUsagePrices: z.boolean().optional(), // Hide hour/product pricing
      completionPercent: z.number().optional(),
      amountToInvoice: z.number().optional(),
    },
    initialValues: {
      totalSalesPrice: invoicedTotalQ.data?.project.totalSalesPrice ?? 0,
      notInvoicedAmount: invoicedTotalQ.data?.project.notInvoicedAmount ?? 0,
      isFinalInvoice: false,
      hideUsagePrices: false,
      completionPercent: invoicedTotalQ.data?.project.completionPercentage ?? 0,
      amountToInvoice: invoicedTotalQ.data?.project.notInvoicedAmount ?? 0,
    },
  });

  const values = formState.getValues();

  function handleCompletionPercentageChange(v: number) {
    formState.onChange("completionPercent", v);
    const amount = roundToDecimal((values.notInvoicedAmount / 100) * v);
    formState.onChange("amountToInvoice", amount);
  }

  function handleAmountToInvoiceChange(v: number) {
    formState.onChange("amountToInvoice", v);
    const percent = roundToDecimal((v / values.notInvoicedAmount) * 100);
    if (percent === Infinity) {
      formState.onChange("completionPercent", undefined);
    } else {
      formState.onChange("completionPercent", percent);
    }
  }

  return (
    <>
      <div className="my-4 flex flex-col gap-2">
        <div className="mb-2 rounded-md border p-4 font-semibold">
          <div className="flex flex-row justify-between ">
            <span>{t("finance:total_sales_price", "Total sales price")}</span>
            <span>{formatCurrency(values.totalSalesPrice)}</span>
          </div>
          <div className="flex flex-row justify-between">
            <span>{t("finance:total_invoiced_amount", "Total invoiced amount")}</span>
            <span>{formatCurrency(invoicedTotalQ.data.project.totalInvoicedAmount ?? 0)}</span>
          </div>
          <div className="flex flex-row justify-between">
            <span>{t("finance:not_invoiced_amount", "Not invoiced amount")}</span>
            <span>{formatCurrency(values.notInvoicedAmount)}</span>
          </div>
        </div>

        <CheckboxLine
          label={t("invoices:is_final_invoice", "Is final invoice")}
          checked={values.isFinalInvoice}
          onChange={(e) => formState.onChange("isFinalInvoice", e.currentTarget.checked)}
        />
        <CheckboxLine
          label={t("invoices:hide_usage_prices", "Hide hour/product pricing")}
          checked={values.hideUsagePrices}
          onChange={(e) => formState.onChange("hideUsagePrices", e.currentTarget.checked)}
        />

        {!values.isFinalInvoice && (
          <div className="mt-4">
            <h4>{t("invoices:what_do_you_want_to_invoice")}</h4>
            <div className="mt-6 flex flex-row justify-between">
              <div>
                <NumberFormatInput
                  label={t("invoices:completion_percent", "Completion percent")}
                  value={values.completionPercent}
                  onChange={handleCompletionPercentageChange}
                />
              </div>
              <div className="text-xs text-shade-500">{t("common:or", "or")}</div>
              <div>
                <NumberFormatInput
                  label={t("common:amount")}
                  value={values.amountToInvoice}
                  className="text-right"
                  onChange={handleAmountToInvoiceChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <DialogFooter
        onClose={onClose}
        primary={{
          label: t("common:create", {
            defaultValue: "Create {{entity}}",
            replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
          }),
          onClick: () =>
            onSubmit({
              createInvoiceRequest: {
                projectId: projectId,
                isFinalInvoice: values.isFinalInvoice,
                hideUsagePrices: values.hideUsagePrices,
                completionPercentage: values.completionPercent,
                amount: values.amountToInvoice,
              },
            }),
        }}
      />
    </>
  );
}
