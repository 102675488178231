import DatePicker from "~/lib/ui/calendar/date-picker";
import { daysBetween, getOffsetDate, getWeekNumbersInPeriod } from "~/lib/utils/date/date-utils";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PlanningView } from "~/pages/planning/_cmp/types";
import { Button, Icon } from "~/lib/ui";

export default function WeekControls({
  selectedDate,
  shownDates,
  planningView = "week",
  onDateChange,
}: {
  selectedDate: Date;
  shownDates: Array<Date>;
  planningView?: PlanningView;
  onDateChange: (date: Date) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const shownWeekNumbers = getWeekNumbersInPeriod(shownDates);

  const setDate = (offset: number) => {
    const d: Date = getOffsetDate(selectedDate, offset);
    onDateChange(d);
  };

  const resetDate = () => {
    if (searchParams.has("date")) {
      searchParams.delete("date");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="flex gap-8">
      <div className="flex flex-grow items-center justify-center gap-4">
        <Button variant="secondary" onClick={() => setDate(-7)} className="xl:py-auto py-3 text-xs">
          <Icon name="chevronLeft" className="h-3 w-3" />
        </Button>
        <div className="w-fit text-center text-xl">
          {t("common:week")}{" "}
          {planningView === "week"
            ? `${shownWeekNumbers[0]} - ${selectedDate.getFullYear()}`
            : `${shownWeekNumbers[0]} & ${shownWeekNumbers[1]}`}
        </div>
        <Button variant="secondary" onClick={() => setDate(7)} className="xl:py-auto py-3 text-xs">
          <Icon name="chevronRight" className="h-3 w-3" />
        </Button>
      </div>
      <div className="flex items-center gap-4">
        <Button variant="secondary" className="flex-shrink-0 text-sm" onClick={() => resetDate()}>
          {t("planning:view.week.now")}
        </Button>
        <DatePicker value={selectedDate} onChange={(e) => setDate(daysBetween(selectedDate, e))}>
          <Button variant="secondary" className="xl:py-auto px-3 py-2 text-xs xl:text-base">
            <Icon name="calendar" className="h-5 w-5" />
          </Button>
        </DatePicker>
      </div>
    </div>
  );
}
