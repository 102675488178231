import Badge from "~/lib/ui/badge";
import { NavItem } from "./navigation-item.type";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { useSidebarContext } from "../../../sidebar-context";

// This is what we render inside the button, link or navlink for the navigation item
export function InnerItem({ item, open }: { item: NavItem; open?: boolean }) {
  const { sidebarCollapsed, sidebarType } = useSidebarContext();

  const showMiniVersion = sidebarCollapsed && sidebarType === "laptop";

  return (
    <span className="inline-flex w-full">
      {item.icon ? (
        <item.icon
          className={
            !showMiniVersion
              ? "mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover"
              : "h-7 w-7 flex-shrink-0 text-gray-200 hover:bg-hover"
          }
          aria-hidden="true"
        />
      ) : null}
      <span className="inline-flex w-full flex-row justify-between whitespace-nowrap rounded-lg">
        {!showMiniVersion && (
          <span className="inline-flex">
            {item.name}
            {item.badge ? (
              <Badge
                className={twMerge("ml-4", item.badge.className)}
                size="sm"
                label={item.badge.label}
              />
            ) : null}
          </span>
        )}
        <span>
          {open !== undefined ? (
            <Icon
              name={open === true ? "chevronUp" : "chevronDown"}
              className="mr-2 h-5 w-5 flex-shrink-0 transform text-gray-200 transition-colors duration-150 ease-in-out group-hover:text-white"
            />
          ) : null}
        </span>
      </span>
    </span>
  );
}
