import { useTranslation } from "react-i18next";
import { CustomersTable } from "./_cmp/customers-table";

import { PageLayout } from "~/lib/ui/page-layout";
import { useNavigate } from "react-router";
import { linkToCustomer } from "~/lib/utils";
import { CreateCustomerDialog } from "./_cmp/create-customer-dialog";
import { Dialog } from "~/lib/ui/dialog";
import { Button } from "~/lib/ui/buttons/button";
import { getIcon } from "~/lib/ui";

export const CACHE_CUSTOMERS = "customers";

export default function CustomersPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout
      title={t("customers:title")}
      renderActions={() => (
        <>
          <Dialog
            trigger={
              <Button variant="tertiary" className="print:hidden" Icon={getIcon("add")}>
                {t("common:create", {
                  defaultValue: "Create {{entity}}",
                  replace: { entity: t("common:customer", { count: 1 }).toLocaleLowerCase() },
                })}
              </Button>
            }
            render={({ onClose }) => (
              <CreateCustomerDialog
                onCustomerCreated={(customerId) => navigate(linkToCustomer(customerId))}
                onOpenChange={onClose}
              />
            )}
          />
        </>
      )}
    >
      <CustomersTable parentType="page" />
    </PageLayout>
  );
}
