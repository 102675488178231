import { User } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { formatDecimals } from "~/lib/utils/number";
import { generateBackgroundColor } from "~/lib/utils/colors";
import colors from "tailwindcss/colors";
import { Icon } from "~/lib/ui";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";

export function EmployeeCard({
  user,
  allowCollapse = true,
  collapsed,
  totalEstimates,
  onTriggerCollapse,
}: {
  user?: User;
  allowCollapse?: boolean;
  collapsed: boolean;
  totalEstimates: number;
  onTriggerCollapse: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleCollapse = (val: boolean): void => {
    onTriggerCollapse(val);
  };

  return (
    <div
      className="relative ml-8 flex h-16 w-64 select-none items-center gap-2 border border-t-4 bg-white px-1 text-sm"
      style={{
        borderTopColor: user ? generateBackgroundColor(user.fullName) : colors["gray"][400],
      }}
    >
      {allowCollapse && (
        <div className="absolute right-1.5 top-1.5">
          <button
            title={collapsed ? t("common:expand") : t("common:collapse")}
            onClick={() => handleCollapse(!collapsed)}
            className="rounded-full p-0.5 hover:bg-shade-100"
          >
            {collapsed ? (
              <Icon name="expandRow" className="h-4 w-4" />
            ) : (
              <Icon name="retractRow" className="h-4 w-4" />
            )}
          </button>
        </div>
      )}
      <div className="rounded-full border bg-white p-1 shadow-sm">
        {!!user ? (
          <UserAvatar user={user} className="h-10 w-10" />
        ) : (
          <div className="h-10 w-10 rounded-full bg-shade-400" />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div
          title={user?.fullName ?? t("planning:employee_section.not_assigned")}
          className="line-clamp-1 pr-6"
        >
          {user?.fullName ?? t("planning:employee_section.not_assigned")}
        </div>
        <div className="flex items-center gap-2 text-xs">
          <div className="flex items-center gap-2">
            <Icon name="time" className="h-4 w-4" />
            <div>{formatDecimals(totalEstimates)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
