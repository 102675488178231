import { useOutletContext } from "react-router";
import { InvoiceOutlet } from "../[invoiceId]";
import { useTranslation } from "react-i18next";
import { CustomerSelection } from "~/lib/ui/selection-combobox/customer-selection";
import DateInput from "~/lib/ui/calendar/date-input";
import { LabelInput } from "~/lib/ui";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import OrderLinesBuilderProvider from "~/lib/ui/order-lines/order-lines-builder-context";
import { invoiceLineToOrderLine } from "../../../lib/ui/order-lines/lib/transformers/invoiceline-to-orderline";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { orderLineToInvoiceLine } from "../../../lib/ui/order-lines/lib/transformers/orderline-to-invoiceline";
import { OrderLinesBuilderOnChangeReturn } from "~/lib/ui/order-lines/lib/types";
import { RichTextEditor } from "~/lib/ui/rich-text-editor";
import { useMe } from "~/lib/auth/use-me";
import { PaymentConditionSelection } from "~/pages/customers/_cmp/payment-condition-selection";
import { useState } from "react";
import { CustomerDetailCard } from "./customer-detail-card";
import { Contact } from "@apacta/sdk";
import { InvoiceKPISection } from "./invoice-kpi-section";

const INVOICE_DESCRIPTION_CHARACTER_LIMIT = 248;

export function InvoiceEditPage() {
  const { t } = useTranslation();
  const me = useMe();

  const { invoice, formState } = useOutletContext<InvoiceOutlet>();
  const [customer, setCustomer] = useState(invoice.contact);

  const handleAddLine = (d: OrderLinesBuilderOnChangeReturn) => {
    const invoiceLines = d.orderLines.map((v) => orderLineToInvoiceLine(v, invoice.id));
    formState.setValues({ lines: invoiceLines }, d.isInitial);
  };

  const values = formState.getValues();

  function handleCustomerSelect(c: Contact) {
    formState.onChange("customerId", c.id);
    setCustomer(c);
  }

  return (
    <div>
      <TabHeading>{t("common:details")}</TabHeading>
      {!invoice.project.isFixedPrice && <InvoiceKPISection />}
      <div className="rounded-md border border-gray-300 bg-white p-8">
        <div className="mb-4 flex w-full flex-row flex-wrap justify-between gap-8">
          <div className="w-full flex-1">
            <CustomerSelection
              controlled
              value={formState.getValue("customerId")}
              onSelect={(_id, c) => handleCustomerSelect(c)}
              onInitialEntity={handleCustomerSelect}
            />
            {values.customerId && <CustomerDetailCard customer={customer} />}
          </div>

          <div className="flex flex-1 flex-col gap-4">
            <DateInput
              label={t("invoices:invoice_date")}
              value={formState.getValue("issueDate")}
              onChange={(v) => formState.onChange("issueDate", v)}
            />
          </div>
        </div>
        <div className="mb-4">
          <LabelInput
            {...formState.registerStringInput("reference")}
            label={t("common:reference")}
          />
        </div>

        <RichTextEditor
          initialData={formState.getValue("message")}
          onChange={(text) => formState.onChange("message", text)}
          characterLimit={INVOICE_DESCRIPTION_CHARACTER_LIMIT}
          disableRichText={true}
          editorTheme={{
            paragraph: "text-base",
          }}
        />

        <div className="mt-4">
          <OrderLinesBuilderProvider
            data={formState.getValue("lines")}
            transformerFn={invoiceLineToOrderLine}
            onChange={handleAddLine}
            initialVatPercent={me.company.vatPercent ?? 25}
          >
            <OrderLinesBuilder
              editMode={true}
              hideProductBundleContent={true}
              buttonSizes="small"
              usePlainTextEditor={true}
              options={{
                orderLinesActionVisibility: {
                  showAddProduct: true,
                  showAddHours: true,
                  showAddProductBundle: false,
                  showAddText: true,
                  showSubtotal: true,
                  showVAT: true,
                  showTotal: true,
                  sumCostPrice: false,
                },
                columns: {
                  product: {
                    visible: true,
                  },
                  costPrice: {
                    visible: true,
                    readOnly: true,
                  },
                },
              }}
              companyVatPercent={me.company.vatPercent}
            />
          </OrderLinesBuilderProvider>
        </div>
        <div className="max-w-[20em]">
          <PaymentConditionSelection
            initialConditionId={invoice.paymentTermId}
            onChange={(v) => formState.onChange("paymentTermId", v?.id)}
          />
        </div>
      </div>
      <div className="mt-6 rounded-md border border-gray-300 bg-white p-6 text-center">
        <div>{`${me.company.name} - ${me.company.streetName} - ${me.company.city?.zipCode}, ${me.company.city?.name}`}</div>
        <div>
          {`Phone: ${me.company.phone}`} -{" "}
          {`Email: ${me.company.invoiceEmail ?? me.company.contactEmail}`}
        </div>
      </div>
    </div>
  );
}
