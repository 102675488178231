import { ControlProps, isNumberControl, rankWith } from "@jsonforms/core";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { NumberInput } from "~/lib/ui/form-elements";
import { useDefaultFormValues } from "./useDefaultFormValues";

// Handles any number. Not really great at floats/currency
export function NumberRenderer(props: ControlProps) {
  useDefaultFormValues(props);
  return (
    <div className="w-28">
      <NumberInput
        label={getLabelForControl(props)}
        type="number"
        value={props.data ?? 0}
        required={props.required}
        onChange={(e) => props.handleChange(props.path, Number(e.currentTarget.value))}
      />
    </div>
  );
}
export const NumberRendererTester = rankWith(4, isNumberControl);
