import { PageError } from "~/pages/p/offer/_cmp/page-error";
import { useTranslation } from "react-i18next";

export default function OfferFallbackPage() {
  const { t } = useTranslation();
  return (
    <PageError
      title={t("offers:public_offer_page.not_found.title")}
      description={t("offers:public_offer_page.not_found.description")}
    />
  );
}
