import { useTranslation } from "react-i18next";
import { ControlPanelApiOfferStatusResponse } from "@apacta/sdk";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useMemo, useState } from "react";

export type OfferStatusItem = { translatedLabel: string } & ControlPanelApiOfferStatusResponse;

export function useOfferStatus(selectedStatusId?: string) {
  const [offerStatusSearchQuery, setOfferStatusSearchQuery] = useState<string>("");

  const api = useAPI();

  const offerStatusQ = useQuery({
    queryKey: ["offerStatus"],
    queryFn: () => api.iListOfferStatuses({}),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });

  const { t } = useTranslation();

  const offerStatusTranslationMap: { [key: string]: string } = {
    accepted: t("offers:status.accepted"),
    rejected: t("offers:status.rejected"),
    draft: t("offers:status.draft"),
    sent: t("offers:status.sent"),
    sent_and_seen: t("offers:status.sent_and_seen"),
    expired: t("offers:status.expired"),
    deleted: t("offers:status.deleted"),
  };

  const offerStatusLabel = (item?: ControlPanelApiOfferStatusResponse): string => {
    if (
      item?.identifier &&
      Object.keys(offerStatusTranslationMap).includes(item?.identifier || "")
    ) {
      return offerStatusTranslationMap[item.identifier];
    }

    return t("offers:status.unknown");
  };

  const offerStatuses =
    offerStatusQ.data?.data.map((item) => {
      return {
        ...item,
        translatedLabel: offerStatusLabel(item),
      };
    }) || [];

  const offerStatus = offerStatuses?.find((v) => v.id === selectedStatusId);
  const offerStatusLoading = offerStatusQ.isLoading;

  const filteredOfferStatuses = useMemo(() => {
    if (offerStatusSearchQuery === "") {
      return offerStatuses;
    }
    return offerStatuses.filter((item) => {
      return (
        item.translatedLabel.toLowerCase().includes(offerStatusSearchQuery.toLowerCase()) ||
        item.identifier.toLowerCase().includes(offerStatusSearchQuery.toLowerCase())
      );
    });
  }, [offerStatusSearchQuery, offerStatuses]);

  return {
    offerStatus,
    offerStatuses,
    filteredOfferStatuses,
    offerStatusLoading,
    offerStatusLabel,
    setOfferStatusSearchQuery,
  };
}
