import { HTMLProps, Ref, forwardRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface DivDropZoneProps extends HTMLProps<HTMLDivElement> {
  onFilesDropped: (files: FileList) => Promise<unknown>;
  disabled?: boolean; // With such a large drop-zone it can cause conflicts
}

/**
 * A div that can be used as a drop zone for files.
 * Nothing special, except it adds a border when files are dragged over it.
 *
 * Override at your own peril
 */
export const DivDropZone = forwardRef(function DivDropZone(
  { onFilesDropped, disabled, ...props }: DivDropZoneProps,
  ref: Ref<HTMLDivElement>
) {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  async function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    if (disabled) return;

    e.preventDefault();
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
      return;
    }
    try {
      await onFilesDropped(e.dataTransfer.files);
    } finally {
      setIsDragOver(false);
    }
  }

  return (
    <div
      ref={ref}
      onDrop={handleDrop}
      onDragOverCapture={(e) => {
        e.dataTransfer.dropEffect = "copy";
      }}
      onDragEnter={(e) => {
        setIsDragOver(true);
        e.dataTransfer.dropEffect = "copy";
      }}
      onDragLeave={() => setIsDragOver(false)}
      onDragOver={(e) => e.preventDefault()}
      className={twMerge(
        "cursor-auto",
        isDragOver ? "outline-dashed outline-2 outline-shade-300" : ""
      )}
      {...props}
    />
  );
});
