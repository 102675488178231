import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";
import { Checkbox } from "~/lib/ui/form-elements";
import { useId } from "react";

export function WeekendSection({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  const includeWeekendsId = useId();
  return (
    <div className="w-fit">
      <label
        htmlFor={includeWeekendsId}
        className="flex cursor-pointer items-center gap-4 border-b-2 border-b-transparent px-3 py-2 focus-within:cursor-pointer focus-within:border-b-hover focus-within:bg-shade-100 hover:bg-shade-100"
      >
        <div className="w-fit">
          <div className="flex w-full items-center gap-2">
            <span>{t("planning:create_modal.include_weekends")}</span>
          </div>
        </div>
        <Checkbox
          id={includeWeekendsId}
          className="mr-0 h-4 w-4 cursor-pointer"
          defaultChecked={formState.getValue("includeWeekends")}
          onChange={(e) => formState.setValues({ includeWeekends: e.currentTarget.checked })}
        />
      </label>
    </div>
  );
}
