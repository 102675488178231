import { ControlProps, isEnumControl, rankWith } from "@jsonforms/core";
import { useId } from "react";
import { useTranslation } from "react-i18next";
import { LabeledSelect } from "~/lib/ui/form-elements/labeled-select";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { useDefaultFormValues } from "./useDefaultFormValues";

export function EnumRenderer(props: ControlProps) {
  useDefaultFormValues(props);
  const { t } = useTranslation();
  const selectId = useId();
  return (
    <LabeledSelect
      id={selectId}
      label={getLabelForControl(props, t("common:untitled"))}
      defaultValue={props.data ?? props.schema.default}
      error={props.errors}
      required={props.required}
      onChange={(e) => {
        let val: string | undefined = e.currentTarget.value;
        if (val === "") val = undefined;
        props.handleChange(props.path, val);
      }}
    >
      {!props.schema.default && (
        <option defaultChecked value="">
          {t("common:pick")}
        </option>
      )}

      {props.schema.enum?.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </LabeledSelect>
  );
}
export const enumControlTester = rankWith(3, isEnumControl);
