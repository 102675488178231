import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { AddressLookup } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { AutoCompleteInput } from "~/lib/ui/autocomplete";
import { useState } from "react";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { BriefcaseIcon, GlobeAltIcon, GlobeEuropeAfricaIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { useToasts } from "~/lib/toast/use-toasts";
import { useMe } from "~/lib/auth/use-me";
import { sleepAsync } from "~/lib/utils/utils";

// TextInput that looks up addresses, onSelect can be used to help fill out other fields
export function AddressOnelineInput({
  onChange,
  onSelect,
  label,
  placeholder,
  initialValue,
  className,
}: {
  label?: string;
  onChange?: (val: string) => void;
  onSelect?: (args: { addressLine: string; latitude?: string; longitude?: string }) => void;
  initialValue?: string;
  placeholder?: string;
  className?: string;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const [internalValue, setInternalValue] = useState(initialValue);
  const q = useQuery({
    queryKey: ["addressLookup", internalValue],
    queryFn: async ({ signal }: { signal: AbortSignal }) => {
      await sleepAsync(500);
      if (signal?.aborted) {
        return { data: [] };
      }
      return api.autoCompleteAddress({ address: internalValue as string }, { signal });
    },
    enabled: !!internalValue,
  });
  const { show: addToast } = useToasts();
  const { company } = useMe();

  function handleSelect(i: AddressLookup) {
    let addressLine = `${i.streetName}`;
    if (i.streetNumber) addressLine += ` ${i.streetNumber}`;
    if (i.zipCode) addressLine += `, ${i.zipCode}`;
    if (i.city) addressLine += `, ${i.city}`;
    setInternalValue(addressLine);
    onSelect?.({
      addressLine,
      latitude: i.latitude?.toString(),
      longitude: i.longitude?.toString(),
    });
  }

  // Attempt to fetch location from device
  // If found, attempt to find address from coordinates
  function handleLocationLookup() {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const res = await api.reverseLookup({ latitude: latitude, longitude });
        if (res.success) {
          const { streetName, streetNumber, country, postalCode, locality } = res.data;
          const addressLine = `${streetName} ${streetNumber}, ${postalCode}, ${locality}, ${country}`;
          setInternalValue(addressLine);
          onSelect?.({
            addressLine,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
          });
        } else {
          alert("No address found");
        }
      },
      () => {
        addToast({
          Icon: GlobeAltIcon,
          title: t("forms:location_error", "Location error"),
          description: t("forms:location_error_description", "Could not get location from device"),
          variant: "error",
        });
      }
    );
  }

  function handleCompanyAddress() {
    if (!company) return;
    const { streetName, city } = company;
    const addressLine = `${streetName}, ${city?.zipCode}, ${city?.name}`;
    setInternalValue(addressLine);
    onChange?.(addressLine);
  }

  return (
    <div className="flex flex-row gap-4">
      <AutoCompleteInput
        label={label}
        items={q.data?.data || []}
        loading={q.isLoading}
        value={internalValue}
        displayFn={(item) => {
          const postfix = `${item.zipCode}, ${item.city}`;
          let street = `${item.streetName}`;
          if (item.streetNumber) street += ` ${item.streetNumber}`;
          if (item.subLocality) street += `, ${item.subLocality}`;
          return `${street}, ${postfix}`;
        }}
        placeholder={placeholder ?? t("common:address")}
        onSelect={handleSelect}
        className={twMerge("w-full", className)}
        onChange={(val) => {
          setInternalValue(val);
          onChange?.(val);
        }}
      />
      <ActionButtons
        collapseAt={0}
        size="small"
        actions={[
          {
            label: t("forms:driving.destination.use_current_location", "Use current location"),
            onClick: handleLocationLookup,
            Icon: GlobeEuropeAfricaIcon,
          },
          {
            label: t("forms:driving.destination.company_address", "Use company address"),
            onClick: handleCompanyAddress,
            Icon: BriefcaseIcon,
          },
        ]}
      />
    </div>
  );
}
