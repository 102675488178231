import { TimeValue } from "~/lib/ui/form-elements";

/**
 * Given a date and a time value, return a new date with time values set
 * This is used strictly for setting the start time of a task
 * We do not need this functionality anywhere else
 * as planning is the only place in the universe where time is not a string
 * @param {Date} d - The date to set the time on
 * @param {TimeValue} time - The hour and minute marks to set on the date
 * @returns {Date | null} - Date with details [d.year, d.month, d.day, time.hour, time.minute]
 */
export const formatStartTime = (d: Date, time: TimeValue): Date | null => {
  const formattedStartTime =
    time.hour !== undefined && time.minute !== undefined && d ? new Date(d) : null;

  if (formattedStartTime && time.hour !== undefined && time.minute !== undefined) {
    formattedStartTime.setHours(time.hour);
    formattedStartTime.setMinutes(time.minute);
    formattedStartTime.setSeconds(0);
    formattedStartTime.setMilliseconds(0);
  }

  return formattedStartTime;
};
