import { Project } from "@apacta/sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { capitalize } from "~/lib/utils/string";
import { KPIDataCard } from "~/pages/projects/_cmp/kpi-data-card";
import { useProjectParams } from "~/pages/projects/_cmp/use-project-params";

export default function ProjectFinanceCombined() {
  const { entity: project } = useOutletContext<{ entity: Project }>();
  const api = useAPI();
  const { t } = useTranslation();
  const { endDate, startDate, isInvoiced } = useProjectParams();

  const q = useSuspenseQuery({
    queryKey: ["project-kpi", project.id as string, "economy"],
    queryFn: () =>
      api.projectGetKpiCardEconomySubPage({
        projectId: project.id as string,
        dateAfter: startDate,
        dateBefore: endDate,
      }),
  });

  const d = q.data?.data;
  return (
    <>
      <TabHeading>{t("projects:index.finance.combined_title", "Economic Overview")}</TabHeading>
      <div className="grid grid-cols-2 gap-4">
        <KPIDataCard
          name={t("common:product", { count: 2 })}
          values={[
            {
              name: t("finance:sales_price", "Sales price"),
              value: d?.products?.salesPrice ?? 0,
              unit: "currency",
              comparedValue: d?.products?.costPrice,
              comparedLabel: t("finance:cost_price", "Cost price"),
              percentage:
                d?.products?.costPrice && d?.products?.salesPrice
                  ? (d.products.costPrice / d.products.salesPrice) * 100
                  : undefined,
            },
          ]}
        />
        <KPIDataCard
          name={t("common:total")}
          values={[
            { name: "Cost price_", value: d?.total?.costPrice, unit: "currency" },
            { name: "Sales price_", value: d?.total?.salesPrice ?? 0, unit: "currency" },
            {
              name: "Contribution margin_",
              value: d?.total?.contributionMargin ?? 0,
              unit: "percent",
            },
          ]}
        />
        <KPIDataCard
          name={capitalize(t("common:hour", { count: 2 }))}
          values={[
            { name: "Cost price_", value: d?.hours?.costPrice, unit: "currency" },
            { name: "Sales price_", value: d?.hours?.salesPrice ?? 0, unit: "currency" },
            {
              name: "Contribution margin_",
              value: d?.hours?.contributionMargin ?? 0,
              unit: "percent",
            },
          ]}
        />
      </div>
      {/*       <EntityTable
        dataFn={() => api.projectGetKpiCardEconomySubPage({ projectId: project.id as string })}
        cacheKey={["project-kpi", project.id as string, "products"]}
        fields={{
          productName: {
            label: t("common:product_name", "Product name"),
            renderColumn: (row) => row.title,
          },
          amount: {
            label: t("common:quantity", "Quantity"),
            renderColumn: (row) => row.quantity,
          },
          costPerUnit: {
            label: t("finance:products.cost_per_unit", "Cost per unit"),
            renderColumn: (row) => formatCurrency(row.unitBuyingPrice),
          },
          totalCost: {
            label: t("finance:products.total_cost", "Total cost"),
            renderColumn: (row) => formatCurrency(row.totalBuyingPrice),
          },
        }}
      />
     */}
    </>
  );
}
