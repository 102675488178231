import { MetaContainer } from "~/lib/ui/meta-container";
import { useTranslation } from "react-i18next";
import { UseCategoryLabelsEntity } from "~/lib/ui/category-label/use-category-labels";
import { LabelEntity } from "@apacta/sdk";
import { useEffect, useState } from "react";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import { CategoryLabelPopover } from "~/lib/ui/category-label/category-label-popover";
import { ArrowPathIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Label } from "../label";

type CategoryLabelSelectProps = {
  initialSelectedLabels?: Array<LabelEntity>;
  entity?: UseCategoryLabelsEntity;
  readonly?: boolean;
  onChange?: (labels: Array<LabelEntity>) => void;
  options?: {
    multiselect: boolean;
  };
};

export default function CategoryLabelSelect({
  initialSelectedLabels,
  entity,
  readonly,
  onChange,
  options,
}: CategoryLabelSelectProps) {
  const defaultEmployeePopoverOptions = {
    multiselect: true,
  };

  const opts = { ...defaultEmployeePopoverOptions, ...options };

  const { t } = useTranslation();

  const [selectedLabels, setSelectedLabels] = useState<Array<LabelEntity>>(
    initialSelectedLabels ?? []
  );

  const handleRemoveLabel = (labelId: string): void => {
    setSelectedLabels((prevState) => prevState && [...prevState].filter((u) => u.id !== labelId));
  };

  useEffect(() => {
    onChange?.(selectedLabels);
  }, [selectedLabels]);

  return (
    <div>
      <Label>{t("common:label", { count: opts.multiselect ? 2 : 1 })}</Label>
      <MetaContainer
        items={selectedLabels}
        readonly={readonly}
        render={(data) => (
          <UICategoryLabel
            id={data.id}
            bgColor={data.bgColor}
            text={data.text}
            onRemove={(id) => handleRemoveLabel(id)}
          />
        )}
        addRender={() => (
          <CategoryLabelPopover
            triggerRender={() => (
              <div className="cursor-pointer rounded-full bg-shade-300 p-1 hover:bg-hover hover:text-white">
                {opts.multiselect || selectedLabels.length === 0 ? (
                  <PlusIcon className="h-4 w-4" />
                ) : (
                  <ArrowPathIcon className="h-4 w-4" />
                )}
              </div>
            )}
            initialSelectedLabels={selectedLabels}
            onSelect={setSelectedLabels}
            options={opts}
            entity={entity}
          />
        )}
      />
    </div>
  );
}
