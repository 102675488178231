import { RegistrationsDTO } from "@apacta/sdk";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { IconProp } from "../../types";
import { getIcon } from "../../icons/icon";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { BADGE_CLASS, BADGE_RED } from "~/pages/projects/[id]/registrations";
import { twMerge } from "tailwind-merge";
import { FormCell } from "~/lib/ui/table/cells/form-cell";

export function RegistrationTypeCell({
  registration,
  projectId,
}: {
  registration: RegistrationsDTO;
  projectId?: string;
}) {
  const { t } = useTranslation();

  let name: ReactNode | undefined =
    registration.template ?? t(`common:${registration.type}`, { count: 1 });
  let link: string | undefined = undefined;
  let Icon: IconProp | undefined = getIcon("unknownType");

  if (registration.type === "form") {
    return (
      <FormCell
        formId={registration.id}
        formTemplateName={registration.template}
        workDescription={registration.workDescription}
        projectId={projectId}
        deleted={registration.deletedDate ?? undefined}
        invoiced={registration.invoicedDate ?? undefined}
      />
    );
  }
  if (registration.type === "invoice") {
    link = `/companies/invoices/edit/${registration.id}`;
    if (registration.invoiced) {
      link = `/companies/invoices/getPDF/${registration.id}`;
    }

    Icon = getIcon("invoice");
  }
  if (registration.type === "expense") {
    link = `/companies/expenses/edit/${registration.id}`;
    Icon = getIcon("expense");
    name = (
      <>
        {t(`common:${registration.type}`, { count: 1 })}
        <div className="mt-2 block text-gray-400">{registration.vendorName}</div>
      </>
    );
  }

  return (
    <div className="flex flex-row items-center justify-between">
      <OptionalLink to={link} openInNewTab={link?.includes("/companies/")}>
        <span>
          <Icon className="mr-2 inline h-5 w-5" aria-hidden="true" />
          <span>{name}</span>
        </span>
      </OptionalLink>
      <div className="flex gap-x-2">
        {registration.invoiced ? (
          <span className={BADGE_CLASS}>{t("common:invoiced", "Invoiced")}</span>
        ) : null}
        {registration.approved ? <span className={BADGE_CLASS}>{t("common:approved")}</span> : null}
        {registration.deleted && (
          <span className={twMerge(BADGE_CLASS, BADGE_RED)}>{t("common:deleted")}</span>
        )}
      </div>
    </div>
  );
}
