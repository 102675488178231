import { useOutletContext, useSearchParams } from "react-router-dom";
import { OutletContext } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMemo, useRef, useState } from "react";
import { usePlanning } from "~/lib/planning";
import { useMount } from "~/lib/lifecycle-helpers";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { User } from "@apacta/sdk";
import { ScrollLayer } from "~/pages/planning/_cmp/drag/scroll-layer";
import { Dropdown } from "~/lib/ui/dropdown";
import { dateIsWeekend, formatDate, getDayName, matchDates } from "~/lib/utils/date/date-utils";
import { PlanningSectionMultipleDays } from "~/pages/planning/_cmp/planning-section-multiple-days";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import { twMerge } from "tailwind-merge";
import { useHolidays } from "~/lib/calendar/use-holidays";
import { Button, getIcon, Icon } from "~/lib/ui";
import { Spinner } from "~/lib/ui/spinner";
import { HolidayBadge } from "~/lib/ui/badges/holiday-badge";

type SortOptionType = "name" | "estimate";
type SortOptionDirection = "asc" | "desc";

export default function PlanningViewMultipleDays() {
  const { isDragging } = useOutletContext<OutletContext>();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<SortOptionType>("name");
  const [sortDirection, setSortDirection] = useState<SortOptionDirection>("asc");

  const { isLoadingCards, cardStateViews, users, selectedDate, viewDates } = usePlanning();
  const { holidays, isHoliday } = useHolidays(viewDates[0]);

  const navigateToDayView = (date: Date) => {
    const dateString = date.toISOString().split("T")[0];
    searchParams.set("date", dateString);
    navigate({ pathname: "/planning", search: searchParams.toString() });
  };

  useMount(() => {
    setLoading(true);
  });

  // Fix flickering when switching between weeks that have cards, and weeks that do not
  useDebounce(
    () => {
      if (!isLoadingCards) {
        setLoading(false);
      }
    },
    [cardStateViews.plannedUnassigned, cardStateViews.plannedAssigned],
    1
  );

  const sortBy = (key?: SortOptionType, direction?: SortOptionDirection): void => {
    if (!key || !direction) return;
    setSortKey(key);
    setSortDirection(direction);
  };

  const sortUsers = (): Array<User> => {
    if (sortKey === "name") {
      return [...users].sort((a, b) => {
        if (sortDirection === "asc") {
          return a.firstName.localeCompare(b.firstName);
        } else {
          return b.firstName.localeCompare(a.firstName);
        }
      });
    } else {
      return [...users].sort((a, b) => {
        const aCards = cardStateViews.plannedAssigned.filter((card) => card.userId === a.id);
        const bCards = cardStateViews.plannedAssigned.filter((card) => card.userId === b.id);
        const aSum = aCards.reduce((sum: number, card) => (sum += card.estimate || 0), 0);
        const bSum = bCards.reduce((sum: number, card) => (sum += card.estimate || 0), 0);
        if (sortDirection === "asc") {
          return aSum - bSum;
        } else {
          return bSum - aSum;
        }
      });
    }
  };

  const sortedUsers = useMemo<Array<User>>(() => sortUsers(), [sortKey, sortDirection, users]);

  const boundingRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={boundingRef}
      className="planning-scrollbar flex flex-grow flex-col overflow-x-auto overflow-y-auto scroll-smooth pb-4"
    >
      <ScrollLayer boundingRef={boundingRef} isDragging={isDragging} />

      <div
        className="absolute ml-8 flex h-16 w-64 flex-shrink-0 cursor-pointer items-start justify-center gap-2 bg-gray-100"
        onClick={() => setCollapsed(!collapsed)}
      >
        <div>
          <Button
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
            className="px-2 py-2"
          >
            {collapsed ? (
              <Icon name="expandRow" className="h-5 w-5" />
            ) : (
              <Icon name="retractRow" className="h-5 w-5" />
            )}
          </Button>
        </div>
        <div className="flex w-full flex-grow">
          <Dropdown
            trigger={
              <Button
                variant="secondary"
                className="flex w-full flex-shrink-0 flex-grow"
                tabIndex={-1}
              >
                <div className="flex w-full flex-grow items-center justify-between gap-2 text-sm">
                  <span className="">{t(`planning:sort.options.${sortKey}.${sortDirection}`)}</span>
                  <Icon name="chevronDown" className="h-4 w-4" />
                </div>
              </Button>
            }
            options={{ trigger: { asChild: true, className: "flex flex-grow flex-shrink-0" } }}
          >
            <Dropdown.Item
              className="text-sm"
              onSelect={() => sortBy("name", "asc")}
              Icon={getIcon("sortAsc")}
            >
              {t("planning:sort.options.name.asc")}
            </Dropdown.Item>
            <Dropdown.Item
              className="text-sm"
              onSelect={() => sortBy("name", "desc")}
              Icon={getIcon("sortDesc")}
            >
              {t("planning:sort.options.name.desc")}
            </Dropdown.Item>
            <Dropdown.Item
              className="text-sm"
              onSelect={() => sortBy("estimate", "asc")}
              Icon={getIcon("sortAsc")}
            >
              {t("planning:sort.options.estimate.asc")}
            </Dropdown.Item>
            <Dropdown.Item
              className="text-sm"
              onSelect={() => sortBy("estimate", "desc")}
              Icon={getIcon("sortDesc")}
            >
              {t("planning:sort.options.estimate.desc")}
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <div className="sticky top-0 ml-74 flex">
        <div className="flex flex-shrink-0">
          {viewDates.map((date, index) => (
            <div
              key={`day-${index}`}
              className={twMerge(
                "relative flex h-20 w-40 flex-shrink-0 cursor-pointer flex-col items-center border border-b-4 py-1 text-center hover:border-b-hover",
                isHoliday(date)
                  ? "bg-yellow-50"
                  : dateIsWeekend(date)
                    ? "bg-gray-300"
                    : matchDates(selectedDate, date, { ignoreTimestamp: true })
                      ? "border-b-primary bg-white"
                      : "bg-white",
                index < viewDates.length - 1 && "border-r-0"
              )}
              onClick={() => navigateToDayView(date)}
            >
              {matchDates(new Date(), date, { ignoreTimestamp: true }) ? (
                <div className="absolute right-1 top-1 h-3 w-3 rounded-full border-2 border-white bg-hover"></div>
              ) : null}
              <div className="text-md">
                {getDayName(date, getResolvedLanguage(), { capitalizeFirst: true })}
              </div>
              <div className="text-xs">
                {formatDate(date, i18n.resolvedLanguage, {
                  excludeTime: true,
                  excludeYear: true,
                  shortMonth: true,
                })}
              </div>
              <div className="h-5 pt-1">
                <HolidayBadge date={date} holidays={holidays} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <PlanningSectionMultipleDays
        key="unassigned-week"
        cards={cardStateViews.plannedUnassigned}
        loading={loading}
        viewDates={viewDates}
        isDragging={isDragging}
        isCollapsed={collapsed}
      />
      {sortedUsers.length > 0 ? (
        sortedUsers.map((user) => (
          <PlanningSectionMultipleDays
            key={`employee-week-${user.id}`}
            cards={cardStateViews.plannedAssigned.filter((card) => card.userId === user.id)}
            loading={loading}
            viewDates={viewDates}
            user={user}
            isDragging={isDragging}
            isCollapsed={collapsed}
          />
        ))
      ) : (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
