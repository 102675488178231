import { WatchersProps } from "~/lib/utils/string/linkify/types";

export const linkifyWatchers: Array<WatchersProps> = [
  {
    type: "link",
    render: (url: string) => (
      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        className="font-medium text-primary hover:text-hover"
      >
        {url}
      </a>
    ),
  },
  {
    type: "email",
    render: (email: string) => (
      <a
        href={`mailto:${email}`}
        target="_blank"
        rel="noreferrer noopener"
        className="font-medium text-primary hover:text-hover"
      >
        {email}
      </a>
    ),
  },
  {
    type: "phone",
    render: (phone: string) => (
      <a
        href={`tel:${phone}`}
        target="_blank"
        rel="noreferrer noopener"
        className="font-medium text-primary hover:text-hover"
      >
        {phone}
      </a>
    ),
  },
];
