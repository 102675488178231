import { ReactNode } from "react";
import { Icon, IconName } from "~/lib/ui";

export function IconLine(props: { icon: IconName; children: ReactNode; title?: string }) {
  return (
    <div title={props.title}>
      <Icon name={props.icon} className="mr-2 inline" />
      {props.children}
    </div>
  );
}
