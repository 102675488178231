import { twMerge } from "tailwind-merge";
import { Checkbox } from "./checkbox";
import { ComponentPropsWithRef, useId } from "react";

export function CheckboxLine({
  label,
  className,
  checkboxClassName,
  ...props
}: { label: string; checkboxClassName?: string } & ComponentPropsWithRef<"input">) {
  const id = useId();
  return (
    <label htmlFor={id} className={twMerge("flex items-center", className)}>
      <Checkbox {...props} id={id} className={twMerge("mr-3", checkboxClassName)} />
      {label}
    </label>
  );
}
