import { EditorConfig } from "lexical";
import { HeadingNode, SerializedHeadingNode } from "@lexical/rich-text";

export class DatasetHeadingNode extends HeadingNode {
  static getType() {
    return "dataset-heading";
  }

  static clone(node: HeadingNode) {
    return new DatasetHeadingNode(node.__tag, node.__key);
  }

  createDOM(config: EditorConfig) {
    const dom: HTMLElement = super.createDOM(config);
    dom.dataset.pdfRule = "no-split";
    return dom;
  }

  static importJSON(serializedNode: SerializedHeadingNode): HeadingNode {
    return HeadingNode.importJSON(serializedNode);
  }

  exportJSON(): SerializedHeadingNode {
    return {
      ...super.exportJSON(),
      type: DatasetHeadingNode.getType(),
      tag: this.__tag,
      version: 1,
    };
  }
}
