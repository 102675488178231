import { Icon } from "../..";
import { CommandItem } from "../../command/command";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useCommandState } from "cmdk";

// Issues with creation: https://github.com/pacocoursey/cmdk/issues/75
export function DefaultCreateComponent(props: { onCreate?: (query: string) => void }) {
  const { t } = useTranslation();
  const search = useCommandState((state) => state.search);

  if (search === "") return null;

  return (
    <CommandItem
      value="{{create-item}}"
      keywords={["{{create-item}}"]}
      onSelect={() => props.onCreate?.(search)}
    >
      <Icon name="add" className={twMerge("mr-2 h-4 w-4")} />
      {t("common:create", { entity: search })}
    </CommandItem>
  );
}
