import { useSuspenseQuery } from "@tanstack/react-query";
import { PaginationBar } from "~/lib/entity-ui/pagination-bar";
import { graphql, useGraphQL } from "~/lib/gql";
import { usePagination } from "~/lib/utils";
import { useToastOnError } from "~/lib/utils/hooks";

const stuff = graphql(/* GraphQL */ `
  query listProjectsTable($params: ProjectListParams!) {
    projects(params: $params) {
      pageInfo {
        limit
        hasNextPage
        hasPrevPage
        currentPage
      }
      edges {
        node {
          id
          name
          created
        }
      }
    }
  }
`);

export default function EntityTableGraphQLDemo() {
  const sdk = useGraphQL();
  const [pagination] = usePagination();

  const { data, error } = useSuspenseQuery({
    queryFn: () =>
      sdk.listProjectsTable({
        params: { limit: 5, page: pagination.page },
      }),
    queryKey: ["listProjectsGQL", pagination, stuff],
  });

  const { pageInfo } = data?.projects;

  useToastOnError(error);

  return (
    <>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>created</th>
            </tr>
          </thead>
          <tbody>
            {data?.projects.edges.map((edge) => (
              <tr key={edge.node.id}>
                <td>{edge.node.name}</td>
                <td>{edge.node.created.toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <PaginationBar
          paginationDetails={{
            currentPage: pagination.page ?? 1,
            hasNextPage: pageInfo.hasNextPage ?? false,
            hasPrevPage: pageInfo.hasPrevPage ?? false,
          }}
        />
      </div>
    </>
  );
}
