import { formatCurrency } from "~/lib/utils/number";
import { calculateLineTotalCost } from "~/lib/ui/order-lines/lib/calculations";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";

export function OrderLinesBuilderTotalCostPart({ line }: { line: Partial<OrderLine> }) {
  return (
    <div className="flex flex-grow justify-end px-3 pb-5 pt-7 text-zinc-900">
      {formatCurrency(calculateLineTotalCost(line), { currency: "DKK" })}
    </div>
  );
}
