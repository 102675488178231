import {
  IProjectDeleteFileRequestInnerTypeEnum,
  IProjectListFilesDirectionEnum,
  ProjectFileReadDTO,
} from "@apacta/sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router";
import { useAPI } from "~/lib/api";
import { captureException } from "~/lib/error-reporting";
import { useToasts } from "~/lib/toast/use-toasts";
import { UploadButton } from "~/lib/ui/buttons/upload-button";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { usePagination } from "~/lib/utils/hooks";
import { DivDropZone } from "~/lib/ui/div-drop-zone";
import TableView from "~/lib/ui/tables/table-view";
import { useState } from "react";
import { FileView } from "./_cmp/file-view";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { SendFilesDialog } from "./_cmp/send-file-dialog";
import { ProjectType } from "../../[id]";
import { FileLoading } from "./_cmp/file-loading";

export type FileItemType = ProjectFileReadDTO;

export default function ProjectFilesPage() {
  const { id: projectId } = useParams<{ id: string }>();
  const { entity: project } = useOutletContext<{
    entity: ProjectType;
  }>();

  if (!projectId) {
    throw new Error("Missing projectId");
  }
  const [selectedFiles, setSelectedFiles] = useState<Array<FileItemType>>([]);
  const [showSendDialog, setShowSendDialog] = useState(false);

  const { t } = useTranslation();
  const [pagination] = usePagination();
  const api = useAPI();
  const toast = useToasts();

  const filesQ = useQuery({
    queryFn: () =>
      api.iProjectListFiles({
        projectId: projectId as string,
        page: pagination.page,
        direction: pagination.direction as IProjectListFilesDirectionEnum,
        sort: pagination.sort,
      }),
    queryKey: ["project_files", projectId, pagination],
  });

  const uploadM = useMutation({
    mutationFn: (files: Array<File>) => {
      return Promise.all(
        files.map((file) => {
          return api.iProjectUploadFile({ projectId: projectId as string, file });
        })
      );
    },
    onSuccess: () => {
      filesQ.refetch();
      toast.show({
        title: t("projects:files.title"),
        description: t("projects:files.description"),
        Icon: getIcon("success"),
      });
    },
    onError: (err) => {
      toast.show({
        Icon: getIcon("warningTriangle"),
        title: t("error.operation.title"),
        description: t("error.operation.subtitle"),
        variant: "error",
      });
      captureException(new Error("Operation failed", { cause: err }));
    },
  });

  const deleteM = useMutation({
    mutationFn: () => {
      return api.iProjectDeleteFile({
        projectId: projectId as string,
        iProjectDeleteFileRequestInner: selectedFiles.map((f) => ({
          id: f.id,
          type: f.type as IProjectDeleteFileRequestInnerTypeEnum,
        })),
      });
    },
    onSettled: () => {
      filesQ.refetch();
      setSelectedFiles([]);
    },
  });

  async function handleUpload(filelist: Array<File>) {
    return uploadM.mutateAsync(filelist);
  }

  function handleToggle(checked: boolean, file: FileItemType) {
    setSelectedFiles((prev) => {
      if (checked) {
        return [...prev, file];
      } else {
        return prev.filter((f) => f.id !== file.id);
      }
    });
  }

  function handleSelectAll() {
    setSelectedFiles(filesQ.data?.data ?? []);
  }

  const fileItems = filesQ.data?.data ?? [];

  return (
    <>
      <DivDropZone onFilesDropped={(files) => handleUpload(Array.from(files))}>
        <TabHeading
          subtitle={t("common:drag_and_drop_here", "Drag and drop files here to upload")}
          actionArea={
            <>
              <UploadButton
                multiple
                variant="tertiary"
                onSelected={(files) => {
                  handleUpload(Array.from(files));
                }}
              >
                {t("common:upload")}
              </UploadButton>
            </>
          }
        >
          {t("common:files")}
        </TabHeading>
        <div className="mb-8 flex flex-row items-center justify-between">
          <div>
            {selectedFiles.length === 0 ? (
              <Button variant="secondary" onClick={handleSelectAll}>
                {t("ui:select_all", {
                  defaultValue: "Select all",
                })}
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setSelectedFiles([])}>
                {t("ui:clear_selection", {
                  defaultValue: "Clear selection",
                })}
              </Button>
            )}
          </div>

          <div className="flex flex-row items-center">
            {selectedFiles.length > 0 && (
              <div className="mr-4 flex h-full items-center text-tertiary">
                {t("common:x_selected", {
                  replace: { x: selectedFiles.length },
                  defaultValue: "{{x}} selected",
                })}
              </div>
            )}

            <ActionButtons
              size="small"
              actions={[
                {
                  Icon: getIcon("send"),
                  label: t("common:send"),
                  isHidden: selectedFiles.length === 0,
                  onClick: () => setShowSendDialog(true),
                },
                {
                  Icon: getIcon("delete"),
                  label: t("common:delete"),
                  isHidden: selectedFiles.length === 0,
                  onClick: () => deleteM.mutateAsync(),
                  confirm: {
                    entity: "file",
                    action: "delete",
                  },
                },
              ]}
            />
          </div>
        </div>
        <div>
          <div className="mb-8 grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">
            {filesQ.isLoading
              ? Array.from({ length: 24 }).map((_, i) => <FileLoading key={i} />)
              : fileItems.map((file: FileItemType, index: number) => (
                  <FileView
                    key={index}
                    item={file}
                    isSelected={selectedFiles.find((f) => f.id === file.id) !== undefined}
                    onToggle={(checked) => handleToggle(checked, file)}
                  />
                ))}
          </div>
          <TableView.Pagination paginationDetails={filesQ.data?.pagination} />
        </div>
      </DivDropZone>
      <Dialog
        open={showSendDialog}
        onOpenChange={() => setShowSendDialog(false)}
        render={({ onClose }) => (
          <SendFilesDialog
            projectId={projectId}
            files={selectedFiles}
            defaultEmail={project.contactPerson?.email ?? project.contact?.email ?? ""}
            defaultSubject={t("projects:send_files_subject", {
              replace: { project: project.name || "project" },
              defaultValue: "Files for {{project}}",
            })}
            defaultMessage={t("projects:send_files_message", {
              replace: { name: project.contactPerson?.name ?? project.contact?.name ?? "" },
              defaultValue: "Hello {{name}}",
            })}
            onClose={onClose}
            onSuccess={() => setSelectedFiles([])}
          />
        )}
      />
    </>
  );
}
