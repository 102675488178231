import { ControlProps, and, rankWith, uiTypeIs } from "@jsonforms/core";
import { isDecendantOf } from "./testers";

export function DrivingDestinationViewRenderer(props: ControlProps) {
  // TODO: Wrap the array controls in a component and call them from here
  const { data, schema, path, enabled, uischema, rootSchema } = props;

  return <div>{data.address}</div>;
}
export const drivingDestinationViewTester = rankWith(
  3,
  and(uiTypeIs("HorizontalLayout"), isDecendantOf("driving"))
);
