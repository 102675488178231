import { Outlet, useParams } from "react-router";
import { useAPI } from "~/lib/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import { useToastOnError } from "~/lib/utils/hooks";

export default function ProductBundlePage() {
  const api = useAPI();
  const { id: productBundleId } = useParams();
  const { data, isLoading, error } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["product_bundle", productBundleId],
    queryFn: () => api.productBundlesView({ productBundleId: productBundleId as string }),
  });

  useToastOnError(error);

  const productBundle = data?.data;

  return <Suspense>{!isLoading && <Outlet context={{ productBundle }} />}</Suspense>;
}
