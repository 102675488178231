import { useEffect, useState } from "react";
import { useDelayedValue } from "~/lib/debounce/use-debounce";

export function useSimpleSearch<ItemType extends object>({
  query = "",
  items,
  filterFn,
  delay = 500,
}: {
  query: string;
  items: Array<ItemType>;
  filterFn: (item: ItemType, query: string) => boolean;
  delay?: number;
}) {
  const { isPending, value: debouncedQuery } = useDelayedValue(query, delay);

  const [filteredItems, setFilteredItems] = useState<Array<ItemType>>(items);

  useEffect(() => {
    setFilteredItems(items.filter((item) => filterFn(item, debouncedQuery)));
  }, [debouncedQuery]);

  return {
    filteredItems,
    loading: isPending,
  };
}
