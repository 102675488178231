import { PageLayout } from "~/lib/ui/page-layout";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { CreateInvoiceDialog } from "./_cmp/create-invoice-dialog";
import { InvoiceTable } from "./_cmp/invoice-table";

export const CACHE_INVOICES = "invoices";

export default function InvoicesPage() {
  const { t } = useTranslation();

  return (
    <>
      <PageLayout
        title={t("navigation:invoices.title")}
        renderActions={() => (
          <Dialog
            render={({ onClose }) => <CreateInvoiceDialog onClose={onClose} />}
            trigger={
              <Button variant="tertiary" className="print:hidden" Icon={getIcon("add")}>
                {t("common:create", {
                  defaultValue: "Create {{entity}}",
                  replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
                })}
              </Button>
            }
          />
        )}
      >
        <InvoiceTable parentType="page" />
      </PageLayout>
    </>
  );
}
