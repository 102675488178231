import { useEffect, useState } from "react";
import Switch from "../../switch";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";

/** Supports only uncontrolled, on purpose so we can do optimistic updates
 * - It will revert on a promise fail
 */
export function FormApprovalCell({
  projectId,
  formId,
  value,
}: {
  projectId: string;
  formId: string;
  value: boolean;
}) {
  // For optimistic updates
  const [internalValue, setInternalValue] = useState(value);
  const api = useAPI();

  const updateApproval = useMutation({
    mutationFn: async (approval: boolean) => {
      try {
        setInternalValue(approval);
        await api.changeStatus({
          projectId,
          changeStatusRequest: {
            approve: approval,
            forms: [formId],
          },
        });
      } catch (err) {
        setInternalValue(!approval);
        // ignore
      }
    },
    onSuccess: () => {
      // Ignore. You can see the switch flip. That's enough
    },
  });

  // This is controlled, so if the prop changes, we need to update the internal value
  useEffect(() => {
    if (value === undefined) return;
    setInternalValue((prev) => {
      if (prev === value) return prev;
      return value;
    });
  }, [value]);

  return (
    <Switch
      key={formId} // This is important to prevent reusing the same component if filter is applied (from /registrations)
      className="flex items-center justify-center"
      checked={internalValue}
      controlled={true}
      onCheckedChange={updateApproval.mutate}
    />
  );
}
