import { useTranslation } from "react-i18next";
import { Checkbox } from "~/lib/ui/form-elements";
import { MediaPreviewLink } from "~/lib/ui/media/";
import { linkToExpense, linkToForm } from "~/lib/utils";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { FileItemType } from "..";

export function FileView({
  item,
  isSelected,
  onToggle,
}: {
  item: FileItemType;
  isSelected: boolean;
  onToggle: (checked: boolean) => void;
}) {
  const { t } = useTranslation();

  let originLink = undefined;
  if (item.type === "form" && item.parentId) originLink = linkToForm(item.parentId, "view");
  if (item.type === "expense_file" && item.parentId)
    originLink = linkToExpense(item.parentId, "view");
  return (
    <div className="relative flex h-full w-full flex-col rounded-md border bg-white shadow-md">
      <div className="flex flex-row p-4">
        <div className="rounded-t-md text-tertiary">
          <Checkbox checked={isSelected} onChange={(e) => onToggle(e.currentTarget.checked)} />
        </div>
        <div className="w-full truncate text-sm">{item.fileOriginalName}</div>
      </div>

      <div className="relative flex w-full flex-col items-center justify-center py-2">
        <MediaPreviewLink
          href={item.downloadUrl}
          previewURL={item.path}
          mimeType={item.fileType}
          title={item.fileOriginalName}
        />
        <div className="absolute bottom-2 right-0 flex gap-2 px-2 pb-2">
          <OptionalLink to={originLink}>
            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              {t(`common:${item.type}`)}
            </span>
          </OptionalLink>
        </div>
      </div>
      {item.description && (
        <div className="mt-4 flex h-10 w-full items-center justify-center bg-shade-100 px-2 text-sm text-gray-500">
          <div className="truncate">{item.description}</div>
        </div>
      )}
    </div>
  );
}
