import { forwardRef, ReactNode, Ref } from "react";

import { FormCard } from "./cards/form-card";
import { FrontpageFeedDTO } from "@apacta/sdk";
import { ExpenseCard } from "./cards/expense-card";
import { InvoiceCard } from "./cards/invoice-card";
import { WallPostCard } from "./cards/wall-post-card";

export const FeedCard = forwardRef(function FeedCardInner(
  { data }: { data: FrontpageFeedDTO },
  ref: Ref<HTMLDivElement>
) {
  const getCard = (): ReactNode | null => {
    switch (data.type) {
      case "form":
        return <FormCard data={data} />;
      case "expense":
        return <ExpenseCard data={data} />;
      case "invoice":
        return <InvoiceCard data={data} />;
      case "wall_post":
        return <WallPostCard data={data} />;
      default:
        return null;
    }
  };

  return (
    <div ref={ref} className="flex basis-full p-2 md:basis-1/2 xl:basis-1/3">
      {getCard()}
    </div>
  );
});
