import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const SEPARATOR = " | ";

/**
 * Sets the page title based on the current path or the supplied title
 */
export function usePageTitle(options?: { hideBrandName?: boolean }) {
  const { t, i18n } = useTranslation();
  const [localTitle, setLocalTitle] = useState<Array<string>>([]);

  function buildPageTitle(lt: Array<string>) {
    let blocks = lt;
    if (!options?.hideBrandName) {
      blocks = [t("common:brand_name"), ...blocks];
    }
    const title = blocks.join(SEPARATOR);
    return title;
  }

  // React to state changes and update the title
  useEffect(() => {
    if (localTitle.length === 0) return;
    document.title = buildPageTitle(localTitle);
  }, [localTitle]);

  const set = (value: string) => {
    setLocalTitle([value]);
  };

  const add = (value: string) => {
    setLocalTitle([...localTitle, value]);
  };

  return { set, add };
}
