import { NavLink } from "react-router-dom";
import { linkToCustomer, linkToInvoice, linkToProject } from "~/lib/utils";
import { formatCurrency } from "~/lib/utils/number";
import { Card, CardField } from "../static/card";
import { CardHeader } from "../static/card-header";
import colors from "tailwindcss/colors";
import { BaseFile, FrontpageFeedDTO } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export const InvoiceCardApacta = ({ data }: { data: FrontpageFeedDTO }) => {
  const { t } = useTranslation();
  const files: Array<BaseFile> = data.invoice?.pdfUrl
    ? [
        {
          name: `${t("common:invoice")}.pdf`,
          originalFilename: `${t("common:invoice")}.pdf`,
          fileUrl: data.invoice.pdfUrl,
          downloadUrl: data.invoice.pdfUrl,
          mimeType: "application/pdf",
          id: "pdf",
        },
      ]
    : [];

  const fields: Array<CardField> = [
    {
      label: t("common:invoice_no"),
      render: () => (
        <NavLink className="hover:underline" to={linkToInvoice(data.invoice?.id ?? "", "view")}>
          {data.invoice?.invoiceNumber}
        </NavLink>
      ),
    },
    {
      label: t("common:project", { count: 1 }),
      render: () => (
        <NavLink to={linkToProject(data.project?.id ?? "")} className="hover:underline">
          {data.project?.fullName}
        </NavLink>
      ),
    },
    {
      label: t("common:customer", { count: 1 }),
      render: () => (
        <NavLink to={linkToCustomer(data.invoice?.contact?.id ?? "")} className="hover:underline">
          {data.invoice?.contact?.name}
        </NavLink>
      ),
    },
    {
      label: t("common:amount"),
      render: () =>
        data.sum !== undefined && data.sum !== null ? (
          formatCurrency(data.sum)
        ) : (
          <span className="text-gray-400">{t("common:not_available")}</span>
        ),
    },
  ];

  return (
    <Card
      renderHeader={() => (
        <CardHeader
          title={t("common:invoice", { count: 1 })}
          icon="invoice"
          color={colors["amber"][500]}
          renderIdentifier={() => (
            <img src="/apacta-logo-blue.png" alt="Apacta logo" className="h-6 w-auto" />
          )}
        />
      )}
      fields={fields}
      files={files}
    />
  );
};
