import { IGetProject200Response } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { useSession } from "~/lib/auth/session";
import { CACHE_PROJECTS } from "..";
import { FeedInput } from "../_cmp/feed-input";
import { twMerge } from "tailwind-merge";
import { DivDropZone } from "~/lib/ui/div-drop-zone";
import { fileListToArray } from "~/lib/utils/utils";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useState } from "react";
import { Dialog } from "~/lib/ui";
import { CreatePostDialog } from "../_cmp/create-post-dialog";
import WallPostList from "~/lib/ui/wall-post-list";

export default function ProjectWallPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const { entity: project } = useOutletContext<{
    entity: IGetProject200Response["data"];
    type: [];
  }>();
  const { me } = useSession();
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const cacheKey = [CACHE_PROJECTS, project.id, "wall"];

  const colWidthClass = "";

  async function handleRefresh() {
    await queryClient.invalidateQueries({
      queryKey: cacheKey,
    });
  }

  const createWallPost = useMutation({
    mutationFn: ({ files, message }: { files: Array<Blob>; message?: string }) =>
      api.iWallpostCreate({
        projectId: project.id,
        postFiles: files,
        message,
      }),
    onSettled: async () => {
      await handleRefresh();
    },
  });

  const placeholderText = t("projects:feed.input_prompt", {
    defaultValue: "What's on your mind {{name}}",
    replace: { name: me?.user.firstName || "" },
  });

  return (
    <>
      <DivDropZone
        disabled={showCreateDialog}
        onFilesDropped={(files) => createWallPost.mutateAsync({ files: fileListToArray(files) })}
      >
        <TabHeading subtitle={t("common:drag_and_drop_here", "Drag and drop files here to upload")}>
          {t("projects:wall")}
        </TabHeading>
        <FeedInput
          onOpenDialog={() => setShowCreateDialog(true)}
          onUploadButtonClick={({ message, files }) =>
            createWallPost.mutateAsync({ files, message })
          }
          placeholder={placeholderText}
        />
        <div className={twMerge("rounded-md bg-white p-4 shadow-sm", colWidthClass)}>
          <WallPostList projectId={project.id} />
        </div>
      </DivDropZone>
      <Dialog
        open={showCreateDialog}
        onOpenChange={() => setShowCreateDialog(false)}
        render={({ onClose }) => (
          <CreatePostDialog
            onClose={onClose}
            onCreatePost={(args) => createWallPost.mutateAsync(args)}
            placeholder={placeholderText}
          />
        )}
      />
    </>
  );
}
