import { Trans, useTranslation } from "react-i18next";
import { Icon } from "../icons/icon";

export default function NoResultsWidget({ query }: { query: string }) {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full items-center justify-center py-4">
      <div className="flex flex-col items-center justify-center">
        <div className="mb-2 flex items-center rounded-full border border-yellow-400 bg-yellow-50 p-2 text-yellow-400">
          <Icon name="warningTriangle" className="h-7 w-7" />
        </div>
        <h3>{t("ui:combobox.no-results.title")}</h3>
        <span>
          {t("ui:combobox.no-results.description")}
          {!!query && " "}
          {!!query ? (
            <Trans
              i18nKey="ui:combobox.no-results.query"
              values={{ query }}
              components={{ semiBold: <span className="font-semibold" /> }}
            />
          ) : null}
        </span>
      </div>
    </div>
  );
}
