import { ReactNode } from "react";
import { useFeatureFlags } from "./use-feature-flags";
import { FeaturesType } from "../auth/session";

export function Feature({ name, children }: { name?: string; children: ReactNode }) {
  const features = useFeatureFlags();

  if (!name || !features.has(name as FeaturesType)) {
    return null;
  }
  return <>{children}</>;
}
