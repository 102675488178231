import { PdfBuilderElementData, PdfBuilderElementRule, PdfBuilderRule } from "~/lib/pdf/lib/types";
import { i18n } from "~/lib/i18n/i18n";

// Fixed pixel height of the page number element to ensure it doesn't get cut off
// Based on the font size of the page number element
export const PAGE_NUMBER_HEIGHT = 30;

/**
 * Calculate the height of an element, including margins.
 * @param {HTMLElement} element - The element to calculate the height for
 */
const elementHeight = (element: HTMLElement): number => {
  let elHeight = element.offsetHeight;
  elHeight += parseInt(window.getComputedStyle(element).getPropertyValue("margin-top"));
  elHeight += parseInt(window.getComputedStyle(element).getPropertyValue("margin-bottom"));
  return elHeight;
};

/**
 * Check if an element is overflowing the wrapper.
 * This assumes that the wrapper is of position absolute or fixed.
 * If not positioned absolute or fixed, the offsetTop of the wrapper will need to be included in the calculation.
 * @param {HTMLElement} element - The element to check
 * @param {HTMLElement} wrapper - The wrapper to check against
 */
export const isOverflowed = (element: HTMLElement, wrapper: HTMLElement) => {
  const wrapperPaddingBottom = parseInt(
    window.getComputedStyle(wrapper).getPropertyValue("padding-bottom")
  );
  return (
    element.offsetTop + elementHeight(element) + wrapperPaddingBottom + PAGE_NUMBER_HEIGHT >
    wrapper.offsetHeight
  );
};

/**
 * Create a page element with the correct size and classes.
 * @param {number} width - The width of the page
 * @param {number} height - The height of the page
 * @param {DOMTokenList} classList - The class list of the wrapper element
 */
export const createPage = (
  width: number,
  height: number,
  classList: DOMTokenList
): HTMLDivElement => {
  const page = document.createElement("div");

  // Set the page size
  page.style.width = `${width}px`;
  page.style.height = `${height}px`;

  // Make the page relative
  page.style.position = "relative";

  // Add the page class and any other classes that were on the wrapper to ensure margin and padding are correct
  page.classList.add("pdf-page");

  classList.forEach((className) => {
    page.classList.add(className);
  });

  page.classList.add("bg-white");

  // Return the page so that we may continue appending elements to it
  return page;
};

export const insertPageNumbers = async (stagingWrapper: HTMLDivElement): Promise<void> => {
  return new Promise((resolve) => {
    const pages = stagingWrapper.getElementsByClassName("pdf-page");
    const totalPageCount = pages.length;

    // get margins of the page
    const margins = {
      bottom: 0,
      right: 0,
    };

    if (pages.length) {
      const page = pages[0] as HTMLElement;
      margins.bottom = parseInt(window.getComputedStyle(page).getPropertyValue("padding-bottom"));
      margins.right = parseInt(window.getComputedStyle(page).getPropertyValue("padding-right"));

      const pageNumberElement = document.createElement("div");
      pageNumberElement.style.position = "absolute";
      pageNumberElement.style.bottom = `${margins.bottom}px`;
      pageNumberElement.style.right = `${margins.right}px`;
      pageNumberElement.style.height = `${PAGE_NUMBER_HEIGHT}px`;

      if (pages.length === 1) {
        const pageNumberClone = pageNumberElement.cloneNode() as HTMLElement;
        pageNumberClone.textContent = i18n.t("common:page-count", { count: 1, pageNumber: 1 });
        page.appendChild(pageNumberClone);
      } else {
        Array.from(pages).forEach((p, idx) => {
          const pageNumberClone = pageNumberElement.cloneNode() as HTMLElement;
          pageNumberClone.textContent = i18n.t("common:page-count", {
            count: totalPageCount,
            pageNumber: idx + 1,
            totalPages: totalPageCount,
          });
          p.appendChild(pageNumberClone);
        });
      }
    }

    resolve();
  });
};

export const getElementRules = (childNode: ChildNode): Array<PdfBuilderElementRule> => {
  const elementRules: Array<PdfBuilderElementRule> = [];
  if ((childNode as HTMLElement).dataset.pdfRule) {
    elementRules.push({
      element: childNode as HTMLElement,
      rules:
        (childNode as HTMLElement).dataset.pdfRule
          ?.split(",")
          .map((r) => r.trim() as PdfBuilderRule) ?? [],
    });
  }

  const allChildren = (childNode as HTMLElement).getElementsByTagName("*");
  for (const child of allChildren) {
    const childElement = child as HTMLElement;
    if (childElement.dataset.pdfRule) {
      elementRules.push({
        element: childElement,
        rules:
          childElement.dataset.pdfRule?.split(",").map((r) => r.trim() as PdfBuilderRule) ?? [],
      });
    }
  }
  return elementRules;
};

/**
 * Get the rules for an element, depicted on elements with the data-pdf-rule attribute.
 * @param element
 * @param elementRules
 */
export const getRulesForElement = (
  element: HTMLElement,
  elementRules: Array<{ element: HTMLElement; rules: Array<string> }>
): Array<string> => {
  return elementRules.filter((e) => e.element === element).flatMap((e) => e.rules);
};

export const splitElementsOnPages = async ({
  node,
  inputWrapper,
  stagingWrapper,
  elementData,
  originalParent,
  elementRules,
}: {
  node: ChildNode;
  inputWrapper: HTMLElement;
  stagingWrapper: HTMLElement;
  elementData: Array<{
    originalParent: HTMLElement;
    parent: HTMLElement;
    children: Array<HTMLElement>;
  }>;
  originalParent: HTMLElement;
  elementRules: Array<{ element: HTMLElement; rules: Array<string> }>;
}): Promise<
  Array<{
    originalParent: HTMLElement;
    parent: HTMLElement;
    children: Array<HTMLElement>;
  }>
> => {
  return new Promise((resolve) => {
    const rules = getRulesForElement(node as HTMLElement, elementRules);

    if (node instanceof HTMLElement) {
      if (isOverflowed(node, stagingWrapper)) {
        if (rules.length) {
          // If rules include no-split, then don't split the element
          if (rules.includes("no-split")) {
            elementData = [...elementData];
          }
        } else {
          node.childNodes.forEach(async (childNode) => {
            const data = await splitElementsOnPages({
              node: childNode,
              inputWrapper,
              stagingWrapper,
              elementData,
              originalParent,
              elementRules,
            });

            elementData = [...data];
          });
        }
      } else {
        const parentElement = node.parentElement;

        if (parentElement) {
          const foundIndex = elementData.findIndex((e) => e.parent === parentElement);
          if (foundIndex !== -1) {
            elementData[foundIndex].children.push(node);
          } else {
            elementData.push({
              originalParent: originalParent,
              parent: parentElement,
              children: [node],
            });
          }
        }
      }
    }

    resolve(elementData);
  });
};

export const mutateElements = async ({
  elementData,
  elementRules,
  shadowRef,
  page,
}: {
  elementData: Array<PdfBuilderElementData>;
  elementRules: Array<PdfBuilderElementRule>;
  shadowRef: HTMLDivElement;
  page: HTMLDivElement;
}): Promise<void> => {
  return new Promise((resolve): void => {
    let originalParent: HTMLElement | null = null;

    elementData.forEach((data) => {
      // Safeguard against last element in shadowRef (input)
      // If the last element is not the shadowRef itself, perform regular logic
      if (data.parent !== shadowRef) {
        // Shallow clone of the parent node
        const parent = data.parent.cloneNode() as HTMLElement;

        // Assign originalParent to either the existing originalParent or shallow clone it from the data
        originalParent = originalParent ?? (data.originalParent.cloneNode() as HTMLElement);

        // Get the number of child nodes in the parent
        const parentNodeCount = Array.from(data.parent.childNodes).length;

        // Get the number of child nodes in the data
        const childrenCount = Array.from(data.children).length;

        data.children.forEach((c) => {
          // Append a deep clone of the child node to the parent
          parent.appendChild(c.cloneNode(true));

          // Get the rules for the element
          const elRules = getRulesForElement(c, elementRules);

          if (!elRules.includes("repeat-on-split")) {
            // If rules do not include repeat-on-split, then remove the element
            c.remove();
          } else if (parentNodeCount <= childrenCount) {
            // If we are adding the rest of the children of the parent, then remove the element
            // This makes it so that we don't repeat any repeat-on-split elements after the last element has been added
            c.remove();
          }
        });

        // If we have added the last child node, remove the parent
        if (data.parent.childNodes.length === 0) {
          data.parent.remove();
        }

        // Append the parent to the originalParent
        originalParent.appendChild(parent);
      } else {
        // Parent is the shadowRef itself
        // This happens for the last page when all elements can fit on the page
        // Simply iterate through and add all the elements to the originalParent
        originalParent = originalParent ?? (data.originalParent.cloneNode() as HTMLElement);

        data.children.forEach((c) => {
          // Typescript doesn't know that originalParent is not null here
          if (originalParent) {
            originalParent.appendChild(c.cloneNode(true));
          }
          c.remove();
        });
      }
    });

    // Typescript doesn't know that originalParent is not null here
    if (originalParent) {
      // Append the originalParent to the page
      // Original parent contains parent and children nodes for all split elements
      page.appendChild(originalParent);
    }

    resolve();
  });
};

export const outputElements = async ({
  stagingRef,
  outputRef,
}: {
  stagingRef: HTMLDivElement;
  outputRef: HTMLDivElement;
}): Promise<void> => {
  return new Promise(async (resolveOutput): Promise<void> => {
    if (stagingRef) {
      await stagingRef.childNodes.forEach((childNode) => {
        if (outputRef) {
          outputRef.appendChild(childNode.cloneNode(true));
        }
      });
      resolveOutput();
    }
  });
};
