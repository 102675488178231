import { Offer, OfferStatusEnum } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import OfferStatusLabel from "~/lib/offers/offer-status-label";
import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useDataColumns } from "~/lib/ui/data-table";
import { linkToOffer } from "~/lib/utils";
import { useLocale } from "~/lib/utils/date";
import { CACHE_OFFERS } from "..";
import { Currency } from "~/lib/ui/currency";
import { CustomerCell } from "~/lib/ui/table/cells/customer-cell";

export function useOfferTableColumns(options: {
  /** Used to navigate if parent is a page */
  onDuplicateOffer?: (id: string) => void;
}) {
  const locale = useLocale();
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();

  const deleteM = useMutation({
    mutationFn: async (id: string) => {
      const res = await api.iDeleteOffer({ offerId: id });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_OFFERS],
      });
      return res;
    },
  });

  const duplicateM = useMutation({
    mutationFn: async (id: string) => {
      const res = await api.duplicateOffer({ offerId: id });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_OFFERS],
      });

      if (res.data.id) {
        options?.onDuplicateOffer?.(res.data.id);
      }
      return res;
    },
  });

  const columns = useDataColumns<Offer>((helper) => [
    helper.accessor("offerNumber", {
      id: "offer_number",
      enableSorting: true,
      header: t("common:nr"),
      cell: ({ row }) => <span className="font-semibold">{row.original.offerNumber}</span>,
      meta: {
        href: (row) => `/offers/${row.id}`,
        className: "text-center w-12",
      },
    }),
    helper.accessor("title", {
      id: "title",
      enableSorting: true,
      header: t("offers:offer_name"),
      cell: ({ row }) => <span>{row.original.title}</span>,
      meta: {
        href: (offer) => linkToOffer(offer.id),
      },
    }),
    helper.accessor("contact.name", {
      id: "contact.name",
      enableSorting: true,
      header: t("common:customer"),
      cell: ({ row }) => <CustomerCell customer={row.original.contact!} />,
      meta: {
        skeletonType: "avatar",
      },
    }),
    helper.accessor("expirationDate", {
      id: "expiration_date",
      enableSorting: true,
      header: t("common:expiration_date"),
      cell: ({ row }) => (
        <span>
          {row.original.expirationDate
            ? locale.format(row.original.expirationDate)
            : t("common:not_available")}
        </span>
      ),
    }),
    helper.accessor("netPayment", {
      id: "net_payment",
      enableSorting: true,
      header: t("finance:sales_price"),
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => <Currency>{row.original.netPayment ?? 0}</Currency>,
    }),
    helper.accessor("offerStatusId", {
      id: "offer_status_id",
      header: t("common:status"),
      cell: ({ row }) => (
        <OfferStatusLabel selectedStatusId={row.original.offerStatusId} readonly />
      ),
    }),
    helper.display({
      id: "actions",
      header: "",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("duplicate"),
              label: t("common:duplicate"),
              confirm: {
                action: "generic",
              },
              onClick: () => duplicateM.mutateAsync(row.original.id),
            },
            {
              Icon: getIcon("delete"),
              label: t("common:delete_prompt", { name: t("common:offer_quote").toLowerCase() }),
              onClick: () => deleteM.mutateAsync(row.original.id),
              confirm: {
                action: "delete",
                entity: "offer",
              },
              isHidden: (() => {
                if (row.original.status === OfferStatusEnum.Accepted) return true;
                if (!!row.original.deleted) return true;
                return false;
              })(),
            },
          ]}
        />
      ),
    }),
  ]);
  return columns;
}
