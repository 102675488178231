import { InvoiceActivityLogPopover } from "./activity-log-popover";
import { InvoiceActivityLogDetailTrigger } from "./activity-log-detail-trigger";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { twMerge } from "tailwind-merge";
import { useOutletContext } from "react-router";
import { InvoiceOutlet } from "../[invoiceId]";
import { useMount } from "~/lib/lifecycle-helpers";
import { CustomerDetailCard } from "./customer-detail-card";
import { InvoiceKPISection } from "./invoice-kpi-section";
import { Button } from "~/lib/ui";

export function InvoiceView() {
  const { t } = useTranslation();
  const { invoice } = useOutletContext<InvoiceOutlet>();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [loadingIsSlow, setLoadingIsSlow] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  // Pre-fetch the PDF to check if it's available. Messy, but the only way atm
  useMount((signal) => {
    timeout.current = setTimeout(() => {
      setLoadingIsSlow(true);
    }, 20000); // 20 seconds, then offer them to reload the page
    async function fetchPdf(s: AbortSignal) {
      try {
        if (!invoice.pdfUrl) return;
        const res = await fetch(invoice.pdfUrl, { signal: s });
        if (res.status !== 200) {
          setIsError(true);
          setLoading(false);
          return;
        }
      } catch (err) {
        // ignore - important that we ignore this because this will trigger twice in dev
      }
    }
    fetchPdf(signal);

    // Cleanup
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  });

  function handleLoadingDone() {
    setLoading(false);
    if (timeout.current) clearTimeout(timeout.current);
  }

  return (
    <div className="flex flex-col">
      <InvoiceKPISection />

      <InvoiceActivityLogPopover
        invoiceId={invoice.id}
        trigger={<InvoiceActivityLogDetailTrigger invoiceId={invoice.id} />}
      />
      <h3 className="mt-4">{t("common:details")}</h3>

      {isError ? (
        <div className="rounded-md bg-red-200 p-4">{t("invoices:unexpected_error_pdf_embed")}</div>
      ) : (
        <div className="relative h-screen w-full rounded-md bg-white p-1">
          {loading && (
            <div className="relative">
              <CustomerDetailCard customer={invoice.contact} showName />
              <SkeletonLoader template="document" className="h-screen p-4" />
              {loadingIsSlow && (
                <div className="absolute left-10 top-40 flex flex-col items-center justify-center gap-4 bg-orange-50 p-4 opacity-90">
                  <div className="opacity-100">
                    <div className="mb-6">{t("common:loading_slow_reload_prompt")}</div>
                    <Button variant="warning" onClick={() => window.location.reload()}>
                      {t("common:reload_page")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          <object
            className={twMerge("absolute left-0 top-0 z-sticky h-screen w-full")}
            type="application/pdf"
            // Trick to prevent Chrome for wasting display space on the PDF
            data={`${invoice.pdfUrl}#pagemode=none&navpanes=0`}
            onLoad={handleLoadingDone}
            onError={() => setIsError(true)}
          >
            <param
              // This is so that we can show the loader through the PDF
              value="transparent"
              name="wmode"
            />
          </object>
        </div>
      )}
    </div>
  );
}
