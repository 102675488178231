import { Icon } from "~/lib/ui";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { TaskFormState } from "~/pages/planning/_cmp/types";

export function DescriptionSection({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = (scrollHeight < 60 ? 60 : scrollHeight) + "px";
    }
  }, [formState.getValue("description")]);

  return (
    <div className="flex items-start gap-2">
      <div className="flex grow items-start gap-3">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center pt-1">
          <Icon name="descriptionSolid" size="small" />
        </div>
        <div
          onClick={() => setOpen(true)}
          className={twMerge(
            "grow px-3 py-2 text-shade-500 ",
            open ? "" : "cursor-pointer  hover:bg-shade-100"
          )}
        >
          {open || !!formState.getValue("description") ? (
            <div>
              <textarea
                ref={textareaRef}
                autoFocus={!formState.getValue("description")}
                placeholder={t("common:enter", { text: t("common:description").toLowerCase() })}
                defaultValue={formState.getValue("description") ?? ""}
                onChange={(e) => formState.setValues({ description: e.currentTarget.value })}
                style={{ height: "60px" }}
                className="scrollbar-hide line w-full resize-none border border-gray-300 !leading-6 focus:border-b-2 focus:border-b-hover focus:border-l-gray-300 focus:border-r-gray-300 focus:border-t-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
              ></textarea>
            </div>
          ) : (
            <div>{t("common:add_entity", { entity: t("common:description").toLowerCase() })}</div>
          )}
        </div>
      </div>
      {open || !!formState.getValue("description") ? (
        <div className="shrink-0 pt-2">
          <button
            onClick={() => {
              setOpen(false);
              formState.setValues({ description: "" });
            }}
            className="rounded-md border bg-white px-3 py-1 text-xs text-zinc-700 hover:bg-shade-50 focus:bg-shade-50"
          >
            {t("common:remove")}
          </button>
        </div>
      ) : null}
    </div>
  );
}
