import { EnvelopeIcon, HomeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { IconProp } from "./types";
import { twMerge } from "tailwind-merge";
import { OptionalLink } from "../utils/routing/optional-link";

interface ContactCardAction {
  label: string;
  icon: IconProp;
  onAction: () => void;
}
/**
 * Similar to ContactCard, except much more compact.
 */
export function ContactEntry({
  email,
  phone,
  address,
  showEmpty,
}: {
  email?: string;
  phone?: string;
  address?:
    | string
    | {
        street?: string | null;
        zipCode?: string | null;
        cityName?: string | null;
      };
  inline?: boolean;
  href?: string;
  showEmpty?: boolean;
}) {
  const { t } = useTranslation();

  if (typeof address === "object") {
    if (address.street && address.zipCode && address.cityName) {
      address = `${address.street}, ${address.zipCode}, ${address.cityName}`;
    } else {
      address = "";
    }
  }

  const isCompletelyEmpty = !email && !phone && !address;
  if (isCompletelyEmpty && !showEmpty) return null;

  return (
    <div className={twMerge("col-span-1 divide-y divide-gray-200 rounded-md border")}>
      <div>
        <div className="flex flex-col divide-y divide-gray-200 text-sm">
          <EachEntry
            label={t("common:address")}
            Icon={HomeIcon}
            value={address}
            showEmpty={showEmpty}
          />
          <EachEntry
            label={t("common:email")}
            href={email ? `mailto:${email}` : undefined}
            Icon={EnvelopeIcon}
            value={email}
            showEmpty={showEmpty}
          />
          <EachEntry
            label={t("common:phone")}
            href={phone ? `tel:${phone}` : undefined}
            Icon={PhoneIcon}
            value={phone}
            showEmpty={showEmpty}
          />
        </div>
      </div>
    </div>
  );
}

function EachEntry({
  label,
  Icon,
  value,
  href,
  showEmpty,
}: {
  label: string;
  Icon: IconProp;
  value?: string;
  href?: string;
  showEmpty?: boolean;
}) {
  const { t } = useTranslation();
  if (!showEmpty) {
    if (!value) {
      return null;
    }
  }
  return (
    <div className="flex flex-1 py-2">
      <OptionalLink to={href}>
        <div className="flex flex-row items-center px-4" title={label}>
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          <span className={twMerge("ml-3", !value && "text-gray-400")}>
            {value || t("common:not_available")}
          </span>
        </div>
      </OptionalLink>
    </div>
  );
}
