import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import { ReactNode, useState } from "react";

export default function Collapsible({
  triggerRender,
  children,
  className,
  asChild,
  defaultOpen = false,
  onOpenChange,
}: {
  triggerRender: (open: boolean) => ReactNode;
  children: ReactNode;
  className?: string;
  asChild?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (to: boolean) => void;
}) {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const handleOpenChange = (to: boolean) => {
    onOpenChange?.(to);
    setOpen(to);
  };

  return (
    <CollapsiblePrimitive.Root className={className} open={open} onOpenChange={handleOpenChange}>
      <CollapsiblePrimitive.Trigger asChild={asChild}>
        {triggerRender(open)}
      </CollapsiblePrimitive.Trigger>
      <CollapsiblePrimitive.Content className="animate-slide-down-fade">
        {children}
      </CollapsiblePrimitive.Content>
    </CollapsiblePrimitive.Root>
  );
}
