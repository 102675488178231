import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export function LoginLayout({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto"
                src="/apacta-logo-blue.png"
                alt={t("ui:navigation.logo_alt")}
              />
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900"></h2>
            </div>

            <div className="mt-8">{children}</div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/background-side.png"
            aria-hidden={true}
          />
          <div className="absolute top-1/4 flex w-full flex-col items-center">
            <img className="h-36" src="/apacta-bookmark-white.svg" aria-hidden={true} />
            <h2 className="mt-2 text-white">
              {t("ui:login.background_tagline", "Komplet overblik over dine byggesager")}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
