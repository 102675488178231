import { TaskFormState } from "~/pages/planning/_cmp/types";
import OrderLinesBuilderProvider from "~/lib/ui/order-lines/order-lines-builder-context";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import {
  OrderLine,
  OrderLinesBuilderOnChangeReturn,
  OrderLinesBuilderRef,
  OrderLineType,
} from "~/lib/ui/order-lines/lib/types";
import { ListResponse } from "~/lib/entity-ui/types";
import { useRef } from "react";
import { ControlPanelProductsToTask } from "@apacta/sdk";
import { Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";

const transformerFn = (line: ControlPanelProductsToTask, index: number): Partial<OrderLine> => {
  return {
    identifier: `tp-${index}`,
    productId: line.productId,
    quantity: line.amount,
    type: "product",
  };
};

const unpackProductBundle = (line: Partial<OrderLine>): Array<ControlPanelProductsToTask> => {
  const allowedTypes: Array<OrderLineType> = [
    "product",
    "vendor_product",
    "expense_line",
    "bundle",
  ];
  return (
    line.childOfferLines
      ?.filter((l) => l.type && allowedTypes.includes(l.type))
      .flatMap((l) => {
        if (l.type === "bundle") {
          return unpackProductBundle(l);
        } else {
          return {
            productId: l.productId ?? undefined,
            vendorProductId: l.vendorProductId ?? undefined,
            expenseLineId: l.expenseLineId ?? undefined,
            amount: l.quantity ?? 0,
            name: l.name,
          };
        }
      }) ?? []
  );
};

export function ProductsTab({
  formState,
  taskId,
  isEditPanel = false,
}: {
  formState: TaskFormState;
  taskId?: string;
  isEditPanel?: boolean;
}) {
  const { t } = useTranslation();
  const orderLinesBuilderRef = useRef<OrderLinesBuilderRef>(null);

  const handleAddProduct = (d: OrderLinesBuilderOnChangeReturn) => {
    const products = d.orderLines.map((l) => {
      if (l.productBundleId) {
        return unpackProductBundle(l);
      } else {
        return {
          name: l.name,
          productId: l.productId ?? undefined,
          expenseLineId: l.expenseLineId ?? undefined,
          vendorProductId: l.vendorProductId ?? undefined,
          amount: l.quantity ?? 0,
        };
      }
    });

    formState.setValues(
      {
        products: products.flat(), // flatten the array
      },
      d.isInitial
    );
  };

  return (
    <div className="flex flex-col">
      <div className="mb-6 px-3">{t("planning:product_section.description")}</div>
      <OrderLinesBuilderProvider
        cacheKey={["task-products", taskId, formState.getValue("products")]}
        dataFn={() => {
          return new Promise<ListResponse<ControlPanelProductsToTask>>((resolve) => {
            resolve({
              success: true,
              data: taskId ? formState.getValue("products") : [],
            });
          });
        }}
        transformerFn={transformerFn}
        onChange={handleAddProduct}
      >
        <OrderLinesBuilder
          ref={orderLinesBuilderRef}
          editMode={true}
          hideProductBundleContent={true}
          maxHeight={isEditPanel ? "max-h-96" : "max-h-56"}
          buttonSizes="small"
          options={{
            orderLinesActionVisibility: {
              showAddProduct: true,
              showAddHours: false,
              showAddProductBundle: true,
              showAddText: false,
              showSubtotal: false,
              showVAT: false,
              showTotal: false,
              sumCostPrice: false,
            },
            columns: {
              handle: { visible: false },
              product: { visible: true },
              quantity: { visible: true },
              unit: { visible: false },
              costPrice: { visible: false },
              salePrice: { visible: false },
              discount: { visible: false },
              total: { visible: false },
              actions: { visible: true },
            },
            popoverOptions: {
              side: isEditPanel ? "bottom" : undefined,
            },
          }}
        />
      </OrderLinesBuilderProvider>
      <div className="flex flex-col gap-2 bg-gray-100 p-2">
        <div className="flex items-center gap-4">
          <div className="rounded-full bg-green-600 p-1.5 text-white">
            <Icon name="warningCircle" className="h-6 w-6" />
          </div>
          <div className="text-sm">{t("planning:product_section.product_bundle_description")}</div>
        </div>
      </div>
    </div>
  );
}
