import { ControlProps, isOneOfEnumControl, rankWith } from "@jsonforms/core";
import { useId } from "react";
import { useTranslation } from "react-i18next";
import { LabeledSelect } from "~/lib/ui/form-elements/labeled-select";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { useDefaultFormValues } from "./useDefaultFormValues";

export function DropdownRenderer(props: ControlProps) {
  useDefaultFormValues(props);
  const { t } = useTranslation();
  const selectId = useId();

  function handleChange(value: string) {
    if (value === undefined || value === "") {
      props.handleChange(props.path, undefined);
      return;
    }
    // Cast to number if the value is integer
    if (props.schema.type === "integer") {
      props.handleChange(props.path, parseInt(value, 10));
      return;
    }
    props.handleChange(props.path, value);
  }

  return (
    <LabeledSelect
      id={selectId}
      label={getLabelForControl(props, t("common:untitled"))}
      error={props.errors}
      defaultValue={props.data ?? props.schema.default}
      required={props.required}
      className="mt-1 block w-fit rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      onChange={(e) => {
        handleChange(e.currentTarget.value);
      }}
    >
      {!props.schema.default && (
        <option defaultChecked value="">
          {t("common:pick")}
        </option>
      )}

      {props.schema.oneOf?.map((option, index) => (
        <option key={index} value={option.const}>
          {option.title}
        </option>
      ))}
    </LabeledSelect>
  );
}
export const DropdownTester = rankWith(4, isOneOfEnumControl);
