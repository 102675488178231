import colors from "tailwindcss/colors";

type RGB = {
  r: number;
  g: number;
  b: number;
};

/**
 * The colors used for labels
 */
const labelColors = [
  colors.slate[400],
  colors.slate[500],
  colors.slate[600],
  colors.neutral[400],
  colors.neutral[500],
  colors.neutral[600],
  colors.red[400],
  colors.red[500],
  colors.red[600],
  colors.orange[400],
  colors.orange[500],
  colors.orange[600],
  colors.yellow[400],
  colors.yellow[500],
  colors.yellow[600],
  colors.lime[400],
  colors.lime[500],
  colors.lime[600],
  colors.green[400],
  colors.green[500],
  colors.green[600],
  colors.teal[400],
  colors.teal[500],
  colors.teal[600],
  colors.cyan[400],
  colors.cyan[500],
  colors.cyan[600],
  colors.sky[400],
  colors.sky[500],
  colors.sky[600],
  colors.blue[400],
  colors.blue[500],
  colors.blue[600],
  colors.violet[400],
  colors.violet[500],
  colors.violet[600],
  colors.fuchsia[400],
  colors.fuchsia[500],
  colors.fuchsia[600],
  colors.pink[400],
  colors.pink[500],
  colors.pink[600],
];

export type LabelColor = (typeof labelColors)[number];

const luminance = ({ r, g, b }: RGB) => {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

function contrast(rgb1: RGB, rgb2: RGB) {
  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

const hexToRgb = (hex: string): RGB => {
  let localHex = hex;
  if (hex.length === 4) {
    localHex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`; // fix for shorthand hex
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(localHex);
  if (!result) {
    return { r: 0, b: 0, g: 0 };
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

const stringToColorGenerator = (str: string, padding = "") => {
  let hash = 0;
  str += padding;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }

  return color;
};

const generateBackgroundColor = (str: string, textColor: RGB = { r: 255, b: 255, g: 255 }) => {
  let color = stringToColorGenerator(str);
  let rgbColor = hexToRgb(color);
  let iter = 0;

  while (contrast(textColor, rgbColor) < 3) {
    color = stringToColorGenerator(str, iter.toString());
    rgbColor = hexToRgb(color);
    iter++;
  }
  return color;
};

/**
 * Returns original color if contrast is greater than 4.5, otherwise returns the contrast color (either white or zinc[700])
 * @returns the contrast color as hex color string
 * @param textColor The text color to contrast
 * @param bgColor The background color to contrast against
 */
const contrastColor = (textColor: string, bgColor: string): string => {
  const light: RGB = { r: 255, b: 255, g: 255 };
  const dark = hexToRgb(colors.zinc[700]);
  const backgroundColor = hexToRgb(bgColor);
  const originalTextColor = hexToRgb(textColor);

  if (contrast(originalTextColor, backgroundColor) >= 3.5) {
    return textColor;
  } else {
    if (contrast(light, backgroundColor) >= 3.5) {
      return "#ffffff";
    } else if (contrast(dark, backgroundColor) >= 3.5) {
      return colors.zinc[700];
    } else {
      return "#000000";
    }
  }
};

export { stringToColorGenerator, hexToRgb, generateBackgroundColor, contrastColor, labelColors };
