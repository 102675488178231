import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { Suspense } from "react";
import { useToastOnError } from "~/lib/utils/hooks";
import { DocumentDuplicateIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import PageTabs from "~/lib/ui/tabs/page-tabs";

export default function VendorProductPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const { id: vendorProductId } = useParams();

  const { data, isLoading, error } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["vendor-product", vendorProductId],
    queryFn: () => api.vendorProductView({ vendorProductId: vendorProductId as string }),
  });

  useToastOnError(error);

  const vendorProduct = data?.data;
  const pageTabs = [
    {
      id: "vendor_product_overview",
      label: t("common:overview"),
      to: "./",
      Icon: DocumentDuplicateIcon,
    },
    {
      id: "vendor_price_history",
      label: t("common:price_history", "History"),
      to: "price-history",
      Icon: DocumentTextIcon,
    },
  ];
  return (
    <PageLayout title={vendorProduct ? vendorProduct.name : ""} onBackClick={() => navigate(-1)}>
      <>
        <PageTabs tabs={pageTabs} />
        <Suspense>{!isLoading && <Outlet context={{ vendorProduct }} />}</Suspense>
      </>
    </PageLayout>
  );
}
