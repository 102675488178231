import { useState } from "react";
import { GetProjectsRequest, Project } from "@apacta/sdk";
import { useQuery, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";

const DefaultProjectRequestOptions: GetProjectsRequest = {
  sort: "created",
  direction: "desc",
  limit: 20,
  showAll: true,
};
/**
 * Infinite query hook for fetching projects
 */
export default function useInfiniteProjects(props?: {
  includedProjectIds?: Array<string>;
  opts?: GetProjectsRequest;
}) {
  const { includedProjectIds, opts } = props ?? {};
  const api = useAPI();

  const [query, setQuery] = useState<string>("");
  const [options, setOptions] = useState<GetProjectsRequest>({
    ...DefaultProjectRequestOptions,
    ...opts,
  });

  const projectsQ = useSuspenseInfiniteQuery({
    queryKey: ["projects", query, options],
    queryFn: async ({ pageParam = 1 }) => {
      return await api.getProjects({
        q: query,
        page: pageParam,
        ...options,
      });
    },
    initialPageParam: 1,
    refetchOnMount: true,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.pagination.hasNextPage) return undefined;
      return lastPage.pagination.currentPage + 1;
    },
  });

  // If projectsQ.data.pages is undefined, return empty array, otherwise merge data from all pages into single array
  const projectData: Array<Project> = projectsQ.data?.pages
    ? projectsQ.data.pages.reduce((acc, val) => [...acc, ...val.data], [] as Array<Project>)
    : [];

  const notIncludedProjectIds = [...(includedProjectIds ?? [])].filter(
    (id) => !projectData.some((p) => p.id === id)
  );

  const includedProjectsQDisabled =
    !includedProjectIds || includedProjectIds.length === 0 || !notIncludedProjectIds.length;

  const includedProjectsQ = useQuery({
    queryKey: ["projects", notIncludedProjectIds],
    queryFn: async () => await api.iListProjects({ ids: notIncludedProjectIds }),
    enabled: !includedProjectsQDisabled,
  });

  const includedProjects = (includedProjectsQ?.data?.data ?? []) as Array<Project>; // coerce to array of projects

  // Merge included projects with fetched projects and sort them by created
  const projects = [...projectData, ...includedProjects].sort((a, b) => {
    return new Date(b.created!).getTime() - new Date(a.created!).getTime();
  });

  return {
    projects,
    query,
    setQuery,
    setOptions,
    hasNextPage: projectsQ.hasNextPage,
    fetchNextPage: projectsQ.fetchNextPage,
    isFetching: projectsQ.isFetching,
  };
}
