import { Outlet } from "react-router";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useMe } from "~/lib/auth/use-me";
import { CACHE_FORMS } from "~/pages/forms";
import { toLocalDateString } from "~/lib/utils/date";
import { Week, weeksInRange } from "~/lib/utils/date/date-utils";

/**
 * Wrapper for the Suspense Outlet that fetches the data for the outlet.
 * If we don't do this in a separate component, the suspense query will enter an infinite loop.
 */
export function SuspenseOutletDataWrapper({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) {
  const api = useAPI();
  const { user } = useMe();

  const dataQ = useSuspenseQuery({
    queryKey: [CACHE_FORMS, dateFrom, dateTo, user.id],
    queryFn: () =>
      api.usersGetWeekly({
        startDate: toLocalDateString(dateFrom),
        endDate: toLocalDateString(dateTo),
        employeeIds: [user.id],
      }),
  });

  const dateRange = dataQ?.data?.dateRange ?? [];
  const _fromDate = dateRange.length > 0 ? dateRange[dateRange.length - 1].fromDate : undefined;
  const _toDate = dateRange.length > 0 ? dateRange[0].toDate : undefined;

  const weeks: Array<Week> = _fromDate && _toDate ? weeksInRange(_fromDate, _toDate).reverse() : [];

  const employee = !!dataQ?.data?.weeklyView?.length ? dataQ.data.weeklyView[0] : null;

  return <Outlet context={{ weeks, employee, isFetching: dataQ.isFetching }} />;
}
