import { LayoutProps, or, rankWith, uiTypeIs } from "@jsonforms/core";

export function FallbackLayoutRenderer(props: LayoutProps) {
  const type = props.uischema?.type;
  const entity = props.uischema?.options?.["x-entity"];
  const dataType = props.schema.type;
  return (
    <p className="text-error">
      {type} renderer for <strong>{entity}</strong> ({dataType}) missing.
    </p>
  );
}
export const LayoutFallbackTester = rankWith(
  1,
  or(
    uiTypeIs("Layout"),
    uiTypeIs("Group"),
    uiTypeIs("HorizontalLayout"),
    uiTypeIs("VerticalLayout")
  )
);
