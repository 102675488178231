import { useState } from "react";
import { ControlPanelApiProjectViewOrIndexResponse } from "@apacta/sdk";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { ProjectStatusLabel } from "./project-status-label";

export default function ProjectStatusChange({
  project,
  readonly = false,
  initialStatusId,
  onChange,
}: {
  project: ControlPanelApiProjectViewOrIndexResponse;
  readonly?: boolean;
  initialStatusId?: string;
  onChange?: (newStatusId: string) => void;
}) {
  // TODO: Store state here and make optimistic update work correctly.
  const [selectedId, setSelected] = useState<string | undefined>(initialStatusId);

  const api = useAPI();

  const statusM = useMutation({
    mutationFn: ({ toId }: { fromId?: string; toId: string }) =>
      api.iEditProject({
        projectId: project.id,
        iCreateProjectRequest: {
          projectStatusId: toId,
          name: project.name, // TODO: SDK issue requiring name here, otherwise we only need id
        },
      }),
    onMutate: (vars) => {
      setSelected(vars.toId);
    },
    onSuccess: (_, vars) => {
      // Overriding the defaults, because we don't want the toast shown on success
      onChange?.(vars.toId);
    },
  });

  async function handleChange({ toId, fromId }: { toId: string; fromId?: string }) {
    if (statusM.isPending || readonly) return;

    try {
      await statusM.mutateAsync({ toId, fromId });
    } catch (err) {
      // Roll back if failed
      if (fromId) {
        setSelected(fromId);
        onChange?.(fromId);
      }
    }
  }

  return <ProjectStatusLabel selectedStatusId={selectedId} onChange={handleChange} />;
}
