import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { useQuery } from "@tanstack/react-query";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { SearchInput } from "~/lib/ui/search-input";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { RenderProductUsageTable } from "~/pages/employees/_cmp/render-product-usage-table";

export default function ProductUsage() {
  const api = useAPI();
  const { t } = useTranslation();

  const employeesParams = useEmployeesParams();
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    productKey?: string;
  }>();

  const q = useQuery({
    queryKey: [CACHE_EMPLOYEES, "users-products-usage", employeesParams, searchParams],
    queryFn: () =>
      api.usersProductsUsage({
        startDate: employeesParams.startDate,
        endDate: employeesParams.endDate,
        employeeIds: employeesParams.employeeIds,
        productKey: searchParams.productKey,
      }),
  });
  const productsUsage = q.data?.data;
  return (
    <div className="flex flex-col gap-2">
      <h2 className="mb-2"> {t("users:product_usage", "Product usage")}</h2>
      <SearchInput
        placeholder={t("common:search")}
        value={searchParams.productKey}
        onChangeValue={(v) => setSearchParams("productKey", v)}
        onClear={() => {
          setSearchParams("productKey", undefined);
        }}
        debounce={500}
      />
      {productsUsage && <RenderProductUsageTable data={productsUsage} />}
    </div>
  );
}
