import { useLocale } from "../utils/date";

/**
 *  Returns relative time, e.g. "5 minutes ago" or "in 5 minutes".
 * Also adds a title attribute with the full date.
 * */
export function TimeAgo({ date }: { date: Date }) {
  const { timeAgo } = useLocale();
  return <span title={date.toLocaleString()}>{timeAgo(date)}</span>;
}
