import { ReportType } from "~/pages/reports/_cmp/report.types";

export const ReportTypes: Array<ReportType> = [
  {
    type: "ongoing_work",
    description: "ongoing_work_description",
    fields: ["date_to"],
  },
  {
    type: "projects_margins",
    description: "projects_margins_description",
    fields: ["date_from", "date_to"],
  },
  {
    type: "customers_statistics",
    description: "customers_statistics_description",
    fields: ["date_from", "date_to"],
  },
];
