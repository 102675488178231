import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, getIcon } from "~/lib/ui";

/**
 * Wraps a list of generic items with controls. Essentially so we can use multiple renderers that look the same.
 */
export function ArrayListContainer({
  onAdd,
  children,
}: {
  children: ReactNode;
  onAdd?: () => void;
}) {
  const { t } = useTranslation();

  function handleAdd() {
    // Note: What do I need to handle adding properly?
    // - path: The path to the array
    // - schema: The schema of the array
    // - uischema: The uischema of the array
    // Note: What if we simply add the row, and then set it as selected. Then hide selected from list.
    // - If they save, then we remove it from selection
    // - If they cancel, we remove it from the list
  }

  return (
    <div>
      <div className="my-4">{children}</div>
      <Button size="small" variant="secondary" Icon={getIcon("create_new")} onClick={onAdd}>
        {t("common:add")}
      </Button>
    </div>
  );
}
