import { MouseEventHandler, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import ContentLoader from "react-content-loader";

export function PillBadge({
  label,
  value,
  isLoading,
  action,
  onAction: onAction,
  className,
}: {
  label: string;
  value?: ReactNode;
  isLoading?: boolean;
  action?: ReactNode;
  onAction?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}) {
  if (isLoading)
    return (
      <ContentLoader className="h-7 w-32 rounded-lg border border-gray-200 bg-gray-50">
        <rect x={0} y={0} width="80%" height="100%"></rect>
      </ContentLoader>
    );

  return (
    <div
      className={twMerge(
        "flex h-7 flex-row rounded-lg border border-gray-200 bg-gray-50 align-middle text-sm",
        className
      )}
    >
      <div
        className={twMerge(
          "flex h-full flex-row  rounded-l-lg py-1 pl-3 align-middle",
          value && "pr-2"
        )}
      >
        {label}
      </div>
      <div
        className={twMerge(
          "flex flex-row rounded-r-lg py-1 align-middle font-semibold",
          value && "border-l-1 bg-white pl-3"
        )}
      >
        {value}
        {onAction && (
          <div className={twMerge("mx-2 flex self-center")}>
            <div className="cursor-pointer rounded-lg p-0.5 hover:bg-gray-300" onClick={onAction}>
              {action}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
