import { City } from "@apacta/sdk";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "../api";
import { useDebouncedValue } from "../debounce/use-debounce";
import { AutoCompleteInput } from "./autocomplete";

// TextInput that looks up addresses, onSelect can be used to help fill out other fields
export function ZipInput({
  onChange,
  onSelect,
  initialValue,
  label,
  placeholder,
  value,
  disabled = false,
}: {
  label?: string;
  onChange?: (val: string) => void;
  onSelect?: (item: { zipCode?: string; cityName?: string }) => void;
  initialValue?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
}) {
  if (value !== undefined && initialValue !== undefined)
    console.warn("Both value and initialValue are set, initialValue will be ignored");

  const { t } = useTranslation();
  const api = useAPI();
  const [debouncedValue, { setValue, isPending }] = useDebouncedValue(value || initialValue || "");
  const q = useQuery({
    queryKey: ["zipLookup", debouncedValue],
    queryFn: () => api.zipCodeSearch({ zipCode: debouncedValue }),
    placeholderData: keepPreviousData,
    enabled: !!debouncedValue,
  });

  function handleSelect(val: City) {
    onSelect?.({
      zipCode: val.zipCode?.toString(),
      cityName: val.name,
    });
  }

  return (
    <AutoCompleteInput
      label={label}
      disabled={disabled}
      items={q.data?.data || []}
      loading={q.isLoading || isPending}
      value={value}
      defaultValue={value ? undefined : initialValue}
      displayFn={(item) => `${item.zipCode} ${item.name}`}
      placeholder={placeholder ?? t("common:zip_code")}
      onSelect={handleSelect}
      onChange={(val) => {
        setValue(val);
        onChange?.(val);
      }}
    />
  );
}
