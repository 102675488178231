import { Project } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { useProjectParams } from "~/pages/projects/_cmp/use-project-params";
import { usePagination } from "~/lib/utils";
import { ExpensesTable } from "~/pages/expenses/_cmp/expenses-table";

export default function ProjectFinanceExpensesPage() {
  const { entity: project } = useOutletContext<{ entity: Project }>();
  const api = useAPI();
  const { t } = useTranslation();
  const [{ page }] = usePagination();
  const projectParams = useProjectParams();

  return (
    <>
      <ExpensesTable
        projectId={project.id}
        parentType="kpi"
        dateFrom={projectParams.startDate ? new Date(projectParams.startDate) : undefined}
        dateTo={projectParams.endDate ? new Date(projectParams.endDate) : undefined}
      />
    </>
  );
}
