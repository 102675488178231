import { Contact } from "@apacta/sdk";
import { twMerge } from "tailwind-merge";

export function CustomerDetailCard({
  customer,
  showName,
  className,
}: {
  customer?: Contact;
  showName?: boolean;
  className?: string;
}) {
  if (!customer) return null;
  return (
    <div
      className={twMerge(
        "rounded-md border border-shade-200 px-4 py-2 text-base text-shade-600",
        className
      )}
    >
      {showName && <div className="font-semibold">{customer.name}</div>}
      {customer.address && customer.zipCode && (
        <>
          <div>{customer.address}</div>
          <div>
            {customer.zipCode} {customer.city?.name}
          </div>
        </>
      )}
      {customer.cvr && <div>CVR: {customer.cvr}</div>}
      {customer.ean && <div>EAN: {customer.ean}</div>}
      {customer.phone && <div>Tlf: {customer.phone}</div>}
    </div>
  );
}
