import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient, useSuspenseQueries } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { useToastOnError } from "~/lib/utils/hooks";
import { Boundary } from "~/lib/ui/boundary";
import { Button, Dialog, Icon, getIcon } from "~/lib/ui";
import { InvoiceBadge } from "./_cmp/invoice-badge";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { linkToInvoiceV3, linkToProject } from "~/lib/utils";
import { InvoiceSendDialog } from "./_cmp/invoice-send-dialog";
import { CACHE_INVOICES } from ".";
import { InvoiceKpiDataQuery, useGraphQL } from "~/lib/gql";
import { EditInvoiceOperationRequest, Invoice } from "@apacta/sdk";
import Badge from "~/lib/ui/badge";
import { useInvoiceFormState } from "./_cmp/use-invoice-formstate";
import { useCallback, useEffect } from "react";

import { CACHE_PROJECTS } from "../projects";
import { BookInvoiceDialog } from "./_cmp/book-invoice-dialog";
import { useMe } from "~/lib/auth/use-me";
import { useFeatureFlags } from "~/lib/feature-flags";
import { PreviewButton } from "./_cmp/preview-button";
import { getInvoiceEntity, getInvoiceType } from "./_cmp/get-invoice-type";

export default function InvoicePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const { invoiceId } = useParams();
  const sdk = useGraphQL();
  const me = useMe();
  const features = useFeatureFlags();

  const [invoiceQ, kpiQ] = useSuspenseQueries({
    queries: [
      {
        queryKey: [CACHE_INVOICES, invoiceId],
        queryFn: () => api.iGetInvoice({ invoiceId: invoiceId as string }),
      },
      {
        queryKey: [CACHE_INVOICES, invoiceId, "kpi"],
        queryFn: () =>
          sdk.invoiceKPIData({
            id: invoiceId as string,
          }),
      },
    ],
  });

  useToastOnError(invoiceQ.error);

  const invoice = invoiceQ.data?.data;
  const kpi = kpiQ.data.invoice;

  const formState = useInvoiceFormState(invoice);

  // The invoice can change, so we need to update the form state so that editing isn't borked
  // React Router will not unmount if you navigate between invoices (intenteded behaviour)
  useEffect(() => {
    formState.setValues({
      customerId: invoice.contactId,
      issueDate: invoice.issuedDate,
      reference: invoice.reference,
      message: invoice.message,
      paymentTermId: invoice.paymentTermId,
      lines: invoice.invoiceLines,
    });
  }, [invoice]);

  const mut = useMutation({
    mutationFn: (args: EditInvoiceOperationRequest) => {
      return api.editInvoice(args);
    },
  });
  const values = formState.getValues();
  const handleSave = useCallback(async () => {
    await mut.mutateAsync({
      invoiceId: invoice.id,
      editInvoiceRequest: {
        contactId: values.customerId,
        issuedDate: values.issueDate,
        reference: values.reference,
        message: values.message,
        invoiceLines: values.lines,
        paymentTermId: values.paymentTermId,
      },
    });
    // This makes sure the project is updated with the new invoice
    await queryClient.invalidateQueries({ queryKey: [CACHE_PROJECTS, invoice.projectId] });
    formState.setValues(values, true);
  }, [invoice, values]);

  const pendingSave = formState.isModified ? handleSave : undefined;

  if (!invoice) return null;

  // Invoice with number if any, otherwise just "Invoice"
  const title = (() => {
    if (invoice?.invoiceNumber) {
      return `${t("common:invoice", { count: 1 })} #${invoice?.invoiceNumber}`;
    }
    return getInvoiceType({ invoice, t });
  })();

  // Creates a draft of a new invoice with the values reversed
  async function handleCreateCreditNote() {
    const res = await api.duplicateInvoice({
      invoiceId: invoice.id,
      asCreditNote: true,
    });
    if (!res.data.id) return;
    navigate(linkToInvoiceV3(res.data.id));
  }

  const showSendButton = !invoice.isDraft;
  const showBookButton = invoice.isDraft && !features.has("locked_invoices") && !invoice.isLocked;
  const showSaveButton = invoice.isDraft && !invoice.isLocked;
  const showCreditNoteButton = !invoice.isDraft;
  const showSyncToERPandLock =
    invoice.isDraft && me.integrationsEnabled.erp && features.has("locked_invoices");

  return (
    <PageLayout
      title={title}
      renderDescription={() => (
        <div className="flex flex-col gap-2">
          <OptionalLink to={linkToProject(invoice.project.id)}>
            <div className="flex flex-row items-center gap-2">
              <Icon name="project"></Icon>
              <div>{invoice?.project?.name}</div>
            </div>
          </OptionalLink>

          <div className="flex flex-row items-center gap-2">
            <InvoiceBadge invoice={invoice} />
            <Badge variant="yellow" icon={invoice.project.isFixedPrice ? "currency" : "time"}>
              {invoice.project.isFixedPrice
                ? t("projects:fixed_price")
                : t("projects:variable_price")}
            </Badge>
          </div>
        </div>
      )}
      renderActions={() => (
        <div className="flex flex-row gap-4">
          {showSaveButton && (
            <Button onClick={handleSave} disabled={!formState.isModified} variant="tertiary">
              {t("common:save_x", {
                replace: {
                  x: t(`common:${getInvoiceEntity(invoice)}`, { count: 1 }).toLocaleLowerCase(),
                },
                defaultValue: "Save {{x}}",
              })}
            </Button>
          )}
          <PreviewButton variant="primary" preview={{ fileUrl: invoice.pdfUrl }} />

          {showBookButton && (
            <Dialog
              trigger={
                <Button Icon={getIcon("invoice")} variant="primary" disabled={!!invoice.erpId}>
                  {me.integrationsEnabled.erp
                    ? t("invoices:export_to_erp")
                    : t("invoices:action_book", "Book")}
                </Button>
              }
              render={({ onClose }) => (
                <BookInvoiceDialog invoice={invoice} onClose={onClose} pendingSave={pendingSave} />
              )}
            />
          )}

          {showSendButton && (
            <Dialog
              trigger={
                <Button variant="primary" Icon={getIcon("send")}>
                  {t("invoices:send_invoice")}
                </Button>
              }
              render={({ onClose }) => (
                <InvoiceSendDialog onClose={onClose} invoice={invoice} pendingSave={pendingSave} />
              )}
            />
          )}
          {showCreditNoteButton && (
            <Button variant="secondary" Icon={getIcon("add")} onClick={handleCreateCreditNote}>
              {t("invoices:create_credit_note")}
            </Button>
          )}
          {showSyncToERPandLock && (
            <Button
              Icon={getIcon("lock")}
              variant="secondary"
              confirm={{
                action: "generic",
              }}
              disabled={invoice.isLocked}
              onClick={() =>
                mut.mutateAsync({
                  invoiceId: invoice.id,
                  editInvoiceRequest: {
                    isDraft: false,
                    isLocked: true,
                  },
                })
              }
            >
              {t("invoices:sync_to_erp_and_lock")}
            </Button>
          )}
        </div>
      )}
      onBackClick={() => navigate(-1)}
    >
      <Boundary variant="tab">
        <Outlet context={{ invoice, kpi, formState, pendingSave }} />
      </Boundary>
    </PageLayout>
  );
}

export type InvoiceOutlet = {
  invoice: Invoice;
  kpi: InvoiceKpiDataQuery["invoice"];
  formState: ReturnType<typeof useInvoiceFormState>;
  pendingSave?: () => Promise<void>;
};
