import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { Popover } from "~/lib/ui/popover/popover";
import Calendar from "~/lib/ui/calendar/calendar";
import { forwardRef, useState } from "react";
import { useLocale } from "~/lib/utils/date";

type DateInputProps = {
  defaultDate?: Date | null;
  hasError: boolean;
  onChange: (value: string | Date | null) => void;
  disabledBefore?: Date;
  disabledAfter?: Date;
  id?: string;
};

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ id, defaultDate, hasError, onChange, disabledBefore, disabledAfter }: DateInputProps, ref) => {
    const [localValue, setLocalValue] = useState<string | Date | null>(defaultDate ?? null);
    const { format, getDateFormat } = useLocale();

    const dateFormat = getDateFormat({ shortDate: true, excludeTime: true });

    const handleDateChange = (value: string | Date | null) => {
      setLocalValue(value);
      onChange(value);
    };

    return (
      <div
        className={twMerge(
          "flex items-center border-b-2  focus-within:bg-shade-100",
          hasError ? "border-b-red-500" : "border-b-transparent focus-within:border-b-hover"
        )}
      >
        <input
          ref={ref}
          defaultValue={
            defaultDate
              ? format(defaultDate, {
                  shortDate: true,
                  excludeTime: true,
                })
              : ""
          }
          id={id}
          placeholder={!!dateFormat ? dateFormat.dateFormat.toLowerCase() : "dd.mm.yyyy"}
          onChange={(e) => handleDateChange(e.currentTarget.value)}
          className="w-22 border-0 bg-transparent px-1 text-left text-sm focus:outline-none focus:ring-transparent"
        />
        <div className="flex h-full items-center">
          {!!localValue ? (
            <div
              className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full text-zinc-700 hover:bg-shade-300"
              onClick={() => handleDateChange(null)}
            >
              <Icon name="close" className="h-4 w-4" />
            </div>
          ) : (
            <div className="h-5 w-5"></div>
          )}
        </div>
        <Popover
          triggerAsChild={true}
          config={{ align: "center", side: "right" }}
          triggerRender={() => (
            <button className="ml-2 flex grow items-center justify-center px-1 py-2 hover:bg-shade-200 focus:bg-shade-200">
              <Icon name="calendarDays" size="small" />
            </button>
          )}
        >
          {(close) => (
            <Calendar
              defaultDate={defaultDate ?? new Date()}
              onSelect={(d) => {
                handleDateChange(d);
                close();
              }}
              disabledBefore={disabledBefore}
              disabledAfter={disabledAfter}
            />
          )}
        </Popover>
      </div>
    );
  }
);

DateInput.displayName = "DateInput";
