import { ReactElement } from "react";
import ContextMenu, { ContextMenuItem } from "~/lib/ui/context-menu/context-menu";
import { useTranslation } from "react-i18next";
import { usePlanning } from "~/lib/planning/use-planning";
import { getIcon } from "~/lib/ui";

export function DropzoneContextMenu({
  canPaste = false,
  index,
  userId,
  date,
  className,
  children,
}: {
  canPaste?: boolean;
  index?: number;
  userId: string | null;
  date?: Date | null;
  className?: string;
  children: ReactElement;
}) {
  const { t } = useTranslation();

  const { modalCreate, cardPaste } = usePlanning();

  const contextItems: Array<ContextMenuItem> = [
    {
      type: "item",
      label: t("planning:context-menu.create"),
      Icon: getIcon("add"),
      onSelect: () => modalCreate({ userId, index, date }),
    },
    {
      type: "separator",
    },
    {
      type: "item",
      label: t("planning:context-menu.paste"),
      disabled: !canPaste,
      Icon: getIcon("paste"),
      onSelect: () => cardPaste({ userId, index, date }),
    },
  ];

  return (
    <ContextMenu className={className} items={contextItems}>
      {children}
    </ContextMenu>
  );
}
