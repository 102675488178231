const GOOGLE_MAPS_STATIC_URL =
  "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAvNrkfNPL8Fe6TzekCBAr1yhvfJSxUdrs";

export const GOOGLE_MAPS_API_KEY = "AIzaSyAvNrkfNPL8Fe6TzekCBAr1yhvfJSxUdrs";
export const GOOGLE_MAPS_MAP_ID = "50f4867a7e40ef01";

// Good enough for now, replace with Leaflet later
export const staticMapUrl = (opts: {
  latitude: number;
  longitude: number;
  size?: string;
  zoom?: number;
}) => {
  const { latitude = 40.707594, longitude = -73.991711, size = "1900x150", zoom = 14 } = opts;
  //&size=500x500&zoom=16
  let base = GOOGLE_MAPS_STATIC_URL;
  if (size) base += `&size=${size}`;
  if (zoom) base += `&zoom=${zoom}`;
  return `${base}&markers=color%3Ared%7C${latitude},${longitude}`;
};
