import { numberToTime } from "~/lib/utils/time";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";
import { useId } from "react";

export function TimeSection({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  const startTimeId = useId();

  return (
    <div className="w-fit">
      <div className="flex cursor-pointer items-center border-b-2 border-b-transparent px-3 focus-within:cursor-auto focus-within:border-b-hover focus-within:bg-shade-100 hover:bg-shade-100">
        <label htmlFor={startTimeId} className="w-40 cursor-pointer">
          {t("planning:create_modal.start_time")}
        </label>
        <div>
          <input
            id={startTimeId}
            placeholder={t("ui:time-input.hour-placeholder")}
            defaultValue={numberToTime(formState.getValue("startTime")?.hour)}
            onClick={(e) => e.currentTarget.select()}
            onChange={(e) =>
              formState.setValues({
                startTime: {
                  hour: Number(e.currentTarget.value),
                  minute: formState.getValue("startTime")?.minute ?? 0,
                },
              })
            }
            className="w-10 cursor-pointer border-0 bg-transparent px-2 text-right text-sm font-semibold focus:cursor-auto focus:outline-none focus:ring-transparent"
          />
          :
          <input
            placeholder={t("ui:time-input.minute-placeholder")}
            defaultValue={numberToTime(formState.getValue("startTime")?.minute)}
            onClick={(e) => e.currentTarget.select()}
            onChange={(e) =>
              formState.setValues({
                startTime: {
                  hour: formState.getValue("startTime")?.hour ?? 0,
                  minute: Number(e.currentTarget.value),
                },
              })
            }
            className="w-10 cursor-pointer border-0 bg-transparent px-2 text-left text-sm font-semibold focus:cursor-auto focus:outline-none focus:ring-transparent"
          />
        </div>
      </div>
    </div>
  );
}
