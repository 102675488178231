import { ReactNode } from "react";
import { formatDate } from "~/lib/utils/date";
import { IconProp } from "../../../lib/ui/types";
import { useTranslation } from "react-i18next";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";

export function FeedItem({
  actor,
  children,
  date,
  Icon,
  title,
  onDelete,
  onEdit,
  readonly,
}: {
  actor: ReactNode;
  children?: ReactNode;
  date: Date;
  Icon?: IconProp;
  title?: ReactNode;
  onDelete?: () => void;
  onEdit?: () => void;
  readonly?: boolean;
}) {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="relative">
        {actor}
        <span
          className="absolute -bottom-1.5 -right-2 rounded-full px-0.5 py-px"
          style={{ backgroundColor: "#f4f4f6", color: "#686b6f" }}
        >
          {Icon ? <Icon className="h-4 w-4" aria-hidden="true" /> : null}
        </span>
      </div>
      <div className="relative w-full min-w-0 whitespace-pre-wrap">
        {title && <div className="mt-1 text-gray-700">{title}</div>}
        <div className="text-sm text-gray-500">{formatDate(date, i18n.resolvedLanguage)}</div>
        {children}
        {onEdit && !readonly && (
          <div className="absolute right-10 top-0">
            <button className="hover:text-hover" onClick={onEdit}>
              <PencilIcon className="h-5 w-5" />
            </button>
          </div>
        )}
        {onDelete && !readonly && (
          <div className="absolute right-2 top-0">
            <button className="hover:text-hover" onClick={onDelete}>
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
