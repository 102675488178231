import { Icon } from "~/lib/ui";
import { OfferAction } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export const ExpiredIcon = () => {
  return (
    <div className="rounded-full bg-yellow-500 p-1 text-white">
      <Icon name="warningCircle" size="small" />
    </div>
  );
};

export const ExpiredText = ({ action }: { action: OfferAction }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-sm">{t(`offers:activity_log.${action.action}`)}</div>
    </div>
  );
};
