import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { Suspense } from "react";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { useToastOnError } from "~/lib/utils/hooks";

export default function ExpensePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const { id: expenseId } = useParams();

  const { data, isLoading, error } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["expense", expenseId],
    queryFn: () => api.expensesView({ expenseId: expenseId as string }),
  });

  useToastOnError(error);

  const expense = data?.data;

  if (isLoading) {
    return (
      <div className="relative h-96 w-full">
        <PageSpinner loadingMessage={t("common:loading")} />
      </div>
    );
  }

  return (
    <PageLayout
      title={`${t("common:vendor_invoice", { count: 1 })} #${expense?.supplierInvoiceNumber}`}
      onBackClick={() => navigate(-1)}
    >
      <Suspense>{!isLoading && <Outlet context={{ expense }} />}</Suspense>
    </PageLayout>
  );
}
