import { forwardRef, Ref } from "react";
import { twMerge } from "tailwind-merge";
import { LabelInput, LabelInputProps } from "~/lib/ui";

type NumberInputProps = LabelInputProps & { onChangeValue?: (value: number) => void };
export const NumberInput = forwardRef(function NumberInputNew(
  { onChangeValue, ...props }: NumberInputProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <LabelInput
      ref={ref}
      type="number"
      step="0.1"
      pattern="-?[0-9]*[.,]?[0-9]+"
      onFocus={(e) => {
        if (e.currentTarget.value === "0") {
          // We expect a new value if the value is 0 (empty)
          e.preventDefault();
          e.currentTarget.select();
        }
      }}
      controls={false}
      {...props}
      // We don't allow overrides of the following props
      className={twMerge("hide-arrow text-right", props.className)}
      onChange={(e) => {
        const value = e.currentTarget.value;
        const num = Number(value);
        if (isNaN(num)) {
          console.warn("NumberInput: Invalid number", value);
          return;
        }
        onChangeValue?.(Number(value));

        // If you're using onChangeValue, then you can't override onChange
        // - it causes weird behavior and typically happens when register is combined with onChange
        if (onChangeValue === undefined) {
          return props.onChange?.(e);
        }
      }}
    />
  );
});
