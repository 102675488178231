import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useNavigationContent } from "~/lib/navigation/navigation-layout/sidebar/components/navigation-item/navigation-item.content";
import { OptionalLink } from "~/lib/utils/routing/optional-link";

export function AuthorizedLink({
  to,
  children,
  openInNewTab = false,
  className,
}: {
  to?: Parameters<typeof Link>[0]["to"];
  children: ReactNode;
  openInNewTab?: boolean;
  className?: string;
}) {
  const { userHasAccess } = useNavigationContent();

  if (to === undefined) {
    return <>{children}</>;
  }

  if (!!to && !userHasAccess(to.toString())) {
    return <>{children}</>;
  }

  return (
    <OptionalLink to={to} openInNewTab={openInNewTab} className={className}>
      {children}
    </OptionalLink>
  );
}
