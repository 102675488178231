import { twMerge } from "tailwind-merge";
import { DNDUpload } from "~/lib/ui/d-n-d-upload";
import { BaseFile } from "@apacta/sdk";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MediaPreviewLink } from "~/lib/ui/media/";
import { randomUUID } from "~/lib/uuid/random-uuid";
import { useTranslation } from "react-i18next";
import { Spinner } from "~/lib/ui/spinner";
import { BadgeLabel } from "~/lib/ui/badge-label";

export default function OfferViewFileSection({
  files,
  editMode,
  loading,
  onSelectFiles,
  onRemoveFile,
}: {
  files: Array<File | BaseFile>;
  editMode: boolean;
  loading: boolean;
  onSelectFiles: (files: FileList) => Promise<void> | void;
  onRemoveFile: (file: File | BaseFile) => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-wrap gap-6">
        {loading ? (
          <Spinner className="h-24 w-24" />
        ) : files.length ? (
          files.map((file) => (
            <div
              /*randomUUID only used for files in memory, should be safe as they will get a unique ID once uploaded*/
              key={`${file.name}-${(file as File).size}-${(file as BaseFile).id ?? randomUUID()}`}
              className={twMerge(
                "flex w-56 flex-col items-center justify-center gap-2 rounded-lg border bg-white text-sm shadow-md",
                editMode ? "pt-2" : "pt-8"
              )}
            >
              <div className="flex w-full justify-between p-1">
                <div>
                  {(file as BaseFile).isStandardFile && (
                    <BadgeLabel className="ml-2 px-3 py-1 text-xs">
                      {t("common:standard_file", { count: 1 })}
                    </BadgeLabel>
                  )}
                </div>
                {editMode && (
                  <div
                    onClick={() => onRemoveFile(file)}
                    className="transition-color mr-2 h-6 w-6 cursor-pointer self-end rounded-full bg-shade-100 p-1 duration-200 ease-in-out hover:bg-red-500 hover:text-white"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center gap-4 px-6">
                <div className="pt-2">
                  <MediaPreviewLink
                    href={(file as BaseFile).fileUrl || URL.createObjectURL(file as File)}
                    mimeType={(file as BaseFile).mimeType || (file as File).type}
                  />
                </div>
                <div className="flex pb-6">
                  <div className="w-44 overflow-hidden text-ellipsis whitespace-nowrap">
                    {(file as BaseFile).originalFilename || file.name}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-shade-400">{t("offers:no_files")}</div>
        )}
      </div>
      {editMode && <DNDUpload onSelected={onSelectFiles} multiple />}
    </>
  );
}
