import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { stackedBarChartOptions, TotalFormatter } from "./stacked-bar-chart-options";

/**
 * Expected data format for the StackedBarChart component.
 * Datasets with the same x-axis label will be stacked on top of each other.
 * @property name - The name of the series
 * @property color - The color of the series
 * @property data - The array of data for the series
 * @property data.x - The x-axis label
 * @property data.y - The y-axis value
 * @property position - The position of the series in the chart, 0 being the bottom-most series
 */
export type BarChartData = {
  name: string;
  color: `#${string}`;
  data: Array<{
    x: string;
    y: number;
  }>;
  position?: number;
};

export default function StackedBarChart({
  data,
  className,
  yAxisFormatter,
  xAxisFormatter,
  legendFormatter,
  totalFormatter,
}: {
  data: Array<BarChartData>;
  className?: string;
  yAxisFormatter?: (value: number) => string;
  xAxisFormatter?: (value: string) => string;
  legendFormatter?: (value: string) => string;
  totalFormatter?: (values: TotalFormatter) => string;
}) {
  /**
   * If the data has a position property, we sort the data by position, otherwise use and show as is
   */
  const chartData = data.some((d) => d.position)
    ? data.sort((a, b) => (a.position! > b.position! ? 1 : -1))
    : data;

  /**
   * We need to create a list of all the x-axis labels to be used in the chart
   */
  const XAxisLabels = chartData.flatMap((bd) => {
    return bd.data.map((d) => {
      return xAxisFormatter ? xAxisFormatter(d.x) : d.x;
    });
  });

  /**
   * We need to create a list of all the bar colors to be used in the chart
   * ApexCharts is pretty primitive, so the order matters here, hence the aforementioned sort
   */
  const barColors = chartData.map((bd) => {
    return bd.color;
  });

  /**
   * Convert the data to the format that ApexCharts expects
   */
  const dataSeries: ApexOptions["series"] = chartData.map((bd) => {
    return {
      name: bd.name,
      data: bd.data.map((d) => d.y),
    };
  });

  /**
   * Check if the data has any negative values, used to accentuate the zero line in the chart
   */
  const hasNegativeValues = chartData.some((cd) => {
    return cd.data.some((d) => d.y < 0);
  });

  /**
   * Create the chart options for the stacked bar chart using the provided and calculated data
   */
  const chartOptions: Partial<ApexOptions> = stackedBarChartOptions(
    XAxisLabels,
    barColors,
    yAxisFormatter,
    legendFormatter,
    totalFormatter,
    hasNegativeValues
  );

  return (
    <div className={className}>
      <Chart options={chartOptions} series={dataSeries} type="bar" height="100%" />
    </div>
  );
}
