import { screens } from "tailwindcss/defaultTheme";

export type ScreenSizeType = "mobile" | "laptop" | "desktop";
/**
 * Point at which the floating menu should be shown
 * 768px is the md breakpoint
 */
const FLOATING_BREAKPOINT: number = Number(screens["md"].replace("px", "")); // 768

/**
 * Point at which the desktop menu should be shown
 * 1536px is the 2xl breakpoint
 */
const DESKTOP_BREAKPOINT: number = Number(screens["2xl"].replace("px", "")); // 1536

/**
 * Get the type of sidebar to display based on the current screen size
 */
export const getScreenSizeType = (): ScreenSizeType => {
  const screenSize = window.innerWidth;
  if (screenSize < FLOATING_BREAKPOINT) {
    return "mobile";
  } else if (screenSize < DESKTOP_BREAKPOINT) {
    return "laptop";
  } else {
    return "desktop";
  }
};
