import { EditorThemeClasses } from "lexical";

/**
 * Default theme for the editor
 * Anything you define here, needs to be touched upon in the appendCustomTheme function
 * @type {EditorThemeClasses}
 */
const EditorTheme: EditorThemeClasses = {
  heading: {
    h2: "text-3xl font-normal mb-2",
    h3: "text-2xl font-normal",
    h4: "text-1xl font-normal",
  },
  paragraph: "text-zinc-900 text-lg",
  list: {
    ol: "list-decimal pl-8",
    ul: "list-disc pl-8",
    listitem: "pl-2 text-lg",
  },
  text: {
    bold: "font-bold",
    italic: "italic",
    underline: "underline",
  },
};

/**
 * Appends custom theme to the default theme
 * All properties in this function must match the properties in the default theme
 * @param {EditorThemeClasses} customTheme - custom theme to append to the default theme
 */
function appendCustomTheme(customTheme?: EditorThemeClasses): EditorThemeClasses {
  if (!customTheme) return EditorTheme;
  return {
    heading: {
      ...EditorTheme.heading,
      ...(customTheme.heading ?? {}),
    },
    paragraph: customTheme.paragraph || EditorTheme.paragraph,
    list: {
      ...EditorTheme.list,
      ...(customTheme.list ?? {}),
    },
    text: {
      ...EditorTheme.text,
      ...(customTheme.text ?? {}),
    },
  };
}

export { EditorTheme, appendCustomTheme };
