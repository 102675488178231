import { UsersProductsUsageEntry } from "@apacta/sdk";
import { linkToFormNew } from "~/lib/utils";
import { Fragment } from "react";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { formatDate } from "~/lib/utils/date";
import { i18n } from "~/lib/i18n/i18n";
import { useTranslation } from "react-i18next";

export function RenderProductUsageFormEntry({ entry }: { entry: UsersProductsUsageEntry }) {
  const link = linkToFormNew(entry.formId);
  const { t } = useTranslation();
  return (
    <Fragment>
      <tr>
        <td>
          {entry.formDate ? (
            <OptionalLink to={link}>
              {formatDate(entry.formDate, i18n.resolvedLanguage, {
                shortDate: true,
              })}
            </OptionalLink>
          ) : (
            t("common:not_available")
          )}
        </td>
        <td>{entry.amount}</td>
        <td>
          <OptionalLink to={link}>{entry.origin}</OptionalLink>
        </td>
      </tr>
    </Fragment>
  );
}
