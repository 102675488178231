import { Icon } from "~/lib/ui";
import { OfferAction } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export const OpenedIcon = () => {
  return (
    <div className="rounded-full bg-blue-600 p-1 text-white">
      <Icon name="open" size="small" />
    </div>
  );
};

export const OpenedText = ({ action }: { action: OfferAction }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-sm">
        {t(`offers:activity_log.opened`)}
        {` ${t("common:by").toLowerCase()} ${t("common:recipient", { count: 1 }).toLowerCase()}`}
      </div>
    </div>
  );
};
