import { useTranslation } from "react-i18next";
import { useLocale } from "~/lib/utils/date";

export function DateCell({ date }: { date: Date | null | undefined }) {
  const locale = useLocale();
  const { t } = useTranslation();
  if (!date) return t("common:not_available");
  return (
    <time title={locale.format(date)} dateTime={date?.toISOString()}>
      {locale.format(date, { shortDate: true })}
    </time>
  );
}
