import { useTranslation } from "react-i18next";
import { useState } from "react";
import CreateTemplateModal, {
  CreateTemplateData,
} from "~/pages/settings/index/_cmp/create-template-modal";
import { useAPI } from "~/lib/api";
import { TextTemplate } from "@apacta/sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Dialog, getIcon, Icon } from "~/lib/ui";
import { TemplateEditSection } from "./_cmp/template-edit-section";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { getExpandedRowModel } from "@tanstack/react-table";
import BlockNavigation from "~/lib/navigation/block-navigation";

export default function TemplatesPage() {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [numberOfPendingRows, setNumberOfPendingRows] = useState(0);

  const api = useAPI();

  const handleAction = async (action: "duplicate" | "delete", entityId: string) => {
    if (action === "delete") {
      await api.deleteTextTemplate({ textTemplateId: entityId });
      await queryClient.invalidateQueries({
        queryKey: ["templates"],
      });
    } else {
      const fetchTemplate = await api.getTextTemplate({ textTemplateId: entityId });
      if (fetchTemplate && fetchTemplate.data) {
        const template = fetchTemplate.data;
        await api.createTextTemplate({ createTextTemplateRequest: template });
      }
    }
  };

  const createTemplateMutation = useMutation({
    mutationFn: async (data: CreateTemplateData) => {
      await api.createTextTemplate({
        createTextTemplateRequest: {
          content: data.content,
          title: data.name,
          expense: data.availability.expense,
          offer: data.availability.offer,
          invoice: data.availability.invoice,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: ["templates"],
      });
      setCreateModalOpen(false);
    },
  });

  const columns = useDataColumns<TextTemplate>((columnHelper) => [
    columnHelper.accessor("title", {
      header: t("settings:templates.table.name"),
      meta: { className: "w-8/12 group" },
      cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor("offer", {
      header: t("common:enabled"),
      meta: { className: "text-center" },
      cell: ({ row }) => (
        <div className="flex justify-center">
          {row.original.offer ? (
            <Icon name="approve" className="h-6 w-6 text-green-500" />
          ) : (
            <Icon name="reject" className="h-6 w-6 text-red-500" />
          )}
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("duplicate"),
              label: t("common:duplicate"),
              onClick: () => handleAction("duplicate", row.original.id),
            },
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              confirm: {
                action: "delete",
                entity: "template",
              },
              onClick: () => handleAction("delete", row.original.id),
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState({
    sorting: [{ id: "created", desc: true }], // defaults
  });

  const dataQ = useQuery({
    queryKey: [
      "templates",
      tableState.pageNumber,
      tableState.state.search,
      tableState.sortBy,
      tableState.sortingDirection,
    ],
    queryFn: async () => {
      const response = await api.getTextTemplates({
        page: tableState.pageNumber,
        q: tableState.state.search,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
      });
      return response;
    },
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      isLoading: dataQ.isLoading,
      data: dataQ.data?.data ?? [],
      singleRowExpansion: true,
      getRowId: (row) => row.id,
      backendPagination: dataQ.data?.pagination,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand: () => true,
      enableGlobalFilter: true,
    }
  );

  return (
    <>
      <Dialog
        open={createModalOpen}
        className="md:max-w-4xl"
        onOpenChange={() => setCreateModalOpen(false)}
        render={({ onClose }) => (
          <CreateTemplateModal
            onSubmit={async (data) => await createTemplateMutation.mutateAsync(data)}
            onClose={onClose}
            isProcessing={createTemplateMutation.isPending}
          />
        )}
      />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
          <h2 className="m-0">{t("settings:tabs.templates")}</h2>
          <div>
            <Button
              onClick={() => setCreateModalOpen(true)}
              variant="tertiary"
              Icon={getIcon("add")}
              loading={false}
            >
              <span>
                {t("common:create", { entity: t("common:template", { count: 1 }).toLowerCase() })}
              </span>
            </Button>
          </div>
        </div>
        <div>
          <DataTable
            table={table}
            renderExpandedRow={({ row, onHasPendingChanges }) => (
              <TemplateEditSection
                key={row.id}
                entity={row.original}
                onExpandChange={(isExpanded) => row.toggleExpanded(isExpanded)}
                onIsModifiedChange={(isModified) => {
                  const newCount = onHasPendingChanges(isModified);
                  setNumberOfPendingRows(newCount);
                }}
              />
            )}
          />
        </div>
      </div>
      <BlockNavigation when={numberOfPendingRows > 0} />
    </>
  );
}
