import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import TextInput from "~/lib/ui/form-elements/text-input";
import { Button } from "~/lib/ui";

export function WallCommentForm({ postId, onRefresh }: { postId: string; onRefresh: () => void }) {
  const { t } = useTranslation();
  const [msg, setMsg] = useState<string>("");
  const api = useAPI();

  const mutation = useMutation({
    mutationFn: () =>
      api.iCreateWallComment({
        postId,
        iCreateWallCommentRequest: {
          message: msg,
        },
      }),
    onSuccess: () => {
      setMsg("");
      onRefresh();
    },
  });

  function handleSubmit() {
    if (!msg) return;
    mutation.mutate();
  }

  return (
    <div className="w-full">
      <div className="mt-4 flex flex-row">
        <TextInput
          controlled
          value={msg}
          onChange={(value) => setMsg(value)}
          onSubmit={handleSubmit}
          placeholder={t("projects:feed.new_comment_placeholder")}
        />
        <Button
          variant="secondary"
          className="ml-4 shrink-0 text-xs"
          loading={mutation.isPending}
          disabled={mutation.isPending}
          onClick={handleSubmit}
        >
          {t("projects:feed.new_comment_submit")}
        </Button>
      </div>
    </div>
  );
}
