import DatePicker from "~/lib/ui/calendar/date-picker";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { monthNames } from "~/lib/calendar/calendar.helpers";
import { daysBetween, getOffsetDate } from "~/lib/utils/date/date-utils";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import { Button, Icon } from "~/lib/ui";

export default function MonthControls({
  selectedDate,
  onDateChange,
}: {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t, i18n } = useTranslation();

  const setDate = (offset: number, nDate?: Date) => {
    let nOffset = offset;
    if (nDate) {
      nOffset = daysBetween(selectedDate, nDate);
    } else {
      const dd = new Date(selectedDate);
      dd.setDate(1);
      dd.setMonth(selectedDate.getMonth() + nOffset);
      nOffset = daysBetween(selectedDate, dd);
    }
    const d: Date = getOffsetDate(selectedDate, nOffset);
    onDateChange(d);
  };

  const resetDate = () => {
    if (searchParams.has("date")) {
      searchParams.delete("date");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="flex gap-8">
      <div className="flex flex-grow items-center justify-center gap-4">
        <Button
          variant="secondary"
          onClick={() => setDate(-1)}
          className="xl:py-auto py-2.5 text-xs xl:text-base"
        >
          <Icon name="chevronLeft" className="h-3 w-3" />
        </Button>
        <div className="w-fit text-center text-xl">
          {`${
            monthNames(getResolvedLanguage())[selectedDate.getMonth()].name
          } ${selectedDate.getFullYear()}`}
        </div>
        <Button variant="secondary" onClick={() => setDate(1)} className="xl:py-auto py-3 text-xs">
          <Icon name="chevronRight" className="h-3 w-3" />
        </Button>
      </div>
      <div className="flex items-center gap-4">
        <Button variant="secondary" className="flex-shrink-0 text-sm" onClick={() => resetDate()}>
          {t("planning:view.month.now")}
        </Button>
        <DatePicker value={selectedDate} onChange={(e) => setDate(0, e)}>
          <Button variant="secondary" className="xl:py-auto px-3 py-2 text-xs xl:text-base">
            <Icon name="calendar" className="h-5 w-5" />
          </Button>
        </DatePicker>
      </div>
    </div>
  );
}
