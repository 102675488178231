import { useTranslation } from "react-i18next";

export function ServiceMessage() {
  const { t } = useTranslation();
  const msg = t("common:service_disruption_message", {
    defaultValue: "",
  }); // Only shown if we have an issue
  if (!msg || msg === "[service_disruption_message]") {
    return null;
  }
  return (
    <div className="mb-6 bg-white p-6">
      <p>{msg}</p>
    </div>
  );
}
