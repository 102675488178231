import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export function CardContainer({
  children,
  label,
  labelClassName,
  className,
}: {
  children: ReactNode;
  label?: string;
  className?: string;
  labelClassName?: string;
}) {
  return (
    <>
      {label && (
        <div className={twMerge("text-normal font-bold text-primary", labelClassName)}>{label}</div>
      )}
      <div
        className={twMerge(
          "grid auto-cols-max grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3",
          className
        )}
      >
        {children}
      </div>
    </>
  );
}
