import { LabelEntity, LabelEntityEntityEnum } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { Button } from "~/lib/ui";
import ColorPicker from "~/lib/ui/colors/color-picker";
import { LabelInput } from "~/lib/ui/form-elements";
import { LabelColor } from "~/lib/utils/colors";
import { CACHE_LABELS } from "./labels";
import { useEffect } from "react";

export function LabelEditFragment({
  label,
  onClose,
  onIsModifiedChange,
}: {
  label: LabelEntity;
  onClose: () => void;
  onIsModifiedChange: (isModified: boolean) => void;
}) {
  const api = useAPI();
  const queryClient = useQueryClient();
  const formState = useFormState({
    schema: {
      text: z.string(),
      bgColor: z.string().optional(),
      textColor: z.string().optional(),
    },
    initialValues: {
      text: label.text,
      bgColor: label.bgColor,
      textColor: label.textColor,
    },
  });

  const editLabelM = useMutation({
    mutationFn: async () => {
      const v = formState.getValues();
      await api.editLabel({
        labelId: label.id,
        createLabelRequest: {
          text: v.text,
          bgColor: v.bgColor,
          textColor: v.textColor,
          entity: label.entity as LabelEntityEntityEnum,
        },
      });
      await queryClient.invalidateQueries({ queryKey: [CACHE_LABELS] });
      formState.resetInitialValues(v);
      onClose();
    },
  });

  // Tells parent if modified state has changed
  useEffect(() => {
    onIsModifiedChange(formState.isModified);
  }, [formState.isModified]);

  const { t } = useTranslation();

  return (
    <div className="flex gap-8">
      <div className="w-4/12">
        <LabelInput
          label={t("common:name")}
          defaultValue={label.text}
          onChange={(e) => formState.onChange("text", e.currentTarget.value)}
        />
      </div>
      <div className="w-4/12">
        <ColorPicker
          label={t("common:color", { count: 1 })}
          currentColor={(formState.getValue("bgColor") ?? label.bgColor) as LabelColor}
          onSelect={(bgColor, textColor) => formState.setValues({ bgColor, textColor }, false)}
        />
      </div>
      <div className="inline-flex flex-1 grow justify-end gap-2 self-end">
        <Button
          disabled={!formState.isModified || !formState.isValid}
          size="small"
          variant="tertiary"
          onClick={() => editLabelM.mutateAsync()}
        >
          {t("common:save")}
        </Button>
        <Button size="small" variant="secondary" onClick={onClose}>
          {t("common:cancel")}
        </Button>
      </div>
    </div>
  );
}
