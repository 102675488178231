import { useTranslation } from "react-i18next";

export function AppLoader() {
  const { t } = useTranslation();
  // Show the Apacta logo centered on the screen while loading
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-2">
      <img
        src="/apacta-icon-blue.svg"
        alt="Apacta"
        className="block h-48 w-48  animate-bounce rounded-full shadow-xl duration-700 ease-linear fill-mode-both"
      />
      <div className="text-lg text-shade-500">{t("common:loading")}...</div>
    </div>
  );
}
