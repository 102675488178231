import { CheckIcon } from "@heroicons/react/24/outline";
import * as RadixDropdown from "@radix-ui/react-dropdown-menu";
import { twMerge } from "tailwind-merge";
import { baseItemClass } from "./shared";

export function DropdownItemCheck({
  children,
  className,
  checked,
  onChange,
  preventClosing,
}: {
  children: string;
  className?: string;
  checked: boolean;
  preventClosing?: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <RadixDropdown.CheckboxItem
      className={twMerge(baseItemClass, className)}
      onCheckedChange={(newChecked) => onChange(!!newChecked)}
      onSelect={(e) => preventClosing && e.preventDefault()}
    >
      <div className="flex flex-row items-center justify-between gap-2">
        {children}
        <div className="mr-3 flex h-5 w-5 items-center justify-center rounded-md border border-zinc-700 text-zinc-700 group-hover:border-white group-hover:text-white">
          {checked && <CheckIcon className="h-4 w-4" />}
        </div>
      </div>
    </RadixDropdown.CheckboxItem>
  );
}
