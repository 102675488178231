import { useMemo } from "react";
import { useTheme } from "./use-theme";

/**
 * Function used to determine the current breakpoint of the window.
 * Use of outerWidth vs. innerWidth:
 * outerWidth will differ if you have zoomed in or out.
 * innerWidth will show the scaled width of the window, while outerWidth will not take zooming into account.
 */
export function useBreakpoints() {
  const theme = useTheme();
  const returns = useMemo(
    () => ({
      screens: theme.screens ?? {},
      isBreakpoint: (bp: keyof typeof theme.screens) => {
        const bpWith = theme.screens[bp as keyof typeof theme.screens];
        return window.innerWidth < Number(bpWith.replace("px", "")); // use innerWidth to avoid zooming issues
      },
    }),
    [] // Theme should not change in runtime
  );
  return returns;
}
