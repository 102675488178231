import { useOutletContext, useParams } from "react-router";
import { ProjectType } from "../../[id]";
import { FormEditView } from "~/lib/json-forms/form-edit-view";

export default function RegistrationPage() {
  const { entity: project } = useOutletContext<{
    entity: ProjectType;
  }>();
  const { formId } = useParams<{
    formId: string;
  }>();

  return <FormEditView formId={formId!} mode="project" project={project} />;
}
