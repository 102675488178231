import { useOutletContext } from "react-router-dom";
import { VendorProduct } from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { Button } from "~/lib/ui";
import { formatCurrency } from "~/lib/utils/number";
import PageSection from "~/lib/ui/page-section";
import Barcode from "react-barcode";
import { MediaPreviewLink } from "~/lib/ui/media";

export default function OverviewVendorProductPage() {
  const api = useAPI();
  const { vendorProduct } = useOutletContext<{ vendorProduct: VendorProduct }>();
  const { t } = useTranslation();

  const addAsFavoriteMutation = useMutation({
    mutationFn: () =>
      api.createProduct({
        createProductRequest: {
          vendorProductId: vendorProduct.id,
        },
      }),
  });

  return (
    <>
      <TabHeading
        actionArea={
          <Button
            variant="tertiary"
            tabIndex={0}
            confirm={{
              action: "generic",
            }}
            onClick={async () => addAsFavoriteMutation.mutateAsync()}
          >
            {t("products:vendors.add_to_my_products")}
          </Button>
        }
      >
        {t("products:product_overview")}
      </TabHeading>

      <PageSection>
        <div className="flex gap-8">
          <div className="shrink-0 self-start border p-2">
            {vendorProduct.imageUrls?.original && vendorProduct.imageUrls?.small ? (
              <MediaPreviewLink
                href={vendorProduct.imageUrls.original}
                height="h-48"
                mimeType={vendorProduct.imageMimeType ?? "image/*"}
                allowDownload={false}
              >
                <div className="flex h-48 w-80 items-center justify-center bg-white">
                  <img src={vendorProduct.imageUrls.small} />
                </div>
              </MediaPreviewLink>
            ) : (
              <div className="flex h-48 w-80 items-center justify-center bg-shade-100">
                <img className="max-h-48 max-w-80" src="/image_not_found.jpg" />
              </div>
            )}
          </div>
          <div className="flex w-full justify-between gap-4">
            <div className="flex basis-4/5 flex-col gap-4">
              <div className="flex items-center gap-4">
                {vendorProduct.vendor?.imageUrl ? (
                  <div>
                    <img className="max-h-10" src={vendorProduct.vendor.imageUrl} />
                  </div>
                ) : (
                  vendorProduct.vendor?.name && (
                    <div className="text-sm text-shade-400">{vendorProduct.vendor.name}</div>
                  )
                )}
              </div>
              <div className="text-xl font-semibold">{vendorProduct.name}</div>
              <div className="flex flex-col">
                <div className="font-semibold">{t("common:description")}</div>
                <div>
                  {!!vendorProduct.description ? (
                    vendorProduct.description
                  ) : (
                    <span className="text-gray-400">{t("common:not_available")}</span>
                  )}
                </div>
              </div>
              <div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("products:number")}:</div>
                  <div>
                    {vendorProduct.productNumber ? (
                      vendorProduct.productNumber
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("common:vendor")}:</div>
                  <div>
                    {vendorProduct.vendor?.name ? (
                      vendorProduct.vendor?.name
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("common:barcode")}:</div>
                  <div>
                    {vendorProduct.barcode ? (
                      vendorProduct.barcode
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("products:latest_buying_price")}:</div>
                  <div>
                    {vendorProduct.buyingPrice ? (
                      formatCurrency(vendorProduct.buyingPrice)
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("common:sales_price")}:</div>
                  <div>
                    {vendorProduct.sellingPrice ? (
                      formatCurrency(vendorProduct.sellingPrice)
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {vendorProduct.barcode && <Barcode format="EAN13" value={vendorProduct.barcode} />}
            </div>
          </div>
        </div>
      </PageSection>
    </>
  );
}
