import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router";
import { useState } from "react";
import { Dialog } from "~/lib/ui";
import { SendFormsDialog } from "./_cmp/send-form-dialog";
import { ProjectType } from "../../[id]";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { RegistrationTable } from "~/pages/projects/[id]/registrations/_cmp/registration-table";

export const CACHE_REGISTRATIONS = "registrations"; // invoices, expenses, forms

export const BADGE_CLASS =
  "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";

export const BADGE_RED = "text-red-700 text-red-700 ring-red-600/20 bg-red-50";
export const BADGE_GRAY = "text-gray-700 text-gray-700 ring-gray-600/20 bg-gray-50";
export const BADGE_BLUE = "text-blue-700 text-blue-700 ring-blue-600/20 bg-blue-50";

export default function ProjectRegistrationsPage() {
  const [formDrafts, setFormDrafts] = useState<Array<string>>(); // For sending forms
  const { id: projectId } = useParams<{ id: string }>();
  const { entity: project } = useOutletContext<{
    entity: ProjectType;
  }>();
  const { t } = useTranslation();

  const [previewFormURL, setPreviewFormURL] = useState<string | undefined>();

  return (
    <>
      <RegistrationTable projectId={projectId!} parentType="tab" />
      <Dialog
        open={!!formDrafts}
        onOpenChange={() => setFormDrafts(undefined)}
        render={({ onClose }) => (
          <SendFormsDialog
            projectId={projectId as string}
            onClose={onClose}
            defaultEmail={project.contactPerson?.email ?? project.contact?.email ?? ""}
            defaultSubject={t("projects:send_registrations_subject", {
              replace: { workaddress: project.streetName || "projekt" },
              defaultValue: "Documentation for {{workaddress}}",
            })}
            defaultMessage={t("projects:send_registrations_message", {
              replace: { name: project.contactPerson?.name ?? project.contact?.name ?? "" },
            })}
            formIds={formDrafts ?? []}
          />
        )}
      />
      <FullScreenFilePreview
        open={!!previewFormURL}
        fileUrl={previewFormURL}
        onClose={() => setPreviewFormURL(undefined)}
      />
    </>
  );
}
