import { useTypedSearchParams } from "./use-typed-search-params";

type PaginationDirection = "asc" | "desc";
export type PaginationParams = {
  limit?: number;
  page?: number;
  q?: string;
  sort?: string;
  direction?: PaginationDirection;
};

export const paginationDefaults: PaginationParams = {
  page: 1,
  q: "",
};

export function usePagination() {
  const tp = useTypedSearchParams<PaginationParams>();
  const paramsWithDefaults: PaginationParams = { ...paginationDefaults, ...tp[0] };

  return [paramsWithDefaults, tp[1]] as typeof tp;
}
