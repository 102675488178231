import { FrontpageFeedDTO } from "@apacta/sdk";
import { NavLink } from "react-router-dom";
import { linkToExpense, linkToProject } from "~/lib/utils";
import { formatCurrency } from "~/lib/utils/number";
import { Card, CardField } from "./static/card";
import { CardHeader } from "./static/card-header";
import colors from "tailwindcss/colors";
import { useTranslation } from "react-i18next";

export const ExpenseCard = ({ data }: { data: FrontpageFeedDTO }) => {
  // TODO: Check file base for expenses
  const { t } = useTranslation();
  const files = data.formFields?.images ?? [];
  const fields: Array<CardField> = [
    {
      label: t("common:vendor", { count: 1 }),
      render: () => data.expense?.vendor?.name,
    },
    {
      label: t("common:invoice_no"),
      render: () => (
        <NavLink className="hover:underline" to={linkToExpense(data.expense?.id ?? "", "view")}>
          {data.expense?.supplierInvoiceNumber}
        </NavLink>
      ),
    },
    {
      label: t("common:project", { count: 1 }),
      render: () => (
        <NavLink to={linkToProject(data.project?.id ?? "")} className="hover:underline">
          {data.project?.fullName}
        </NavLink>
      ),
    },
    {
      label: t("common:amount"),
      render: () =>
        data.sum !== undefined && data.sum !== null ? (
          formatCurrency(data.sum)
        ) : (
          <span className="text-gray-400">{t("common:not_available")}</span>
        ),
    },
  ];

  return (
    <Card
      renderHeader={() => (
        <CardHeader
          title={t("common:vendor_invoice", { count: 1 })}
          icon="expense"
          color={colors["red"][500]}
          renderIdentifier={() =>
            data.expense?.vendor?.imageUrl ? (
              <img src={data.expense?.vendor?.imageUrl} alt="Vendor logo" className="h-6 w-auto" />
            ) : null
          }
        />
      )}
      fields={fields}
      files={files}
    />
  );
};
