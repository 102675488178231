import { useTranslation } from "react-i18next";
import { PageLayout } from "~/lib/ui/page-layout";
import { useNavigate } from "react-router";
import { linkToProject } from "~/lib/utils";
import { CreateProjectDialog } from "./_cmp/create-project-dialog";
import { Dialog, getIcon } from "~/lib/ui";
import { Button } from "~/lib/ui/buttons/button";
import { ProjectTable } from "./_cmp/project-table";

export const CACHE_PROJECTS = "projects"; // Used to cache the list query, and invalidated by create/delete
export const DEFAULT_STATUS_ID_FILTER = "bea03f03-42ba-4f5c-91e7-4609f86e00a8"; // Open
export const MAXIMUM_DESCRIPTION_LENGTH = 200;

export default function ProjectListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout
      title={t("common:project", { count: 2 })}
      renderActions={() => (
        <>
          <Dialog
            trigger={
              <Button variant="tertiary" className="print:hidden" Icon={getIcon("add")}>
                {t("common:create", {
                  defaultValue: "Create {{entity}}",
                  replace: { entity: t("common:project", { count: 1 }).toLocaleLowerCase() },
                })}
              </Button>
            }
            className="md:max-w-3xl"
            render={({ onClose: onCloseCreateProject }) => (
              <CreateProjectDialog
                onProjectCreated={(newProjectId) => {
                  onCloseCreateProject();
                  navigate(
                    linkToProject(newProjectId, {
                      subPage: "data",
                    })
                  );
                }}
                onOpenChange={() => onCloseCreateProject()}
              />
            )}
          />
        </>
      )}
    >
      <ProjectTable parentType="page" />
    </PageLayout>
  );
}
