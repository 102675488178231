import { ChartBarIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { KPICardWrapper, KPICardWrapperProps } from "../_cmp/kpi-card-wrapper";
import { GaugeSVG } from "../_cmp/gauge-svg";

export const CONTRIBUTION_RATIO_RED = 24.99;
export const CONTRIBUTION_RATIO_YELLOW = 34.99;
export const CONTRIBUTION_RATIO_GREEN = 100;

export function ContributionRatioCard({
  contributionRatio = 0,
  paddingXClassname = "px-8",
  label,
  ...kpiWrapperProps
}: KPICardWrapperProps & { contributionRatio?: number; paddingXClassname?: string }) {
  const { t } = useTranslation();
  const percent = contributionRatio * 100;

  return (
    <KPICardWrapper
      label={label ?? t("finance:operating_margin", "Operating margin")}
      Icon={ChartBarIcon}
      {...kpiWrapperProps}
    >
      <div className={paddingXClassname}>
        <GaugeSVG
          unit="percent"
          value={percent}
          areas={[
            {
              value: CONTRIBUTION_RATIO_RED,
              className: "stroke-red-600",
            },
            {
              value: CONTRIBUTION_RATIO_GREEN,
              className: "stroke-green-600",
            },
            {
              value: CONTRIBUTION_RATIO_YELLOW,
              className: "stroke-orange-600",
            },
          ]}
        />
      </div>
    </KPICardWrapper>
  );
}
