export function MediaDetail({ href, mimeType }: { href: string; mimeType: string }) {
  if (mimeType.endsWith("pdf")) {
    return <embed src={href} type="application/pdf" className="h-[80vh] w-full" />;
  }
  if (mimeType.startsWith("image/")) {
    return <img className="max-h-[70vh]" src={href} />;
  }
  if (mimeType.startsWith("video/")) {
    return (
      <div className="flex p-2">
        <video className="h-full w-full" controls preload="none" autoPlay>
          <source src={href} type={mimeType} />
        </video>
      </div>
    );
  }
  return <div className="flex flex-col">No Preview supported</div>;
}
