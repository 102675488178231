import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { KPICardWrapper } from "../_cmp/kpi-card-wrapper";
import { FormatValue } from "../_cmp/FormatValue";
import { KPIRatioView } from "../_cmp/kpi-ratio-view";

export function ProductConsumptionCardFixed({
  productsCostPriceDelivered = 0,
  productsCostPriceEstimated = 0,
  isProjectFinished,
  warningZeroCost = false,
}: {
  productsCostPriceEstimated?: number;
  productsCostPriceDelivered?: number;
  isProjectFinished: boolean;
  warningZeroCost?: boolean;
}) {
  const { t } = useTranslation();

  const notDelivered = productsCostPriceEstimated - productsCostPriceDelivered;
  const remainderPercent = Math.round(
    (productsCostPriceDelivered / productsCostPriceEstimated) * 100
  );

  let remainderLabel = t("common:not_delivered");
  let overShootClass = "bg-red-600";
  let remainderClass = "bg-green-600";

  const isProjectOverperforming = productsCostPriceDelivered <= productsCostPriceEstimated;

  if (isProjectFinished) {
    if (isProjectOverperforming) {
      remainderLabel = t("finance:surplus");
      overShootClass = "bg-green-600";
    } else {
      remainderLabel = t("finance:deficit");
      remainderClass = "bg-red-600";
    }
  }
  return (
    <KPICardWrapper
      label={t("common:product_usage", "Products")}
      Icon={WrenchScrewdriverIcon}
      href="./finance/products"
      warning={
        warningZeroCost ? t("common:warning_zero_price", "One or more prices are zero") : undefined
      }
    >
      <div className="flex flex-row items-center justify-end gap-x-2">
        <span className="text-md text-gray-400">{t("common:estimated")}</span>
        <span className="text-xl font-bold">
          <FormatValue value={productsCostPriceEstimated} unit="currency" />
        </span>
      </div>
      <KPIRatioView
        left={{
          label: t("common:delivered"),
          value: productsCostPriceDelivered,
          percent: remainderPercent,
        }}
        right={{
          label: remainderLabel,
          value: Math.abs(notDelivered),
          percent: 100 - remainderPercent,
        }}
        unit="currency"
        barClasses={{
          overshoot: overShootClass,
          remainder: remainderClass,
        }}
      />
    </KPICardWrapper>
  );
}
