import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";

export function FileLoading() {
  return (
    <div className="rounded-md bg-white">
      <ContentLoader className={twMerge("h-full w-full bg-white")}>
        <rect x={0} y={0} width="100%" height="100%"></rect>
      </ContentLoader>
    </div>
  );
}
