import { hashCode } from "../utils/utils";

export let BACKEND_URL = import.meta.env.VITE_BACKEND_URL || "https://app-qa.apacta.com";
if (typeof window !== "undefined" && window.BACKEND_URL) BACKEND_URL = window.BACKEND_URL;
export const isProduction = BACKEND_URL === "https://app.apacta.com";

// When running in dev-mode. This is set by Vite.
export const DEV = import.meta.env.MODE === "development";

// Use a specific cookie for each endpoint
export const API_KEY_NAME = `APACTA_COOKIE_${hashCode(BACKEND_URL)}`;

export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APPID;
