import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useLocationFromFrame } from "~/lib/utils/use-location-from-frame";
import { BACKEND_URL } from "../../auth/config";
import { useSession } from "~/lib/auth/session";
import { availableLanguages } from "../../i18n/i18n";
import { PageSpinner } from "../page-spinner";
import { twMerge } from "tailwind-merge";
import { useMount } from "~/lib/lifecycle-helpers";
import { usePageTitle } from "~/lib/navigation/use-page-title";

// Used to display Apacta Classic
// - Will inject API keys, locale and other necessary things
export function ClassicFrame({
  iframeURL,
  baseURL = BACKEND_URL,
  onLoaded,
  disableLocationUpdates = false,
  hideTopbar = false,
}: {
  iframeURL: string;
  onLoaded?: () => void;
  baseURL?: string;
  disableLocationUpdates?: boolean;
  hideTopbar?: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const { i18n } = useTranslation();
  const { apiKey } = useSession();
  const { state: routerState } = useLocation(); // Used to force a frame re-render on a sidebar click
  const pageTitle = usePageTitle();

  // Key changing will not re-engage the spinner, fix for now
  useEffect(() => {
    setLoading(true);
  }, [routerState?.refreshKey]);

  // Set page title
  useMount(() => {
    pageTitle.set("Classic");
  });

  useLocationFromFrame({ disabled: disableLocationUpdates });

  const language =
    availableLanguages[i18n.resolvedLanguage as keyof typeof availableLanguages] ||
    availableLanguages["da"];

  const queryParams: Array<string> = [
    "hide_sidebar=true",
    `api_key=${apiKey}`,
    `lang=${language.defaultLocale}`,
  ];
  if (hideTopbar) queryParams.push(`hide_topbar=true`);

  let fullURL = `${baseURL}${iframeURL}`;

  const firstSeparator = !iframeURL.match(/\?/) ? "?" : "&";
  fullURL += firstSeparator;
  fullURL += queryParams.join("&");

  function handleOnLoad() {
    setLoading(false);
    onLoaded?.();
  }

  return (
    <div key={routerState?.refreshKey} className="relative h-full w-full">
      {loading || !apiKey ? <PageSpinner className="min-h-screen" /> : null}
      <iframe
        onLoad={handleOnLoad}
        className={twMerge(loading ? "hidden" : "block", "h-full min-h-screen w-full")}
        src={fullURL}
      />
    </div>
  );
}
