import { ReactElement } from "react";
import ContextMenu, { ContextMenuItem } from "~/lib/ui/context-menu/context-menu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { usePlanning } from "~/lib/planning/use-planning";
import { Card } from "~/lib/planning";
import { linkToProject } from "~/lib/utils";
import { getIcon } from "~/lib/ui";

export function CardContextMenu({
  card,
  canPaste,
  className,
  children,
}: {
  card: Card;
  canPaste: boolean;
  className?: string;
  children: ReactElement;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { cardCopy, showDeleteDialog, cardGetTask, cardPaste, setSelectedCard } = usePlanning();

  const contextItems: Array<ContextMenuItem> = [
    {
      type: "item",
      label: t("planning:context-menu.edit"),
      Icon: getIcon("edit"),
      onSelect: () => setSelectedCard(card),
    },
    {
      type: "separator",
    },
    {
      type: "item",
      label: t("planning:context-menu.go-to-task"),
      Icon: getIcon("externalLink"),
      onSelect: () => {
        const projectId = cardGetTask({ card })?.projectId;
        if (!projectId) return;
        navigate(linkToProject(projectId));
      },
    },
    {
      type: "separator",
    },
    {
      type: "item",
      label: t("planning:context-menu.copy"),
      Icon: getIcon("copy"),
      onSelect: () => cardCopy({ card }),
    },
    {
      type: "item",
      label: t("planning:context-menu.paste"),
      disabled: !canPaste,
      Icon: getIcon("paste"),
      onSelect: () => {
        return cardPaste({
          userId: card.userId,
          index: 999,
          date: card.date,
        });
      },
    },
    {
      type: "separator",
    },
    {
      type: "item",
      label: t("planning:context-menu.delete"),
      Icon: getIcon("delete"),
      onSelect: () => card && showDeleteDialog(card),
    },
  ];

  return (
    <ContextMenu className={className} items={contextItems}>
      {children}
    </ContextMenu>
  );
}
