import { Button } from "~/lib/ui";

export function LanguageItem({
  item,
  onClick,
}: {
  item: { name: string; defaultLocale: string; flag: string };
  onClick: () => void;
}) {
  return (
    <Button
      className="h-auto overflow-hidden rounded-md bg-white px-4 py-2 outline-none hover:bg-gray-100 focus-visible:bg-gray-100"
      onClick={onClick}
    >
      <span className="inline-flex w-full flex-1 items-center justify-between">
        <img src={`/flags/${item.flag}.png`} className="h-8 max-h-full w-12 object-cover" />
        <span className="flex-1 px-4 py-2 text-sm">
          <span className="text-zinc-600">{item.name}</span>
        </span>
      </span>
    </Button>
  );
}
