import { Icon } from "~/lib/ui";
import { debounce } from "lodash";
import { useState } from "react";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useMount } from "~/lib/lifecycle-helpers";
import { useSuspenseQuery } from "@tanstack/react-query";
import FormSelector from "~/pages/planning/_cmp/crud/sections/form-section/form-selector";
import FormRender from "~/pages/planning/_cmp/crud/sections/form-section/form-render";
import { FormTemplateSimpleEntity } from "@apacta/sdk";

export function FormSection({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  const [formSearchQuery, setFormSearchQuery] = useState<string>("");
  const [selectedFormTemplates, setSelectedFormTemplates] = useState<
    Array<FormTemplateSimpleEntity>
  >([]);

  const api = useAPI();

  const formTemplateQ = useSuspenseQuery({
    queryKey: ["form-templates"],
    queryFn: () => api.formTemplatesList({}),
  });

  const formTemplateModels = formTemplateQ.data?.data ?? [];

  // Set selected forms from form state
  useMount(() => {
    setSelectedFormTemplates(() => {
      return formTemplateModels.filter((form) =>
        formState.getValue("formTemplateIds")?.includes(form.id)
      );
    });
  });

  // Only allow selection of form templates that are not already selected, otherwise filter by search query
  const availableFormTemplates = formTemplateModels.filter((f) => {
    if (selectedFormTemplates.find((e) => e.id === f.id)) {
      return false;
    } else {
      return f.name.toLowerCase().includes(formSearchQuery.toLowerCase());
    }
  });

  const updateSearchQuery = debounce((value: string) => {
    setFormSearchQuery(value);
  }, 500);

  const removeSelectedFormTemplate = (template: FormTemplateSimpleEntity) => {
    setSelectedFormTemplates(selectedFormTemplates.filter((e) => e.id !== template.id));
    formState.setValues({
      formTemplateIds: selectedFormTemplates.filter((e) => e.id !== template.id).map((e) => e.id),
    });
  };

  const addSelectedFormTemplate = (formTemplate: FormTemplateSimpleEntity) => {
    setSelectedFormTemplates([...selectedFormTemplates, formTemplate]);
    formState.setValues({
      formTemplateIds: [...selectedFormTemplates, formTemplate].map((e) => e.id),
    });
  };

  const handleQueryChange = (q: string, d?: boolean) => {
    return d ? updateSearchQuery(q) : setFormSearchQuery(q);
  };

  const SHOW_TEMPLATE_LOADER =
    selectedFormTemplates.length === 0 &&
    formState.getValue("formTemplateIds") &&
    formState.getValue("formTemplateIds")!.length > 0 &&
    formTemplateQ.isFetching;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex grow items-center gap-3">
        <div
          title={t("planning:form", { count: 2 })}
          className="flex h-8 w-8 shrink-0 items-center justify-center"
        >
          <Icon name="formSolid" size="small" />
        </div>
        <FormSelector
          templates={availableFormTemplates}
          isFetching={formTemplateQ.isFetching}
          onSelect={addSelectedFormTemplate}
          onQueryChange={handleQueryChange}
        />
      </div>
      <FormRender
        isLoading={!!SHOW_TEMPLATE_LOADER}
        selectedFormTemplates={selectedFormTemplates}
        onRemove={removeSelectedFormTemplate}
      />
    </div>
  );
}
