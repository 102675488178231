import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { IconProp } from "~/lib/ui/types";
import { Suspense } from "react";

/**
 * A tab header item.
 * @property label - The label of the tab, this is the displayed string.
 * @property Icon - The icon of the tab, this is optional.
 * @property id - The id of the tab, this is used to identify the selected tab.
 * @property to - If this is set, the tab will be a link to the given path.
 */
export type TabHeaderItem = {
  label: string;
  Icon?: IconProp;
  id: string;
  to?: string;
};

export type TabSize = "small" | "medium";

export function TabHeader({
  selectedTab,
  tabs,
  onSelect,
  className,
  tabSize = "medium",
}: {
  selectedTab: TabHeaderItem;
  tabs: Array<TabHeaderItem>;
  onSelect?: (tab: TabHeaderItem) => void;
  className?: string;
  tabSize?: TabSize;
}) {
  const { t } = useTranslation();

  const handleSelectTab = (idx: number) => {
    const tab = tabs[idx];
    onSelect?.(tab);
  };

  return (
    <Suspense>
      <div className={className}>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            {t("ui:view-tabs.select-tab")}
          </label>
          <select
            id="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => handleSelectTab(Number(e.currentTarget.value))}
            defaultValue={selectedTab.id}
          >
            {tabs.map((tab, idx) => (
              <option key={`${tab.id}`} value={idx}>
                {tab.label}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8 overflow-x-auto" aria-label="Tabs">
              {tabs.map((tab, idx) => (
                <div
                  key={tab.id}
                  onClick={() => handleSelectTab(idx)}
                  className={twMerge(
                    tab.id === selectedTab.id
                      ? "border-primary font-medium text-primary"
                      : "border-transparent text-gray-500 hover:border-hover hover:text-hover",
                    "flex cursor-pointer items-center gap-2 whitespace-nowrap border-b-4",
                    tabSize === "small" ? "px-4 py-2 text-sm" : "px-4 py-4 text-base"
                  )}
                  aria-current={tab === selectedTab ? "page" : undefined}
                >
                  {tab.Icon && <tab.Icon className="hidden h-5 w-5 xl:block" />}
                  <span>{tab.label}</span>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </Suspense>
  );
}
