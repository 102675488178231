import { useEffect, useRef } from "react";
import { useSession } from "../auth/session";
import { useLocation } from "react-router";

const SEGMENT_KEY =
  import.meta.env.MODE === "production"
    ? "4IdOvt2awbAuVHsfVvxa0ywxrdlnoc9W"
    : "YbXcDoL0Ge2aJjT27oLBlGq90XDjyglg";

export function useSegment() {
  const { me } = useSession();
  const initialised = useRef(false);
  const location = useLocation();

  // Initialisation
  useEffect(() => {
    if (typeof window.analytics === "undefined") return;
    if (initialised.current) return;
    if (!me) return;
    const { analytics } = window;
    if (!analytics?.load) return; // If Segment isn't loaded for some reason
    const { company, companySettings, user } = me;

    if (company && companySettings && user) {
      analytics.load(SEGMENT_KEY);
      analytics.page();

      analytics.identify(user.id, {
        email: user.email,
        name: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: company.name,
        phone: user.phone,
        language: user.language?.name,
        locale: user.language?.shortCode,
      });

      analytics.group(company.id, {
        address: company.streetName,
        cvr: company.cvr,
        createdAt: company.created,
        email: company.contactEmail,
        id: company.id,
        name: company.name,
        phone: company.phone,
        website: company.website,
      });

      // Prevent this from running twice
      initialised.current = true;
    }
  }, [me]);

  // Track page changes
  useEffect(() => {
    if (typeof window.analytics === "undefined") return;
    if (!initialised.current) return;
    window.analytics.page();
  }, [location.pathname]);
}
