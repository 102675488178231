import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useFormState } from "~/lib/form-state";
import useCards from "~/lib/planning/use-cards";
import { defaultTaskFormState, taskFormStateSchema } from "./state/task-form-state";
import { useTranslation } from "react-i18next";
import { DateRange, TaskFormState } from "../types";
import { ProjectSection } from "./sections";
import { CardAndTaskMutationArgs } from "~/lib/planning";
import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";
import { PlanningTabs } from "./tabs/planning-tabs";

export function CreateTaskDialog({
  onSubmit,
  onClose,
  selectedStartDate,
  selectedIndex,
  selectedEmployee,
  dateRange,
}: {
  onSubmit: ({ type }: CardAndTaskMutationArgs) => void;
  onClose: () => void;
  selectedStartDate?: Date | null;
  selectedIndex?: number;
  selectedEmployee?: string | null;
  dateRange?: DateRange;
}) {
  const { t } = useTranslation();
  const { taskLabels } = useCategoryLabels();

  const formState: TaskFormState = useFormState({
    schema: taskFormStateSchema,
    initialValues: {
      ...defaultTaskFormState,
      fromDate: selectedStartDate,
      employeeIds: selectedEmployee ? [selectedEmployee] : [],
    },
  });

  const { cardAction } = useCards();

  const handleSubmit = async (): Promise<void> => {
    if (!formState.isValid) return;

    const creationResults = await cardAction({
      action: "CREATE",
      formState,
      data: {
        selectedIndex,
        selectedEmployee,
        selectedLabel: taskLabels.find((l) => l.id === formState.getValue("labelId")) ?? null,
      },
    });

    if (creationResults) {
      onSubmit?.(creationResults);
    }
  };

  return (
    <div className="flex flex-col">
      <ProjectSection formState={formState} />
      <div className="pt-4">
        <PlanningTabs formState={formState} selectedEmployee={selectedEmployee} />
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", { entity: t("common:task").toLowerCase() }),
          onClick: () => handleSubmit(),
          disabled: !formState.isValid,
        }}
        onClose={onClose}
      />
    </div>
  );
}
