import { useSession } from "~/lib/auth/session";
import { ClassicFrame } from "~/lib/ui/helpers/classic-frame";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useEffect } from "react";

export default function LogoutPage() {
  const { isLoggedIn, logout } = useSession();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // As soon as backend has registred us logged out, we can do the same.
  function handleLoaded() {
    logout();
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return (
      <div>
        <p>{t("ui:logout.logged_out", "Logged out")}</p>
      </div>
    );
  }

  return <ClassicFrame iframeURL="/login/logout" onLoaded={handleLoaded} />;

  // At this point, we should've already been redirected
  return null;
}
