import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { ProductBundle } from "@apacta/sdk";
import { useState } from "react";

type UseProductBundlesReturn = {
  productBundles: Array<ProductBundle>;
  isFetchingProductBundles: boolean;
  setProductBundleQuery: (query: string) => void;
};

export function useProductBundles(): UseProductBundlesReturn {
  const [productBundleQuery, setProductBundleQuery] = useState<string>("");

  const api = useAPI();

  const products = useQuery({
    queryKey: ["products-bundles", productBundleQuery],
    queryFn: () =>
      api.productBundlesList({
        q: productBundleQuery,
      }),
  });

  return {
    productBundles: products.data?.data ?? [],
    isFetchingProductBundles: products.isFetching,
    setProductBundleQuery,
  };
}
