import { Fragment, ReactNode, useId } from "react";
import { twMerge } from "tailwind-merge";
import CardFooter from "./card-footer";
import { BaseFile } from "@apacta/sdk";

export type CardField = {
  label?: string;
  render: () => ReactNode;
};

export const Card = ({
  renderHeader,
  fields,
  files,
}: {
  renderHeader: () => ReactNode;
  fields: Array<CardField>;
  files?: Array<BaseFile>;
}) => {
  const id = useId();
  return (
    <div className="flex w-full grow flex-col justify-between overflow-hidden rounded-lg border bg-white shadow-md">
      <div>
        {renderHeader()}
        <div className="px-4 py-3">
          <div className="flex flex-col gap-2 divide-y">
            {fields.map((field, idx) =>
              field.label ? (
                <div
                  key={`${id}-${field.label}-${idx}`}
                  className={twMerge(
                    "flex items-center justify-between px-2 text-sm",
                    idx > 0 && "pt-2"
                  )}
                >
                  <div className="font-semibold">{field.label}</div>
                  <div>{field.render()}</div>
                </div>
              ) : (
                <Fragment key={`${id}-${idx}`}>{field.render()}</Fragment>
              )
            )}
          </div>
        </div>
      </div>
      <CardFooter files={files} />
    </div>
  );
};
