import { twMerge } from "tailwind-merge";
import {
  CalculatorIcon,
  ClockIcon,
  CubeIcon,
  DocumentIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import TextArea from "~/lib/ui/form-elements/textarea";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";

export function OrderLinesBuilderDescriptionPart({
  line,
  editMode,
  depth,
  parentId,
  width,
}: {
  line: Partial<OrderLine>;
  editMode: boolean;
  depth: number;
  parentId?: string | null;
  width: string;
}) {
  const { updateLine } = useOrderLinesBuilder();

  return (
    <div
      style={{ paddingLeft: `calc(1rem * ${1 + depth})`, width }}
      className={twMerge("flex-shrink px-3 py-5")}
    >
      <div className={twMerge("flex gap-4", line.childOfferLines ? "cursor-pointer" : "")}>
        {editMode && (
          <div className="flex w-full grow gap-4">
            <div className="py-2">
              {line.type === "product" && <CubeIcon className="h-6 w-6 text-gray-400" />}
              {line.type === "expense_line" && <CalculatorIcon className="h-6 w-6 text-gray-400" />}
              {line.type === "vendor_product" && <DocumentIcon className="h-6 w-6 text-gray-400" />}
              {line.type === "bundle" && <SquaresPlusIcon className="h-6 w-6 text-gray-400" />}
              {line.type === "hours" && <ClockIcon className="h-6 w-6 text-gray-400" />}
            </div>
            <div className="flex grow">
              <TextArea
                fullHeight={true}
                autoHeight={true}
                className="scrollbar-hide"
                initialValue={line.name}
                onChange={(v) => updateLine(["name"], { name: v }, line.identifier, parentId)}
              />
            </div>
          </div>
        )}
        {!editMode && <span>{line.name}</span>}
      </div>
    </div>
  );
}
