import { useMount } from "~/lib/lifecycle-helpers";
import { useState } from "react";
import { Spinner } from "./spinner";
import { twMerge } from "tailwind-merge";

/**
 * @description Display a spinner with or without a message associated with it. Delay can be added to avoid a "blinking" spinner on fast loads.
 * @param {string} loadingMessage The message to display beneath the spinner
 * @param {number} delay The delay before displaying the spinner in milliseconds
 * @param {string} className Custom class names to apply to spinner wrapper
 */
export function PageSpinner({
  loadingMessage,
  delay,
  className,
}: {
  loadingMessage?: string;
  delay?: number;
  className?: string;
  screen?: boolean;
}) {
  const [display, setDisplay] = useState<boolean>(!delay);

  useMount(() => {
    let timer = 0;
    if (delay) {
      timer = window.setTimeout(() => {
        setDisplay(true);
      }, delay);
    }
    return () => clearTimeout(timer);
  });

  if (!display) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "absolute flex h-full w-full flex-col items-center justify-center gap-4",
        className
      )}
    >
      <Spinner />
      <span aria-live="polite">{loadingMessage}</span>
    </div>
  );
}
