import {
  $createNodeSelection,
  $getNodeByKey,
  $getSelection,
  $setSelection,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SELECTION_CHANGE_COMMAND,
  SerializedLexicalNode,
  Spread,
} from "lexical";
import { ReactNode, useRef, useState } from "react";
import { RichTextEditorInsertAction } from "~/lib/ui/rich-text-editor";
import { DOMExportOutput } from "lexical/LexicalNode";
import { TextMatchTransformer } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { twMerge } from "tailwind-merge";

export type SerializedVariableNode = Spread<
  {
    variableName: string;
    inline: boolean;
    clear: boolean;
    key: NodeKey;
  },
  SerializedLexicalNode
>;

function VariableComponent({
  variableName,
  identifier,
  selected = false,
}: {
  variableName: string;
  identifier: NodeKey;
  selected?: boolean;
}) {
  const [editor] = useLexicalComposerContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const innerText = `{{${variableName}}}`;
  const [isSelected, setIsSelected] = useState<boolean>(false);

  editor.registerCommand(
    SELECTION_CHANGE_COMMAND,
    (p, e) => {
      const selection = $getSelection();
      console.log("selection", selection);

      const nodes = selection?.getNodes();

      console.log("nodes", nodes);

      if (nodes) {
        const node = nodes.find((n) => n.__key === identifier);
        console.log("node found", node);
        if (node) {
          setIsSelected(true);
        } else {
          setIsSelected(false);
        }
      }
      console.log("selection changed");
      return true;
    },
    1
  );

  const handleSelect = (val: boolean) => {
    editor.update(() => {
      const node = $getNodeByKey(identifier);
      const parentNode = node?.getParent();
      const nodeSelection = $createNodeSelection();
      nodeSelection.add(identifier);
      nodeSelection.add(parentNode?.__key ?? "");

      //console.log(nodeSelection);
      $setSelection(nodeSelection);
      editor.dispatchCommand(SELECTION_CHANGE_COMMAND, undefined);
    });
  };

  return (
    <div
      className={twMerge(
        "inline cursor-pointer rounded-full bg-green-100 px-3 py-1",
        isSelected ? "border border-green-600" : ""
      )}
      onClick={() => handleSelect(true)}
      ref={containerRef}
    >
      {innerText}
    </div>
  );
}

export class VariableNode extends DecoratorNode<ReactNode> {
  __variableName: string;
  __inline: boolean;
  __clear: boolean;
  selected: boolean = false;

  static getType(): string {
    return "variable";
  }

  static clone(node: VariableNode): VariableNode {
    const data: RichTextEditorInsertAction = {
      type: "variable",
      content: node.__variableName,
      inline: node.__inline,
      clear: node.__clear,
    };
    return new VariableNode(data, node.__key);
  }

  constructor(data: RichTextEditorInsertAction, key?: NodeKey) {
    super(key);
    this.__variableName = data.content;
    this.__inline = data.inline ?? false;
    this.__clear = data.clear ?? false;
  }

  public setSelected(val: boolean): void {
    const writable = this.getWritable();
    writable.selected = val;
  }
  static importJSON(json: any): VariableNode {
    const data: RichTextEditorInsertAction = {
      type: json.type,
      content: json.variableName,
      inline: json.inline ?? false,
      clear: json.clear ?? false,
      key: json.key ?? undefined,
    };
    return $createVariableNode(data);
  }

  exportJSON(): SerializedVariableNode {
    return {
      variableName: this.__variableName,
      inline: this.__inline,
      clear: this.__clear,
      key: this.__key,
      type: "variable",
      version: 1,
    };
  }

  createDOM(): HTMLElement {
    const dom = document.createElement("div");
    if (this.__inline) {
      dom.className = "inline";
    }
    return dom;
  }

  exportDOM(): DOMExportOutput {
    return {
      element: this.createDOM(),
    };
  }

  isInline(): boolean {
    return true;
  }

  updateDOM(): false {
    return false;
  }

  setVariable(data: RichTextEditorInsertAction) {
    // getWritable() creates a clone of the node
    // if needed, to ensure we don't try and mutate
    // a stale version of this node.
    const self = this.getWritable();
    self.__variableName = data.content;
    self.__inline = data.inline ?? false;
    self.__clear = data.clear ?? false;
  }

  getVariable(): RichTextEditorInsertAction {
    // getLatest() ensures we are getting the most
    // up-to-date value from the EditorState.
    const self = this.getLatest();
    return parseVariable(self);
  }

  decorate(): ReactNode {
    return (
      <VariableComponent
        variableName={this.__variableName}
        selected={this.selected}
        identifier={this.__key}
      />
    );
  }
}

export function $isVariableNode(node: LexicalNode | null | undefined): node is VariableNode {
  return node instanceof VariableNode;
}

export function $createVariableNode(data: RichTextEditorInsertAction): VariableNode {
  return new VariableNode(data);
}

function parseVariable(node: VariableNode): RichTextEditorInsertAction {
  return {
    type: "variable",
    content: node.__variableName,
    inline: node.__inline ?? false,
    clear: node.__clear ?? false,
  };
}

/**
 * TRANSFORMER
 * Used for converting a VariableNode to/from markdown
 */

export const VARIABLE_NODE_TRANSFORMER: TextMatchTransformer = {
  dependencies: [],
  export: (node) => {
    if (!$isVariableNode(node)) {
      return null;
    }
    return `{{${node.__variableName},${node.__inline},${node.__clear}}}`; // text to be put into markdown
  },
  importRegExp: /{{(.*)}}/, // regex to match in the markdown
  type: "text-match",
  trigger: "{{", // don't trigger this transformer unless a node starts with "{{"
  regExp: /{{(.*)}}/, // regex to match from the node DOM
  replace: (node, match) => {
    const [, variable] = match;

    const [content, inline, clear] = variable.split(",");

    // Create actual node to be inserted
    const matchNode = $createVariableNode({
      type: "variable",
      content,
      inline: Boolean(inline),
      clear: Boolean(clear),
    });

    node.replace(matchNode);
  },
};
