import { useTranslation } from "react-i18next";
import { FormatValue } from "../_cmp/FormatValue";
import { KPIRatioView } from "../_cmp/kpi-ratio-view";
import { KPICardWrapper } from "../_cmp/kpi-card-wrapper";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";

export function InvoicedVariable({
  salesPrice = 0,
  invoiced = 0,
  notInvoiced,
}: {
  salesPrice: number;
  invoiced: number;
  notInvoiced: number;
}) {
  const { t } = useTranslation();

  return (
    <>
      <KPICardWrapper
        label={t("finance:invoiced")}
        Icon={DocumentCheckIcon}
        href="./finance/invoices"
      >
        <div className="flex flex-row items-center justify-end gap-x-2">
          <span className="text-md text-gray-400">{t("finance:sales_price")}</span>
          <span className="text-xl font-bold">
            <FormatValue value={salesPrice} unit="currency" />
          </span>
        </div>

        <KPIRatioView
          left={{
            label: t("finance:invoiced"),
            value: invoiced,
            percent: (invoiced / salesPrice) * 100,
          }}
          right={
            notInvoiced >= 0
              ? {
                  label: t("finance:not_invoiced"),
                  value: notInvoiced,
                  percent: (notInvoiced / salesPrice) * 100,
                }
              : undefined
          }
          unit="currency"
          extraUnits={[]}
        />
      </KPICardWrapper>
    </>
  );
}
