import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "../toast/use-toasts";
import { captureException } from "../error-reporting";

export * from "./pagination";

export function useToastOnError(err?: unknown) {
  const { t } = useTranslation();
  const toast = useToasts();

  useEffect(() => {
    if (!err) return;
    toast.showTemplate("UNEXPECTED_ERROR");
    captureException(new Error(`Failed to get query`, { cause: err }));
  }, [err]);
}
