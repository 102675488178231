import { CurrencyEuroIcon } from "@heroicons/react/20/solid";
import { WrenchIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IconProp } from "~/lib/ui/types";
import { FormatValue } from "./FormatValue";
import { KPICardWrapper } from "./kpi-card-wrapper";

export type SupportedUnits =
  | "currency"
  | "hours"
  | "fancy_hours"
  | "percent"
  | "number"
  | "distance";

export function KPIDataCard(item: {
  name: string;
  Icon?: IconProp;
  href?: string;
  iconContainerClassName?: string;
  description?: string;
  values: Array<{
    name: string;
    value?: number;
    unit: SupportedUnits;
    comparedValue?: number;
    comparedLabel?: string;
    percentage?: number;
  }>;
}) {
  const Icon = item.Icon || CurrencyEuroIcon;
  const { t } = useTranslation();

  // Empty description is no description
  if (item.description === "") item.description = undefined;

  return (
    <KPICardWrapper label={item.name} Icon={WrenchIcon} href={item.href}>
      <div className="flex flex-col gap-2">
        {item.values
          .filter((v) => v.value !== undefined)
          .map((v, idx) => (
            <Fragment key={idx}>
              <div className="flex w-full flex-row justify-between">
                <div>
                  <span className="block truncate text-sm font-medium uppercase text-gray-500">
                    {v.name}
                  </span>
                  <p className="text-2xl font-semibold text-gray-900">
                    <FormatValue value={v.value} unit={v.unit} />
                  </p>
                </div>
                {v.comparedValue && (
                  <div className="text-right">
                    <span className="block text-sm text-gray-500">
                      {v.comparedLabel ?? t("projects:index.expected", "Expected")}
                    </span>
                    <span className="block text-sm font-semibold">
                      <FormatValue value={v.comparedValue} unit={v.unit} />
                    </span>
                  </div>
                )}
              </div>
              {v.percentage !== undefined && (
                <div className="">
                  <div className="mb-4 w-full rounded-md bg-green-100">
                    <div
                      className="relative h-1 rounded-md bg-green-500"
                      style={{ width: `${v.percentage.clamp(0, 100)}%` }}
                    >
                      <span className="absolute -right-4 top-2 text-xs text-gray-400">{`${v.percentage.clamp(
                        0,
                        100
                      )}%`}</span>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
      </div>
    </KPICardWrapper>
  );
}
