import { WallPostItem } from "~/pages/projects/_cmp/wall-post-item";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import noRowsSVG from "~/lib/ui/data-table/no-rows.svg";
import { Button } from "~/lib/ui/buttons/button";
import { keepPreviousData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { CACHE_PROJECTS } from "~/pages/projects";
import { useRef } from "react";

export default function WallPostList({
  projectId,
  wallPostFocusId,
}: {
  projectId: string;
  wallPostFocusId?: string;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const wallPostRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const cacheKey = [CACHE_PROJECTS, projectId, "wall"];

  const fetchWallPosts = async ({ pageParam = 1 }) => {
    return api.getWallPosts({
      projectId: projectId,
      page: pageParam,
    });
  };

  const feedQ = useInfiniteQuery({
    queryFn: fetchWallPosts,
    queryKey: cacheKey,
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.pagination.hasNextPage) return undefined;
      return lastPage.pagination.currentPage + 1;
    },
  });

  const pages = feedQ.data?.pages;

  async function handleRefresh() {
    await queryClient.invalidateQueries({
      queryKey: cacheKey,
    });
  }

  return (
    <>
      {pages && pages.length > 0 && pages[0].data.length > 0 ? (
        <div className="flow-root">
          <ul role="list" className="-mb-8">
            {pages && pages.length > 0 ? (
              pages.map((currentPage) => {
                return currentPage.data.map((item, idx) => (
                  <li key={item.id}>
                    <div className="relative pb-8">
                      {idx !== currentPage.data.length - 1 ? (
                        <span
                          className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-shade-100"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div
                        ref={(ref) => (wallPostRefs.current[item.id] = ref)}
                        className="relative flex items-start space-x-3"
                      >
                        <WallPostItem item={item} onRefresh={handleRefresh} />
                      </div>
                    </div>
                  </li>
                ));
              })
            ) : feedQ.isLoading ? (
              <li>
                <div className="relative pb-8">
                  <ContentLoader className={twMerge("h-6 w-full")}>
                    <rect x={0} y={0} width="80%" height="100%"></rect>
                  </ContentLoader>
                </div>
              </li>
            ) : (
              <li>
                <div className="relative pb-8">
                  {t("projects:feed.no_items", {
                    defaultValue: "Be the first to post something!",
                  })}
                </div>
              </li>
            )}
          </ul>
        </div>
      ) : (
        <div className="flex justify-center text-center">
          <div>
            <img className="inline h-30" src={noRowsSVG} />
            <p className="mt-4 text-gray-500">{t("common:no_results")}</p>
          </div>
        </div>
      )}
      {feedQ.hasNextPage && (
        <div className="mt-4">
          <Button
            variant="secondary"
            onClick={() => feedQ.fetchNextPage()}
            disabled={!feedQ.hasNextPage}
            className="w-full items-center justify-center text-center"
          >
            {t("common:load_more")}
          </Button>
        </div>
      )}
    </>
  );
}
