import { Layout, LayoutProps, rankWith, uiTypeIs } from "@jsonforms/core";
import { renderChildren } from "./renderChildren";
import { getLabelForControl } from "../getLabelForControl";
import { Label } from "~/lib/ui";

export function VerticalLayoutRenderer(props: LayoutProps) {
  const layout = props.uischema as Layout;
  const label = getLabelForControl(props);
  return (
    <div className="flex flex-col gap-2">
      {label && <Label>{label}</Label>}
      {renderChildren({
        schema: props.schema,
        path: props.path,
        enabled: props.enabled,
        elements: layout.elements,
      })}
    </div>
  );
}
export const VerticalLayoutTester = rankWith(2, uiTypeIs("VerticalLayout"));
