import { User } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { FilterGroup } from "./filter-group";
import { useEmployees } from "~/lib/employees/use-employees";

export function FilterGroupEmployees({
  onConfirmSelection,
  value,
  onClear,
  allowMultiple = true,
}: {
  value: Parameters<typeof FilterGroup>[0]["value"];
  onClear: Parameters<typeof FilterGroup>[0]["onClear"];
  onConfirmSelection: (selection: Array<User>) => void;
  allowMultiple?: boolean;
}) {
  const { employees, isFetching } = useEmployees();
  const { t } = useTranslation();
  return (
    <FilterGroup
      name={t("common:employees")}
      isLoading={isFetching}
      value={value}
      selection={{
        items: employees,
        valueFn: (item) => item.id,
        filterFn: (item, query) => {
          return (
            item.fullName.toLowerCase().includes(query.toLowerCase()) ||
            item.email.toLowerCase().includes(query.toLowerCase())
          );
        },
        allowMultiple,
        renderSelectionItem: (item) => <span>{item.fullName}</span>,
        onConfirmSelection: (selected) => {
          onConfirmSelection(selected);
        },
      }}
      onClear={onClear}
    />
  );
}
