import { Contact } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { useAPI } from "~/lib/api";
import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useDataColumns } from "~/lib/ui/data-table";
import { CACHE_CUSTOMERS } from "..";
import { CustomerCell } from "~/lib/ui/table/cells/customer-cell";

export function useCustomerTableColumns() {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();

  const deleteCustomer = useMutation({
    mutationFn: async (customerId: string) => {
      const res = await api.deleteContact({ contactId: customerId });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_CUSTOMERS, "index"],
      });
      return res;
    },
  });

  const columns = useDataColumns<Contact>((columnHelper) => [
    columnHelper.accessor("name", {
      enableSorting: true,
      header: t("customers:table.columns.name"),
      cell: ({ row }) => <CustomerCell customer={row.original} primary />,
      meta: {
        skeletonType: "avatar",
      },
    }),
    columnHelper.accessor("address", {
      header: t("customers:table.columns.address"),
      cell: ({ row }) => (
        <div className={twMerge("flex flex-col", !row.original.address && "text-gray-400")}>
          {row.original.address ? (
            <>
              <span>{row.original.address}</span>
              <span>
                {row.original.city?.zipCode} {row.original.city?.name}
              </span>
            </>
          ) : (
            <span>{t("common:not_available")}</span>
          )}
        </div>
      ),
      meta: {
        className: "hidden md:table-cell",
      },
    }),
    columnHelper.display({
      header: t("customers:table.columns.contact"),
      cell: ({ row }) => (
        <div
          className={twMerge(
            "flex flex-col",
            !row.original.email && !row.original.phone && "text-gray-400"
          )}
        >
          {row.original.email || row.original.phone ? (
            <>
              <span>{row.original.email}</span>
              <span>
                {row.original.phoneCountrycode} {row.original.phone}
              </span>
            </>
          ) : (
            <span>Not available</span>
          )}
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      meta: {
        cellClassName: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("delete"),
              label: t("customers:modal.delete.title"),
              confirm: {
                action: "delete",
                entity: "customer",
              },
              onClick: () => deleteCustomer.mutateAsync(row.original.id),
            },
          ]}
        />
      ),
    }),
  ]);
  return columns;
}
