/**
 * Motivation: We want to have a single source of truth for our icons.
 * This way we can easily change them, and we can easily see which icons are available.
 *
 * What prompted this was a breaking change in heroicons/react making it impossible to upgrade.
 * At the time of this writing, we have over 190 usages of heroicons/react in our codebase.
 *
 * Unifying this in one place should make maintaining this much easier.
 */

import { ComponentProps, createElement } from "react";
import {
  HiAdjustmentsHorizontal,
  HiBars3BottomLeft,
  HiBarsArrowDown,
  HiBarsArrowUp,
  HiCalendar,
  HiChartBar,
  HiClock,
  HiDocumentCheck,
  HiEye,
  HiEyeSlash,
  HiFolderOpen,
  HiMiniQueueList,
  HiOutlineArrowDown,
  HiOutlineArrowLeft,
  HiOutlineArrowLongUp,
  HiOutlineArrowPathRoundedSquare,
  HiOutlineArrowRight,
  HiOutlineArrowTopRightOnSquare,
  HiOutlineArrowTrendingUp,
  HiOutlineArrowUp,
  HiOutlineBars3,
  HiOutlineBars3BottomLeft,
  HiOutlineBugAnt,
  HiOutlineCalculator,
  HiOutlineCalendar,
  HiOutlineCalendarDays,
  HiOutlineChatBubbleBottomCenter,
  HiOutlineChatBubbleLeftEllipsis,
  HiOutlineCheck,
  HiOutlineCheckCircle,
  HiOutlineChevronDoubleDown,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronDoubleUp,
  HiOutlineChevronDown,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineChevronUp,
  HiOutlineChevronUpDown,
  HiOutlineClipboardDocument,
  HiOutlineClipboardDocumentCheck,
  HiOutlineClock,
  HiOutlineCog,
  HiOutlineCog6Tooth,
  HiOutlineCube,
  HiOutlineCurrencyDollar,
  HiOutlineDevicePhoneMobile,
  HiOutlineDocument,
  HiOutlineDocumentArrowDown,
  HiOutlineDocumentArrowUp,
  HiOutlineDocumentCheck,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentMagnifyingGlass,
  HiOutlineDocumentText,
  HiOutlineEllipsisHorizontal,
  HiOutlineEllipsisVertical,
  HiOutlineEnvelope,
  HiOutlineEnvelopeOpen,
  HiOutlineExclamationCircle,
  HiOutlineExclamationTriangle,
  HiOutlineEye,
  HiOutlineFaceFrown,
  HiOutlineFaceSmile,
  HiOutlineFolderOpen,
  HiOutlineForward,
  HiOutlineGlobeAlt,
  HiOutlineGlobeEuropeAfrica,
  HiOutlineHome,
  HiOutlineInformationCircle,
  HiOutlineLifebuoy,
  HiOutlineListBullet,
  HiOutlineLockClosed,
  HiOutlineMagnifyingGlass,
  HiOutlineNewspaper,
  HiOutlinePaperClip,
  HiOutlinePauseCircle,
  HiOutlinePencil,
  HiOutlinePhone,
  HiOutlinePlayCircle,
  HiOutlinePlus,
  HiOutlineQuestionMarkCircle,
  HiOutlineReceiptRefund,
  HiOutlineSparkles,
  HiOutlineSquaresPlus,
  HiOutlineStar,
  HiOutlineSwatch,
  HiOutlineTrash,
  HiOutlineUserGroup,
  HiOutlineUserMinus,
  HiOutlineUserPlus,
  HiOutlineUsers,
  HiOutlineViewColumns,
  HiOutlineXMark,
  HiUserGroup,
} from "react-icons/hi2";
import { twMerge } from "tailwind-merge";
import { FaRectangleList } from "react-icons/fa6";
import { GoDot, GoDotFill } from "react-icons/go";

// Adapt this to our company language and use-cases.
// DO NOT just randomly put whatever the icon sets call it.
// - Feel free to comment the use-case
const icons = {
  activateUser: HiOutlineUserPlus,
  add: HiOutlinePlus,
  addUser: HiOutlineUserPlus,
  administration: HiOutlineCog6Tooth,
  approve: HiOutlineCheck,
  back: HiOutlineChevronLeft,
  bulletClear: GoDot,
  bulletFilled: GoDotFill,
  attachment: HiOutlinePaperClip,
  calendar: HiOutlineCalendar,
  calendarDays: HiOutlineCalendarDays,
  calendarSolid: HiCalendar,
  chevronDoubleUp: HiOutlineChevronDoubleUp,
  chevronDoubleDown: HiOutlineChevronDoubleDown,
  chevronDoubleLeft: HiOutlineChevronDoubleLeft,
  chevronDoubleRight: HiOutlineChevronDoubleRight,
  chevronDown: HiOutlineChevronDown,
  chevronLeft: HiOutlineChevronLeft,
  chevronRight: HiOutlineChevronRight,
  chevronUp: HiOutlineChevronUp,
  chevronUpDown: HiOutlineChevronUpDown,
  clearSearch: HiOutlineXMark,
  close: HiOutlineXMark,
  clipboard: HiOutlineClipboardDocument,
  copy: HiOutlineDocumentDuplicate,
  currency: HiOutlineCurrencyDollar,
  credit: HiOutlineReceiptRefund,
  create_new: HiOutlinePlus,
  customer: HiOutlineUsers,
  customerContact: HiOutlinePhone,
  deactivateUser: HiOutlineUserMinus,
  devDebug: HiOutlineBugAnt,
  delete: HiOutlineTrash,
  description: HiOutlineBars3BottomLeft,
  descriptionSolid: HiBars3BottomLeft,
  details: HiOutlineDocumentMagnifyingGlass,
  documentDownload: HiOutlineDocumentArrowDown,
  download: HiOutlineArrowDown,
  draw: HiOutlinePencil,
  duplicate: HiOutlineDocumentDuplicate,
  edit: HiOutlinePencil,
  employee: HiOutlineUserGroup,
  employeeSolid: HiUserGroup,
  entityOverview: HiOutlineDocumentDuplicate, // Overview page in tabs
  errorCircle: HiOutlineExclamationCircle,
  successCircle: HiOutlineCheckCircle,
  expandRow: HiOutlineChevronRight,
  expense: HiOutlineClipboardDocumentCheck,
  export: HiOutlineViewColumns,
  externalLink: HiOutlineArrowTopRightOnSquare,
  faceHappy: HiOutlineFaceSmile,
  faceSad: HiOutlineFaceFrown,
  file: HiOutlineDocument,
  files: HiOutlinePaperClip,
  filter: HiAdjustmentsHorizontal,
  formTemplate: HiOutlineDocumentCheck,
  form: HiOutlineDocumentCheck,
  formSolid: HiDocumentCheck,
  hours: HiOutlineClock,
  home: HiOutlineHome,
  infoCircle: HiOutlineInformationCircle,
  invoice: HiOutlineDocumentArrowUp,
  label: HiOutlineSwatch,
  language: HiOutlineGlobeAlt,
  location: HiOutlineGlobeEuropeAfrica,
  lock: HiOutlineLockClosed,
  log: HiOutlineListBullet,
  masterData: HiOutlineDocumentText, // Settings page in tabs
  menuBurger: HiOutlineBars3,
  menuKebab: HiOutlineEllipsisVertical,
  menuMeatballs: HiOutlineEllipsisHorizontal,
  merge: HiOutlineClipboardDocument,
  moveUp: HiOutlineArrowUp,
  moveDown: HiOutlineArrowDown,
  moveLeft: HiOutlineArrowLeft,
  moveRight: HiOutlineArrowRight,
  news: HiOutlineNewspaper,
  offer: HiOutlineCalculator,
  open: HiOutlineEnvelopeOpen,
  password: HiOutlineLockClosed,
  passwordShow: HiEye,
  passwordHide: HiEyeSlash,
  paste: HiOutlineDocumentText,
  playCircle: HiOutlinePlayCircle,
  pauseCircle: HiOutlinePauseCircle,
  planning: HiOutlineCalendar,
  pointUp: HiOutlineArrowLongUp,
  preview: HiOutlineDocumentMagnifyingGlass,
  product: HiOutlineCube,
  productBundle: HiOutlineSquaresPlus,
  project: HiOutlineFolderOpen,
  projectSolid: HiFolderOpen,
  projectManager: HiOutlineStar,
  registration: HiOutlineClock,
  reject: HiOutlineXMark,
  remove: HiOutlineXMark,
  rental: HiOutlineSwatch,
  reload: HiOutlineArrowPathRoundedSquare,
  report: HiOutlineArrowTrendingUp,
  retractRow: HiOutlineChevronDown,
  search: HiOutlineMagnifyingGlass,
  selectedCheck: HiOutlineCheck,
  send: HiOutlineEnvelope,
  settings: HiOutlineCog,
  sms: HiOutlineDevicePhoneMobile,
  sortAsc: HiBarsArrowDown,
  sortDesc: HiBarsArrowUp,
  skip: HiOutlineForward,
  sparkles: HiOutlineSparkles,
  sync: HiOutlineArrowPathRoundedSquare,
  status: HiMiniQueueList,
  success: HiOutlineCheck,
  superadmin: HiOutlineLockClosed,
  task: HiOutlineDocument,
  time: HiOutlineClock,
  timeSolid: HiClock,
  tooltip: HiOutlineQuestionMarkCircle,
  undelete: HiOutlineLifebuoy,
  unknownType: HiOutlineQuestionMarkCircle,
  view: HiOutlineEye,
  wall: HiOutlineChatBubbleBottomCenter,
  wallPost: HiOutlineChatBubbleLeftEllipsis,
  warningCircle: HiOutlineExclamationCircle,
  warningTriangle: HiOutlineExclamationTriangle,
  chart: HiChartBar,
  feed: FaRectangleList,
};

export type IconName = keyof typeof icons;

const sizeClasses = {
  small: "h-5 w-5",
  medium: "h-6 w-6",
};

interface IconProps extends ComponentProps<"svg"> {
  name: IconName;
  /** small: 5, medium: 6 */
  size?: keyof typeof sizeClasses;
}

/**
 * Returns an SVG component by name. All SVG overrides are available.
 *
 * Ideally, use `size` prop instead of setting the size with `className`
 * @returns
 */
export function Icon({ name, size, ...props }: IconProps) {
  return createElement(icons[name], {
    ...props,
    className: twMerge(size ? sizeClasses[size] : undefined, props.className),
  });
}

/**
 * Returns an Icon component matching a given name. Used when we're passing IconProps
 *
 * Please use a specific function-name like "back" instead of "chevronLeft" or "arrowLeft"
 *
 * @param name
 * @returns
 */
export function getIcon(name: IconName) {
  return icons[name];
}
