import { getIcon } from "~/lib/ui";
import { ActionButtons, ButtonAction } from "~/lib/ui/action-buttons";

async function slowPromise() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, 5000);
  });
}

const actions: Array<ButtonAction> = [
  {
    label: "With Confirm",
    onClick: () => slowPromise(),
    Icon: getIcon("add"),
    confirm: {
      action: "generic",
    },
  },
  {
    label: "Normal",
    onClick: () => slowPromise(),
    Icon: getIcon("devDebug"),
  },
  {
    label: "Always collapsed",
    onClick: () => slowPromise(),
    Icon: getIcon("devDebug"),
    collapseBehavior: "always",
  },
  {
    label: "No icon",
    onClick: () => slowPromise(),
  },
];

export default function ActionButtonPlayground() {
  return (
    <div className="p-8">
      <p>Small</p>
      <ActionButtons actions={actions} size="small" />
      <p>Small w. label</p>
      <ActionButtons actions={actions} size="small" showLabelInButton />
      <p>Medium</p>
      <ActionButtons actions={actions} size="medium" />
      <p>Medium w. label</p>
      <ActionButtons actions={actions} size="medium" showLabelInButton />
    </div>
  );
}
