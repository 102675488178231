import { deepEqual, isObject } from "~/lib/utils/object/deep-equal";

export function matchingArrays<T>(a: Array<T>, b: Array<T>): boolean {
  if (a.length !== b.length) return false;
  return a.every((v, i) => {
    if (isObject(v)) {
      return deepEqual(v as { [key: string]: unknown }, b[i] as { [key: string]: unknown });
    }
    return v === b[i];
  });
}
