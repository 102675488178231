import { z } from "zod";
import { useFormState } from "~/lib/form-state";
import { EditOfferOperationRequest, EditOfferRequestOfferLinesRuleEnum, Offer } from "@apacta/sdk";
import { useEffect } from "react";

export const offerSchema = {
  contactId: z.string().nullish(),
  contactPersonId: z.string().nullish(),
  expirationDate: z.date(),
  title: z.string().min(1),
  type: z.string(),
  description: z.string(),
  vatPercent: z.number(),
  offerLinesRule: z.string().optional(),
  streetName: z.string().optional(),
  zipCode: z.string().optional(),
  cityId: z.string().optional(),
  cityName: z.string().optional(),
  manualTotalSalesPrice: z.number().optional(),
  contributionRatioOverwrite: z.number().optional(),
  shouldSetManualPrice: z.boolean(),
};

export type OfferFormState = ReturnType<typeof useOfferFormState>["formState"];

export function useOfferFormState(offer?: Offer) {
  const defaultExpiryDate = (): Date => {
    if (offer?.expirationDate) return offer.expirationDate;
    const d = new Date();
    d.setDate(d.getDate() + 14);
    return d;
  };

  const getInitialValues = () => {
    return {
      contactId: offer?.contactId || "",
      contactPersonId: offer?.contactPersonId || "",
      expirationDate: defaultExpiryDate(),
      title: offer?.title || "",
      type: offer?.isQuote ? "quote" : "estimate",
      description: offer?.description || "",
      vatPercent: offer?.vatPercent || 0,
      offerLinesRule: offer?.offerLinesRule as string,
      streetName: offer?.address ?? undefined,
      zipCode: offer?.city?.zipCode?.toString() ?? undefined,
      cityId: offer?.cityId ?? undefined,
      cityName: offer?.city?.name,
      manualTotalSalesPrice: offer?.manualTotalSalesPrice ?? undefined,
      contributionRatioOverwrite: 0,
      shouldSetManualPrice: !!offer?.manualTotalSalesPrice,
    };
  };

  const formState = useFormState({
    schema: offerSchema,
    initialValues: getInitialValues(),
  });

  const getOfferData = (): EditOfferOperationRequest => {
    return {
      offerId: offer?.id || "",
      editOfferRequest: {
        contactId: formState.getValue("contactId"),
        contactPersonId: formState.getValue("contactPersonId"),
        title: formState.getValue("title"),
        description: formState.getValue("description"),
        isQuote: formState.getValue("type") === "quote",
        expirationDate: formState.getValue("expirationDate"),
        vatPercent: formState.getValue("vatPercent"),
        offerLinesRule: formState.getValue("offerLinesRule")
          ? (formState.getValue("offerLinesRule") as EditOfferRequestOfferLinesRuleEnum)
          : undefined,
        address: formState.getValue("streetName") ?? "",
        cityId: formState.getValue("cityId") ?? null,
        manualTotalSalesPrice: formState.getValue("manualTotalSalesPrice") ?? null,
      },
    };
  };

  useEffect(() => {
    if (offer) {
      setInitialValues(offer);
    }

    return () => undefined;
  }, [offer]);

  const setInitialValues = (values?: Offer) => {
    formState.resetInitialValues({
      contactId: values?.contactId ?? undefined,
      contactPersonId: values?.contactPersonId ?? undefined,
      title: values?.title ?? "",
      description: values?.description ?? "",
      expirationDate: values?.expirationDate ?? defaultExpiryDate(),
      type: values?.isQuote ? "quote" : "estimate",
      vatPercent: values?.vatPercent ?? 0,
      offerLinesRule: values?.offerLinesRule ?? undefined,
      streetName: values?.address ?? undefined,
      zipCode: values?.city?.zipCode?.toString() ?? undefined,
      cityId: values?.cityId ?? undefined,
      cityName: values?.city?.name ?? undefined,
      manualTotalSalesPrice: values?.manualTotalSalesPrice ?? undefined,
      contributionRatioOverwrite: 0,
      shouldSetManualPrice: !!values?.manualTotalSalesPrice,
    });
  };

  return { formState, getOfferData, setInitialValues };
}
