import { and, ControlProps, formatIs, isControl, rankWith } from "@jsonforms/core";
import { parse } from "tinyduration";
import { DurationInput } from "~/lib/ui/form-elements/duration-input";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";

export function DurationRenderer(props: ControlProps) {
  const duration = parse(props.data ?? "PT0H0M");

  return (
    <DurationInput
      value={duration}
      label={getLabelForControl(props, "")}
      required={props.required}
      onChangeString={(dur) => props.handleChange(props.path, dur)}
      // Minute intervals
      minuteOptions={[0, 15, 30, 45]}
    />
  );
}
export const durationTester = rankWith(4, and(isControl, formatIs("duration")));
