import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import { Suspense } from "react";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { Outlet } from "react-router";
import { getIcon } from "~/lib/ui/icons/icon";

export const CACHE_PRODUCTS = "products";

export default function ProductsPage() {
  const { t } = useTranslation();

  return (
    <PageLayout title={t("navigation:products.title")}>
      <PageTabs
        tabs={[
          {
            id: "products",
            label: t("common:own_products"),
            Icon: getIcon("product"),
            to: "",
          },
          {
            id: "expense_line",
            label: t("products:product_type.expense_line", { count: 2 }),
            Icon: getIcon("expense"),
            to: "expense-lines/",
          },
          {
            id: "vendor_product",
            label: t("common:vendor_product", { count: 2 }),
            Icon: getIcon("product"),
            to: "vendor-products/",
          },
          {
            id: "product_bundle",
            label: t("common:product_bundle", { count: 2 }),
            Icon: getIcon("productBundle"),
            to: "product-bundles/",
          },
        ]}
      />
      <Suspense>
        <Outlet />
      </Suspense>
    </PageLayout>
  );
}
