import { ReactNode } from "react";

// A large card element with a title and an optional description
export function PageSection({ label, children }: { label?: string; children: ReactNode }) {
  return (
    <div className="flex-1">
      {label && <div className="mb-2 text-gray-500">{label}</div>}
      <div className="flex flex-col bg-white p-4 shadow sm:rounded-lg">{children}</div>
    </div>
  );
}
