import { generateBackgroundColor } from "~/lib/utils/colors";
import { getInitials } from "~/lib/utils";
import { twMerge } from "tailwind-merge";

/**
 *
 * @param user Used to infer avatar image and background color
 * @param className Used to override default styles
 * @constructor
 */
export default function Avatar({
  className,
  name = "N/A",
  image,
}: {
  className?: string;
  name?: string;
  image?: string;
}) {
  return (
    <div
      className={twMerge(
        "flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full text-lg text-white transition-all duration-500",
        className ? className : "h-12 w-12"
      )}
      style={
        image
          ? {
              backgroundImage: `url(${image})`,
              backgroundColor: "#eeeeee",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }
          : {
              backgroundColor: generateBackgroundColor(name),
            }
      }
    >
      {!image ? getInitials(name) : null}
    </div>
  );
}
