import { twMerge } from "tailwind-merge";
import { SupportedUnits } from "./kpi-data-card";
import { FormatValue } from "./FormatValue";
import { useTranslation } from "react-i18next";
import { PercentBar } from "./percent-bar";

export type KPIRatioViewProps = {
  unit: SupportedUnits;
  left: {
    label: string;
    value: number;
    percent: number;
    percentLabel?: string;
  };
  right?: {
    label: string;
    value: number;
    percent?: number;
    percentLabel?: string;
  };
  extraUnits?: Array<{
    unit: SupportedUnits;
    leftValue?: number;
    rightValue?: number;
    leftLabel?: string;
    rightLabel?: string;
  }>;
  barClasses?: {
    primary?: string;
    remainder?: string;
    overshoot?: string;
  };
};

export function KPIRatioView({ unit, left, right, extraUnits, barClasses }: KPIRatioViewProps) {
  const { t } = useTranslation();
  let leftPercent = left.percent;

  // Guardrails for NaN and Infinity
  if (leftPercent === Infinity) leftPercent = 100;
  if (isNaN(leftPercent)) leftPercent = 0;

  return (
    <div>
      <div className={twMerge("w-full")}>
        <PercentBar
          percentage={leftPercent}
          overshootClassName={barClasses?.overshoot}
          primaryClassName={barClasses?.primary}
          remainderClassName={barClasses?.remainder}
        />
      </div>
      <div className="flex h-full flex-row items-center justify-between pt-2">
        <div className="flex h-full flex-row">
          <div className="text-left">
            <span className="text-gray-400">{left.label}</span>
            <br />
            <div className="font-bold">
              <FormatValue value={left.value} unit={unit} />
              {extraUnits?.map((u, idx) => (
                <div key={idx}>
                  {u.leftLabel && (
                    <span className="block font-normal text-gray-400">{u.leftLabel}</span>
                  )}
                  {u.leftValue !== undefined && <FormatValue value={u.leftValue} unit={u.unit} />}
                </div>
              ))}
            </div>
          </div>
        </div>
        {right && (
          <div className="flex h-full flex-row">
            <div className="text-right">
              <span className="text-gray-400">{right.label}</span>
              <br />
              <div className="font-bold">
                <FormatValue value={right.value} unit={unit} />
                {extraUnits?.map((u, idx) => (
                  <div key={idx}>
                    {u.rightLabel && (
                      <span className="block font-normal text-gray-400">{u.rightLabel}</span>
                    )}
                    {u.rightValue !== undefined && (
                      <FormatValue value={u.rightValue} unit={u.unit} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
