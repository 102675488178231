import { SidebarCollapsed } from "./components/sidebars/sidebar-collapsed";
import { SidebarExpanded } from "./components/sidebars/sidebar-expanded";

export function SidebarDesktop() {
  // Note: Both are mounted, but context controls visibility
  return (
    <>
      <SidebarCollapsed />
      <SidebarExpanded />
    </>
  );
}
