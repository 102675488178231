import { and, composePaths, ControlProps, rankWith, uiTypeIs } from "@jsonforms/core";
import { isDecendantOf } from "./testers";
import { AddressOnelineInput } from "../components/address-oneline-input";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";

export function DrivingDestinationEditRenderer(props: ControlProps) {
  return (
    <AddressOnelineInput
      label={getLabelForControl(props)}
      initialValue={props.data?.address}
      onChange={(address) => props.handleChange(composePaths(props.path, "address"), address)}
      onSelect={({ addressLine, latitude, longitude }) => {
        props.handleChange(composePaths(props.path, "address"), addressLine);
        props.handleChange(composePaths(props.path, "latitude"), latitude);
        props.handleChange(composePaths(props.path, "longitude"), longitude);
      }}
    />
  );
}
export const drivingDestinationEditTester = rankWith(
  3,
  and(uiTypeIs("VerticalLayout"), isDecendantOf("driving"))
);
