import { KPICardWrapper, KPICardWrapperProps } from "../_cmp/kpi-card-wrapper";
import { GaugeSVG } from "../_cmp/gauge-svg";
import { ReactNode } from "react";

export const CONTRIBUTION_MARGIN_RED = 149.99;
export const CONTRIBUTION_MARGIN_YELLOW = 249.99;
export const CONTRIBUTION_MARGIN_GREEN = 500;
export const CONTRIBUTION_MARGIN_MAX = 500;

export function ContributionMarginCard({
  contributionMargin = 0,
  paddingXClassname = "px-8",
  renderGaugeText,
  ...cardWrapperProps
}: KPICardWrapperProps & {
  renderGaugeText?: (value: number) => ReactNode;
  contributionMargin?: number;
  paddingXClassname?: string;
}) {
  return (
    <KPICardWrapper {...cardWrapperProps}>
      <div className={paddingXClassname}>
        <GaugeSVG
          unit="currency"
          max={CONTRIBUTION_MARGIN_MAX}
          value={contributionMargin}
          renderValue={renderGaugeText}
          areas={[
            {
              value: CONTRIBUTION_MARGIN_RED,
              className: "stroke-red-600",
            },
            {
              value: CONTRIBUTION_MARGIN_YELLOW,
              className: "stroke-orange-600",
            },
            {
              value: CONTRIBUTION_MARGIN_GREEN,
              className: "stroke-green-600",
            },
          ]}
        />
      </div>
    </KPICardWrapper>
  );
}
