import { ChangeEvent, useState } from "react";
import { LabeledSelect } from "~/lib/ui/form-elements/labeled-select";
import { useTranslation } from "react-i18next";

/**
 * Shows two selections for hours and minutes.
 * If check-in is selected the user can select a time in the future.
 * If check-out is selected, the user can only select a time in the past.
 */
export function CheckTime({
  type,
  onChange,
}: {
  type: "check-in" | "check-out";
  onChange: (d: Date) => void;
}) {
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  const [selectedHour, setSelectedHour] = useState<number>();
  const [selectedMinute, setSelectedMinute] = useState<number>();

  const { t } = useTranslation();
  const availableHours = (() => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      if (type === "check-in" && i >= currentHour) {
        hours.push(i);
      }
      if (type === "check-out" && i <= currentHour) {
        hours.push(i);
      }
    }
    return hours;
  })();

  const availableMinutes = (() => {
    const allowedMinutes = [0, 15, 30, 45];
    const minutes = [];
    for (const i of allowedMinutes) {
      if (type === "check-in") {
        if (selectedHour === currentHour && i <= currentMinute) {
          continue;
        }
      }
      if (type === "check-out") {
        if (selectedHour === currentHour && i >= currentMinute) {
          continue;
        }
      }
      minutes.push(i);
    }
    return minutes;
  })();

  function handleChangeHour(e: ChangeEvent<HTMLSelectElement>) {
    const newHour = Number(e.currentTarget.value);
    setSelectedHour(newHour);
    if (newHour === currentHour) {
      setSelectedMinute(currentMinute);
    }
    reportChange(newHour, selectedMinute);
  }

  function handleChangeMinute(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedMinute(Number(e.currentTarget.value));
    reportChange(selectedHour, Number(e.currentTarget.value));
  }

  function reportChange(hour?: number, minute?: number) {
    const newDate = new Date();
    if (hour) {
      newDate.setHours(hour);
    }
    if (minute) {
      newDate.setMinutes(minute);
    }
    onChange(newDate);
  }

  return (
    <div className="flex flex-row gap-2">
      <LabeledSelect
        labelClassName="capitalize"
        label={t("common:hour")}
        onChange={handleChangeHour}
        value={`${selectedHour}`}
      >
        <option></option>
        {availableHours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </LabeledSelect>
      <LabeledSelect
        labelClassName="capitalize"
        label={t("common:minute")}
        onChange={handleChangeMinute}
        value={`${selectedMinute}`}
      >
        <option></option>
        {availableMinutes.map((minute) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </LabeledSelect>
    </div>
  );
}
