import { PageLayout } from "~/lib/ui/page-layout";
import { Button, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { ExpensesTable } from "./_cmp/expenses-table";

export default function ExpensesPage() {
  const { t } = useTranslation();
  return (
    <PageLayout
      title={t("navigation:expenses.title")}
      renderActions={() => (
        <Button Icon={getIcon("add")} variant="tertiary" className="print:hidden">
          {t("common:create", {
            defaultValue: "Create {{entity}}",
            replace: { entity: t("expenses:expense", { count: 1 }).toLocaleLowerCase() },
          })}
        </Button>
      )}
    >
      <ExpensesTable parentType="page" />
    </PageLayout>
  );
}
