import { useRef } from "react";
import { DocumentProperties, jsPDF as JsPDF } from "jspdf";
import {
  createPage,
  getElementRules,
  insertPageNumbers,
  mutateElements,
  outputElements,
  PAGE_NUMBER_HEIGHT,
  splitElementsOnPages,
} from "~/lib/pdf/lib/helpers";
import {
  PdfBuilder,
  PdfBuilderElementData,
  PdfBuilderElementRule,
  PdfBuilderOptions,
} from "~/lib/pdf/lib/types";
import { fontFaces } from "~/lib/pdf/lib/font-faces";

const usePdfBuilderOptionsDefault: PdfBuilderOptions = {
  contentWidth: 1216,
};

// A4 dimensions in mm
const A4_WIDTH = 210;
const A4_HEIGHT = 297;

/**
 * Hook to generate a PDF from HTML content
 * @param {PdfBuilderOptions} opts - Options for the PDF builder
 */
export const usePdfBuilder = (opts?: PdfBuilderOptions): PdfBuilder => {
  /**
   * Reference to the original content div
   * This is exposed and to be used as a ref on the content div that you want to generate a PDF from
   */
  const contentRef = useRef<HTMLDivElement>(null);

  // Merge the options with the defaults
  const options = { ...usePdfBuilderOptionsDefault, ...opts };

  // Dimensions for the PDF output
  const dimensions: { width: number; height: number } = {
    width: options.contentWidth,
    height: Math.floor(A4_HEIGHT / (A4_WIDTH / options.contentWidth)),
  };

  /**
   * References to the shadow, staging and output divs
   * These will be created and removed as needed
   */
  let shadowRef: HTMLDivElement | null = null;
  let stagingRef: HTMLDivElement | null = null;
  let outputRef: HTMLDivElement | null = null;

  /**
   * This function will generate all the pages for the PDF, stage them and finally put them into the output div
   */
  const generatePages = async (): Promise<void> => {
    if (!shadowRef || !stagingRef || !outputRef) {
      throw new Error("[shadowRef, stagingRef, outputRef] One or more refs are not set");
    } else {
      return new Promise(async (resolve) => {
        // Make sure shadowRef and stagingRef are mounted and present in the DOM
        if (shadowRef && stagingRef) {
          // If the shadowRef scrollHeight is larger than our expected dimension height, we have overflown elements
          if (shadowRef.scrollHeight + PAGE_NUMBER_HEIGHT > dimensions.height) {
            // Iterate through the child nodes and split them on pages
            while (shadowRef.childNodes.length) {
              // Create a new page
              const page = createPage(dimensions.width, dimensions.height, shadowRef.classList);
              // Append the page to the stagingRef
              stagingRef.appendChild(page);

              // Array containing the element data, consisting of the original parent, the parent and the children
              let elementData: Array<PdfBuilderElementData> = [];

              // Array containing the element rules, consisting of the element and its rules
              const elementRules: Array<PdfBuilderElementRule> = [];

              // Iterate through the child nodes and get the element data needed to split them on pages
              for (const childNode of shadowRef.childNodes) {
                // Get elements and rules for childnode
                elementRules.push(...getElementRules(childNode));

                const data = await splitElementsOnPages({
                  node: childNode,
                  inputWrapper: shadowRef,
                  stagingWrapper: page,
                  elementData,
                  originalParent: childNode as HTMLElement,
                  elementRules,
                });

                // Assign the element data to the elementData array
                elementData = [...data];
              }

              // Perform mutations on the shadowRef and stagingRef
              await mutateElements({
                elementData,
                elementRules,
                shadowRef,
                page,
              });
            }
          } else {
            // If everything fits, just clone nodes
            const page = createPage(dimensions.width, dimensions.height, shadowRef.classList);
            stagingRef.appendChild(page);

            shadowRef.childNodes.forEach((childNode) => {
              page.appendChild(childNode.cloneNode(true));
              childNode.remove();
            });
          }

          await insertPageNumbers(stagingRef);

          // Output the pages to the outputRef
          if (outputRef) {
            await outputElements({ stagingRef, outputRef });
          }

          resolve();
        }
      });
    }
  };

  const prepareDocument = async (): Promise<void> => {
    if (!contentRef.current) {
      throw new Error("[contentRef] is not set!");
    } else {
      return new Promise((resolve) => {
        const shadow = document.createElement("div");
        shadow.id = "pdf-builder-shadow";
        const staging = document.createElement("div");
        staging.id = "pdf-builder-staging";
        const output = document.createElement("div");
        output.id = "pdf-builder-output";

        [shadow, staging, output].forEach((el) => {
          if (el.id !== "pdf-builder-output") {
            el.style.height = `${dimensions.height}px`;
          }
          el.style.width = `${dimensions.width}px`;
          el.style.position = "fixed";
          el.style.right = "0";
          el.style.marginRight = "-99999px";
        });

        // typescript.......
        if (contentRef.current) {
          // Copy the contents of the contentRef to the shadow and break any and all references
          // If we copy the child nodes, references remain
          shadow.innerHTML = contentRef.current.innerHTML;

          // Also remember to apply any styles (margin, padding etc.) to the shadow
          contentRef.current.classList.forEach((className) => {
            if (className !== "hidden") {
              shadow.classList.add(className);
            }
          });
        }

        shadowRef = shadow;
        stagingRef = staging;
        outputRef = output;

        document.body.appendChild(shadow);
        document.body.appendChild(staging);
        document.body.appendChild(output);

        resolve();
      });
    }
  };

  const cleanupDocument = async (): Promise<void> => {
    return new Promise((resolve) => {
      if (!shadowRef || !stagingRef || !outputRef) {
        resolve();
      } else {
        shadowRef.remove();
        stagingRef.remove();
        outputRef.remove();
        resolve();
      }
    });
  };

  const generateDocument = async (
    properties?: Partial<DocumentProperties>
  ): Promise<JsPDF | null> => {
    return new Promise(async (resolve) => {
      // safeguard
      await cleanupDocument();

      // prepare the document
      await prepareDocument();

      // run async logic here
      await generatePages();

      // Make sure the shadow DOM is ready
      await new Promise((res) => requestAnimationFrame(res));

      const doc = new JsPDF({
        format: "a4",
        unit: "mm",
        orientation: "p",
      });

      doc.setProperties({
        creator: `Apacta - ${window.navigator.userAgent}`,
        title: "Document",
        ...properties,
      });

      if (outputRef !== null) {
        doc.html(outputRef, {
          async callback(d) {
            await cleanupDocument();
            resolve(d);
          },
          autoPaging: "text",
          width: 210,
          windowWidth: dimensions.width,
          fontFaces,
        });
      } else {
        resolve(null);
      }
    });
  };

  const previewPdf = async (): Promise<HTMLDivElement> => {
    return new Promise(async (resolve) => {
      await cleanupDocument();
      await prepareDocument();
      await generatePages();

      resolve(outputRef as HTMLDivElement);
    });
  };

  const downloadPdf = async (fileName?: string): Promise<void> => {
    // Generate PDF
    const file = await generateDocument({
      title: fileName ?? "document",
    });

    if (file) {
      // Trigger download of the PDF
      file.save(fileName ?? "document");
    }
  };

  const getPdf = async (fileName?: string): Promise<Blob | void> => {
    // Generate PDF
    const file = await generateDocument({ title: fileName });

    if (file) {
      // Return pdf file blob
      return file.output("blob");
    }
  };

  return {
    contentRef,
    generateDocument,
    downloadPdf,
    getPdf,
    previewPdf,
  };
};
