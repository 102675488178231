import { ControlProps, composePaths, or, rankWith } from "@jsonforms/core";
import { isControlEntity } from "./testers";
import { Button, Icon, IconName, Label } from "~/lib/ui";
import { twMerge } from "tailwind-merge";
import { TextArea } from "~/lib/ui/form-elements";
import { useTranslation } from "react-i18next";

const baseClass =
  "rounded-md aria-selected:border-primary aria-selected:border-4 border border-shade-100 border-4 hover:opacity-80";

const COMMENT_ALLOWED = ["reject", "warning"];

export function QARenderer(props: ControlProps) {
  const { t } = useTranslation();

  const options: Array<{
    value: string;
    label: string;
    iconName: IconName;
    className?: string;
  }> = [
    // These values are static and come from the backend
    {
      value: "ok",
      label: t("forms:qa_ok_label", "OK"),
      iconName: "faceHappy",
      className: "bg-green-100",
    },
    {
      value: "reject",
      label: t("forms:qa_reject_label", "Reject"),
      iconName: "faceSad",
      className: "bg-red-100",
    },
    {
      value: "warning",
      label: t("forms:qa_warning_label", "Warning"),
      iconName: "warningTriangle",
      className: "bg-yellow-100",
    },
    { value: "skip", label: t("common:skip", "Skip"), iconName: "skip", className: "bg-gray-100" },
  ];

  const answer = props.data?.answer;

  function handleAnswerChange(v: string) {
    props.handleChange(composePaths(props.path, "answer"), v);
    if (COMMENT_ALLOWED.includes(v) && !props.data?.comment) {
      props.handleChange(composePaths(props.path, "comment"), "");
    } else {
      props.handleChange(composePaths(props.path, "comment"), undefined);
    }
  }

  function handleCommentChange(v: string) {
    props.handleChange(composePaths(props.path, "comment"), v);
  }

  const ICON_SIZE = props.uischema.options?.["x-compact-mode"] ? "4rem" : "6rem";

  return (
    <>
      <div>
        <Label required={props.required}>{props.label}</Label>
        {props.description && (
          <div className="mb-2 text-xs text-shade-400">{props.description}</div>
        )}
        <div className="flex w-full flex-row justify-between gap-4">
          {options.map((option) => (
            <Button
              title={option.label}
              key={option.value}
              className={twMerge(
                baseClass,
                option.className,
                "inline-flex flex-1 items-center justify-center"
              )}
              aria-selected={answer === option.value}
              onClick={() => handleAnswerChange(option.value)}
            >
              <Icon name={option.iconName} style={{ width: ICON_SIZE, height: ICON_SIZE }} />
            </Button>
          ))}
        </div>
        {COMMENT_ALLOWED.includes(answer) && (
          <TextArea
            autoHeight={true}
            placeholder={t("common:reason", "Reason")}
            onChange={(s) => handleCommentChange(s)}
            initialValue={props.data?.comment}
          />
        )}
        {props.errors ? (
          <p className="mt-1 text-left text-sm text-red-600">{props.errors}</p>
        ) : null}
      </div>
    </>
  );
}
export const qaTester = rankWith(
  3,
  or(
    isControlEntity("compact_qa_question"),
    isControlEntity("qa_question"),
    isControlEntity("standard_ks_question")
  )
);
