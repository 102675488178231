import { twMerge } from "tailwind-merge";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MediaPreview } from "./media-preview";
import { BaseFile } from "@apacta/sdk";
import { forwardRef } from "react";

type MediaPreviewContainerProps = {
  file: File | BaseFile;
  disabled?: boolean;
  onRemoveFile: (file: File | BaseFile) => void;
};

export const MediaPreviewContainer = forwardRef<HTMLDivElement, MediaPreviewContainerProps>(
  function MediaPreviewContainerInner(
    { file, disabled = false, onRemoveFile }: MediaPreviewContainerProps,
    ref
  ) {
    return (
      <div
        ref={ref}
        className={twMerge(
          "flex w-56 flex-col items-center justify-center gap-2 rounded-lg border bg-white text-sm shadow-md",
          disabled ? "pt-8" : "pt-2"
        )}
      >
        {!disabled && (
          <div
            onClick={() => onRemoveFile(file)}
            className="transition-color mr-2 h-6 w-6 cursor-pointer self-end rounded-full bg-shade-100 p-1 duration-200 ease-in-out hover:bg-red-500 hover:text-white"
          >
            <XMarkIcon className="h-4 w-4" />
          </div>
        )}
        <div className="flex flex-col items-center gap-4 px-6">
          <div className="pt-2">
            <MediaPreview
              filename={(file as BaseFile).originalFilename || file.name}
              href={(file as BaseFile).fileUrl || URL.createObjectURL(file as File)}
              mimeType={(file as BaseFile).mimeType || (file as File).type}
            />
          </div>
          <div className="flex pb-6">
            <div className="w-44 overflow-hidden text-ellipsis whitespace-nowrap">
              {(file as BaseFile).originalFilename || file.name}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
