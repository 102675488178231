import { ControlProps, and, isControl, isStringControl, or, rankWith } from "@jsonforms/core";
import { isDecendantOf, isEntityOf } from "./testers";
import { Icon, Label } from "~/lib/ui";
import { useEffect, useRef } from "react";
import SignaturePad from "signature_pad";
import { useFormsContext } from "../components/form-renderer";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export function SignatureRenderer(props: ControlProps) {
  const { mode } = useFormsContext();
  return (
    <div>
      <Label required={props.required}>{props.label}</Label>
      <div>
        {mode === "edit" ? (
          <SignatureViewMode value={props.data} />
        ) : (
          <SignatureEditMode
            onChange={(v) => props.handleChange(props.path, v)}
            value={props.data}
          />
        )}
      </div>
    </div>
  );
}

export function SignatureEditMode(props: { value: string; onChange: (value: string) => void }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const padRef = useRef<SignaturePad>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!canvasRef.current) return;
    if (padRef.current) return;

    const pad = new SignaturePad(canvasRef.current);
    function handleEndStore() {
      const svg = pad.toSVG();
      props.onChange(svg);
    }

    pad.addEventListener("endStroke", handleEndStore);
    padRef.current = pad;

    // TODO maybe: Handle canvas resizing https://www.npmjs.com/package/signature_pad#tips-and-tricks

    return () => {
      padRef.current = undefined;
      pad.off();
    };
  }, [canvasRef.current]);

  return (
    <div className="relative overflow-hidden border">
      <div
        className={twMerge(
          "pointer-events-none absolute left-3 top-3 flex flex-row items-center justify-center gap-2 text-sm text-shade-400",
          props.value && "hidden"
        )}
      >
        <Icon name="draw" />
        {t("forms:signature_draw", "Draw your signature")}
      </div>
      <canvas ref={canvasRef} className="" width={1720} height="100%" />
    </div>
  );
}

export function SignatureViewMode(props: { value: string }) {
  const { t } = useTranslation();
  if (!props.value) {
    return (
      <div className="flex h-full w-full items-center justify-center border p-6 text-xl text-shade-400">
        {t("forms:no_signature")}
      </div>
    );
  }
  return <svg className="h-full w-full border" dangerouslySetInnerHTML={{ __html: props.value }} />;
}

export const signatureTester = rankWith(
  3,
  and(
    isControl,
    isStringControl,
    or(isEntityOf("signature"), isDecendantOf("checkbox_with_signature"))
  )
);
