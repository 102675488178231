import { IGetProject200Response } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import Switch from "~/lib/ui/switch";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";
import { useEffect, useMemo, useState } from "react";
import { deepEqual } from "~/lib/utils/object/deep-equal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CACHE_PROJECTS } from "~/pages/projects";
import { captureException } from "@sentry/core";
import { useToasts } from "~/lib/toast/use-toasts";
import { twMerge } from "tailwind-merge";
import ContentLoader from "react-content-loader";
import { PageSection } from "~/lib/ui/form-elements/page-section";
import { useMe } from "~/lib/auth/use-me";
import { Input } from "~/lib/ui/form-elements";
import { useEmployees } from "~/lib/employees/use-employees";

export type ProjectEntity = IGetProject200Response["data"];

export default function EmployeeAccessSection({ project }: { project: ProjectEntity }) {
  const { t } = useTranslation();
  const api = useAPI();

  const { employees: users, isFetching } = useEmployees();

  // Can not use form state as Zod does not support objects with dynamic keys, and we can't use z.record() without modifying raw data
  const [userAccess, setUserAccess] = useState<{ [key: string]: boolean }>(project.userAccess);
  const [isModified, setIsModified] = useState(false);
  const [userQuery, setUserQuery] = useState("");

  const queryClient = useQueryClient();
  const toast = useToasts();
  const { companySettings } = useMe();

  const updateM = useMutation({
    mutationFn: () => api.usersAccessToProject({ projectId: project.id, requestBody: userAccess }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS],
      });
    },
    onError: (err) => {
      toast.showTemplate("UNEXPECTED_ERROR");
      captureException(new Error("Failed to update user access list", { cause: err }));
    },
  });

  // Auto-save, if modified
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isModified) {
      timer = setTimeout(() => {
        updateM.mutate();
      }, 800);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isModified, userAccess]);

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      if (!userQuery) return true;
      return user.fullName.toLowerCase().includes(userQuery.toLowerCase());
    });
  }, [userQuery, users]);

  const handleSwitchAll = (value: boolean) => {
    const newAccess = { ...userAccess };
    Object.keys(userAccess).forEach((key) => {
      newAccess[key] = value;
    });
    setUserAccess(newAccess);
  };

  const handleChange = (value: boolean, id: string) => {
    setUserAccess({ ...userAccess, [id]: value });
  };

  useEffect(() => {
    if (!deepEqual(userAccess, project.userAccess, true)) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [userAccess, project.userAccess]);

  const allIsChecked = Object.values(userAccess).every((value) => value);

  // Only shown for users with this feature
  if (!companySettings.addEmployeesToProject) return null;

  return (
    <>
      <div className="flex flex-col gap-8 md:flex-row">
        <PageSection label={t("projects:employee_access")}>
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            <div className="flex flex-1 items-center justify-between border-b border-gray-200 border-b-gray-200 bg-gray-50 p-4 text-sm font-bold text-gray-500">
              <div className="flex flex-col">
                <Input
                  placeholder={t("common:search_x", {
                    replace: { x: t("common:employee", { count: 2 }).toLocaleLowerCase() },
                    defaultValue: "Search {{x}}...",
                  })}
                  className="w-64"
                  value={userQuery}
                  onChange={(e) => setUserQuery(e.currentTarget.value)}
                />
              </div>
              <div className={filteredUsers.length > 4 ? "pr-4" : ""}>
                <Switch
                  defaultChecked={allIsChecked}
                  onCheckedChange={(v) => handleSwitchAll(v)}
                  disabled={updateM.isPending}
                />
              </div>
            </div>
            <div className="planning-scrollbar flex max-h-96 flex-1 flex-col divide-y divide-gray-200 overflow-y-auto">
              {isFetching && (
                <div className="flex flex-col gap-8 overflow-hidden p-4">
                  {Array.from({ length: 5 }).map((_, i) => (
                    <ContentLoader key={`loader-${i}`} className={twMerge("h-12 w-full")}>
                      <rect x={0} y={0} width="100%" height="100%"></rect>
                    </ContentLoader>
                  ))}
                </div>
              )}
              {filteredUsers.map((user) => (
                <div key={user.id} className="flex flex-1 justify-between p-4 hover:bg-gray-50">
                  <div className="flex items-center gap-4">
                    <UserAvatar user={user} className="h-8 w-8 text-sm" />
                    <div>{user.fullName}</div>
                  </div>
                  <div className="flex items-center">
                    <Switch
                      onCheckedChange={(v) => handleChange(v, user.id)}
                      checked={userAccess[user.id]}
                      controlled
                      disabled={updateM.isPending}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PageSection>
      </div>
    </>
  );
}
