import { twMerge } from "tailwind-merge";
import { IconProp } from "../types";
import { baseItemClass } from "./shared";
import * as RadixDropdown from "@radix-ui/react-dropdown-menu";
import { ReactNode } from "react";

type PrimitiveProps = Parameters<typeof RadixDropdown.Item>[0];

type Props = {
  className?: string;
  Icon?: IconProp;
  children: ReactNode;
  primitive?: PrimitiveProps;
  preventClosing?: boolean;
  onSelect: PrimitiveProps["onSelect"];
};

export const DropdownItemText = (props: Props) => {
  function handleSelect(e: Event) {
    if (props.preventClosing) e.preventDefault();
    props.onSelect?.(e);
  }

  return (
    <RadixDropdown.Item
      {...props.primitive}
      onSelect={handleSelect}
      onClick={(e) => e.stopPropagation()} // Necessary for when used in a row. Open for suggestions.
      className={twMerge(baseItemClass, props.className)}
    >
      <div className="flex items-center gap-2">
        {props.Icon && <props.Icon className="h-5 w-5" />}
        <span>{props.children}</span>
      </div>
    </RadixDropdown.Item>
  );
};
