import { useToasts } from "~/lib/toast/use-toasts";
import { getIcon } from "~/lib/ui";

type UseNavigatorCopyToClipboardProps = {
  value: string;
  toastTitle?: string;
  toastDescription?: string;
};

type UseNavigator = {
  copyToClipboard: ({
    value,
    toastTitle,
    toastDescription,
  }: UseNavigatorCopyToClipboardProps) => Promise<void>;
};

/**
 * Hook for navigator related functionality.
 * This can be expanded in the future to include more navigator related functionality.
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator
 */
export function useNavigator(): UseNavigator {
  const { show } = useToasts();

  const copyToClipboard = async ({
    value,
    toastTitle,
    toastDescription,
  }: {
    value: string;
    toastTitle?: string;
    toastDescription?: string;
  }): Promise<void> => {
    await navigator.clipboard.writeText(value);
    if (toastTitle && toastDescription) {
      show({
        variant: "success",
        Icon: getIcon("clipboard"),
        title: toastTitle,
        description: toastDescription,
      });
    }
  };

  return { copyToClipboard };
}
