import { Card, TaskStatus } from "~/lib/planning";
import { RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum } from "@apacta/sdk";
import { formatStartTime } from "~/pages/planning/_cmp/crud/utility/task-fns";
import { notNullOrUndefined } from "~/lib/utils/utils";

type GenerateCardProps = {
  estimate: number | null;
  dates: Array<Date>;
  startTime: { hour?: number | null | undefined; minute?: number | null | undefined };
  selectedUserIds: Array<string>;
  selectedEmployee?: string | null;
  selectedStartDate?: Date | null;
  selectedIndex?: number;
  taskId?: string;
  repeat?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum;
  interval?: number;
  endTime?: Date | null;
  status?: TaskStatus;
};

/**
 * This function will create cards based on a matrix of dates (Array<Date>) and selected users (Array<User>)
 * This function allows for either or both arrays to be empty
 * @param {number | null} estimate - Estimate value to save on each card
 * @param {Array<Date>} dates - Array of dates to create cards for
 * @param {Date | null} startTime - Start time value to save on each card
 * @param {Array<string>} selectedUsers - Array of users to create cards for
 * @param {string | null | undefined} selectedEmployee - Used to determine if listed index on the card should be specific
 * @param {Date | null | undefined} selectedStartDate - Used to determine if listed index on the card should be specific
 * @param {number | undefined} selectedIndex - The specific listed index to use if any
 */
export const generateCards = ({
  estimate,
  dates,
  startTime,
  selectedUserIds,
  selectedEmployee,
  selectedStartDate,
  selectedIndex,
  taskId,
  repeat,
  interval,
  endTime,
  status,
}: GenerateCardProps): Array<Partial<Card>> => {
  const getStartTime = (d: Date, st: GenerateCardProps["startTime"]): Date | null => {
    if (notNullOrUndefined(st.hour) && notNullOrUndefined(st.minute)) {
      return formatStartTime(d, {
        hour: st.hour,
        minute: st.minute,
      });
    }

    return null;
  };

  if (!dates.length) {
    /**
     * UNPLANNED
     * No dates selected, create cards based on number of employees
     * but allow for no employees
     */
    const cards: Array<Partial<Card>> = [];
    if (!selectedUserIds.length) {
      const cardUpdate: Partial<Card> = {
        estimate,
        listIndex: selectedIndex,
        taskId,
        startTime: getStartTime(new Date(), startTime),
        repeat,
        interval,
        endTime,
        status,
      };
      cards.push(cardUpdate);
    } else {
      selectedUserIds.forEach((userId) => {
        const cardUpdate: Partial<Card> = {
          userId,
          startTime: getStartTime(new Date(), startTime),
          estimate,
          taskId,
          repeat,
          interval,
          endTime,
          status,
        };

        if (userId === selectedEmployee) {
          cardUpdate.listIndex = selectedIndex;
        }
        cards.push(cardUpdate);
      });
    }
    return cards;
  } else if (!selectedUserIds.length) {
    /**
     * UNASSIGNED
     * No employees selected, create cards based on dates
     */
    const cards: Array<Partial<Card>> = [];
    dates.forEach((date) => {
      const cardUpdate: Partial<Card> = {
        date,
        startTime: getStartTime(date, startTime),
        estimate,
        taskId,
        repeat,
        interval,
        endTime,
        status,
      };

      if (date === selectedStartDate) {
        cardUpdate.listIndex = selectedIndex;
      }

      cards.push(cardUpdate);
    });
    return cards;
  } else {
    /**
     * PLANNED
     * Create cards based on employees and dates
     */
    const cards: Array<Partial<Card>> = [];
    selectedUserIds.forEach((userId) => {
      dates.forEach((date) => {
        const cardUpdate: Partial<Card> = {
          date,
          startTime: getStartTime(date, startTime),
          userId,
          estimate,
          taskId,
          repeat,
          interval,
          endTime,
          status,
        };

        if (userId === selectedEmployee && date === selectedStartDate) {
          cardUpdate.listIndex = selectedIndex;
        }

        cards.push(cardUpdate);
      });
    });
    return cards;
  }
};
