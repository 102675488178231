import { useMount } from "~/lib/lifecycle-helpers";

// For testing error handling
export default function BrokenPage() {
  useMount(() => {
    // @ts-expect-error This is meant to be broken
    myUndefinedFunction(); // Uncomment this to try the error screen
  });
  return (
    <section>
      <h1>Broken Page</h1>
    </section>
  );
}
