import { useMutation, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { Outlet } from "react-router";
import { graphql } from "~/lib/gql";
import { useGraphQL } from "~/lib/gql/use-sdk";

graphql(/* GraphQL */ `
  query listProjects($params: ProjectListParams!) {
    projects(params: $params) {
      pageInfo {
        limit
        hasNextPage
        hasPrevPage
        currentPage
      }
      edges {
        node {
          id
          name
          created
        }
      }
    }
  }
`);

graphql(`
  mutation editProjectName($id: ID!, $name: String!) {
    projectUpdate(id: $id, params: { name: $name }) {
      id
      projectNumber
    }
  }
`);

export default function ShowMoreGraphQLDemo() {
  const sdk = useGraphQL();

  const { data, error, refetch, fetchNextPage, hasNextPage } = useSuspenseInfiniteQuery({
    queryKey: ["listProjectsGQL"],
    queryFn: async ({ pageParam }) => sdk.listProjects({ params: { page: pageParam, limit: 5 } }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.projects.pageInfo.hasNextPage && lastPage.projects.pageInfo.currentPage
        ? lastPage.projects.pageInfo.currentPage + 1
        : undefined,
  });

  if (error) {
    <p>Error</p>;
  }

  const dataM = useMutation({
    mutationFn: ({ id, name }: { id: string; name: string }) => sdk.editProjectName({ id, name }),
    onSuccess: () => {
      refetch();
    },
  });

  return (
    <div className="p-6">
      <h2>Show more pagination demo</h2>

      <Outlet />
      {data?.pages.map((page, idx) => (
        <Fragment key={idx}>
          <div className="my-2 text-gray-400">Page {idx}</div>
          {page.projects.edges.map((edge) => (
            <div key={edge.node.id} className="mb-1 flex flex-row gap-x-4">
              <div title={edge.node.id}>{edge.node.created.toLocaleDateString()}</div>
              <div className="flex-1">{edge?.node?.name}</div>
              <div>
                <button
                  className="rounded-md border bg-violet-100 px-2 text-white"
                  onClick={() =>
                    dataM.mutate({
                      id: edge.node.id,
                      name: `${Math.round(Math.random() * 100)} fish!`,
                    })
                  }
                >
                  🐟
                </button>
              </div>
            </div>
          ))}
        </Fragment>
      ))}
      {hasNextPage && (
        <button
          className="w-full rounded-md border border-primary p-2 hover:bg-primary hover:text-white "
          onClick={() => fetchNextPage()}
        >
          Show More
        </button>
      )}
    </div>
  );
}
