// A context that stores the sidebar type

import { createContext, useContext } from "react";
import { ScreenSizeType } from "~/lib/utils/tailwind/screen-sizes";

type SidebarContext = {
  sidebarType: ScreenSizeType;
  sidebarOpen: boolean;
  sidebarCollapsed: boolean;
  onVisibilityChange: (b: boolean) => void;
  onSidebarChange: (type: ScreenSizeType) => void;
  onSidebarCollapse: (collapsed: boolean) => void;
} | null;

export const sidebarContext = createContext<SidebarContext>(null);

export const useSidebarContext = () => {
  const ctx = useContext(sidebarContext);
  if (ctx === null) {
    throw new Error("useSidebarContext must be used within a SidebarContextProvider");
  }
  return ctx;
};

export const SidebarContextProvider = sidebarContext.Provider;
