import { twMerge } from "tailwind-merge";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function EditorFooter({
  characterCount,
  characterLimit,
}: {
  characterCount: number;
  characterLimit: number;
}) {
  return (
    <div
      className={twMerge(
        "flex h-6 items-center justify-end gap-2 pb-2 pr-2 text-sm",
        characterCount > characterLimit ? "text-red-500" : "text-shade-500"
      )}
    >
      {characterCount > characterLimit && <ExclamationTriangleIcon className="h-5 w-5" />}
      <span>
        {characterCount} / {characterLimit}
      </span>
    </div>
  );
}
