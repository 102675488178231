import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  formatDate,
  getDateFormat,
  getDayName,
  hoursMinutesToLocaleString,
  hoursMinutesToTimeString,
  parseStringToDate,
  timeAgo,
} from "./date-utils";
import { DayNameFormatOptions, FormatDateOptions } from "./date-utils.types";
import { da, de, enGB } from "date-fns/locale";

export function useLocale() {
  const { i18n } = useTranslation();
  const locale = i18n.resolvedLanguage ?? "da-DK";
  const { t } = useTranslation();
  // Always return the same object, unless locale changes
  const returns = useMemo(
    () => ({
      format: (date: Date, options?: FormatDateOptions) => formatDate(date, locale, options),
      stringToDate: (date: string, options?: FormatDateOptions) =>
        parseStringToDate(date, locale, options),
      timeAgo: (date: Date, options?: Intl.RelativeTimeFormatOptions) =>
        timeAgo(date, locale, options),
      fnsLocale: localeToLocale(locale),
      formatHours: (hours: number, options?: FormatDateOptions) => {
        // HH:MM with leading zeroes
        const h = Math.floor(hours);
        const m = Math.round((hours - h) * 60);
        let output = hoursMinutesToTimeString(h, m);
        if (options?.localeStringFormat === true) {
          // Apply HHh MMmin format. Example: (09h 30min)
          output = hoursMinutesToLocaleString(h, m, t);
        }
        return output;
      },
      dayName: (day: Date, options?: DayNameFormatOptions) => getDayName(day, locale, options),
      getDateFormat: (formatOptions?: FormatDateOptions) => getDateFormat(locale, formatOptions),
    }),
    [locale]
  );

  return returns;
}

function localeToLocale(locale?: string) {
  switch (locale) {
    case "da":
      return da;
    case "de":
      return de;
    default:
      return enGB;
  }
}
