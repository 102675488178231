import { OfferKpiCardDTO, OfferKpiCardDTOStatusEnum } from "@apacta/sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { capitalize } from "~/lib/utils/string";
import { KPICardWrapper } from "~/pages/projects/_cmp/kpi-card-wrapper";
import { FormatValue } from "~/pages/projects/_cmp/FormatValue";
import { captureMessage } from "~/lib/error-reporting";

export default function OffersKpiCards() {
  const api = useAPI();
  const { t } = useTranslation();
  /** For how many days in past show data for offer statuses: [expired, accepted, rejected] */
  const PERIOD_IN_DAYS = 90;
  const q = useSuspenseQuery({
    queryKey: ["offers-kpi-cards", "offers"],
    queryFn: () =>
      api.iGetOffersKpiCards({
        period: PERIOD_IN_DAYS,
      }),
  });

  const getTranslationKey = (key: OfferKpiCardDTOStatusEnum): string => {
    switch (key) {
      case OfferKpiCardDTOStatusEnum.Draft:
        return t(`offers:status.${OfferKpiCardDTOStatusEnum.Draft}`);
      case OfferKpiCardDTOStatusEnum.Sent:
        return t(`offers:status.${OfferKpiCardDTOStatusEnum.Sent}`);
      case OfferKpiCardDTOStatusEnum.Expired:
        return t(`offers:status.${OfferKpiCardDTOStatusEnum.Expired}`);
      case OfferKpiCardDTOStatusEnum.Accepted:
        return t(`offers:status.${OfferKpiCardDTOStatusEnum.Accepted}`);
      case OfferKpiCardDTOStatusEnum.Rejected:
        return t(`offers:status.${OfferKpiCardDTOStatusEnum.Rejected}`);
      default:
        captureMessage(`Unknown OfferKpiCardDTOStatus found: ${key}`);
        return "";
    }
  };

  const kpiCards = q.data?.data;
  return (
    <div className="grid grid-cols-5 gap-5 pb-5">
      {kpiCards.map((kpiCard: OfferKpiCardDTO, idx: number) => (
        <KPICardWrapper
          key={idx}
          label={capitalize(getTranslationKey(kpiCard.status))}
          description={
            kpiCard.period > 0
              ? t("offers:data_for_past_days", {
                  period: kpiCard.period,
                })
              : ""
          }
        >
          <div className="items-lef flex flex-row justify-start gap-x-2">
            <span className="text-xl font-bold">
              <FormatValue value={kpiCard.count} unit="number" />
            </span>
            <span className="text-md pt-0.5 text-gray-400">{t("common:offer", { count: 2 })}</span>
          </div>
          <div className="flex flex-row items-center justify-start gap-x-2">
            <span className="text-xl font-bold">
              <FormatValue value={kpiCard.amount} unit="currency" />
            </span>
          </div>
        </KPICardWrapper>
      ))}
    </div>
  );
}
