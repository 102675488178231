import { useTranslation } from "react-i18next";
import { Button, getIcon } from "../ui";

export function SessionError() {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  const handleGoHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="flex min-h-screen flex-col">
      <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="items-center text-center">
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {t("error.unexpected.title")}
            </h1>
            <p>{t("error.unexpected.subtitle")}</p>

            <div className="mt-2 flex flex-col text-base text-gray-500">
              <p>&nbsp;</p>
              <p>
                <>
                  {t("errors:unexpected.contact_support")}
                  &nbsp;
                  <strong>{t("common:customer_service_telephone")}</strong>
                </>
              </p>
            </div>

            <div className="mt-6 flex flex-row justify-center gap-8">
              <Button variant="secondary" Icon={getIcon("reload")} onClick={() => handleReload()}>
                {t("common:try_again", "Try again")}
              </Button>
              <Button variant="primary" Icon={getIcon("home")} onClick={() => handleGoHome()}>
                {t("common:go-home")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
