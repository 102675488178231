import DataViewChange, { DataViewType } from "./data-view-change";
import { ChartDataCard } from "./chart-data-card";
import ContributionChart from "./charts/contribution-chart";
import DeliveredChart from "./charts/delivered-chart";
import { useTranslation } from "react-i18next";
import { BarChartData } from "~/lib/ui/charts/stacked-bar-chart";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useToastOnError } from "~/lib/utils/hooks";
import { Spinner } from "~/lib/ui/spinner";
import { useAPI } from "~/lib/api";
import {
  FrontpageWeeklyConsumptionDTO,
  IGetConsumption200ResponseData,
  IGetConsumption200ResponseDataWeeklyConsumption,
  IGetFrontpageInvoiced200ResponseData,
  IGetFrontpageInvoiced200ResponseDataWeekly,
} from "@apacta/sdk";
import colors from "tailwindcss/colors";

type CardData = { name: string; value: number; color: `#${string}` };

const barChartColors = {
  contribution: colors["green"][500],
  hoursCost: colors["orange"][500],
  productCost: colors["blue"][500],
  notInvoiced: colors["red"][500],
  invoiced: colors["green"][500],
};

export default function FinancialOverview() {
  const [dataView, setDataView] = useState<DataViewType>("contribution");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contributionData, setContributionData] = useState<Array<BarChartData>>([]);
  const [deliveredData, setDeliveredData] = useState<Array<BarChartData>>([]);
  const [cardsToShow, setCardsToShow] = useState<Array<CardData>>([]);

  const { t } = useTranslation();

  const api = useAPI();

  const createConsumptionCards = (data: IGetConsumption200ResponseData): Array<CardData> => {
    const totalConsumption = data.totalConsumption;
    return [
      {
        name: "common:contribution",
        value: totalConsumption.contribution,
        color: barChartColors.contribution,
      },
      {
        name: "common:work_hour_usage",
        value: totalConsumption.hoursCost,
        color: barChartColors.hoursCost,
      },
      {
        name: "common:product_usage",
        value: totalConsumption.productCost,
        color: barChartColors.productCost,
      },
    ];
  };

  const createDeliveredCards = (data: IGetFrontpageInvoiced200ResponseData): Array<CardData> => {
    const totalConsumption = data.total;
    return [
      {
        name: "common:invoiced",
        value: totalConsumption.invoiced,
        color: barChartColors.invoiced,
      },
      {
        name: "common:not_invoiced",
        value: totalConsumption.notInvoiced,
        color: barChartColors.notInvoiced,
      },
    ];
  };

  const transformData = (
    data: IGetConsumption200ResponseData | IGetFrontpageInvoiced200ResponseData
  ): Array<BarChartData> => {
    let weeklyData:
      | IGetConsumption200ResponseDataWeeklyConsumption
      | IGetFrontpageInvoiced200ResponseDataWeekly;

    if ("weeklyConsumption" in data) {
      weeklyData = data.weeklyConsumption;
    } else {
      weeklyData = data.weekly;
    }

    return Object.keys(weeklyData).map((key, idx) => {
      const dataPoints: Array<FrontpageWeeklyConsumptionDTO> =
        "contribution" in weeklyData
          ? weeklyData[key as keyof IGetConsumption200ResponseDataWeeklyConsumption]
          : weeklyData[key as keyof IGetFrontpageInvoiced200ResponseDataWeekly];

      return {
        name: key,
        color: barChartColors[key as keyof typeof barChartColors],
        data: dataPoints
          .map((d) => ({
            x: d.week.toString(),
            y: d.value,
          }))
          .toReversed(),
        position: idx,
      };
    });
  };

  const { isFetching: contributionFetching, error: contributionError } = useQuery({
    queryKey: ["consumption", dataView],
    queryFn: async () => {
      const res = await api.iGetConsumption();
      setDeliveredData([]);
      setContributionData(transformData(res.data));
      setCardsToShow(createConsumptionCards(res.data));
      setIsLoading(false);
      return res;
    },
    enabled: dataView === "contribution",
  });

  const { isFetching: deliveredFetching, error: deliveredError } = useQuery({
    queryKey: ["deliveredData", dataView],
    queryFn: async () => {
      const res = await api.iGetFrontpageInvoiced();
      setContributionData([]);
      setDeliveredData(transformData(res.data));
      setCardsToShow(createDeliveredCards(res.data));
      setIsLoading(false);
      return res;
    },
    enabled: dataView === "delivered",
  });

  useToastOnError(contributionError);
  useToastOnError(deliveredError);

  const switchViewType = (view: DataViewType) => {
    if (view === dataView) return;
    setIsLoading(true);
    setDataView(view);
  };

  const showContributionData = contributionData.length > 0;
  const showDeliveredData = deliveredData.length > 0;
  const showInitialLoading =
    !deliveredData.length &&
    !contributionData.length &&
    (deliveredFetching || contributionFetching);
  const showNoData =
    !deliveredData.length &&
    !contributionData.length &&
    !deliveredFetching &&
    !contributionFetching;

  return (
    <div>
      <div className="flex flex-col gap-4">
        {!showInitialLoading && !showNoData && (
          <div className="relative flex w-full flex-col gap-2 divide-x overflow-hidden rounded-lg border bg-white shadow-md md:flex-row">
            {isLoading && (
              <div className="absolute left-0 top-0 z-popover flex h-full w-full items-center justify-center bg-white/70">
                <Spinner />
              </div>
            )}
            <div className="flex w-full shrink flex-col p-6">
              <DataViewChange defaultView={dataView} onViewChange={switchViewType} />
              {showContributionData && <ContributionChart data={contributionData} />}
              {showDeliveredData && <DeliveredChart data={deliveredData} />}
            </div>
            <div className="flex shrink-0 flex-col divide-y">
              {cardsToShow &&
                cardsToShow.map((d, idx) => (
                  <ChartDataCard
                    key={`${d.name}-${idx}`}
                    title={t(d.name)}
                    subtitle={t("common:past_x_weeks", { x: 4 })}
                    value={d.value}
                    color={d.color}
                  />
                ))}
            </div>
          </div>
        )}
        {showInitialLoading && (
          <div className="flex h-96 flex-col items-center justify-center gap-2">
            <Spinner />
            <p>{t("common:loading")}</p>
          </div>
        )}
        {showNoData && (
          <div className="flex h-96 items-center justify-center">
            <p>{t("common:no_data")}</p>
          </div>
        )}
      </div>
    </div>
  );
}
