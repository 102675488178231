import { Layout, LayoutProps, rankWith, uiTypeIs } from "@jsonforms/core";
import { JsonFormsDispatch } from "@jsonforms/react";
import { Fragment } from "react";
import { childWithAncestor } from "./childWithAncestor";

/**
 * Displaying pages. We'll be flattening this on the desktop. Mobile to be discussed.
 * @param props
 * @returns
 */

export function CategorizationRenderer(props: LayoutProps) {
  const layout = props.uischema as Layout;
  const elements = layout.elements;
  return (
    <>
      {elements.map((step, index) => {
        return (
          <Fragment key={index}>
            <div className="mb-4">
              <JsonFormsDispatch
                renderers={props.renderers}
                uischema={childWithAncestor(step, props.uischema)}
                schema={props.schema}
                path={props.path}
                enabled={true}
              />
            </div>
          </Fragment>
        );
      })}
    </>
  );
}
export const CategorizationTester = rankWith(100, uiTypeIs("Categorization"));
