import { ComponentProps, useCallback, useState } from "react";
import { Button } from "./button";

export function useButtonClick(
  onClick: ComponentProps<typeof Button>["onClick"],
  manualLoading?: boolean
) {
  const [isLoading, setIsLoading] = useState(false);

  const handler = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!onClick) return;
      setIsLoading(true);
      // This await a promise (or creates one), then set loading to false regardless of outcome
      await Promise.resolve(onClick(e)).finally(() => {
        setIsLoading(false);
      });
    },
    [onClick]
  );

  return [handler, manualLoading ?? isLoading] satisfies [typeof handler, boolean];
}
