import * as Popover from "@radix-ui/react-popover";
import { InvoiceActivityLogContent } from "./activity-log-content";
import { ReactNode } from "react";

/**
 * Popin for Invoice ActivityLog.
 *
 * Trigger is pluggable, so it can be used both for list and detail view.
 */
export function InvoiceActivityLogPopover(props: {
  invoiceId: string;
  trigger: ReactNode;
  onClose?: () => void;
}) {
  return (
    <Popover.Root
      onOpenChange={() => {
        props.onClose?.();
      }}
    >
      <Popover.Trigger>{props.trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content side="bottom" sideOffset={10}>
          <InvoiceActivityLogContent invoiceId={props.invoiceId} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
  return <p>todo</p>;
}
