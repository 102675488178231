import { ControlProps, isTimeControl, or, rankWith } from "@jsonforms/core";
import { LabelInput } from "~/lib/ui";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { useDefaultFormValues } from "./useDefaultFormValues";

// Always Danish Local Time
export function TimeInputRenderer(props: ControlProps) {
  useDefaultFormValues(props);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.currentTarget.value;
    props.handleChange(props.path, val);
  }
  return (
    <LabelInput
      type="time"
      label={getLabelForControl(props)}
      defaultValue={props.data ?? "00:00"}
      onChange={handleChange}
      required={props.required}
      error={props.errors}
      className="w-fit"
    />
  );
}
export const TimeInputTester = rankWith(4, or(isTimeControl));
