import { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react";
import { DraggableCard } from "~/pages/planning/_cmp/draggable-card";
import { dateIsWeekend, matchDates } from "~/lib/utils/date/date-utils";
import { DropZone } from "~/pages/planning/_cmp/drag/drop-zone";
import { randomId } from "~/lib/utils/string";
import { User } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { DropZoneOrientation } from "~/pages/planning/_cmp/drag/drag-drop.types";
import { DropzoneContextMenu } from "~/pages/planning/_cmp/context/dropzone-context-menu";
import { CardContextMenu } from "~/pages/planning/_cmp/context/card-context-menu";
import { usePlanning } from "~/lib/planning/use-planning";
import { Card, CardTypeEnum } from "~/lib/planning";
import { EmployeeCard } from "~/pages/planning/_cmp/employee-card";
import { twMerge } from "tailwind-merge";
import { CustomDropZone } from "~/pages/planning/_cmp/drag/custom-drop-zone";
import { Icon } from "~/lib/ui";
import { useHolidays } from "~/lib/calendar/use-holidays";
import { AddCard } from "~/pages/planning/_cmp/add-card";
import ContentLoader from "react-content-loader";
import CardTooltip from "~/pages/planning/_cmp/card-tooltip";

type EmployeeSectionProps = {
  user?: User;
  cards: Array<Card>;
  loading: boolean;
  viewDates: Array<Date>;
  initialCollapsed?: boolean;
  isCollapsed?: boolean;
  isDragging: boolean;
};

type EmployeeSectionRef = {
  collapse: () => void;
  expand: () => void;
};

const PlanningSectionMultipleDays = forwardRef<EmployeeSectionRef, EmployeeSectionProps>(
  (
    {
      user,
      cards,
      loading,
      viewDates,
      initialCollapsed = false,
      isDragging,
      isCollapsed,
    }: EmployeeSectionProps,
    ref
  ) => {
    const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);

    const { t } = useTranslation();

    const { isLoadingCards, cardCanPaste, modalCreate, updateCard, cardGetTask, setSelectedCard } =
      usePlanning();

    const { isHoliday } = useHolidays(viewDates[0]);

    useImperativeHandle(ref, () => ({
      collapse(): void {
        setCollapsed(true);
      },
      expand(): void {
        setCollapsed(false);
      },
    }));

    useEffect(() => {
      if (isCollapsed !== undefined) {
        setCollapsed(isCollapsed);
      }
    }, [isCollapsed]);

    const handleDrop = (card: Card, details: { index: number; date: Date }) => {
      if (
        card.listIndex === details.index &&
        card.date &&
        matchDates(card.date, details.date, { ignoreTimestamp: true }) &&
        card.userId === user?.id
      ) {
        return;
      }

      let startTime: Date | null = null;
      if (card.startTime) {
        startTime = new Date(details.date);
        startTime.setHours(card.startTime.getHours());
        startTime.setMinutes(card.startTime.getMinutes());
      }

      const updatedCard = {
        ...card,
        listIndex: details.index,
        date: details.date,
        startTime,
        userId: user?.id || null,
        type: CardTypeEnum.Task,
      };

      const updatedTask = {};

      updateCard(updatedCard, updatedTask, false);
    };

    const calculateWeeklyEstimates = (): number => {
      return cards.reduce((sum: number, card) => (sum += card.estimate || 0), 0);
    };

    const cardsByDay = (date: Date): number => {
      return cards.filter(
        (card) => card.date && matchDates(card.date, date, { ignoreTimestamp: true })
      ).length;
    };

    const getNextIndex = (d: Date): number => {
      return cards.filter(
        (card) => card.date && matchDates(card.date, d, { ignoreTimestamp: true })
      ).length;
    };

    // Currently showing project name as card name
    const getCardName = (card: Card) => {
      const task = cardGetTask({ card });
      if (task) {
        return task?.project ? `${task.project.name}` : t("planning:card.task_name_missing");
      }
      return t("planning:card.task_name_missing");
    };

    return (
      <div className={twMerge("flex w-fit transition-height duration-500")}>
        <div className="sticky left-0 bg-gray-100 pb-4 pr-2">
          <EmployeeCard
            user={user}
            collapsed={collapsed}
            allowCollapse={true}
            onTriggerCollapse={(val) => setCollapsed(val)}
            totalEstimates={calculateWeeklyEstimates()}
          />
        </div>

        <div className={twMerge("flex flex-col pb-4")}>
          {!collapsed && (
            <div className="flex border border-r-0">
              {viewDates &&
                viewDates.map((date, index) => (
                  <div
                    key={`drop-${index}`}
                    className={twMerge(
                      "group flex min-h-8 w-40 flex-col items-start border-r",
                      isHoliday(date)
                        ? "bg-yellow-50"
                        : dateIsWeekend(date)
                          ? "bg-gray-200/50"
                          : "bg-white"
                    )}
                  >
                    {(isLoadingCards || loading) && (
                      <div className="relative flex h-full w-full items-center justify-center">
                        <ContentLoader className={twMerge("h-6 w-auto px-2")}>
                          <rect x={0} y={0} width="100%" height="100%"></rect>
                        </ContentLoader>
                      </div>
                    )}
                    {!isLoadingCards &&
                      !loading &&
                      cards
                        .filter(
                          (card) =>
                            card.date && matchDates(card.date, date, { ignoreTimestamp: true })
                        )
                        .map((sectionCard, idx, cc) => (
                          <div
                            key={`card-${sectionCard.id}-${idx}`}
                            className={twMerge(
                              "flex flex-col",
                              idx === cc.length - 1 && isDragging && "grow"
                            )}
                          >
                            <CardContextMenu card={sectionCard} canPaste={cardCanPaste}>
                              <DraggableCard
                                id={sectionCard.id}
                                title={getCardName(sectionCard)}
                                tooltip={<CardTooltip card={sectionCard} />}
                                date={sectionCard.date}
                                startTime={sectionCard.startTime}
                                estimate={sectionCard.estimate}
                                listIndex={sectionCard.listIndex}
                                userId={sectionCard.userId}
                                isCollapsed={collapsed && !!user}
                                onClick={() => setSelectedCard(sectionCard)}
                                labels={cardGetTask({ card: sectionCard })?.labels}
                                onDrop={(card: Card, details: { index: number }) =>
                                  handleDrop(card, { index: details.index, date })
                                }
                              />
                            </CardContextMenu>
                            {idx === cc.length - 1 && isDragging && (
                              <DropZone
                                key={randomId()}
                                isDragging={isDragging}
                                index={getNextIndex(date)}
                                isLast={true}
                                onDrop={(card: Card, details: { index: number }) =>
                                  handleDrop(card, { index: details.index, date })
                                }
                                orientation={DropZoneOrientation.VERTICAL}
                                isCollapsed={collapsed && !!user}
                              />
                            )}
                          </div>
                        ))}

                    {!isLoadingCards && !loading && (
                      <DropzoneContextMenu
                        userId={user?.id || null}
                        index={getNextIndex(date)}
                        date={date}
                        canPaste={cardCanPaste} // if copied element exist
                        className="flex w-full flex-grow flex-col"
                      >
                        <AddCard
                          isDragging={isDragging}
                          onClick={() =>
                            modalCreate({ userId: user?.id, index: getNextIndex(date), date })
                          }
                        />
                      </DropzoneContextMenu>
                    )}
                    {!isLoadingCards &&
                      !loading &&
                      cards.filter(
                        (card) =>
                          card.date && matchDates(card.date, date, { ignoreTimestamp: true })
                      ).length === 0 && (
                        <Fragment>
                          <DropzoneContextMenu
                            userId={user?.id || null}
                            index={0}
                            date={date}
                            canPaste={cardCanPaste} // if copied element exist
                            className="flex w-full flex-grow flex-col"
                          >
                            <>
                              <DropZone
                                key={randomId()}
                                isDragging={isDragging}
                                index={0}
                                isLast={true}
                                onDrop={(card: Card, details: { index: number }) =>
                                  handleDrop(card, { index: details.index, date })
                                }
                                orientation={DropZoneOrientation.VERTICAL}
                                isCollapsed={collapsed && !!user}
                              />
                            </>
                          </DropzoneContextMenu>
                        </Fragment>
                      )}
                  </div>
                ))}
            </div>
          )}
          {collapsed && (
            <div className={twMerge("flex border border-r-0")}>
              {viewDates &&
                viewDates.map((date, idx) => (
                  <CustomDropZone
                    key={`summarized-tasks-${idx}`}
                    index={getNextIndex(date)}
                    onDrop={(card: Card, details: { index: number }) =>
                      handleDrop(card, { index: details.index, date })
                    }
                  >
                    {(isOver) => (
                      <div
                        className={twMerge(
                          "group flex w-40 flex-grow items-center justify-center border-r",
                          dateIsWeekend(date) ? "bg-gray-200/50" : "bg-white",
                          isOver ? "ring-2 ring-inset ring-hover" : ""
                        )}
                      >
                        {!isLoadingCards && !loading && (
                          <div className="flex h-8 w-full items-center justify-between px-3">
                            <span
                              className={cardsByDay(date) > 0 ? "font-semibold" : "text-shade-400"}
                            >
                              {`${cardsByDay(date)} ${t("common:task", {
                                count: cardsByDay(date),
                              }).toLowerCase()}`}
                            </span>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                modalCreate({ userId: user?.id, date });
                              }}
                              className="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-shade-200 hover:bg-shade-300"
                            >
                              <Icon name="add" className="h-3 w-3" />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </CustomDropZone>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }
);

PlanningSectionMultipleDays.displayName = "EmployeeSection";

export { PlanningSectionMultipleDays };
