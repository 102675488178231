import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";
import { Spinner } from "../spinner";

type FullScreenFilePreviewFileProps = {
  file?: Blob;
  fileUrl?: undefined;
  open: boolean;
  onClose: () => void;
};

type FullScreenFilePreviewFileUrlProps = {
  file?: undefined;
  fileUrl?: string;
  open: boolean;
  onClose: () => void;
};

/**
 * Preview a file in a full-screen modal with a download link
 * Please only use this for files and try not to add state to it
 *
 * Note that if no file is provided, the download button will not work
 * as such, it is hidden. - You can still download the file from the embedded viewer
 * if it supports it.
 *
 * @version 0.0.1 - @aos 2024-03-05 initial
 * @version 0.0.2 - @lah 2024-03-08 added open and fileUrl prop to make this work with all files in our system
 * @param file - The file to preview, use either this or fileUrl
 * @param fileUrl - The file URL to preview, use either this or file
 * @param open - Whether the modal is open or closed
 * @param onClose - Function to run when the modal is closed
 */
export function FullScreenFilePreview({
  file,
  fileUrl,
  open,
  onClose,
}: FullScreenFilePreviewFileProps | FullScreenFilePreviewFileUrlProps) {
  const [embedURL, setEmbedURL] = useState<string | undefined>(fileUrl);
  const { isBreakpoint } = useBreakpoints();
  const [loading, setLoading] = useState(true);
  const isMobile = isBreakpoint("sm");

  // Create (and cleanup) object URL for file
  useEffect(() => {
    if (!file) return; // Don't run on closed state

    setEmbedURL(URL.createObjectURL(file));

    return () => {
      // This prevents memory leaks in the browser from createObjectURL
      if (embedURL) {
        URL.revokeObjectURL(embedURL);
      }
    };
  }, [file]);

  useEffect(() => {
    if ((!file && !fileUrl) || !embedURL || !open) return; // Don't run on closed state

    if (isMobile && open) {
      // I wanted this to use a new tab, but Mobile Safari is blocking it
      window.location.href = embedURL;
    }
  }, [embedURL, open]);

  useEffect(() => {
    if (fileUrl !== embedURL) {
      setEmbedURL(fileUrl);
    }
  }, [fileUrl]);

  function handleLoadingState() {
    setLoading(false);
  }

  function handleClose() {
    onClose();
    setLoading(true);
  }

  // Do not even open the dialog if there is no embedURL or if we are on mobile
  return (
    <DialogPrimitive.Root onOpenChange={handleClose} open={open && !!embedURL && !isMobile}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="fixed inset-0  bg-black/30 data-[state=closed]:animate-fade-out data-[state=open]:animate-fade-in" />
        <DialogPrimitive.Content className="fixed left-[50%] top-[50%] flex max-h-[95%] w-[90vw] max-w-fit translate-x-[-50%] translate-y-[-50%] items-center justify-center overflow-hidden px-8 focus:outline-none data-[state=closed]:animate-contentHide data-[state=open]:animate-contentShow ">
          <div className="planning-scrollbar z-popover h-[95vh] w-[80vw]">
            <div
              className={twMerge(
                "hidden h-full w-full items-center justify-center bg-shade-800",
                loading && "flex"
              )}
            >
              <Spinner />
            </div>
            <embed onLoad={handleLoadingState} src={embedURL} className="h-full w-full" />
          </div>
          <div className="fixed right-0 top-0 flex flex-col gap-2">
            <DialogPrimitive.Close asChild>
              <button
                className="h-6 w-6 appearance-none rounded-full bg-shade-200 text-shade-900 focus:outline-none"
                aria-label="Close"
              >
                <div className="flex items-center justify-center">
                  <Icon name="close" />
                </div>
              </button>
            </DialogPrimitive.Close>
            <a
              className="flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-shade-200 text-shade-900 focus:outline-none"
              aria-label="Download"
              href={file ? embedURL : fileUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="download" />
            </a>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}
