import { ContactPerson } from "@apacta/sdk";
import { ContactCard } from "~/pages/customers/_cmp/customer-tabs/contacts/contact-card";

export function ContactList({
  contacts,
  onDelete,
  onEdit,
}: {
  contacts: Array<ContactPerson>;
  onDelete: (contact: ContactPerson) => void;
  onEdit: (contact: ContactPerson) => void;
}) {
  return (
    <>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
      >
        {contacts.map((contact: ContactPerson) => (
          <ContactCard
            key={`${contact.id}`}
            contact={contact}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </ul>
    </>
  );
}
