import { Fragment, ReactNode, Suspense } from "react";
import { useTranslation } from "react-i18next";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Button, Icon } from "~/lib/ui";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import Lozenge from "~/lib/ui/data-table/filters/filter-components/lozenge";

export type DataFilterItem = {
  identifier: string;
  lozenge: ({ value }: { value: unknown }) => ReactNode;
  render: () => ReactNode;
};

export const DataFilter = ({ filters }: { filters: Array<DataFilterItem> }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useTypedSearchParams<Record<string, any>>();

  const filterKeys = filters.map((filter) => filter.identifier);
  const handleRemoveAllFilters = () => {
    filterKeys.forEach((key) => setSearchParams(key, undefined));
  };

  const countFiltersApplied = filterKeys.filter((key) => searchParams[key] !== undefined).length;

  return (
    <div className="flex flex-col items-start gap-4">
      <TableFilterDropdown
        filters={filters}
        onRemoveAll={handleRemoveAllFilters}
      ></TableFilterDropdown>
      <div className="flex flex-wrap gap-2">
        {filters.map((filter) => (
          <Fragment key={filter.identifier}>
            <TableFilterLozenge filter={filter} />
          </Fragment>
        ))}
        {countFiltersApplied > 0 && (
          <Lozenge
            label={`${t("common:clear")} ${t("common:filter", { count: countFiltersApplied }).toLowerCase()}`}
            iconName="remove"
            variant="red"
            onClick={handleRemoveAllFilters}
          />
        )}
      </div>
    </div>
  );
};

const TableFilterLozenge = ({ filter }: { filter: DataFilterItem }) => {
  const [searchParams] = useTypedSearchParams<Record<string, any>>();

  const data: Array<unknown> = (
    Array.isArray(searchParams[filter.identifier])
      ? searchParams[filter.identifier]
      : [searchParams[filter.identifier]]
  ).filter((value: unknown) => value !== undefined);

  if (!data.length) return null;

  return (
    <>
      {data.map((value, idx) => (
        <Fragment key={`${filter.identifier}-${idx}`}>
          <Suspense>
            <filter.lozenge value={value} />
          </Suspense>
        </Fragment>
      ))}
    </>
  );
};

const TableFilterDropdown = ({
  filters,
  onRemoveAll,
}: {
  filters: Array<DataFilterItem>;
  onRemoveAll: () => void;
}) => {
  const { t } = useTranslation();
  const [searchParams] = useTypedSearchParams<Record<string, any>>();

  const filterKeys = filters.map((filter) => filter.identifier);
  const hasAnyFiltersApplied = filterKeys.some((key) => searchParams[key] !== undefined);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button variant="secondary" className="shrink-0 grow-0">
          <Icon name="filter" size="small" />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={0}
          side="right"
          align="start"
          className="animate-slideLeftAndFade"
        >
          <div className="min-w-64 rounded-lg border bg-white p-1.5 shadow-md">
            <DropdownMenu.Label className="flex justify-between pl-1.5 text-xs font-semibold leading-6">
              <div>{t("common:filters")}</div>
              {hasAnyFiltersApplied && (
                <div
                  onClick={onRemoveAll}
                  className="flex cursor-pointer items-center gap-1 rounded-md px-1.5 font-normal hover:bg-shade-100"
                >
                  <Icon name="remove" />
                  <span>{`${t("common:clear")} ${t("common:all_nonspecific").toLowerCase()}`}</span>
                </div>
              )}
            </DropdownMenu.Label>
            <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />

            {filters.map((filter, idx) => (
              <Fragment key={filter.render.name}>
                {idx !== 0 && <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />}
                <filter.render />
              </Fragment>
            ))}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
