import { Icon, IconName } from "~/lib/ui";
import { twMerge } from "tailwind-merge";

type LozengeVariant = "red" | "yellow" | "orange" | "green" | "blue" | "purple" | "gray";

const lozengeColors = {
  red: {
    bg: "bg-red-50",
    text: "text-red-800",
    border: "border-red-200",
    hoverBorder: "hover:border-red-400",
  },
  yellow: {
    bg: "bg-yellow-50",
    text: "text-yellow-800",
    border: "border-yellow-200",
    hoverBorder: "hover:border-yellow-400",
  },
  orange: {
    bg: "bg-orange-50",
    text: "text-orange-800",
    border: "border-orange-200",
    hoverBorder: "hover:border-orange-400",
  },
  green: {
    bg: "bg-green-50",
    text: "text-green-800",
    border: "border-green-200",
    hoverBorder: "hover:border-green-400",
  },
  blue: {
    bg: "bg-blue-50",
    text: "text-blue-800",
    border: "border-blue-200",
    hoverBorder: "hover:border-blue-400",
  },
  purple: {
    bg: "bg-purple-50",
    text: "text-purple-800",
    border: "border-purple-200",
    hoverBorder: "hover:border-purple-400",
  },
  gray: {
    bg: "bg-gray-50",
    text: "text-gray-800",
    border: "border-gray-200",
    hoverBorder: "hover:border-gray-400",
  },
};

const lozengeSizes = {
  small: {
    buttonSize: "h-4 w-4",
    iconSize: "h-3 w-3",
    px: "px-2",
    py: "py-1",
  },
  medium: {
    buttonSize: "h-5 w-5",
    iconSize: "h-4 w-4",
    px: "px-3",
    py: "py-2",
  },
};

export default function Lozenge({
  label,
  value,
  onClick,
  onRemove,
  iconName,
  variant = "gray",
  size = "medium",
}: {
  label: string;
  value?: string;
  onClick?: () => void;
  onRemove?: () => void;
  iconName?: IconName;
  variant?: LozengeVariant;
  size?: "small" | "medium";
}) {
  return (
    <span
      onClick={onClick}
      className={twMerge(
        "0 inline-flex items-center justify-start overflow-hidden rounded-md border bg-white text-xs font-medium",
        onClick && "cursor-pointer",
        lozengeColors[variant].hoverBorder
      )}
    >
      {!!iconName && (
        <div
          className={twMerge(
            "flex h-full items-center gap-2 border border-b-0 border-l-0 border-t-0",
            !value && "border-r-0",
            lozengeColors[variant].bg,
            lozengeColors[variant].border,
            lozengeColors[variant].text,
            lozengeSizes[size].px,
            lozengeSizes[size].py
          )}
        >
          <Icon name={iconName} className={lozengeSizes[size].iconSize} />
          <span>{label}</span>
        </div>
      )}
      {!!value && (
        <div
          className={twMerge(
            "flex items-center gap-3",
            lozengeSizes[size].px,
            lozengeSizes[size].py
          )}
        >
          <span>{value}</span>
          <button
            type="button"
            onClick={onRemove}
            className={twMerge(
              "flex items-center justify-center rounded-full hover:bg-gray-500/20",
              lozengeSizes[size].buttonSize
            )}
          >
            <span className="sr-only">Remove</span>
            <Icon name="close" className={lozengeSizes[size].iconSize} />
          </button>
        </div>
      )}
    </span>
  );
}
