import { useRef, useState } from "react";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { useMount } from "~/lib/lifecycle-helpers";
import { usePlanning } from "~/lib/planning";
import { dateInRange } from "~/lib/utils/date/date-utils";
import { ScrollLayer } from "./drag/scroll-layer";
import { PlanningSectionDay } from "./planning-section-day";
import { useOutletContext } from "react-router";
import { OutletContext } from "./types";
import { twMerge } from "tailwind-merge";

export function PlanningViewSingleDay() {
  const { isDragging } = useOutletContext<OutletContext>();
  const [collapsed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { isLoadingCards, users, cardStateViews, dateRange, selectedDate } = usePlanning();

  useMount(() => {
    setLoading(true);
  });

  // Fix flickering when switching between days that have cards, and days that do not
  useDebounce(
    () => {
      if (!isLoadingCards) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    },
    [
      cardStateViews.plannedUnassigned,
      cardStateViews.plannedAssigned,
      selectedDate,
      isLoadingCards,
    ],
    1
  );

  const boundingRef = useRef<HTMLDivElement | null>(null);

  const matchDate = (date?: Date | null, from?: Date, to?: Date): boolean => {
    if (date && from && to) {
      return dateInRange(date, from, to, { ignoreTimestamp: true });
    }

    return false;
  };

  return (
    <div
      ref={boundingRef}
      className={twMerge(
        "planning-scrollbar relative flex flex-grow flex-col gap-4 overflow-y-auto overflow-x-hidden scroll-smooth pb-4"
      )}
    >
      <ScrollLayer boundingRef={boundingRef} isDragging={isDragging} disableHorizontal={true} />
      <PlanningSectionDay
        key="unassigned-day"
        cards={cardStateViews.plannedUnassigned.filter((card) =>
          matchDate(card.date, dateRange?.start, dateRange?.end)
        )}
        loading={loading}
        initialCollapsed={collapsed}
        isDragging={isDragging}
      />
      {users.map((user) => (
        <PlanningSectionDay
          key={`employee-day-${user.id}`}
          cards={cardStateViews.plannedAssigned.filter(
            (card) =>
              card.userId === user.id && matchDate(card.date, dateRange?.start, dateRange?.end)
          )}
          loading={loading}
          user={user}
          initialCollapsed={collapsed}
          isDragging={isDragging}
        />
      ))}
    </div>
  );
}
