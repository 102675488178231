import { UserAvatar } from "~/lib/ui/avatar/user-avatar";
import { useEmployees } from "~/lib/employees/use-employees";

export default function UserIdAvatar({
  userId,
  className,
}: {
  userId: string;
  className?: string;
}) {
  const { employees } = useEmployees({ requestOverride: { ids: [userId] } });

  return <UserAvatar className={className} user={employees[0]} />;
}
