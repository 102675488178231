import { twMerge } from "tailwind-merge";
import { formatCurrency } from "~/lib/utils/number";
import { calculateLineTotal } from "~/lib/ui/order-lines/lib/calculations";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";

export function OrderLinesBuilderTotalPart({
  line,
  editMode,
  parentId,
}: {
  line: Partial<OrderLine>;
  editMode: boolean;
  parentId?: string | null;
}) {
  const lineTotal = calculateLineTotal(line);

  return (
    <div
      className={twMerge(
        "flex flex-grow justify-end text-zinc-900",
        editMode ? "px-3 pb-5 pt-7" : "px-3 py-5"
      )}
    >
      {!parentId ? (lineTotal ? formatCurrency(lineTotal, { currency: "DKK" }) : "") : ""}
    </div>
  );
}
