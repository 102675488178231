import { Popover, PopoverAlign, PopoverSide } from "~/lib/ui/popover/popover";
import { FilterList } from "~/lib/ui/form-elements/filter-list";
import TextInput from "~/lib/ui/form-elements/text-input";
import { twMerge } from "tailwind-merge";
import { CheckIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import {
  useCategoryLabels,
  UseCategoryLabelsEntity,
} from "~/lib/ui/category-label/use-category-labels";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { useTranslation } from "react-i18next";
import { LabelEntity } from "@apacta/sdk";
import { Button } from "~/lib/ui";

type CategoryLabelSelectorProps = {
  triggerRender: () => ReactNode;
  initialSelectedLabels?: Array<LabelEntity>;
  onOpenChange?: (open: boolean) => void;
  onSelect?: (labels: Array<LabelEntity>) => void;
  entity?: UseCategoryLabelsEntity;
  options?: {
    multiselect?: boolean;
    side?: PopoverSide;
    sideOffset?: number;
    align?: PopoverAlign;
    alignOffset?: number;
  };
};

export function CategoryLabelPopover({
  triggerRender,
  initialSelectedLabels,
  onOpenChange,
  onSelect,
  options,
  entity,
}: CategoryLabelSelectorProps) {
  const defaultEmployeePopoverOptions = {
    multiselect: true,
    side: "right" as PopoverSide,
    sideOffset: 0,
    align: "center" as PopoverAlign,
    alignOffset: 0,
  };

  const opts = { ...defaultEmployeePopoverOptions, ...options };
  const { t } = useTranslation();
  const { labels, isLoadingLabels, setSearchQuery } = useCategoryLabels(entity);

  const [labelSearchInput, setLabelSearchInput] = useState<string>("");

  useDebounce(
    () => {
      setSearchQuery(labelSearchInput);
    },
    [labelSearchInput],
    500
  );

  const handleOpenChange = (open: boolean) => {
    onOpenChange?.(open);
  };

  const handleSelectLabels = (labelList: Array<LabelEntity>, callback: () => void) => {
    onSelect?.(labelList);
    callback();
  };

  return (
    <Popover
      onOpenChange={handleOpenChange}
      config={{
        side: opts.side,
        sideOffset: opts.sideOffset,
        align: opts.align,
        alignOffset: opts.alignOffset,
        usePortal: false,
      }}
      triggerRender={triggerRender}
      triggerAsChild={true}
    >
      {(close) => (
        <div className="flex h-96 min-w-[18em] flex-shrink-0 flex-col gap-2 overflow-hidden rounded-md border bg-white p-2 shadow-sm">
          <FilterList
            isLoading={isLoadingLabels}
            filterRender={() => (
              <div className="flex">
                <TextInput
                  id="label-search"
                  label=""
                  placeholder={`${t("common:search")} ${t("common:label", {
                    count: 2,
                  }).toLowerCase()}`}
                  onChange={(value) => setLabelSearchInput(value)}
                />
              </div>
            )}
            itemRender={({ item, isSelected, triggerSelect }) => (
              <div
                key={`label-${item.id}`}
                className={twMerge(
                  "flex cursor-pointer items-center justify-between rounded-md px-2 py-2 hover:bg-gray-100"
                )}
                onClick={() =>
                  opts.multiselect ? triggerSelect() : handleSelectLabels([item], close)
                }
              >
                <div className="flex gap-2">
                  <UICategoryLabel id={item.id} text={item.text} bgColor={item.bgColor} readonly />
                </div>
                <div className="ml-4">
                  <CheckIcon className={twMerge("h-5 w-5", !isSelected && "invisible")} />
                </div>
              </div>
            )}
            buttonRender={(list) => (
              <div className="flex items-center justify-end gap-6">
                <span
                  className="cursor-pointer justify-center underline hover:text-hover"
                  onClick={() => close()}
                >
                  {t("common:cancel")}
                </span>
                {opts.multiselect && (
                  <Button
                    variant="primary"
                    className="justify-center"
                    onClick={() => handleSelectLabels(list, close)}
                  >
                    {t("common:confirm")}
                  </Button>
                )}
              </div>
            )}
            items={labels}
            initialSelected={initialSelectedLabels}
          />
        </div>
      )}
    </Popover>
  );
}
