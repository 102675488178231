import { formatCurrency } from "~/lib/utils/number";
import { forwardRef, HTMLProps, Ref } from "react";
import { twMerge } from "tailwind-merge";

/**
 * ChartDataCard - A card to display the totals of the data used in the frontpage charts
 * It will display a card using 1/3 of the available space as maximum number of cards to be shown is 3
 * @param title - The title of the card
 * @param subtitle - The subtitle of the card
 * @param value - The value to be displayed and formatted as currency
 * @param color - The hex color code of the color to be used for the value
 */
export const ChartDataCard = forwardRef(function innerChartDataCard(
  props: HTMLProps<HTMLDivElement> & {
    title: string;
    subtitle: string;
    value: number;
    color: `#${string}`;
  },
  ref: Ref<HTMLDivElement>
) {
  const { className, title, subtitle, color, value, ...restProps } = props;

  return (
    <div
      ref={ref}
      {...restProps}
      className={twMerge(
        "relative flex w-full shrink-0 basis-1/3 flex-col items-center justify-center gap-2 overflow-hidden bg-white px-12 py-6 md:items-start",
        className
      )}
    >
      <div className="flex w-full flex-col">
        <div className="text-center text-xl">{title}</div>
        <div className="text-center text-sm">{subtitle}</div>
      </div>
      <div className="flex w-full items-center justify-center">
        <div className="text-center text-2xl font-semibold" style={{ color }}>
          {formatCurrency(value)}
        </div>
      </div>
    </div>
  );
});
