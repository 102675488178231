import {
  ControlElement,
  isControl,
  JsonSchema,
  Tester,
  TesterContext,
  UISchemaElement,
  uiTypeIs,
} from "@jsonforms/core";

export const isDecendantOf =
  (ancestor: string): Tester =>
  (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext): boolean => {
    const elAncestor = uischema?.options?.["x-ancestor"];
    if (elAncestor === ancestor) return true;
    return false;
  };

export const isEntityOf = (entity: string): Tester => {
  return (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext): boolean => {
    const elEntity = uischema?.options?.["x-entity"];
    if (elEntity === entity) return true;
    return false;
  };
};

export const isControlEntity =
  (expected: string): Tester =>
  (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext): boolean => {
    const isControlEl = uiTypeIs("Control")(uischema, schema, context);
    const isExpected = uischema.options ? uischema.options["x-entity"] === expected : false;

    return isControlEl && isExpected;
  };

export const isProductEntity =
  (): Tester =>
  (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext): boolean => {
    if (!isControl(uischema)) return false;
    const el = uischema as ControlElement;

    // Note: I'd really prefer to use the `x-entity` property here, but it's not available in the `uischema` object.
    if (el?.scope === "#/properties/product_id") return true;
    return false;
  };

export const isProductUnitEntity =
  (): Tester =>
  (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext): boolean => {
    if (!isControl(uischema)) return false;
    const el = uischema as ControlElement;

    // Note: I'd really prefer to use the `x-entity` property here, but it's not available in the `uischema` object.
    if (el?.scope === "#/properties/product_unit") return true;
    return false;
  };
