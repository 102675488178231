import { Icon } from "~/lib/ui";
import { OfferAction } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export const SendFailIcon = () => {
  return (
    <div className="rounded-full bg-red-600 p-1 text-white">
      <Icon name="send" size="small" />
    </div>
  );
};

export const SendFailText = ({ action }: { action: OfferAction }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-sm">
        {t(`offers:activity_log.${action.action}`)}
        {` ${t("common:to").toLowerCase()} `}
        <span className="text-sm font-semibold">{action.email?.recipients}</span>
      </div>
    </div>
  );
};
