import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { FormTemplateSimpleEntity } from "@apacta/sdk";

export default function FormRender({
  isLoading,
  selectedFormTemplates,
  onRemove,
}: {
  isLoading: boolean;
  selectedFormTemplates: Array<FormTemplateSimpleEntity>;
  onRemove: (template: FormTemplateSimpleEntity) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="planning-scrollbar ml-11 flex max-h-52 flex-wrap gap-4 overflow-y-auto">
      {isLoading && (
        <ContentLoader className={twMerge("h-6 w-32")}>
          <rect x={0} y={0} width="80vw" height="100vh"></rect>
        </ContentLoader>
      )}
      {selectedFormTemplates.length ? (
        selectedFormTemplates.map((template) => (
          <div
            key={template.id}
            className="relative flex w-64 flex-col gap-2 rounded-lg border bg-white py-2 pl-3 pr-8 text-sm shadow-sm"
          >
            <div
              onClick={() => onRemove(template)}
              className="absolute right-1 top-1 cursor-pointer rounded-full p-0.5 hover:bg-shade-200"
            >
              <Icon name="close" className="h-4 w-4" />
            </div>
            <div title={template.name} className="line-clamp-1 font-semibold">
              {template.name}
            </div>
            <div className="line-clamp-2">
              {template.description ? (
                <span>{template.description}</span>
              ) : (
                <span className="text-shade-400">
                  {t("common:no_entity_found", { entity: t("common:description").toLowerCase() })}
                </span>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="px-3 text-shade-400">
          {t("common:no_entity_added", {
            entity: t("planning:form", { count: 2 }).toLowerCase(),
          })}
        </div>
      )}
    </div>
  );
}
