import { BaseFormElementProps } from "~/lib/ui/form-elements/props";
import { forwardRef, Ref, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Label } from "../label";

type TextareaProps = BaseFormElementProps & { fullHeight?: boolean; autoHeight?: boolean };

/**
 * Note: Setting ref here will disable the autoHeight feature
 */
export const TextArea = forwardRef(function TextArea(
  {
    className,
    id,
    label,
    name,
    initialValue,
    onChange,
    fullHeight,
    autoHeight,
    required,
    placeholder,
  }: TextareaProps,
  ref: Ref<HTMLTextAreaElement>
) {
  const [value, setValue] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
    onChange?.(e.currentTarget.value);
  };

  useEffect(() => {
    if (textareaRef.current && autoHeight) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = (scrollHeight < 40 ? 40 : scrollHeight) + "px";
    }
  }, [value]);

  return (
    <div className={twMerge("flex min-h-full flex-grow flex-col text-left", className)}>
      {!!label && (
        <Label htmlFor={id} required={required}>
          {label}
        </Label>
      )}
      <div className={twMerge(fullHeight ? "flex flex-grow" : "mt-1")}>
        <textarea
          ref={ref ?? textareaRef}
          placeholder={placeholder}
          name={name}
          id={id}
          style={{ filter: "blur(.0)" }} // https://stackoverflow.com/a/43138551 - to prevent blurry text
          className={twMerge(
            "block w-full resize-none rounded-md border-gray-300 shadow-sm invalid:border-red-500 focus:border-hover focus:outline-none focus:ring-transparent sm:text-sm",
            autoHeight ? "scrollbar-hide line !leading-6" : "",
            fullHeight ? "h-full" : "h-32 sm:h-64"
          )}
          defaultValue={initialValue || ""}
          onChange={(e) => handleOnChange(e)}
        />
      </div>
    </div>
  );
});

export default TextArea;
