import DocIcon from "~/lib/ui/icons/doc-icon";
import PdfIcon from "~/lib/ui/icons/pdf-icon";
import XlsIcon from "~/lib/ui/icons/xls-icon";
import FileIcon from "~/lib/ui/icons/file-icon";
import { createElement } from "react";
import VideoIcon from "~/lib/ui/icons/video-icon";

export default function MimeIcon({
  mime,
  className,
}: {
  mime?: string | null;
  className?: string;
}) {
  switch (mime) {
    case "application/msword":
    case "application/rtf":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.sun.xml.writer":
    case "application/vnd.sun.xml.writer.global":
    case "application/vnd.sun.xml.writer.template":
    case "application/vnd.oasis.opendocument.text":
    case "text/plain":
    case "text/richtext":
    case "text/rtf":
      return createElement(DocIcon, { className });
    case "application/pdf":
      return createElement(PdfIcon, { className });
    case "application/ms-excel":
    case "application/msexcel":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.sun.xml.calc":
    case "application/vnd.sun.xml.calc.template":
    case "application/vnd.oasis.opendocument.spreadsheet":
    case "excel/*":
      return createElement(XlsIcon, { className });
    case "video/3gpp":
    case "video/3gpp2":
    case "video/avi":
    case "video/mpeg":
    case "video/x-mpeg":
    case "video/mp4":
    case "video/msvideo":
    case "video/x-msvideo":
    case "video/quicktime":
    case "video/ogg":
    case "video/*":
      return createElement(VideoIcon, { className });
    default:
      return createElement(FileIcon, { className });
  }
}
