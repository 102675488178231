import { ChangeEvent, FocusEvent, forwardRef, KeyboardEvent, Ref, useId } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { IconProp } from "../types";
import { twMerge } from "tailwind-merge";
import { Label } from "../label";

export type InputType =
  | "email"
  | "hidden"
  | "number"
  | "password"
  | "search"
  | "text"
  | "url"
  | "week";

interface ITextInputProps {
  type?: InputType;
  textAlign?: "left" | "center" | "right";
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
  hasButton?: boolean;
  Icon?: IconProp;
  ref?: Ref<HTMLInputElement>;
  label?: string;
  name?: string;
  id?: string /** optional id, otherwise one is created automatically */;
  wrapperClassName?: string;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onClick?: () => void;
  onIconClick?: () => void;
  onChange?: (s: string) => void;
  onKeyDown?: (s: string, e?: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (s: string, e?: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (s: string, e?: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (s: string) => void;
  onSubmit?: (s: string) => void;
  onReset?: (s: string) => void;
}

type UncontrolledInput = ITextInputProps & {
  defaultValue?: string | number;
  value?: never;
  controlled?: never;
};
type ControlledInput = ITextInputProps & {
  defaultValue?: never;
  controlled: boolean /** Input value is now manually controlled and must be set */;
  value?: string | number;
};

export type TextInputProps = UncontrolledInput | ControlledInput;

/**
 * @deprecated Use `LabelInput` instead. This component will be removed in the future.
 */
export const TextInput = forwardRef(function TextInputInner(
  {
    id,
    name,
    label,
    placeholder,
    Icon,
    onClick,
    onIconClick,
    onFocus,
    onBlur,
    required,
    disabled,
    readonly,
    wrapperClassName,
    className,
    controlled,
    hasButton,
    type = "text",
    defaultValue,
    value,
    errorMessage,
    onChange,
    onSubmit,
    onKeyDown,
    onKeyUp,
    textAlign = "left",
    size = "sm",
  }: TextInputProps,
  ref: Ref<HTMLInputElement>
) {
  if (defaultValue !== undefined && value !== undefined) {
    console.warn(
      "TextInput: Both value and initialValue are defined. Unsupported behaviour. Pick one."
    );
  }
  const inputId = useId();

  const sizeClasses = {
    sm: {
      text: "text-sm",
      height: "h-10",
    },
    md: {
      text: "text-base",
      height: "h-10",
    },
    lg: {
      text: "text-lg",
      height: "h-10",
    },
    xl: {
      text: "text-xl",
      height: "h-12",
    },
    "2xl": {
      text: "text-2xl",
      height: "h-12",
    },
  };

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    onChange?.(e.currentTarget.value);
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (e.key === "Enter") {
      onSubmit?.(v);
    }

    onKeyDown?.(v, e);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    onKeyUp?.(v, e);
  };

  const valid = !errorMessage;

  return (
    <div className={twMerge("w-full", wrapperClassName)}>
      {label && (
        <Label required={required} htmlFor={id || inputId}>
          {label}
        </Label>
      )}
      <div className="flex rounded-md shadow-sm">
        <div
          className={twMerge(
            "flex h-10 flex-grow items-stretch overflow-hidden border focus:outline-none focus:ring-transparent",
            sizeClasses[size].height,
            hasButton ? "rounded-none rounded-l-md" : "rounded-md",
            valid
              ? "border-gray-300 text-gray-700 focus:border-hover"
              : "border-red-300 text-red-900 focus:border-red-500"
          )}
        >
          <input
            type={type}
            name={name}
            ref={ref}
            id={id || inputId}
            disabled={disabled}
            value={controlled ? (value === undefined ? "" : value) : undefined}
            defaultValue={controlled ? undefined : defaultValue}
            readOnly={readonly}
            className={twMerge(
              "block w-full rounded-md border-none transition-colors duration-300 focus:outline-none focus:ring-transparent",
              sizeClasses[size].height,
              sizeClasses[size].text,
              disabled ? "bg-gray-100" : "bg-white",
              textAlign === "center" ? "text-center" : "",
              textAlign === "right" ? "text-right" : "",
              className
            )}
            placeholder={placeholder}
            onClick={() => onClick && onClick()}
            onFocus={(e) => onFocus && onFocus(e.currentTarget.value)}
            onBlur={onBlur ? (e) => onBlur(e.currentTarget.value, e) : undefined}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onSubmit={onSubmit ? (e) => onSubmit(e.currentTarget.value) : undefined}
            onWheel={(e) => (type === "number" ? (e.target as HTMLInputElement).blur() : undefined)}
          />
          {!valid && errorMessage ? (
            <div
              className={twMerge(
                "pr-2",
                sizeClasses[size].height,
                disabled ? "bg-gray-100" : "bg-white"
              )}
            >
              <div
                className={twMerge(
                  "pointer-events-none flex w-5 items-center",
                  sizeClasses[size].height
                )}
              >
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            </div>
          ) : Icon ? (
            <div
              onClick={() => onIconClick && onIconClick()}
              className={twMerge(
                "pointer-events-none flex items-center pr-3",
                onIconClick && "pointer-events-auto cursor-pointer hover:text-hover"
              )}
            >
              <Icon className="h-5 w-5" aria-hidden="true" />
            </div>
          ) : null}
        </div>
      </div>
      {errorMessage ? <p className="mt-2 text-left text-sm text-red-600">{errorMessage}</p> : null}
    </div>
  );
});

export default TextInput;
