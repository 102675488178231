import { MouseEvent, ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Button, Dialog } from "~/lib/ui";
import { t } from "i18next";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { MediaPreview } from "~/lib/ui/media/";
import { MediaDetail } from "~/lib/ui/media/media-detail";

export function MediaPreviewLink({
  href,
  previewURL,
  mimeType,
  title,
  height,
  children = <MediaPreview href={href} mimeType={mimeType} filename={title} height={height} />,
  className,
  disabled = false,
  allowDownload = true,
  stopPropagation = false,
}: {
  href: string; // Link to image, used for preview if no previewURL
  previewURL?: string; // Preview URL. Overrides href if set
  children?: ReactNode; // What to wrap in the link
  className?: string;
  title?: string;
  height?: `h-${number}`;
  disabled?: boolean;
  mimeType: string;
  allowDownload?: boolean;
  stopPropagation?: boolean;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const allowPreview =
    (mimeType &&
      (mimeType.startsWith("video") || mimeType.startsWith("image") || mimeType.endsWith("pdf"))) ||
    false;
  const isPdf = mimeType && mimeType.endsWith("pdf");

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    if (allowPreview) {
      e.preventDefault();
      stopPropagation && e.stopPropagation();
      setIsOpen(true);
    } else {
      window.open(href);
    }
  }

  const preview = previewURL ?? href;

  return (
    <>
      {disabled ? (
        children
      ) : (
        <>
          <a
            href={!allowPreview ? href : undefined}
            onClick={allowPreview ? handleClick : undefined}
            className={twMerge("cursor-pointer hover:opacity-80", className)}
          >
            {children}
          </a>
          {allowPreview && !isPdf && (
            <Dialog
              className="max-h-[95vh] md:max-w-4xl"
              open={isOpen}
              onOpenChange={(state) => setIsOpen(state)}
              render={() => (
                <div className="flex grow-0 flex-col flex-nowrap items-center justify-center">
                  <MediaDetail href={preview} mimeType={mimeType} />
                  <div className="mt-4 flex w-full items-center justify-between">
                    <div>{title}</div>
                    {allowDownload && (
                      <Button variant="secondary" onClick={() => (window.location.href = href)}>
                        {t("common:download")}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            />
          )}
          {allowPreview && isPdf && (
            <FullScreenFilePreview
              open={isOpen}
              fileUrl={preview}
              onClose={() => setIsOpen(false)}
            />
          )}
        </>
      )}
    </>
  );
}
