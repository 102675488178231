import { extendElement } from "~/lib/utils/react/extend-element";

export const TableContainer = extendElement("div", {
  className: "shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg",
  displayName: "TableContainer",
});

export const Table = extendElement("table", {
  className: "min-w-full table-auto divide-y divide-shade-300 text-left mt-4",
  displayName: "Table",
});

export const TBody = extendElement("tbody", {
  className: "divide-y divide-shade-200 bg-white",
  displayName: "TBody",
});

export const THead = extendElement("thead", {
  className: "bg-shade-50 border-t-0",
  displayName: "THead",
});

export const TR = extendElement("tr", {
  className: "",
  displayName: "TR",
});

export const TH = extendElement("th", {
  className: "py-3.5 pl-4 pr-3 text-sm font-semibold text-shade-900 sm:pl-6",
  displayName: "TH",
});

export const TD = extendElement("td", {
  className: "py-4 pl-6 pr-4 text-sm text-shade-500",
  displayName: "TD",
});

export const TFoot = extendElement("tfoot", {
  className: "bg-shade-50 border-t-0",
  displayName: "TFoot",
});
