import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import { $wrapNodes } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { Popover } from "~/lib/ui/popover/popover";
import OrderedListIcon from "./icons/ordered-list-icon";
import UnorderedListIcon from "./icons/unordered-list-icon";
import { RichTextEditorInsertAction, ToolbarAction } from "~/lib/ui/rich-text-editor";
import { INSERT_VARIABLE_COMMAND } from "~/lib/ui/rich-text-editor/editor/plugins/variable-plugin";
import { INSERT_TEMPLATE_COMMAND } from "~/lib/ui/rich-text-editor/editor/plugins/template-plugin";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

const dropdownBlockTypes = ["paragraph", "h2", "h3", "h4"];

export function EditorToolbar({
  namespace,
  toolbarActions,
  isRichText = true,
}: {
  namespace: string;
  toolbarActions?: Array<ToolbarAction>;
  isRichText?: boolean;
}) {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const [blockType, setBlockType] = useState<string>("paragraph");

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();

      const elementKey: string = element.getKey();
      const elementDOM: HTMLElement | null = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        //setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        1
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        1
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        1
      )
    );
  }, [editor, updateToolbar]);

  const getBlockTypeName = (bType: string) => {
    switch (bType) {
      case "h2":
        return "Large Heading";
      case "h3":
        return "Medium Heading";
      case "h4":
        return "Small Heading";
      case "paragraph":
      default:
        return "Normal";
    }
  };

  const formatHeading = (type: "h2" | "h3" | "h4") => {
    if (blockType !== type) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(type));
        }
      });
    }
  };

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatText = (bType: string) => {
    switch (bType) {
      case "h2":
      case "h3":
      case "h4":
        return formatHeading(bType);
      case "paragraph":
      default:
        return formatParagraph();
    }
  };

  const handleActionClick = async (action: () => Promise<RichTextEditorInsertAction> | void) => {
    const actionResponse = await action();

    if (actionResponse) {
      if (actionResponse.type === "variable") {
        editor.dispatchCommand(INSERT_VARIABLE_COMMAND, actionResponse);
      }

      if (actionResponse.type === "template") {
        editor.dispatchCommand(INSERT_TEMPLATE_COMMAND, actionResponse);
      }
    }
  };

  return (
    <div className="toolbar flex h-12 divide-x divide-gray-200 border-b" ref={toolbarRef}>
      <div className="flex items-center gap-2 px-4">
        <button
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
          className={twMerge(
            "flex h-9 items-center justify-center gap-2 rounded-lg border px-2 text-xs transition-colors duration-200",
            canUndo
              ? "cursor-pointer hover:border-hover hover:text-hover"
              : "border-gray-300 text-gray-300"
          )}
          aria-label="Undo"
        >
          <ArrowUturnLeftIcon className="h-4 w-4" />
          {t("common:undo")}
        </button>
        <button
          disabled={!canRedo}
          onClick={() => {
            editor.dispatchCommand(REDO_COMMAND, undefined);
          }}
          className={twMerge(
            "flex h-9 items-center justify-center gap-2 rounded-lg border px-2 text-xs transition-colors duration-200",
            canRedo
              ? "cursor-pointer hover:border-hover hover:text-hover"
              : "border-gray-300 text-gray-300"
          )}
          aria-label="Redo"
        >
          <ArrowUturnRightIcon className="h-5 w-5" />
          {t("common:redo")}
        </button>
      </div>
      {isRichText && (
        <>
          <div className="flex items-center px-4">
            <Popover
              config={{ align: "start" }}
              triggerRender={() => (
                <div className="flex h-9 cursor-pointer items-center gap-4 rounded-lg border px-4 transition-colors duration-200 hover:border-hover hover:text-hover">
                  <div>{getBlockTypeName(blockType)}</div>
                  <div>
                    <ChevronDownIcon className="h-5 w-5" />
                  </div>
                </div>
              )}
            >
              {(close) => (
                <div className="flex min-w-[10em] flex-col rounded-lg border bg-white p-2 shadow-md">
                  {dropdownBlockTypes.map((type) => (
                    <div
                      key={type}
                      className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
                      onClick={() => formatText(type)}
                    >
                      {getBlockTypeName(type)}
                    </div>
                  ))}
                </div>
              )}
            </Popover>
          </div>

          <div className="flex items-center gap-2 px-4">
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
              }}
              className={twMerge(
                "h-9 w-9 cursor-pointer rounded-lg border transition-colors duration-200 hover:border-hover",
                isBold
                  ? "border-hover bg-hover text-white hover:text-white"
                  : "bg-white text-zinc-700 hover:text-hover"
              )}
              aria-label="Format Bold"
            >
              <span className="text-xl font-bold">{t("common:rte_bold")}</span>
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
              }}
              className={twMerge(
                "h-9 w-9 cursor-pointer rounded-lg border transition-colors duration-200 hover:border-hover",
                isItalic
                  ? "border-hover bg-hover text-white hover:text-white"
                  : "bg-white text-zinc-700 hover:text-hover"
              )}
              aria-label="Format Italics"
            >
              <span className="text-xl italic">{t("common:rte_italic")}</span>
            </button>
          </div>
          <div className="flex items-center gap-2 px-4">
            <button
              onClick={() => {
                if (blockType === "ol") {
                  editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                } else {
                  editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
                }
              }}
              className={twMerge(
                "flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg border transition-colors duration-200 hover:border-hover hover:text-hover",
                blockType === "ol" ? "border-hover bg-hover text-white" : "bg-white text-zinc-700 "
              )}
              aria-label="Insert ordered list"
            >
              <OrderedListIcon className="h-7 w-7" />
            </button>
            <button
              onClick={() => {
                if (blockType === "ul") {
                  editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                } else {
                  editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
                }
              }}
              className={twMerge(
                "flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg border transition-colors duration-200 hover:border-hover hover:text-hover",
                blockType === "ul" ? "border-hover bg-hover text-white" : "bg-white text-zinc-700 "
              )}
              aria-label="Insert unordered list"
            >
              <UnorderedListIcon className="h-7 w-7" />
            </button>
          </div>
          {toolbarActions && (
            <div className="flex items-center gap-2 px-4">
              {toolbarActions.map((action, idx) => (
                <button
                  key={`${namespace}-action-${idx}`}
                  onClick={() => handleActionClick(action.onClick)}
                  className="flex h-9 w-auto items-center justify-center rounded-lg border bg-white px-2 text-zinc-700 transition-colors duration-200 hover:border-hover hover:text-hover"
                >
                  {action.render()}
                </button>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
