import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { getIcon, Icon } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { CACHE_INVOICES } from "..";
import { ProjectStatusSelection } from "~/lib/ui/selection-combobox/project-status-selection";
import { useState } from "react";
import Switch from "~/lib/ui/switch";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { InvoiceSendFragment, SendData } from "./invoice-send-fragment";
import { Invoice } from "@apacta/sdk";
import { twMerge } from "tailwind-merge";
import { useMe } from "~/lib/auth/use-me";
import { LabeledSelect } from "~/lib/ui/form-elements/labeled-select";

export function BookInvoiceDialog({
  invoice,
  onClose,
  pendingSave,
}: {
  invoice: Invoice;
  onClose: () => void;
  pendingSave?: () => Promise<unknown>;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const api = useAPI();
  const me = useMe();

  const isERP = me.integrationsEnabled.erp;

  const formState = useFormState({
    schema: {
      projectStatusId: z.string().uuid(),
      type: z.enum(["draft", "invoice"]),
    },
    initialValues: {
      type: "draft", // draft by default (only used by ERP)
      projectStatusId: invoice.project.projectStatusId,
    },
  });

  const [sendInvoiceAfterSaving, setSendInvoiceAfterSaving] = useState(true); // Default on
  const [sendData, setSendData] = useState<SendData>();

  const actionTitle = (() => {
    if (isERP) {
      return t("invoices:export_to_erp");
    }
    return sendInvoiceAfterSaving ? t("invoices:action_book_and_send") : t("invoices:action_book");
  })();

  const isDraft = (() => {
    if (isERP) {
      return formState.getValue("type") === "draft";
    }
    // If we're booking it, and it's not ERP - then it's no longer a draft
    return false;
  })();

  const mut = useMutation({
    mutationFn: async () => {
      await pendingSave?.();
      await api.editInvoice({
        invoiceId: invoice.id,

        editInvoiceRequest: {
          isDraft,
          toErp: isERP,
          projectStatusId: formState.getValue("projectStatusId"),
        },
      });
      // Sending is only applied for invoices, not drafts
      if (!isDraft && sendInvoiceAfterSaving && sendData) {
        await api.iInvoiceSendEmail({
          invoiceId: invoice.id,
          recipientEmail: sendData?.recipientEmail,
          body: sendData?.body,
          subject: sendData?.subject,
        });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, invoice.id] });
      onClose();
    },
  });

  const allowSubmit = (() => {
    if (!formState.isValid) return false;
    if (sendInvoiceAfterSaving && !sendData?.isValid) return false;
    if (mut.isPending) return false;
    return true;
  })();

  return (
    <>
      <DialogHeader title={actionTitle} Icon={getIcon("invoice")} />
      <div className="mb-4 flex flex-col gap-4">
        <div>
          {pendingSave && (
            <div className="mb-4 flex items-center gap-4">
              <div>
                <Icon name="infoCircle" className="h-8 w-8 text-shade-600" />
              </div>
              <div className="whitespace-pre-line">
                {t("common:unsaved_changes_will_be_saved_before_proceeding")}
              </div>
            </div>
          )}
          {isERP && (
            <div className="mb-4 flex items-center gap-4">
              <div>
                <Icon name="warningCircle" className="h-8 w-8 text-red-600" />
              </div>
              <div className="whitespace-pre-line">{t("invoices:warning_before_send_to_erp")}</div>
            </div>
          )}
        </div>
        {isERP && (
          <LabeledSelect
            value={formState.getValue("type")}
            onChange={(e) => formState.onChange("type", e.currentTarget.value)}
          >
            <option value="draft">{t("common:draft")}</option>
            <option value="invoice">{t("invoices:booked", { count: 1 })}</option>
          </LabeledSelect>
        )}

        <ProjectStatusSelection
          required
          label={t("invoices:change_project_status_after_booking")}
          onSelect={(v) => v && formState.onChange("projectStatusId", v)}
          defaultValue={formState.getValue("projectStatusId")}
        />
        {!isDraft && (
          <Switch
            defaultChecked={sendInvoiceAfterSaving}
            label={t("invoices:send_invoice")}
            onCheckedChange={(v) => setSendInvoiceAfterSaving(v)}
            error={formState.getField("projectStatusId").errors[0]}
          />
        )}
        <div
          className={twMerge("hidden flex-col gap-4", !isDraft && sendInvoiceAfterSaving && "flex")}
        >
          <InvoiceSendFragment invoice={invoice} onChange={setSendData} />
        </div>
      </div>
      <DialogFooter
        onClose={onClose}
        primary={{
          label: actionTitle,
          onClick: () => mut.mutateAsync(),
          disabled: !allowSubmit,
        }}
      />
    </>
  );
}
