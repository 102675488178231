import { castDraft, produce } from "immer";
import { Dispatch, useReducer } from "react";
import { MockUser } from "./mock";
import { getISODate, toArray } from "./utils";
import { Card, Task } from "./types";

export type CardReducerAction =
  | {
      type: "SET_INITIAL";
      cards: Record<string, Card>;
      tasks: Record<string, Task>;
    }
  | {
      type: "UPDATE_CARD";
      data: { card: Partial<Card>; task?: Partial<Task> };
      onFinish?: ({
        from,
        to,
      }: {
        newCard: Card;
        oldCard: Card;
        newTask: Task;
        oldTask: Task;
        from: Array<Card>;
        to: Array<Card>;
      }) => void;
    }
  | {
      type: "ADD_CARD";
      card: Card;
      onFinish?: ({ to }: { to: Array<Card> }) => void;
    }
  | {
      type: "REMOVE_CARD";
      card: Partial<Card>;
      onFinish?: ({ from, originalCard }: { from: Array<Card>; originalCard: Card }) => void;
    }
  | {
      type: "ADD_TASK";
      task: Task;
    }
  | {
      type: "SET_USERS";
      users: Record<string, MockUser>;
    };

export type CardReducerViewState = {
  allCards: Array<Card>;
  plannedAssigned: Array<Card>;
  plannedUnassigned: Array<Card>;
  unplanned: Array<Card>;
};

export type CardReducerState = {
  isReady: boolean; // False until first set of cards
  cards: Record<string, Card>;
  tasks: Record<string, Task>;
  users: Record<string, MockUser>;
  views: CardReducerViewState;
};

export function initialCardReducerState(): CardReducerState {
  return {
    isReady: false,
    cards: {},
    tasks: {},
    users: {},
    views: {
      allCards: [],
      plannedAssigned: [],
      plannedUnassigned: [],
      unplanned: [],
    },
  };
}

export function cardReducer(
  state: CardReducerState = initialCardReducerState(),
  action: CardReducerAction
): CardReducerState {
  switch (action.type) {
    case "SET_INITIAL":
      const newState = {
        ...state,
        cards: { ...action.cards },
        tasks: { ...action.tasks },
        isReady: true,
      };
      newState.views = naiveViewGenerator(newState);
      return newState;
    case "UPDATE_CARD":
      if (!action.data.card.id) {
        throw new Error("Cannot update card without id");
      }

      const fromCard = { ...state.cards[action.data.card.id] } as Card;

      return produce(state, (draft) => {
        const toCard = {
          ...fromCard,
          ...action.data.card,
        } as Card;
        const cardArr = toArray(draft.cards);
        const isSameContainer: boolean =
          fromCard.userId === toCard.userId &&
          getISODate(fromCard.date) === getISODate(toCard.date);

        let fromTask = {} as Task;
        let toTask = {} as Task;

        if (action.data.task?.id) {
          fromTask = { ...state.tasks[action.data.task.id] } as Task;
          toTask = {
            ...fromTask,
            ...action.data.task,
          } as Task;
        }

        // Remove from old list
        const from = cardArr
          .filter(
            (c) => c.userId === fromCard.userId && getISODate(c.date) === getISODate(fromCard.date)
          )
          .sort((a, b) => {
            if (a.listIndex === null || b.listIndex === null) {
              return 0;
            }
            return a.listIndex - b.listIndex;
          });

        from.splice(
          from.findIndex((v) => v.id === fromCard.id),
          1
        );

        if (isSameContainer && toCard.listIndex !== null && fromCard.listIndex !== null) {
          // If we're moving in the same space, we need to adjust the index
          from.splice(toCard.listIndex, 0, castDraft(toCard));

          // Hand out new indexes
          from.forEach((c, idx) => {
            draft.cards[c.id].listIndex = idx;
          });

          toCard.listIndex = draft.cards[toCard.id].listIndex;

          action.onFinish?.({
            from,
            to: [],
            newCard: { ...toCard, listIndex: draft.cards[toCard.id].listIndex },
            oldCard: fromCard,
            oldTask: fromTask,
            newTask: toTask,
          });
        }

        if (isSameContainer && !toCard.date) {
          action.onFinish?.({
            from: [],
            to: [],
            newCard: toCard,
            oldCard: fromCard,
            oldTask: fromTask,
            newTask: toTask,
          });
        }

        // Get new list, and splice into that
        if (!isSameContainer && toCard.date) {
          // Hand out new indexes
          from.forEach((c, idx) => {
            draft.cards[c.id].listIndex = idx;
          });
          const to = cardArr
            .filter(
              (c) => c.userId === toCard.userId && getISODate(c.date) === getISODate(toCard.date)
            )
            .sort((a, b) => {
              if (a.listIndex === null || b.listIndex === null) {
                return 0;
              }
              return a.listIndex - b.listIndex;
            });

          to.splice(toCard.listIndex || 0, 0, castDraft(toCard));

          // Hand out new indexes
          to.forEach((c, idx) => {
            if (draft.cards[c.id] !== undefined) {
              draft.cards[c.id].listIndex = idx;
            }
          });

          toCard.listIndex = draft.cards[toCard.id].listIndex;

          // TODO:  Not ideal that this is called in many places, impossible to fix without test coverage
          action.onFinish?.({
            from,
            to,
            oldCard: fromCard,
            newCard: { ...toCard, listIndex: draft.cards[toCard.id].listIndex },
            oldTask: fromTask,
            newTask: toTask,
          });
        }

        if (!isSameContainer && !toCard.date) {
          // Hand out new indexes
          from.forEach((c, idx) => {
            draft.cards[c.id].listIndex = idx; // TODO: This will possibly not work for unplanned!
          });
          const to = cardArr
            .filter((c) => !c.date)
            .sort((a, b) => {
              if (a.listIndex === null || b.listIndex === null) {
                return 0;
              }
              return a.listIndex - b.listIndex;
            });

          to.splice(
            to.findIndex((v) => v.id === toCard.id),
            0,
            castDraft(toCard)
          );

          // Hand out new indexes
          to.forEach((c) => {
            if (draft.cards[c.id] !== undefined) {
              draft.cards[c.id].listIndex = null;
            }
          });

          toCard.listIndex = draft.cards[toCard.id].listIndex;

          action.onFinish?.({
            from,
            to,
            oldCard: fromCard,
            newCard: toCard,
            oldTask: fromTask,
            newTask: toTask,
          });
        }

        // Overwrite card data
        draft.cards[toCard.id] = toCard;
        draft.tasks[toTask.id || ""] = toTask; // TODO remove quotes when Task.id is fixed
        draft.views = naiveViewGenerator(draft);
      });

    case "ADD_CARD":
      if (!action.card.id) {
        throw new Error("Cannot update card without id");
      }

      return produce(state, (draft) => {
        draft.cards[action.card.id] = action.card;
        draft.views = naiveViewGenerator(draft);
      });

    case "REMOVE_CARD":
      if (!action.card.id) {
        throw new Error("Cannot remove card without id");
      }

      const card = { ...state.cards[action.card.id] } as Card;
      const originalCard = Object.assign({}, card);

      return produce(state, (draft) => {
        const cards = toArray(draft.cards);

        const from = card.date
          ? cards.filter((c) => {
              return c.userId === card.userId && getISODate(c.date) === getISODate(card.date);
            })
          : cards.filter((c) => !c.date);

        from.splice(
          from.findIndex((v) => v.id === card.id),
          1
        );

        from.forEach((c, idx) => {
          draft.cards[c.id].listIndex = idx;
        });

        action.onFinish?.({ from, originalCard });

        delete draft.cards[card.id];

        draft.views = naiveViewGenerator(draft);
      });

    case "ADD_TASK":
      return produce(state, (draft) => {
        if (action.task && action.task.id) {
          draft.tasks[action.task.id] = action.task;
        }
      });

    case "SET_USERS":
      return {
        ...state,
        users: action.users,
        views: {
          ...state.views,
        },
      };
    default:
      throw new Error("Unsupported action");
  }
}

// Ideally we should avoid recreating this every time. Room for improvement
function naiveViewGenerator(state: CardReducerState): CardReducerViewState {
  return {
    ...state.views,
    allCards: toArray(state.cards),
    plannedAssigned: toArray(state.cards)
      .filter((c) => c.userId && c.date)
      .sort((a, b) => {
        if (a.listIndex === null || b.listIndex === null) {
          return 0;
        }
        return a.listIndex - b.listIndex;
      }),
    plannedUnassigned: toArray(state.cards)
      .filter((c) => !c.userId && c.date)
      .sort((a, b) => {
        if (a.listIndex === null || b.listIndex === null) {
          return 0;
        }
        return a.listIndex - b.listIndex;
      }),
    unplanned: toArray(state.cards)
      .filter((c) => c.date === undefined || c.date === null)
      .sort((a, b) => {
        if (a.listIndex === null || b.listIndex === null) {
          return 0;
        }
        return a.listIndex - b.listIndex;
      }),
  };
}

export function useCardReducer(): [CardReducerState, Dispatch<CardReducerAction>] {
  return useReducer(cardReducer, initialCardReducerState());
}
