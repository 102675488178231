import { Contact } from "@apacta/sdk";

import { useTranslation } from "react-i18next";
import { linkToCustomer } from "~/lib/utils";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { AVATAR_CLASS } from "./settings";
import Avatar from "~/lib/ui/avatar/avatar";
import { Icon } from "~/lib/ui/icons/icon";

export function CustomerCell({
  customer,
  address,
  primary,
}: {
  primary?: boolean;
  customer?: Contact;
  /** In rare cases like the projects table we want to show the project address */
  address?: string;
}) {
  const { t } = useTranslation();
  if (!customer) return t("common:not_available");
  const addressText = address ?? customer.address;
  return (
    <span className="inline-flex w-full min-w-44 items-center justify-between">
      <span className="inline-flex items-center">
        <OptionalLink to={linkToCustomer(customer.id)} className="inline-flex items-center">
          <Avatar name={customer.name} className={AVATAR_CLASS} />
          <span>
            <span>{customer.name}</span>
            {addressText && <span className="break block text-gray-400">{addressText}</span>}
          </span>
        </OptionalLink>
      </span>
      {primary && (
        <OptionalLink to={linkToCustomer(customer.id)} openInNewTab>
          <Icon name="externalLink" size="small" />
        </OptionalLink>
      )}
    </span>
  );
}
