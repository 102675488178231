import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { formatDate } from "~/lib/utils/date";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { DeleteContactRequest } from "@apacta/sdk";
import { PageLayout } from "~/lib/ui/page-layout";
import { getIcon } from "~/lib/ui/icons/icon";
import { Boundary } from "~/lib/ui/boundary";
import { Button } from "~/lib/ui";

export default function CustomerPage() {
  const api = useAPI();
  const queryClient = useQueryClient();
  const { id: customerId } = useParams();
  const { data, isLoading } = useSuspenseQuery({
    queryKey: ["customer", customerId],
    queryFn: () => api.getContact({ contactId: customerId as string }),
  });
  const customerDelete = useMutation({
    mutationFn: (args: DeleteContactRequest) => api.deleteContact(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customers"], // this causes a refetch
      });
      navigate("/customers", { state: { customer_deleted: true } });
    },
  });
  const customer = data?.data;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleDeleteCustomer = async () => {
    if (!customer) return;
    await customerDelete.mutateAsync({
      contactId: customer.id as string,
    });
  };

  if (isLoading) {
    return (
      <div className="relative min-h-screen">
        <PageSpinner />
      </div>
    );
  }

  return (
    <>
      <PageLayout
        title={customer.name}
        onBackClick={() => navigate(-1)}
        renderDescription={() => (
          <>
            {customer.modified ? (
              <p className="hidden sm:block">
                {t("common:modified")}{" "}
                {formatDate(new Date(customer.modified), i18n.resolvedLanguage)}
              </p>
            ) : null}
          </>
        )}
        renderActions={() => (
          <>
            <Button
              title={`${t("common:delete")} ${t("common:customer", {
                count: 1,
              }).toLowerCase()}`}
              variant="secondary"
              confirm={{ action: "delete", entity: "customer" }}
              Icon={getIcon("delete")}
              onClick={handleDeleteCustomer}
            />
          </>
        )}
      >
        <>
          <PageTabs
            tabs={[
              {
                id: "primary",
                label: t("customers:tabs.primary_label", "Customer information"),
                to: "",
                Icon: getIcon("entityOverview"),
              },
              {
                id: "contacts",
                label: t("common:customer_contact", { count: 2 }),
                to: "contacts/",
                Icon: getIcon("customerContact"),
              },
              {
                id: "offers",
                label: t("common:offer", { count: 2 }),
                to: "offers/",
                Icon: getIcon("offer"),
              },
              {
                id: "projects",
                label: t("common:project", { count: 2 }),
                to: "projects/",
                Icon: getIcon("project"),
              },
              {
                id: "invoices",
                label: t("common:invoice", { count: 2 }),
                to: "invoices/",
                Icon: getIcon("invoice"),
              },
            ]}
          />
          <Boundary variant="tab">
            <Outlet context={{ customer }} />
          </Boundary>
        </>
      </PageLayout>
    </>
  );
}
