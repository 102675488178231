import {
  CalculatorIcon,
  CogIcon,
  CreditCardIcon,
  FaceSmileIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Command } from "cmdk";
import { ReactNode } from "react";
import { Icon } from "~/lib/ui";
import {
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "~/lib/ui/command/command";

export default function CommandPlayground() {
  return (
    <>
      <Showcase label="Command menu w. search">
        <Command>
          <CommandInput placeholder="Type a command or search..." />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup heading="Suggestions">
              <CommandItem>
                <Icon name="calendar" className="mr-2 h-4 w-4" />
                <span>Calendar</span>
              </CommandItem>
              <CommandItem>
                <FaceSmileIcon className="mr-2 h-4 w-4" />
                <span>Search Emoji</span>
              </CommandItem>
              <CommandItem>
                <CalculatorIcon className="mr-2 h-4 w-4" />
                <span>Calculator</span>
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="Settings">
              <CommandItem>
                <UserIcon className="mr-2 h-4 w-4" />
                <span>Profile</span>
                <CommandShortcut>⌘P</CommandShortcut>
              </CommandItem>
              <CommandItem>
                <CreditCardIcon className="mr-2 h-4 w-4" />
                <span>Billing</span>
                <CommandShortcut>⌘B</CommandShortcut>
              </CommandItem>
              <CommandItem>
                <CogIcon className="mr-2 h-4 w-4" />
                <span>Settings</span>
                <CommandShortcut>⌘S</CommandShortcut>
              </CommandItem>
              <CommandItem>
                <span>Ja</span>
              </CommandItem>
              <CommandItem>
                <span>Nej</span>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </Showcase>
      <Showcase label="Command menu">
        <Command>
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup heading="Suggestions">
              <CommandItem>
                <span>Ja</span>
              </CommandItem>
              <CommandItem>
                <span>Nej</span>
              </CommandItem>
              <CommandItem>
                <CalculatorIcon className="mr-2 h-4 w-4" />
                <span>Calculator</span>
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="Settings">
              <CommandItem>
                <UserIcon className="mr-2 h-4 w-4" />
                <span>Profile</span>
                <CommandShortcut>⌘P</CommandShortcut>
              </CommandItem>
              <CommandItem>
                <CreditCardIcon className="mr-2 h-4 w-4" />
                <span>Billing</span>
                <CommandShortcut>⌘B</CommandShortcut>
              </CommandItem>
              <CommandItem>
                <CogIcon className="mr-2 h-4 w-4" />
                <span>Settings</span>
                <CommandShortcut>⌘S</CommandShortcut>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </Showcase>
    </>
  );
}

function Showcase({ label, children }: { label: string; children: ReactNode }) {
  return (
    <div className="max-w-xl p-8">
      <h2 className="mb-4 text-lg font-semibold">{label}</h2>
      {children}
    </div>
  );
}
