import { IGetProject200Response, KpiCardsIndexResponse } from "@apacta/sdk";
import { useOutletContext } from "react-router";
import { CardContainer } from "../_cmp/card-container";
import {
  ContributionMarginCard,
  ContributionRatioCard,
  HourConsumptionCardFixed,
  InvoicedCardFixed,
  ProductConsumptionCardFixed,
} from "../_kpi-cards";
import { InvoicedVariable } from "../_kpi-cards/invoiced-variable";
import { TotalVariableClosedCard } from "../_kpi-cards/total-variable-closed";
import { ProductConsumptionCardVariable } from "../_kpi-cards/product-consumption-variable";
import { HourConsumptionCardVariable } from "../_kpi-cards/hour-consumption-variable";
import { StatusCardFixed } from "../_kpi-cards/status-card-fixed";
import { ComparisonBarChartCard } from "../_cmp/comparison-bar-chart-card";
import { useTranslation } from "react-i18next";
import ZeroCostWarning from "~/pages/projects/[id]/_cmp/zero-cost-warning";

export default function ProjectOverviewPage() {
  const { entity: project, kpiData } = useOutletContext<{
    entity: IGetProject200Response["data"];
    kpiData: KpiCardsIndexResponse;
  }>();
  const { t } = useTranslation();
  const {
    invoicedCurrent,
    notInvoiced,
    costPriceCurrent,
    costPriceEstimated,
    salesPriceEstimated,
    salesPriceCurrent,
    productsCostPriceDelivered,
    productsCostPriceEstimated,
    productsSalesPriceDelivered,
    hoursCostPriceDelivered,
    hoursCostPriceEstimated,
    hoursCountDelivered,
    hoursSalesPriceDelivered,
    contributionMargins,
    preCalculation,
    completionPercentage,
    hasZeroPricedProducts,
  } = kpiData;

  const isOpen = !kpiData.isProjectFinished;
  const isFixedPrice = project.isFixedPrice;
  const showZeroCostWarning =
    project.workingHoursTotalCostPrice === 0 &&
    project.productsTotalCostPrice === 0 &&
    project.totalSalesPrice > 0;

  const preCalculationcontributionRatioPercentage =
    (preCalculation?.contributionRatio ?? 0.0) * 100;
  const actualContributionRatioPercentage = (contributionMargins?.contributionRatio ?? 0.0) * 100;

  return (
    <>
      <div className="flex flex-col gap-6">
        {isFixedPrice ? (
          <>
            {showZeroCostWarning && (
              <ZeroCostWarning
                project={project}
                showManualButton={true}
                showAutomaticButton={true}
              />
            )}
            {isOpen ? ( // Fixed Price: Open
              <>
                <StatusCardFixed
                  completionPercent={completionPercentage}
                  salesPriceCurrent={salesPriceCurrent}
                  estimatedSalesPrice={salesPriceEstimated}
                  costPriceCurrent={costPriceCurrent}
                  estimatedCostPrice={costPriceEstimated}
                />
                <CardContainer>
                  <InvoicedCardFixed
                    salesPrice={salesPriceCurrent ?? 0}
                    deliveredPercentage={completionPercentage ?? 0}
                    notInvoiced={notInvoiced ?? 0}
                    invoiced={invoicedCurrent}
                  />
                  <ProductConsumptionCardFixed
                    isProjectFinished={false}
                    productsCostPriceDelivered={productsCostPriceDelivered}
                    productsCostPriceEstimated={productsCostPriceEstimated}
                    warningZeroCost={hasZeroPricedProducts}
                  />
                  <HourConsumptionCardFixed
                    isProjectFinished={false}
                    hoursCostPriceDelivered={hoursCostPriceDelivered}
                    hoursCostPriceEstimated={hoursCostPriceEstimated}
                  />
                </CardContainer>
              </>
            ) : (
              // Fixed Price: Closed
              <>
                <CardContainer>
                  <ComparisonBarChartCard
                    label={t("finance:gross_profit")}
                    unit="currency"
                    estimated={{
                      label: t("common:estimated"),
                      value: preCalculation?.contributionMargin ?? 0,
                    }}
                    actual={{
                      label: t("common:actual"),
                      value: contributionMargins?.contributionMargin ?? 0,
                    }}
                  />
                  <ComparisonBarChartCard
                    label={t("finance:gross_profit_per_hour")}
                    unit="currency"
                    estimated={{
                      label: t("common:estimated"),
                      value: preCalculation?.contributionMarginPerHour ?? 0,
                    }}
                    actual={{
                      label: t("common:actual"),
                      value: contributionMargins?.contributionMarginPerHour ?? 0,
                    }}
                  />
                  <ComparisonBarChartCard
                    label={t("common:contribution_ratio")}
                    unit="percent"
                    estimated={{
                      label: t("common:estimated"),
                      value: Math.round(preCalculationcontributionRatioPercentage * 10) / 10,
                    }}
                    actual={{
                      label: t("common:actual"),
                      value: Math.round(actualContributionRatioPercentage * 10) / 10,
                    }}
                  />
                </CardContainer>
                <CardContainer>
                  <InvoicedCardFixed
                    notInvoiced={notInvoiced ?? 0}
                    invoiced={invoicedCurrent}
                    salesPrice={salesPriceCurrent}
                    deliveredPercentage={completionPercentage ?? 0}
                  />
                  <ProductConsumptionCardFixed
                    productsCostPriceDelivered={productsCostPriceDelivered}
                    productsCostPriceEstimated={productsCostPriceEstimated}
                    isProjectFinished={true}
                    warningZeroCost={hasZeroPricedProducts}
                  />
                  <HourConsumptionCardFixed
                    hoursCostPriceDelivered={hoursCostPriceDelivered}
                    hoursCostPriceEstimated={hoursCostPriceEstimated}
                    isProjectFinished={true}
                  />
                </CardContainer>
              </>
            )}
          </>
        ) : (
          <>
            {isOpen ? ( // Variable Price: Open
              <>
                <CardContainer>
                  <TotalVariableClosedCard
                    contributionMargin={contributionMargins?.contributionMargin}
                    salesPriceCurrent={salesPriceCurrent}
                    costPriceCurrent={costPriceCurrent}
                  />

                  <ContributionMarginCard
                    label={t("finance:gross_profit_per_hour", "Gross profit pr. hour")}
                    contributionMargin={contributionMargins?.contributionMarginPerHour ?? 0}
                  />
                  <ContributionRatioCard
                    contributionRatio={contributionMargins?.contributionRatio}
                  />
                </CardContainer>
                <CardContainer className="mt-2">
                  <InvoicedVariable
                    salesPrice={salesPriceCurrent}
                    invoiced={invoicedCurrent}
                    notInvoiced={notInvoiced ?? 0}
                  />
                  <ProductConsumptionCardVariable
                    costPrice={productsCostPriceDelivered}
                    salesPrice={productsSalesPriceDelivered}
                    warningZeroCost={hasZeroPricedProducts}
                  />
                  <HourConsumptionCardVariable
                    costPrice={hoursCostPriceDelivered}
                    salesPrice={hoursSalesPriceDelivered}
                    hoursDelivered={hoursCountDelivered}
                  />
                </CardContainer>
              </>
            ) : (
              // Variable Price: Closed
              <>
                <CardContainer label={t("projects:post_calculation")}>
                  <TotalVariableClosedCard
                    contributionMargin={contributionMargins?.contributionMargin}
                    salesPriceCurrent={salesPriceCurrent}
                    costPriceCurrent={costPriceCurrent}
                  />
                  <ContributionMarginCard
                    label={t("finance:gross_profit_per_hour", "Gross profit pr. hour")}
                    contributionMargin={contributionMargins?.contributionMarginPerHour}
                  />
                  <ContributionRatioCard
                    contributionRatio={contributionMargins?.contributionRatio}
                  />
                </CardContainer>
                <CardContainer className="mt-2">
                  <InvoicedVariable
                    salesPrice={salesPriceCurrent}
                    invoiced={invoicedCurrent}
                    notInvoiced={notInvoiced ?? 0}
                  />
                  <ProductConsumptionCardVariable
                    costPrice={productsCostPriceDelivered}
                    salesPrice={productsSalesPriceDelivered}
                    warningZeroCost={hasZeroPricedProducts}
                  />
                  <HourConsumptionCardVariable
                    costPrice={hoursCostPriceDelivered}
                    salesPrice={hoursSalesPriceDelivered}
                    hoursDelivered={hoursCountDelivered}
                  />
                </CardContainer>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
