import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";

import { useToastOnError } from "~/lib/utils/hooks";
import { SharedTableBase } from "~/lib/ui/data-table/shared-table-base";
import { useCustomerTableColumns } from "./use-customer-table-columns";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { CACHE_CUSTOMERS } from "..";
import { DataTable, useDataTable } from "~/lib/ui/data-table";

interface CustomersTableProps extends SharedTableBase {}

export function CustomersTable(props: CustomersTableProps) {
  const api = useAPI();

  const columns = useCustomerTableColumns();
  const tableState = useDataTableState({
    columnVisibility: props.columnVisibility,
    sorting: [{ id: "name", desc: false }],
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_CUSTOMERS,
      "index",
      tableState.state.search,
      tableState.state.pagination,
      tableState.sortingDirection,
      tableState.sortBy,
      tableState.pageNumber,
    ],
    queryFn: () =>
      api.getContacts({
        q: tableState.state.search,
        page: tableState.pageNumber,
        limit: tableState.state.pagination.pageSize,
        direction: tableState.sortingDirection,
        sort: tableState.sortBy,
      }),
  });
  useToastOnError(dataQ.error);

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      backendPagination: dataQ.data?.pagination,
      getRowId: (row) => row.id,
    },
    {
      enableGlobalFilter: props.enableGlobalFilter ?? true,
      enableFilters: props.enableFilters ?? true,
    }
  );

  return <DataTable table={table} />;
}
