import { Button, Dialog, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import MimeIcon from "~/lib/ui/icons/mime-icon";
import { useAPI } from "~/lib/api";
import { BaseFile, StandardOfferFile } from "@apacta/sdk";
import CreateStandardFileDialog from "~/pages/settings/index/_cmp/create-standard-file-dialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { useState } from "react";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { DateCell } from "~/lib/ui/table/cells/date-cell";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";

export const CACHE_STANDARD_FILES = "STANDARD_FILES";

export default function Files() {
  const [previewBaseFile, setPreviewBaseFile] = useState<BaseFile | undefined>();
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await api.iStandardOfferFileDelete({ standardOfferFileId: id }),
        await queryClient.invalidateQueries({ queryKey: [CACHE_STANDARD_FILES] });
    },
  });

  const handlePreviewFile = (file: BaseFile) => {
    if (file.mimeType?.endsWith("pdf")) {
      setPreviewBaseFile(file);
    }
  };

  const columns = useDataColumns<StandardOfferFile>((columnHelper) => [
    columnHelper.display({
      header: t("common:file", { count: 1 }),
      cell: ({ row }) => (
        <div className="flex items-center gap-4">
          <MimeIcon mime={row.original.file.mimeType} className="h-10 w-auto" />
        </div>
      ),
      meta: {
        className: "w-2/12 group",
      },
    }),
    columnHelper.accessor("file", {
      header: t("common:name", { count: 1 }),
      meta: { className: "w-5/12 group" },
      cell: ({ row, getValue }) => (
        <span
          className="cursor-pointer hover:underline"
          onClick={() => handlePreviewFile(row.original.file)}
        >
          {getValue().originalFilename}
        </span>
      ),
    }),
    columnHelper.accessor("modified", {
      header: t("common:modified_date"),
      meta: { className: "w-2/12 group" },
      cell: ({ getValue }) => <DateCell date={getValue()} />,
    }),
    columnHelper.accessor("type", {
      header: t("common:availability"),
      meta: { className: "w-3/12 group" },
      cell: ({ getValue }) => (
        <div className="flex gap-4 font-semibold">
          {t(`settings:standard_files.availability.${getValue()}`)}
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              confirm: {
                action: "delete",
                entity: "standard_file",
              },
              onClick: async () => deleteMutation.mutateAsync(row.id),
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState();

  const dataQ = useQuery({
    queryKey: [
      CACHE_STANDARD_FILES,
      tableState.pageNumber,
      tableState.sortBy,
      tableState.sortingDirection,
      tableState.state.pagination.pageSize,
      tableState.state.search,
    ],
    queryFn: () =>
      api.iStandardOfferFilesList({
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
      }),
  });

  const table = useDataTable(
    {
      tableState,
      columns,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      getRowId: (row) => row.id,
      backendPagination: dataQ.data?.pagination,
    },
    {
      enableGlobalFilter: true,
    }
  );

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-4">
            <h2 className="m-0">{t("common:standard_file", { count: 2 })}</h2>
            <div className="whitespace-pre-line text-sm">
              {t("settings:standard_files.description")}
            </div>
          </div>
          <div>
            <Dialog
              className="md:max-w-3xl"
              render={({ onClose }) => (
                <CreateStandardFileDialog cacheKey={CACHE_STANDARD_FILES} onClose={onClose} />
              )}
              trigger={
                <Button variant="tertiary" className="flex-shrink-0" Icon={getIcon("add")}>
                  <span>
                    {t("common:create", {
                      entity: t("common:standard_file", { count: 1 }).toLowerCase(),
                    })}
                  </span>
                </Button>
              }
            />
          </div>
        </div>
        <div>
          <DataTable table={table} />
        </div>
      </div>
      <FullScreenFilePreview
        open={!!previewBaseFile}
        fileUrl={previewBaseFile?.fileUrl}
        onClose={() => setPreviewBaseFile(undefined)}
      />
    </>
  );
}
