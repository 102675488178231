import { Button } from "~/lib/ui";
import { Offer } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export function MobileViewControls({
  offer,
  acceptLoading,
  rejectLoading,
  onAccept,
  onReject,
  hideLabel,
}: {
  offer: Offer;
  acceptLoading: boolean;
  rejectLoading: boolean;
  onAccept: () => void;
  onReject: () => void;
  hideLabel?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className="px-4 py-3">
      {offer.offerStatus?.identifier === "accepted" ? (
        !hideLabel && (
          <span className="inline-flex items-center rounded-md border bg-green-50 px-2 py-1 text-xs font-medium text-green-700">
            {t("offers:status.accepted")}
          </span>
        )
      ) : offer.offerStatus?.identifier === "rejected" ? (
        !hideLabel && (
          <span className="inline-flex items-center rounded-md border bg-red-50 px-2 py-1 text-xs font-medium text-red-700">
            {t("offers:status.rejected")}
          </span>
        )
      ) : (
        <div className="flex w-full justify-end gap-4">
          <Button
            variant="tertiary"
            loading={acceptLoading}
            disabled={acceptLoading}
            onClick={onAccept}
          >
            {t("common:accept")}
          </Button>
          <Button
            variant="secondary"
            loading={rejectLoading}
            disabled={rejectLoading}
            onClick={onReject}
          >
            {t("common:reject")}
          </Button>
        </div>
      )}
    </div>
  );
}
