import { formatCurrency } from "~/lib/utils/number";
import StackedBarChart, { BarChartData } from "~/lib/ui/charts/stacked-bar-chart";
import { useTranslation } from "react-i18next";

export default function ContributionChart({ data = [] }: { data: Array<BarChartData> }) {
  const { t } = useTranslation();
  return (
    <StackedBarChart
      className="h-[18rem] md:h-[26rem]"
      data={data}
      yAxisFormatter={(value) => formatCurrency(value)}
      xAxisFormatter={(value) => `${t("common:week")} ${value}`}
      legendFormatter={(value) => t(`common:${value}`)}
      totalFormatter={(values) => {
        // Return sum of the "contribution" value only (do not sum price of time/products)
        const total = values.dataSeries.reduce((acc, curr) => acc + (curr.contribution || 0), 0);

        return formatCurrency(total);
      }}
    />
  );
}
