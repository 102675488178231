import { FrontpageFeedDTO } from "@apacta/sdk";
import { InvoiceCardDraftErp } from "./variants/invoice-card-draft-erp";
import { InvoiceCardDraftApacta } from "./variants/invoice-card-draft-apacta";
import { InvoiceCardErp } from "./variants/invoice-card-erp";
import { InvoiceCardApacta } from "./variants/invoice-card-apacta";

export const InvoiceCard = ({ data }: { data: FrontpageFeedDTO }) => {
  if (data.invoice?.isDraft) {
    if (data.invoice.erpId) {
      return <InvoiceCardDraftErp data={data} />;
    } else {
      return <InvoiceCardDraftApacta data={data} />;
    }
  } else {
    if (data.invoice?.erpId) {
      return <InvoiceCardErp data={data} />;
    } else {
      return <InvoiceCardApacta data={data} />;
    }
  }
};
