import { useMutation } from "@tanstack/react-query";
import { Card, CardRemoveDetails, Task } from "~/lib/planning/types";
import { CreateCardAndTaskRequest, UpdateCardListingOperationRequest } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useToasts } from "~/lib/toast/use-toasts";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export const usePlanningMutations = () => {
  const api = useAPI();
  const { show: addToast } = useToasts();
  const { t } = useTranslation();

  /* MUTATIONS START */
  const deleteCardMutation = useMutation({
    mutationFn: ({ originalCard, from }: CardRemoveDetails) =>
      api.iDeleteCard({
        cardId: originalCard.id,
        iDeleteCardRequest: { indexes: from },
      }),
    retry: 1,
    onSuccess: () => null,
  });

  const createCardMutation = useMutation({
    mutationFn: ({ args }: { args: CreateCardAndTaskRequest }) => {
      return new Promise<{ cards: Array<Card>; tasks: Array<Task> }>((resolve) => {
        api.createCardAndTask({ createCardAndTaskRequest: args }).then((res) => {
          resolve({
            cards: (res.data.cards as Array<Card>) ?? [],
            tasks: (res.data.tasks as Array<Task>) ?? [],
          });
        });
      });
    },
    onSuccess: () => null,
  });

  const updateCardListingMutation = useMutation({
    mutationFn: ({ args }: { args: UpdateCardListingOperationRequest & { showToast?: boolean } }) =>
      api.updateCardListing(args),
    onSuccess: (data, variables) => {
      if (variables.args.showToast) {
        addToast({
          variant: "success",
          title: t("planning:toast.update.success.title"),
          description: t("planning:toast.update.success.description"),
          Icon: CheckCircleIcon,
        });
      } else {
        return null;
      }
    },
    onError: (error, args) => {
      console.debug(
        "PlanningContext.updateCardListingMutation :: Error when updating card listing",
        error
      );
    },
  });
  /* MUTATIONS END */

  return { createCardMutation, updateCardListingMutation, deleteCardMutation };
};
