import { ComponentProps, useCallback, useState } from "react";
import { FormViewWrapper } from "./FormViewWrapper";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { ChangeStatusOperationRequest, ResponseError } from "@apacta/sdk";
import { useAPI } from "../api";
import { useLocale } from "../utils/date";
import { useTranslation } from "react-i18next";
import { Button, Dialog, getIcon, Icon } from "../ui";
import { TimeAgo } from "../ui/time-ago";
import { IconLine } from "~/pages/time-registration/_cmp/icon-line";
import { BADGE_CLASS, BADGE_RED } from "~/pages/projects/[id]/registrations";
import { twMerge } from "tailwind-merge";
import { FormRenderer } from "./components/form-renderer";
import { createAjv, JsonSchema, UISchemaElement } from "@jsonforms/core";
import { SendFormsDialog } from "~/pages/projects/[id]/registrations/_cmp/send-form-dialog";
import { ProjectType } from "~/pages/projects/[id]";
import { OptionalLink } from "../utils/routing/optional-link";
import { linkToEmployee, linkToProject, linkToProjectForm } from "../utils";
import { FullScreenFilePreview } from "../ui/media/full-screen-file-preview";
import { ActionButtons } from "../ui/action-buttons";
import { DEV } from "../auth/config";
import { MoveToProjectDialog } from "./move-to-project-dialog";
import { ErrorObject } from "ajv";
import ViewTabs from "../ui/tabs/view-tabs";
import { JSONEditor } from "./json-editor";
import _ from "lodash";
import BlockNavigation from "../navigation/block-navigation";
import { useToasts } from "../toast/use-toasts";
import { CACHE_PROJECTS } from "~/pages/projects";
import { CACHE_FORMS } from "~/pages/forms";

export function FormEditView(props: {
  mode: ComponentProps<typeof FormViewWrapper>["mode"];
  projectId?: never;
  project?: ProjectType;
  formId: string;
  onBackClick?: () => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const formId = props.formId;
  const [showDebug, setShowDebug] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);

  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [moveToProjectOpen, setMoveToProjectOpen] = useState(false);
  const [backendErrors, setBackendErrors] = useState<Array<ErrorObject>>([]);
  const toast = useToasts();

  const { timeAgo } = useLocale();

  const api = useAPI();

  const dataQ = useSuspenseQuery({
    queryKey: [CACHE_FORMS, formId],
    queryFn: () =>
      api.formView({
        formId: formId as string,
      }),
  });

  const form = dataQ.data?.data;
  const [internalData, setInternalData] = useState<unknown>(form.data);
  const [internalSchema, setInternalSchema] = useState<unknown>(form.template?.fields.schema ?? {});
  const [internalUiSchema, setInternalUiSchema] = useState<unknown>(form.template?.fields.uiSchema);

  const projectId = dataQ.data?.data.projectId;
  const project = props.project;

  const updateM = useMutation({
    mutationFn: () =>
      api.updateForm({
        formId: formId as string,
        createFormRequest: {
          fields: internalData as object,
        },
      }),
    onSettled: async () => {
      await dataQ.refetch(); // Refetch form
      queryClient.invalidateQueries({ queryKey: [CACHE_PROJECTS, form.projectId] }); // Make sure project is fresh
    },
    onSuccess: () => {
      setBackendErrors([]);
      toast.showTemplate("CHANGES_SAVED");
    },
    onError: async (error) => {
      toast.showTemplate("OPERATION_FAILED");
      if (error instanceof ResponseError && error.response.status === 422) {
        const err = await error.response.json();
        if (err.errors && Array.isArray(err.errors)) {
          setBackendErrors(err.errors);
        }
      }
    },
  });

  const changeStatusM = useMutation({
    mutationFn: (args: ChangeStatusOperationRequest) => api.changeStatus(args),
    onSettled: async () => {
      await dataQ.refetch();
    },
  });

  const handleSaveClick = useCallback(async () => {
    await updateM.mutateAsync();
    window.scrollTo(0, 0);
  }, [internalData, formId, updateM]);

  async function handleApprove() {
    return changeStatusM.mutateAsync({
      projectId: projectId as string,
      changeStatusRequest: {
        approve: true,
        forms: [formId as string],
      },
    });
  }

  async function handleReject() {
    return changeStatusM.mutateAsync({
      projectId: projectId as string,
      changeStatusRequest: {
        approve: false,
        forms: [formId as string],
      },
    });
  }

  // Dirty check
  const ajv = createAjv({ useDefaults: true });
  const formDataWithDefaults = form.data; // ajv mutates this below
  ajv.validate(internalSchema as JsonSchema, formDataWithDefaults);
  const isDirty = !_.isEqual(internalData, formDataWithDefaults);

  const actionsArea = (
    <div className="flex gap-2">
      <ActionButtons
        collapseAt={Infinity}
        actions={[
          {
            label: t("common:send"),
            onClick: () => setSendDialogOpen(true),
            Icon: getIcon("send"),
          },
          {
            label: t("common:show"),
            onClick: () => setShowFilePreview(true),
            Icon: getIcon("preview"),
          },
          {
            label: t("common:approve"),
            onClick: handleApprove,
            Icon: getIcon("approve"),
            disabled: !!form?.approved,
            collapseBehavior: "never",
            confirm: isDirty
              ? {
                  action: "ignore_unsaved_changes",
                  entity: "form",
                }
              : undefined,
          },
          {
            label: t("common:reject"),
            onClick: handleReject,
            disabled: !form?.approved,
            Icon: getIcon("reject"),
            confirm: isDirty
              ? {
                  action: "ignore_unsaved_changes",
                  entity: "form",
                }
              : undefined,
          },
          {
            label: t("forms:move_to_another_project"),
            onClick: () => setMoveToProjectOpen(true),
            Icon: getIcon("project"),
            collapseBehavior: "always",
            confirm: isDirty
              ? {
                  action: "ignore_unsaved_changes",
                  entity: "form",
                }
              : undefined,
          },
          {
            label: "Show form-debug (dev)",
            onClick: () => setShowDebug((prev) => !prev),
            Icon: getIcon("unknownType"),
            collapseBehavior: "always",
            isHidden: !DEV,
          },
        ]}
      />

      <Button
        variant="tertiary"
        onClick={handleSaveClick}
        loading={updateM.isPending}
        disabled={!isDirty}
      >
        {t("common:save")}
      </Button>
    </div>
  );

  const descriptionArea = (
    <>
      <div className="mb-2">
        <div
          title={t("common:created_by_x", {
            defaultValue: "Created by {{x}}",
            replace: { x: form.user?.fullName },
          })}
        >
          <IconLine icon="employee">
            <OptionalLink to={linkToEmployee(form?.userId)}>{form?.user?.fullName}</OptionalLink>
          </IconLine>
        </div>
        {props.mode === "stand-alone" && (
          <IconLine icon="project">
            <OptionalLink to={linkToProject(form.projectId)}>
              {form.project?.name ?? t("common:project", { count: 1 })}
            </OptionalLink>
          </IconLine>
        )}

        <IconLine icon="registration">
          {form?.created && (
            <>
              {t("common:created")} <TimeAgo date={form?.created} />
              .&nbsp;
            </>
          )}
          {form.modified && form.created && timeAgo(form.modified) !== timeAgo(form.created) && (
            <>
              {t("common:edited")} <TimeAgo date={form?.modified} />.
            </>
          )}
        </IconLine>
        {form.approved && (
          <IconLine icon="approve">
            {t("common:approved")} <TimeAgo date={form.approved} />
          </IconLine>
        )}
        {form.deleted && (
          <IconLine icon="delete">
            {t("common:deleted")} <TimeAgo date={form.deleted} />
          </IconLine>
        )}
      </div>
      <div className="flex flex-row gap-2">
        {form.isInvoiced ? (
          <span title={form.isInvoiced.toLocaleDateString()} className={BADGE_CLASS}>
            {t("common:invoiced", "Invoiced")}
          </span>
        ) : null}
        {form.deleted && (
          <span
            title={form.approved?.toLocaleDateString()}
            className={twMerge(BADGE_CLASS, BADGE_RED)}
          >
            {t("common:deleted")}
          </span>
        )}
      </div>
    </>
  );

  const title = form?.template?.name ?? t("forms:edit_form", "Edit form");
  const showMovedWarning = form && project && project?.id !== form.projectId;

  return (
    <>
      {showMovedWarning && (
        <OptionalLink to={linkToProjectForm(form?.projectId, formId)}>
          <div className="mb-6 flex items-center gap-2 border border-warning p-4 text-warning">
            <Icon name="warningTriangle" className="text-warning" size="medium" />
            {t("forms:registration_has_been_moved_to_another_project")}
          </div>
        </OptionalLink>
      )}
      <FormViewWrapper
        mode={props.mode}
        title={title}
        actions={actionsArea}
        description={descriptionArea}
        onBackClick={props.onBackClick}
      >
        <>
          {form && (
            <FormRenderer
              mode="edit"
              schema={internalSchema as JsonSchema}
              uischema={internalUiSchema as UISchemaElement}
              data={internalData ?? form.data}
              onChange={({ data: formData }) => setInternalData(formData)}
              additionalErrors={backendErrors}
            />
          )}
          {form && showDebug && (
            <div className="fixed left-0 top-0 z-50 h-full w-full overflow-scroll bg-white p-4">
              <ViewTabs
                tabs={[
                  { id: "data", label: "Data" },
                  { id: "schema", label: "Schema" },
                  { id: "uischema", label: "UISchema" },
                  { id: "formstate", label: "State difference" },
                  { id: "backenderrors", label: "Backend errors" },
                ]}
              >
                <JSONEditor value={internalData} onChange={setInternalData} />
                <JSONEditor value={internalSchema} onChange={setInternalSchema} />
                <JSONEditor value={internalUiSchema} onChange={setInternalUiSchema} />
                <div>
                  <h3>Dirty? {isDirty ? "Yes" : "No"}</h3>
                  {isDirty && (
                    <pre>
                      {JSON.stringify(
                        objDiff(internalData as any, formDataWithDefaults as any, [
                          "Internal",
                          "Backend",
                        ]),
                        null,
                        2
                      )}
                    </pre>
                  )}
                </div>
                <JSONEditor
                  value={backendErrors}
                  onChange={(val) => setBackendErrors(val as Array<ErrorObject>)}
                />
              </ViewTabs>
              <button onClick={() => setShowDebug(false)} className="absolute right-0 top-0 p-4">
                Close
              </button>
            </div>
          )}

          <Dialog
            open={sendDialogOpen}
            onOpenChange={() => setSendDialogOpen(false)}
            render={({ onClose }) => (
              <SendFormsDialog
                projectId={projectId as string}
                onClose={onClose}
                formIds={[formId!]}
                defaultEmail={project?.contactPerson?.email ?? project?.contact?.email ?? ""}
                defaultSubject={t("projects:send_registrations_subject", {
                  replace: { workaddress: project?.streetName || project?.name || "projekt" },
                  defaultValue: "Documentation for {{workaddress}}",
                })}
                defaultMessage={t("projects:send_registrations_message", {
                  replace: { name: project?.contactPerson?.name ?? project?.contact?.name ?? "" },
                  defaultValue: "Hello {{name}}",
                })}
                onBeforeSave={handleSaveClick}
                isDirty={isDirty}
              />
            )}
          />
          <FullScreenFilePreview
            open={showFilePreview}
            onClose={() => setShowFilePreview(false)}
            fileUrl={form.linkToPdf}
          />
          <Dialog
            open={moveToProjectOpen}
            onOpenChange={setMoveToProjectOpen}
            render={({ onClose }) => (
              <MoveToProjectDialog onClose={onClose} formId={form.id} projectId={form.projectId} />
            )}
          />
        </>
      </FormViewWrapper>
      <div className="mt-2 flex max-w-7xl justify-end px-8">
        <Button
          variant="tertiary"
          onClick={handleSaveClick}
          loading={updateM.isPending}
          disabled={!isDirty}
        >
          {t("common:save")}
        </Button>
      </div>
      <BlockNavigation when={isDirty} onSaveBeforeNavigate={handleSaveClick} />
    </>
  );
}

// Used to display state differences in the form debug view.
function objDiff(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
  labels?: Array<string>
) {
  const combined = { ...obj1, ...obj2 };
  const diff = Object.entries(combined).reduce(
    (acc, [key]) => {
      if (!_.isEqual(obj1[key], obj2[key])) {
        const label1 = labels?.[0] ?? "1";
        const label2 = labels?.[1] ?? "2";
        acc[key] = { [label1]: obj1[key], [label2]: obj2[key] };
        return acc;
      }
      return acc;
    },
    {} as Record<string, unknown>
  );
  return diff;
}
