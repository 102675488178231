import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui/icons/icon";
import { Button } from "~/lib/ui/buttons/button";
import { ReactNode } from "react";
import { ButtonVariant } from "~/lib/ui/buttons/shared/button.shared";

type ActionButton = {
  text: string;
  variant?: ButtonVariant;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

type ActionButtonGroup = Array<ActionButton>;

type SidePanelProps = {
  open: boolean;
  onClose: () => void;
  header: ReactNode;
  children: ReactNode;
  actionButtons: Array<ActionButtonGroup>;
  config?: {
    width?: `w-${string}`;
    maxWidth?: `max-w-${string}`;
  };
};

export default function SidePanel({
  open,
  onClose,
  header,
  children,
  actionButtons,
  config,
}: SidePanelProps) {
  return (
    <div
      className={twMerge(
        "fixed right-0 top-0 h-full bg-white shadow-xl transition-transform duration-200 ease-in-out",
        config?.width ?? "w-96",
        config?.maxWidth ?? "",
        open ? "translate-x-0" : "translate-x-full"
      )}
    >
      <div className="relative h-full w-full py-6">
        {/* Close Icon */}
        <button
          className="absolute right-2 top-2 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hover focus:ring-offset-2"
          onClick={() => onClose()}
        >
          <Icon name="close" size="medium" />
        </button>

        {/* Body */}
        <div className="flex h-full flex-col gap-4">
          {/* Header */}
          {header}

          {/* Content */}
          {children}

          {/* Bottom Controls */}
          <div className="flex flex-col gap-6 justify-self-end px-6">
            <hr />
            <div className="flex w-full justify-between pr-14">
              {actionButtons.map((group, idx) => (
                <div className="flex gap-4" key={`sidepanel-button-group-${idx}`}>
                  {group.map((button, index) => (
                    <Button
                      key={`sidepanel-button-group-${idx}-${index}`}
                      disabled={button.disabled}
                      variant={button.variant}
                      className={button.className}
                      onClick={button.onClick}
                    >
                      {button.text}
                    </Button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
