export function randomRecord<ObjType extends { id: string }>(obj: Record<string, ObjType>) {
  return randomItem(toArray(obj));
}

export function randomItem<ArrType>(items: Array<ArrType>, allowUndefined = false) {
  const totalItems = allowUndefined ? items.length + 1 : items.length;
  const randomIdx = Math.floor(Math.random() * totalItems);
  if (randomIdx > items.length) {
    return undefined;
  }
  return items[randomIdx];
}

export function toArray<RecordType>(arr: Record<string, RecordType>) {
  return Object.entries(arr).map(([, value]) => value);
}

export function toRecord<EntityType extends { id: string }>(
  entities: Array<EntityType>
): Record<string, EntityType> {
  return entities.reduce(
    (acc, entity) => {
      acc[entity["id"]] = entity;
      return acc;
    },
    {} as Record<string, EntityType>
  );
}

/**
 * Used to parse date as ISO date string
 * @param d {Date|null} - The date to convert
 * @returns {string} The date string in YYYY-MM-DD format, without time
 */
export function getISODate(d?: Date | null): string | void {
  if (!d) {
    return;
  }
  return d.toISOString().split("T")[0];
}

export function dateToPeriod(timestamp: Date, period: "day"): { start: Date; end: Date } {
  const startDay = getISODate(timestamp);
  if (!startDay) {
    throw new Error("Cannot create period from undefined timestamp");
  }
  const start = new Date(startDay);
  if (period === "day") {
    const endDay = new Date(new Date(start).getTime() + 60 * 60 * 24 * 1000);
    return { start, end: endDay };
  }
  throw new Error("Unsupported period");
}

export function randomDate(start: Date, end: Date) {
  const s = start.getTime();
  const e = end.getTime();
  return new Date(s + Math.random() * (e - s));
}
