import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonLegacy from "../ui/buttons/button-legacy";

export function FeedbackPanel({ onFeedback }: { onFeedback: (feedback: string) => void }) {
  const [feedback, setFeedback] = useState<string>("");
  const [done, setDone] = useState(false);
  const { t } = useTranslation();

  function handleFeedback(feedbackText: string) {
    onFeedback?.(feedbackText);
    setDone(true);
  }

  return (
    <div className="mt-10 max-w-xl bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        {!done ? (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("errors:feedback.title", "We're on it. But you can help!")}
            </h3>

            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>{t("errors:feedback.help_us_fix_bugs")}</p>
            </div>
            <form className="mt-5 sm:items-center">
              <div className="w-full">
                <textarea
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                  placeholder={t("errors:feedback.step_by_step")}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <ButtonLegacy
                  variant="tertiary"
                  disabled={feedback === ""}
                  onClick={() => handleFeedback(feedback)}
                >
                  {t("errors:feedback.send_error_details")}
                </ButtonLegacy>
              </div>
            </form>
          </>
        ) : (
          <p>{t("errors:feedback.thanks")}</p>
        )}
      </div>
    </div>
  );
}
