import { twMerge } from "tailwind-merge";
import { formatCurrency } from "~/lib/utils/number";
import { OrderLine, OrderLinesColumns } from "~/lib/ui/order-lines/lib/types";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";
import { useTranslation } from "react-i18next";

export function OrderLinesBuilderCostPart({
  line,
  editMode,
  parentId,
  width,
}: {
  line: Partial<OrderLine>;
  editMode: boolean;
  parentId?: string | null;
  width: string;
  columnOptions: OrderLinesColumns["costPrice"];
}) {
  const { updateLine } = useOrderLinesBuilder();
  const { t } = useTranslation();

  const showEditMode = editMode && !line.childOfferLines;
  // Only apply input padding if we are not in edit mode or if the line has children
  const applyInputPadding = !editMode || line.childOfferLines;

  return (
    <div
      style={{ width }}
      className={twMerge(
        "flex-shrink-0 px-3 text-right text-zinc-900",
        applyInputPadding ? "py-7" : "py-5"
      )}
    >
      {showEditMode ? (
        <NumberFormatInput
          defaultValue={line.costPrice}
          className="text-right text-sm"
          placeholder={t("common:cost_price")}
          onChange={(v) => updateLine(["costPrice"], { costPrice: v }, line.identifier, parentId)}
        />
      ) : (
        <span>{line.costPrice ? formatCurrency(line.costPrice, { currency: "DKK" }) : null}</span>
      )}
    </div>
  );
}
