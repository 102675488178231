// Purpose: Adds posts and uploads to feed
import { useTranslation } from "react-i18next";
import { useSession } from "~/lib/auth/session";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";
import { UploadButton } from "~/lib/ui/buttons/upload-button";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export function FeedInput({
  placeholder,
  onUploadButtonClick,
  onOpenDialog,
}: {
  placeholder: string;
  onOpenDialog: () => void;
  onUploadButtonClick: (args: { message?: string; files: Array<File> }) => Promise<unknown>;
}) {
  const { me } = useSession();
  const { t } = useTranslation();

  async function handleUploadButton(files: FileList) {
    await onUploadButtonClick({ files: Array.from(files) });
  }

  return (
    <div className={twMerge("mb-8 flex max-w-xl flex-col gap-4 rounded-md bg-white p-4 shadow-sm")}>
      <div className="flex flex-row gap-4">
        <UserAvatar user={me?.user} />
        <input
          type="text"
          role="button"
          className="block h-12 w-full rounded-md border-gray-300  bg-white text-gray-700 shadow-sm transition-colors duration-300 focus:border-hover focus:outline-none focus:ring-transparent sm:text-sm"
          placeholder={placeholder}
          onClick={onOpenDialog}
          readOnly
        ></input>
      </div>
      <hr />
      <div className="flex gap-4">
        <UploadButton onSelected={handleUploadButton} variant="secondary" multiple>
          {t("common:share_file")}
        </UploadButton>
      </div>
    </div>
  );
}

export function PreviewContainer({ children }: { children: ReactNode; onRemove?: () => void }) {
  return <div>{children}</div>;
}
