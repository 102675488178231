import { Invoice } from "@apacta/sdk";
import { TFunction } from "i18next";
import { TEMPLATE_ENTITIES } from "~/lib/ui/dialog/templates/confirmation-templates";

// Single source of invoice-type name
export function getInvoiceType({ invoice, t }: { invoice?: Invoice; t: TFunction }): string {
  if (!invoice) return "";

  if (invoice.grossPayment !== undefined && invoice.grossPayment < 0)
    return t("common:credit_note", { count: 1, defaultValue: "Credit note" });
  if (invoice.isDraft) return t("common:draft");
  return t("common:invoice", { count: 1 });
}

export function getInvoiceEntity(invoice: Invoice): TEMPLATE_ENTITIES {
  if (!invoice) throw new Error("Invoice is required");

  if (invoice.grossPayment !== undefined && invoice.grossPayment < 0) return "credit_note";
  if (invoice.isDraft) return "draft";
  return "invoice";
}
