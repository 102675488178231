import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { Outlet } from "react-router";
import { Suspense } from "react";
import {
  DocumentIcon,
  DocumentTextIcon,
  IdentificationIcon,
  SwatchIcon,
} from "@heroicons/react/24/outline";

export default function SettingsPage() {
  const { t } = useTranslation();

  return (
    <PageLayout title={t("settings:title")}>
      <PageTabs
        tabs={[
          {
            id: "company",
            label: t("settings:tabs.company"),
            Icon: IdentificationIcon,
            to: "",
          },
          {
            id: "templates",
            label: t("settings:tabs.templates"),
            Icon: DocumentTextIcon,
            to: "templates/",
          },
          {
            id: "labels",
            label: t("settings:tabs.labels"),
            Icon: SwatchIcon,
            to: "labels/",
          },
          {
            id: "files",
            label: t("settings:tabs.files"),
            Icon: DocumentIcon,
            to: "files/",
          },
        ]}
      />
      <Suspense>
        <Outlet />
      </Suspense>
    </PageLayout>
  );
}
