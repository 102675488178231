import { GetMe200Response } from "@apacta/sdk";
import { useSession } from "./session";

export function useMe() {
  const { me } = useSession();
  if (!me) {
    throw new Error(
      "Attempting to access 'me' from session and it's undefined. Session should be initialised by now."
    );
  }
  return me as GetMe200Response;
}
