import {
  IListProjectsDirectionEnum,
  KpiCardHoursSummedIndividualEntry,
  ProjectGetKpiCardHoursSummedSubPage200ResponseDataInner,
} from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getExpandedRowModel } from "@tanstack/react-table";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useLocale } from "~/lib/utils/date";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { randomId } from "~/lib/utils/string";
import { BadgeLabel } from "~/lib/ui/badge-label";
import { EmployeeCell } from "~/lib/ui/table/cells/employee-cell";

export default function EmployeesSummedTab() {
  const api = useAPI();
  const { t } = useTranslation();
  const { formatHours } = useLocale();

  const columns = useDataColumns<ProjectGetKpiCardHoursSummedSubPage200ResponseDataInner>(
    (columnHelper) => [
      columnHelper.accessor("user.fullName", {
        header: t("common:employee", { count: 1 }),
        id: "name",
        cell: ({ row }) => <EmployeeCell user={row.original.user} />,
        meta: {
          skeletonType: "avatar",
        },
      }),
      columnHelper.accessor("totalHours", {
        header: t("common:quantity"),
        id: "count",
        cell: ({ getValue }) => formatHours(getValue() ?? 0),
        meta: {
          className: "text-right",
        },
      }),
    ]
  );
  const tableState = useDataTableState({
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
  });

  const employeeParams = useEmployeesParams();

  const dataQ = useQuery({
    queryKey: [
      "employees.specified",
      CACHE_EMPLOYEES,
      employeeParams,
      tableState.state.pagination,
      tableState.state.sorting,
    ],
    queryFn: () =>
      api.usersGetSummed({
        page: tableState.pageNumber,
        limit: tableState.state.pagination.pageSize,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection as IListProjectsDirectionEnum,
        ...employeeParams,
      }),
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      getRowId: (row) => row.id,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand(row) {
        if (!row.original.entries || row.original.entries.length < 1) return false;
        return row.original.entries.some((item) => item.count > 0);
      },
    }
  );

  return (
    <div className="flex flex-col gap-2">
      <h2 className="mb-2"> {t("users:summed_overview", "Summed overview")}</h2>
      <DataTable
        table={table}
        renderExpandedRow={({ row }) => {
          return (
            <table>
              <tbody>
                {row.original.entries?.map((subrow: KpiCardHoursSummedIndividualEntry) => {
                  return subrow.count ? (
                    <tr key={randomId()} className="bg-gray-50">
                      <td>&nbsp;</td>
                      <td className="pad-left-20 pad-right-12 text-left">{subrow.label}</td>
                      <td className="text-right">
                        {subrow.isAbsent ? (
                          <BadgeLabel className="ml-2 px-3 py-1 text-xs" backgroundColor="#ffffff">
                            {t("users:absence")}
                          </BadgeLabel>
                        ) : null}
                        &nbsp;
                        <EntryValue entry={subrow} />
                      </td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          );
        }}
      />
    </div>
  );
}

function EntryValue({ entry }: { entry: KpiCardHoursSummedIndividualEntry }) {
  const { formatHours } = useLocale();
  switch (entry.type) {
    case "driving":
      return `${entry.count} km`; // We don't support other units here
    case "time_entry":
      return formatHours(entry.count);
  }
}
