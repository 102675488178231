import { useCallback, useMemo, useState } from "react";
import * as htmlparser2 from "htmlparser2";
import { useAPI } from "../api";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import { Icon } from "../ui";
import { twMerge } from "tailwind-merge";
import Badge from "../ui/badge";
import { useMount } from "../lifecycle-helpers";

export function useFetchReleaseNotes() {
  const api = useAPI();
  const q = useSuspenseQuery({
    queryKey: ["release-notes"],
    queryFn: () => api.getReleaseNotes(),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const feed = useMemo(() => {
    const f = htmlparser2.parseFeed(q.data, {
      xmlMode: true,
    });
    return f;
  }, [q.data]);

  return feed;
}

export function ReleaseNotesNavItem() {
  const { t } = useTranslation();
  const f = useReleaseNotes();

  const handleClick = () => {
    f.markRead();
  };

  return (
    <ErrorBoundary fallback={null}>
      <div className="w-full">
        <a
          href={f.feed?.link}
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
          className="w-full"
        >
          <div className="m-0 flex w-full rounded-lg p-0 px-2 py-2 text-shade-200 hover:bg-hover hover:text-white">
            <div className="flex w-full flex-row justify-between">
              <div>
                <Icon
                  name="news"
                  className={twMerge(
                    "mr-3 inline h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover"
                  )}
                />
                {t("common:news", "News")}
              </div>
              {f.unreadCount > 0 && (
                <div>
                  <Badge label={f.unreadCountString} className="ml-4 bg-red-700 text-white" />
                </div>
              )}
            </div>
          </div>
        </a>
      </div>
    </ErrorBoundary>
  );
}

const LAST_READ_KEY = "APACTA_RELEASE_NOTES_LAST_READ";

function useReleaseNotes() {
  const f = useFetchReleaseNotes();
  const [lastRead, setLastRead] = useState<Date>();

  useMount(() => {
    const lr = localStorage.getItem(LAST_READ_KEY);
    if (lr) {
      setLastRead(new Date(lr));
    }
  });

  const markRead = useCallback(() => {
    // Mark last read timestamp
    const newRead = new Date();
    localStorage.setItem(LAST_READ_KEY, newRead.toISOString());
    setLastRead(newRead);
  }, []);

  const unreadCount = useMemo(() => {
    if (!f) return 0;
    const count = f.items.filter((i) => {
      if (!lastRead) return true;
      if (!i.pubDate) return false;
      const idate = new Date(i.pubDate);
      return idate > lastRead;
    }).length;

    return count;
  }, [lastRead]);

  return {
    unreadCount: unreadCount satisfies number,
    unreadCountString: unreadCount > 5 ? "5+" : unreadCount.toString(),
    markRead,
    feed: f,
  };
}
