import { useRef } from "react";
import { Button } from "../ui";

// Renders a TextArea and a change button. Only change when button is pressed.
export function JSONEditor(props: { value: unknown; onChange?: (v: unknown) => void }) {
  const uiRef = useRef<HTMLTextAreaElement>(null);

  function handleChange() {
    if (uiRef.current) {
      try {
        props.onChange?.(JSON.parse(uiRef.current.value));
        uiRef.current.setCustomValidity("");
      } catch (e) {
        uiRef.current.setCustomValidity("Invalid JSON");
      }
    }
  }

  return (
    <div className="flex h-full flex-col gap-4">
      <textarea
        ref={uiRef}
        defaultValue={JSON.stringify(props.value, null, "\t")}
        className="text-normal h-full min-h-[20rem] overflow-y-auto font-mono invalid:border-red-500"
      />
      {props.onChange && (
        <Button variant="primary" onClick={handleChange}>
          Change
        </Button>
      )}
    </div>
  );
}
