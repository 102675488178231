import { getInitials } from "~/lib/utils";
import {
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PencilIcon,
  PhoneIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ContactPerson } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

import { Dropdown } from "~/lib/ui/dropdown";
import { twMerge } from "tailwind-merge";

export function ContactCard({
  contact,
  onEdit,
  onDelete,
}: {
  contact: ContactPerson;
  onEdit: (v: ContactPerson) => void;
  onDelete: (v: ContactPerson) => void;
}) {
  const { t } = useTranslation();

  return (
    <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="flex w-full justify-between space-x-6 p-6">
        <div className="flex flex-1 items-center gap-4 truncate">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-300 text-lg font-semibold text-zinc-800">
            {getInitials(contact.name)}
          </div>
          <div>
            <div className="flex items-center space-x-3">
              <h3 className="m-0 truncate text-sm font-medium text-gray-900">{contact.name}</h3>
            </div>
            <p className="truncate text-sm text-gray-500">{contact.title}</p>
          </div>
        </div>
        <div>
          <Dropdown
            trigger={
              <div className="rounded-md p-1 hover:bg-tertiary hover:text-white">
                <EllipsisVerticalIcon className="h-8 w-8" />
              </div>
            }
            options={{ content: { align: "center" } }}
          >
            <Dropdown.Item onSelect={() => onEdit?.(contact)} Icon={PencilIcon}>
              {t("common:edit")}
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => onDelete?.(contact)} Icon={TrashIcon}>
              {t("common:delete")}
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <div>
        <div className="flex flex-col divide-y divide-gray-200 text-sm">
          <div className="flex flex-1 py-1">
            <div className="flex flex-row items-center px-5">
              <a
                href={contact.email ? `mailto:${contact.email}` : undefined}
                className={twMerge(
                  "rounded-md p-1 text-gray-400",
                  contact.email && "hover:bg-tertiary hover:text-white"
                )}
              >
                <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
              </a>
              <span className={twMerge("ml-3", !contact.email && "text-gray-400")}>
                {contact.email ? contact.email : t("common:not_available")}
              </span>
            </div>
          </div>
          <div className="flex flex-1 py-1">
            <div className="flex flex-row items-center px-5">
              <a
                href={contact.phone ? `tel:${contact.phone}` : undefined}
                className={twMerge(
                  "rounded-md p-1 text-gray-400",
                  contact.phone && "hover:bg-tertiary hover:text-white"
                )}
              >
                <PhoneIcon className="h-6 w-6 " aria-hidden="true" />
              </a>
              <span className={twMerge("ml-3", !contact.phone && "text-gray-400")}>
                {contact.phone ? contact.phone : t("common:not_available")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
