import { twMerge } from "tailwind-merge";
import { Currency } from "../../currency";

export function CurrencyCell({
  value = 0,
  warnOnZero,
  warnOnPositive,
}: {
  value?: number;
  warnOnZero?: boolean;
  warnOnPositive?: boolean;
}) {
  const showWarning = (() => {
    if (warnOnZero) {
      return value === 0;
    }
    if (warnOnPositive) {
      return value > 0;
    }
    return false;
  })();

  return (
    <Currency className={twMerge("text-right", showWarning && "text-orange-600")}>{value}</Currency>
  );
}
