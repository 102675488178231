import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { Suspense } from "react";
import { useToastOnError } from "~/lib/utils/hooks";
import { DocumentDuplicateIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import PageTabs from "~/lib/ui/tabs/page-tabs";

export default function ExpenseLinePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const { id: expenseLineId } = useParams();
  const queryKey = "expense-lines";
  const { data, isLoading, error } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [queryKey, expenseLineId],
    queryFn: () => api.expenseLinesView({ expenseId: expenseLineId as string }),
  });

  useToastOnError(error);

  const expenseLine = data?.data;
  const pageTabs = [
    {
      id: "overview",
      label: t("common:overview"),
      to: "./",
      Icon: DocumentDuplicateIcon,
    },
    {
      id: "price-history",
      label: t("common:price_history", "Price history"),
      to: "price-history",
      Icon: DocumentTextIcon,
    },
  ];
  return (
    <PageLayout title={expenseLine?.text ?? ""} onBackClick={() => navigate(-1)}>
      <>
        <PageTabs tabs={pageTabs} />
        <Suspense>{!isLoading && <Outlet context={{ expenseLine }} />}</Suspense>
      </>
    </PageLayout>
  );
}
