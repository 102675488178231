import { useState } from "react";
import { CardContainer } from "../projects/_cmp/card-container";
import { KPICardWrapper } from "../projects/_cmp/kpi-card-wrapper";
import { PercentBar } from "../projects/_cmp/percent-bar";
import { ComparisonBarChartCard } from "../projects/_cmp/comparison-bar-chart-card";

export default function KPIPlayground() {
  const [percentBar, setPercentBar] = useState(100);
  return (
    <div className="p-6">
      <h1>KPI Playground</h1>
      <CardContainer label="Percentage Bar">
        <KPICardWrapper label="test">
          <input type="number" onChange={(e) => setPercentBar(e.target.valueAsNumber)} />
          <label>Vanilla</label>
          <PercentBar percentage={percentBar} />
          <label>Primary Green</label>
          <PercentBar percentage={percentBar} primaryClassName="bg-green-600 text-white" />
          <label>Overshoot good</label>
          <PercentBar percentage={percentBar} overshootClassName="bg-green-600 text-white" />
          <label>Overshoot bad</label>
          <PercentBar percentage={percentBar} overshootClassName="bg-red-600 text-white" />
        </KPICardWrapper>
      </CardContainer>
      <BarGraphs />
    </div>
  );
}

function BarGraphs() {
  const [estimated, setEstimated] = useState<
    Parameters<typeof ComparisonBarChartCard>[0]["estimated"]
  >({
    label: "Estimated",
    value: 4000,
  });

  const [actual, setActual] = useState<Parameters<typeof ComparisonBarChartCard>[0]["actual"]>({
    label: "Actual",
    value: 5000,
  });
  return (
    <CardContainer label="Bar Graphs">
      <div className="rounded-md bg-white p-4">
        <label>Estimated kr</label>
        <br />
        <input
          type="number"
          onChange={(e) => setEstimated({ ...estimated, value: e.target.valueAsNumber })}
          value={estimated.value}
        />
        <p />
        <label>Actual kr</label>
        <br />
        <input
          type="number"
          onChange={(e) => setActual({ ...actual, value: e.target.valueAsNumber })}
          value={actual.value}
        />
      </div>
      <ComparisonBarChartCard estimated={estimated} actual={actual} label="Test" unit="currency" />
    </CardContainer>
  );
}
