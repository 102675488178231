import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { linkToFormNew } from "~/lib/utils";
import { formatDate, useLocale } from "~/lib/utils/date";
import { i18n } from "~/lib/i18n/i18n";
import { capitalize } from "~/lib/utils/string";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { createColumnHelper, getExpandedRowModel } from "@tanstack/react-table";
import { UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { timeStringToDecimal, Week } from "~/lib/utils/date/date-utils";
import { WeeklyEmployee } from "~/pages/employees/index/weekly";
import { notNullOrUndefinedFilter } from "~/lib/utils/filters";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { getIcon } from "~/lib/ui";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { CACHE_FORMS } from "~/pages/forms";
import { ProjectCell } from "~/lib/ui/table/cells/project-cell";

// Simplicity is the key to success
type Entry = UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner;

type SpecifiedDetails = {
  formDate: Date;
  formId: string;
  quantity: number;
  unit: string;
  projectName: string;
  projectId: string;
  workDescription: string;
  entries: Array<Entry>;
  breakTime?: string;
  formPdf?: string | null;
};

const columnHelper = createColumnHelper<SpecifiedDetails>();

export default function SpecifiedPage() {
  const { t } = useTranslation();
  const { formatHours } = useLocale();
  const api = useAPI();
  const queryClient = useQueryClient();

  const [previewFormURL, setPreviewFormURL] = useState<string | undefined>();

  const { employee } = useOutletContext<{
    weeks: Array<Week>;
    employee: WeeklyEmployee;
    isFetching: boolean;
  }>();

  // get array of all projects ordered by form dates
  const specifiedData: Array<SpecifiedDetails> = employee?.weeks
    .flatMap((week) => {
      return week.projects.flatMap((project) => {
        return project.forms.map((form) => {
          if (!form.date) return;
          return {
            formDate: form.date,
            formId: form.id,
            quantity: form.entries.reduce(
              (acc, entry) => acc + (entry.timeUnit === "" ? entry.total : 0),
              0
            ),
            unit: "hourly",
            projectName: project.name,
            projectId: project.id,
            workDescription: form.workDescription ?? "",
            formPdf: form.formPdf,
            entries: form.entries,
            breakTime: form.pause && timeStringToDecimal(form.pause) > 0 ? form.pause : undefined, // Sometimes we get 00:00 as break time
          };
        });
      });
    })
    .filter(notNullOrUndefinedFilter)
    .sort((a, b) => (+a.formDate - +b.formDate < 0 ? 1 : -1));

  const deleteFormMutation = useMutation({
    mutationKey: [CACHE_FORMS],
    mutationFn: (formId: string) => api.deleteForm({ formId: formId }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_FORMS] });
    },
  });

  const columns = useDataColumns(() => [
    columnHelper.accessor("formDate", {
      header: t("common:date"),
      id: "formDate",
      meta: {
        className: "align-top",
      },
      enableSorting: true,
      cell: ({ row }) => (
        <OptionalLink to={linkToFormNew(row.original.formId)} openInNewTab={true}>
          {formatDate(row.original.formDate, i18n.resolvedLanguage, { shortDate: true })}
        </OptionalLink>
      ),
    }),
    columnHelper.accessor("quantity", {
      header: t("common:quantity"),
      id: "count",
      cell: ({ getValue }) => <div className="max-w-12">{formatHours(getValue() ?? 0)}</div>,
      meta: {
        className: "text-left align-top",
      },
    }),
    columnHelper.accessor("unit", {
      header: t("common:unit"),
      id: "unit",
      cell: ({ getValue }) => (
        <div className="max-w-12">{capitalize(t(`common:${getValue()}`))}</div>
      ),
      meta: {
        className: "text-left align-top",
      },
    }),
    columnHelper.accessor("projectName", {
      header: t("common:project", { count: 1 }),
      enableSorting: true,
      id: "projectName",
      meta: {
        cellClassName: "w-[24rem] align-top",
      },
      cell: ({ row }) => (
        <ProjectCell projectId={row.original.projectId} projectName={row.original.projectName} />
      ),
    }),
    columnHelper.accessor("workDescription", {
      header: t("common:work_description"),
      enableSorting: true,
      id: "workDescription",
      meta: {
        cellClassName: "w-[36rem] align-top",
      },
      cell: ({ row }) => <div className="line-clamp-2">{row.original.workDescription}</div>,
    }),
    columnHelper.display({
      id: "actions",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          collapseAt={4}
          size="small"
          actions={[
            {
              Icon: getIcon("preview"),
              label: t("common:preview"),
              isHidden: !row.original.formPdf,
              onClick: () => setPreviewFormURL(row.original.formPdf!),
            },
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              confirm: {
                entity: "form",
                action: "delete",
              },
              onClick: async () => await deleteFormMutation.mutateAsync(row.original.formId),
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState({
    expanded: true,
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      data: specifiedData,
      mode: "client",
      getRowId: (row) => row.formId,
      isLoading: !employee,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand(row) {
        return row.original.entries.length > 0;
      },
    }
  );

  return (
    <div className="flex flex-col gap-2">
      <h2 className="mb-2"> {t("users:specified_overview", "Specified overview")}</h2>
      <DataTable
        table={table}
        renderExpandedRow={({ row }) => {
          return (
            <table className="border">
              <tbody>
                {row.original.entries?.map((entry) => {
                  return (
                    <tr key={entry.id} className="bg-gray-50">
                      <td className="w-[9.2rem] min-w-[9.2rem] ">&nbsp;</td>
                      <td className="w-28 min-w-28">
                        {entry.timeUnit === ""
                          ? formatHours(entry.total)
                          : `${entry.total} ${entry.timeUnit}`}
                      </td>
                      <td>{entry.entryType}</td>
                      <td></td>
                      <td className="text-right"></td>
                    </tr>
                  );
                })}
                {row.original.breakTime && (
                  <tr key={`pause-${row.original.formId}`} className="bg-gray-50">
                    <td className="w-[9.2rem] min-w-[9.2rem] ">&nbsp;</td>
                    <td className="w-28 min-w-28">{row.original.breakTime}</td>
                    <td>{t("common:break_time")}</td>
                    <td></td>
                    <td className="text-right"></td>
                  </tr>
                )}
              </tbody>
            </table>
          );
        }}
      />
      <FullScreenFilePreview
        open={!!previewFormURL}
        fileUrl={previewFormURL}
        onClose={() => setPreviewFormURL(undefined)}
      />
    </div>
  );
}
