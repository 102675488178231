import { Checkbox } from "~/lib/ui/form-elements";
import { Label } from "~/lib/ui";
import { Timepicker } from "~/lib/ui/timepicker";
import { useTranslation } from "react-i18next";
import { UserMobileAppPreferences } from "@apacta/sdk";

export function MobileAppSettings({ data }: { data: UserMobileAppPreferences }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-2 text-gray-500">
        {" "}
        {t("users:notifications_settings", "Notification settings for employee")}
      </div>
      <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
        <div className="flex flex-1 flex-col gap-6">
          <div className="sm:flex sm:gap-2">
            <Checkbox
              id="empoyee-notification-task"
              className="inline disabled:hover:border-gray-300 disabled:hover:bg-gray-200"
              disabled={true}
              checked={data.enableNotificationsTasks}
            />
            <label htmlFor="empoyee-notificanpption-task">{t("common:Tasks")}</label>
          </div>
          <div className="sm:flex sm:gap-2">
            <Checkbox
              id="employee-form-reminders"
              className="inline disabled:hover:border-gray-300 disabled:hover:bg-gray-200"
              disabled={true}
              checked={data.enableNotificationsFormsDailyReminder}
            />
            <label htmlFor="employee-form-reminders">
              {t("common:form_reminders", "Form reminders")}
            </label>
          </div>
          {data.enableNotificationsFormsDailyReminder && (
            <div className="flex-wrap sm:flex sm:gap-2">
              {data.formsDailyReminderPreferences &&
                Object.entries(data.formsDailyReminderPreferences).map(([key, value]) => (
                  <div className="max-w-[8rem]" key={key}>
                    <Label htmlFor={key} className="mb-2 block text-sm font-medium">
                      {t(`common:${key}`)}
                    </Label>
                    <Timepicker value={value} disabled={true} />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
