import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";

export function useActivities() {
  const api = useAPI();
  const statusQ = useQuery({
    queryKey: ["activities"],
    queryFn: () => api.iGetActivities({}),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });

  const items = statusQ.data?.data ?? [];

  return { statuses: items, isFetching: statusQ.isFetching };
}
