import Holidays from "date-holidays";

export class ApactaHolidays extends Holidays {
  constructor(country: string) {
    super(country);
    this.setCustomHolidays();
  }

  /**
   * Set custom holidays
   * Follow the formatting rules from https://github.com/commenthol/date-holidays/blob/master/docs/specification.md#grammar-for-day-rules
   * @private
   */
  private setCustomHolidays() {
    this.setHoliday("1st Friday after 11-01", { name: "J-Dag", type: "public" });
  }
}
