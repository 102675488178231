import { ControlProps, rankWith } from "@jsonforms/core";
import { LocationType, LocationView } from "../components/location-view";
import { isControlEntity } from "./testers";
import { useFormsContext } from "../components/form-renderer";
import { useEffect, useState } from "react";
import { useToasts } from "~/lib/toast/use-toasts";
import { useTranslation } from "react-i18next";
import { Button, getIcon, Label } from "~/lib/ui";
import { getDefaults } from "../getDefaults";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";

export function LocationRenderer(props: ControlProps) {
  const { mode } = useFormsContext();
  const { t } = useTranslation();
  const toast = useToasts();
  const [loading, setLoading] = useState(false);

  const defaults = getDefaults(props.schema);
  const data = props.data ?? defaults;

  function handleCoordinateChange(location: LocationType) {
    const newData = { ...data, ...location };
    props.handleChange(props.path, newData);
  }

  function detectLocation() {
    return new Promise((resolve, reject) => {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          handleCoordinateChange({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLoading(false);
          resolve(true);
        },
        () => {
          toast.showTemplate("OPERATION_FAILED", {
            title: t("forms:location_error"),
            description: t("forms:location_error_description"),
          });
          setLoading(false);
          reject();
        }
      );
    });
  }

  // Attempt to get location if we're creating a form
  useEffect(() => {
    if (mode !== "create") return;
    if (!data?.automatic) return;
    detectLocation();
  }, []);

  return (
    <>
      <Label required={props.required}>{props.label ?? t("common:location")}</Label>
      {data.automatic === false && !data.latitude && !data.longitude && (
        <Button Icon={getIcon("location")} onClick={detectLocation} variant="secondary">
          {t("forms:driving.destination.use_current_location")}
        </Button>
      )}
      <LocationView loading={loading} location={data} label={getLabelForControl(props)} />
    </>
  );
}

export const LocationTester = rankWith(5, isControlEntity("form_location"));
