import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ApactaHolidays } from "~/lib/utils/date/apacta-holidays";

export function useHolidays(date: Date) {
  const [localDate, setLocalDate] = useState<Date>(date ?? new Date());
  const [lastYear, setLastYear] = useState<number>(localDate.getFullYear() - 1);
  const [thisYear, setThisYear] = useState<number>(localDate.getFullYear());
  const [nextYear, setNextYear] = useState<number>(localDate.getFullYear() + 1);

  useEffect(() => {
    if (localDate.getFullYear() === thisYear) {
      return;
    }

    setLastYear(localDate.getFullYear() - 1);
    setThisYear(localDate.getFullYear());
    setNextYear(localDate.getFullYear() + 1);
  }, [localDate]);

  const { i18n } = useTranslation();

  const hd = new ApactaHolidays("DK");

  const { data: dataLastYear } = useQuery({
    queryKey: ["holidays", i18n.resolvedLanguage, lastYear],
    queryFn: async () => hd.getHolidays(lastYear, i18n.resolvedLanguage),
  });

  const { data: dataThisYear } = useQuery({
    queryKey: ["holidays", i18n.resolvedLanguage, thisYear],
    queryFn: async () => hd.getHolidays(thisYear, i18n.resolvedLanguage),
  });

  const { data: dataNextYear } = useQuery({
    queryKey: ["holidays", i18n.resolvedLanguage, nextYear],
    queryFn: async () => hd.getHolidays(nextYear, i18n.resolvedLanguage),
  });

  // merge dataFrom and dataTo into a single array and remove duplicates by converting to a Set
  const holidays = Array.from(
    new Set([...(dataLastYear ?? []), ...(dataThisYear ?? []), ...(dataNextYear ?? [])])
  );

  const isHoliday = (d: Date) => {
    return hd.isHoliday(d);
  };

  return { holidays, setDate: setLocalDate, isHoliday };
}
