import { useTranslation } from "react-i18next";
import { Icon } from "../icons/icon";
import { usePreferences } from "~/lib/preferences/use-preferences";
import { Button } from "../buttons/button";

export function UnsupportedBrowserWarning() {
  const { t } = useTranslation();
  const [p, setP] = usePreferences();

  if (!isOldSafari(navigator.userAgent)) return null;
  if (p.dismissBrowswerWarning === true) return null;

  return (
    <div className="relative bg-red-100 p-6">
      <div className="mb-2 flex flex-row items-center gap-4 font-bold">
        <Icon size="medium" name="warningTriangle" />
        <div>{t("errors:unsupported_browser")}</div>
      </div>
      {t("errors:unsupported_browser_description")}
      <Button
        className="absolute right-1 top-1 text-shade-500 hover:underline"
        onClick={() => setP("dismissBrowswerWarning", true)}
      >
        {t("ui:do_not_show_again", "Don't show again")}
      </Button>
    </div>
  );
}

// Detect Safari below 14.4
function isOldSafari(ua: string) {
  // If it's not Safari, false
  if (!ua.match(/^Mozilla\/5\.0.*Safari\//)) return false;
  if (ua.match(/Chrome|CriOS|FxiOS|OPiOS|mercury/i)) return false;

  // Detect Safari version
  const match = ua.match(/Version\/(\d+)\.(\d+)?/);
  if (!match || match.length < 3) return false;
  const major = parseInt(match[1], 10);
  const minor = parseInt(match[2], 10);
  if (major > 14) return false;
  if (major < 14) return true;
  if (major === 14 && minor < 4) return true;

  return false;
}
