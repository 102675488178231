import { Icon } from "../icons/icon";
import { t } from "i18next";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { useLocale } from "~/lib/utils/date";
import { useSession } from "~/lib/auth/session";

const DINERO_EXPIRE_TIME = 1000 * 60 * 60 * 24 * 7; // 7 days in milliseconds

export function DineroWarning() {
  const session = useSession();
  const { me } = session;
  const locale = useLocale();

  if (!me) return null;
  if (!me.dineroTokenExpireDate) return null;

  // If it expires in more than 7 days, don't show the warning
  if (me.dineroTokenExpireDate.getTime() - DINERO_EXPIRE_TIME > new Date().getTime()) return null;

  return (
    <div className="flex flex-row items-center gap-4 bg-red-100 p-6">
      <Icon size="medium" name="warningTriangle" />
      <OptionalLink to={me.dineroAuthUrl} openInNewTab={true}>
        {t(
          "errors:dinero_expiry_warning",
          "Your Dinero integration expires in {{period}}. Click here to renew it.",
          {
            replace: { period: locale.timeAgo(me.dineroTokenExpireDate) },
          }
        )}
      </OptionalLink>
      {}
    </div>
  );
}
