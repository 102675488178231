import { useTranslation } from "react-i18next";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "~/lib/ui";
import { Suspense } from "react";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { useDebouncedValue } from "~/lib/debounce/use-debounce";
import { useAPI } from "~/lib/api";
import { useSuspenseQuery } from "@tanstack/react-query";
import { DataFilterItem } from "~/lib/ui/data-table/data-filter";
import Lozenge from "~/lib/ui/data-table/filters/filter-components/lozenge";
import SingleEmployeeFilterSubmenu from "~/lib/ui/data-table/filters/filter-components/single-employee-filter-submenu";

const SingleEmployeeFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useTypedSearchParams<{ employeeId?: string }>();
  const [debouncedValue, { setValue, isPending }] = useDebouncedValue("");

  const clearSearchParam = () => setSearchParams("employeeId", undefined);

  return (
    <>
      <DropdownMenu.Label className="flex justify-between py-1.5 pl-1.5 text-xs font-semibold leading-6">
        <Lozenge
          label={t("common:employee", { count: 1 })}
          size="small"
          iconName="employee"
          variant="purple"
        />
        {searchParams.employeeId !== undefined && (
          <div
            onClick={clearSearchParam}
            className="flex cursor-pointer items-center gap-1 rounded-md px-1.5 font-normal hover:bg-shade-100"
          >
            <Icon name="remove" />
            <span>{t("common:clear")}</span>
          </div>
        )}
      </DropdownMenu.Label>

      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger className=" group relative flex h-6 select-none items-center rounded-md px-1.5 pl-6 text-sm leading-none outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-shade-100 data-[highlighted]:data-[state=open]:bg-shade-100 data-[state=open]:bg-shade-100">
          <div className="absolute left-0 inline-flex w-6 items-center justify-center">
            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-shade-200 text-xs">
              {searchParams.employeeId ? 1 : 0}
            </div>
          </div>
          {t("common:select_entity", { entity: t("common:employee", { count: 1 }).toLowerCase() })}
          <div className=" group- ml-auto pl-[20px] ">
            <Icon name="chevronRight" />
          </div>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className="data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade min-w-64 rounded-md border bg-white p-1.5 shadow-md will-change-[opacity,transform]"
            sideOffset={2}
            alignOffset={-5}
          >
            <div className="relative flex items-center">
              <Icon name="search" className="absolute left-1.5 h-4 w-4" />
              <input
                type="text"
                onChange={(e) => setValue(e.currentTarget.value)}
                defaultValue={debouncedValue}
                placeholder={t("common:search")}
                className="w-full border-none bg-transparent pb-0.5 pl-7 pr-1.5 pt-1 text-sm outline-none ring-0 focus:outline-none focus:ring-0"
              />
            </div>
            <DropdownMenu.Separator className="m-1.5 h-px bg-shade-200" />
            <Suspense fallback={<SkeletonLoader template="list" />}>
              <SingleEmployeeFilterSubmenu query={debouncedValue} />
            </Suspense>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    </>
  );
};

const SingleEmployeeFilterLozenge = ({ value }: { value: unknown }) => {
  const [, setSearchParams] = useTypedSearchParams<{ employeeId?: string }>();
  const api = useAPI();
  const { t } = useTranslation();

  const employeeQ = useSuspenseQuery({
    queryKey: ["employee", { value }],
    queryFn: async () => await api.getUsers({ ids: [value as string] }),
  });

  const data = employeeQ.data?.data.length ? employeeQ.data?.data[0] : undefined;

  const handleRemoveEmployee = () => {
    setSearchParams("employeeId", undefined);
  };

  return (
    <Lozenge
      label={t("common:employee", { count: 1 })}
      iconName="employee"
      variant="purple"
      value={data?.fullName ?? "N/A"}
      onRemove={() => handleRemoveEmployee()}
    />
  );
};

export const SINGLE_EMPLOYEE_FILTER: DataFilterItem = {
  identifier: "employeeId",
  lozenge: SingleEmployeeFilterLozenge,
  render: SingleEmployeeFilter,
};
