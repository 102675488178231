import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Popover } from "~/lib/ui/popover/popover";
import { forwardRef, Fragment, Ref, useImperativeHandle, useState } from "react";
import { useMount } from "~/lib/lifecycle-helpers";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { EditOfferRequestOfferLinesRuleEnum } from "@apacta/sdk";
import { i18n } from "~/lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";

type OfferLineRuleType = "show_all" | EditOfferRequestOfferLinesRuleEnum;

type OfferLineRule = {
  id: OfferLineRuleType;
  name: string;
};
type OfferLinesRuleSelectorProps = {
  formState: OfferFormState;
  initialRuleId?: string;
  disabled?: boolean;
};
export type OfferLinesRuleSelectorRef = {
  getRule: () => OfferLineRule;
};

export const availableRules: () => Array<OfferLineRule> = () => [
  {
    id: "show_all",
    name: i18n.t("offers:rules.show_all"),
  },
  {
    id: "distribution_hours_over_products",
    name: i18n.t("offers:rules.distribution_hours_over_products"),
  },
  {
    id: "gather_offer_lines",
    name: i18n.t("offers:rules.gather_offer_lines"),
  },
  {
    id: "show_only_product_bundles",
    name: i18n.t("offers:rules.show_only_product_bundles"),
  },
  {
    id: "group_by_product_and_hours",
    name: i18n.t("offers:rules.group_by_product_and_hours"),
  },
  {
    id: "total_price_line",
    name: i18n.t("offers:rules.total_price_line"),
  },
];

const OfferLinesRuleSelector = forwardRef<OfferLinesRuleSelectorRef, OfferLinesRuleSelectorProps>(
  OfferLinesRuleSelectorInner
);
function OfferLinesRuleSelectorInner(
  { formState, initialRuleId, disabled }: OfferLinesRuleSelectorProps,
  ref: Ref<OfferLinesRuleSelectorRef>
) {
  const { t } = useTranslation();
  const rules = availableRules();
  const [selectedRule, setSelectedRule] = useState<OfferLineRule>(rules[0]);
  const { orderLines } = useOrderLinesBuilder();

  const hasProductBundles = orderLines.some((line) => line.type === "bundle");

  useMount(() => {
    if (initialRuleId) {
      setSelectedRule(rules.find((r) => r.id === initialRuleId) ?? rules[0]);
    }
  });

  useImperativeHandle(ref, () => ({
    getRule: () => selectedRule,
  }));

  const handleSelectRule = (rule: OfferLineRule) => {
    setSelectedRule(rule);
    formState.setValues({ offerLinesRule: rule.id });
  };

  return (
    <div className="flex justify-end">
      <div className="flex flex-col">
        <div className="flex justify-end">
          <label
            htmlFor="rule-selector"
            className="mb-1 block text-left text-sm font-medium text-gray-700"
          >
            {t("offers:price_layout")}
          </label>
        </div>
        {disabled ? (
          <div
            id="rule-selector"
            className="flex items-center justify-between gap-2 py-2 text-base font-medium"
          >
            <span>{selectedRule.name}</span>
          </div>
        ) : (
          <Popover
            config={{ align: "end" }}
            triggerRender={() => (
              <div
                id="rule-selector"
                className="flex items-center justify-between gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium shadow-sm transition-colors duration-200 hover:border-hover hover:text-hover"
              >
                <>
                  <span>{selectedRule.name}</span>
                  <ChevronDownIcon className="h-5 w-5" />
                </>
              </div>
            )}
          >
            {(close) => (
              <div className="min-w-[10em] rounded-lg border bg-white p-2 text-base shadow-md">
                <ul>
                  {rules.map((rule) => (
                    <Fragment key={rule.id}>
                      {rule.id === "show_only_product_bundles" && !hasProductBundles ? null : (
                        <li
                          className="cursor-pointer rounded-lg p-2 hover:bg-shade-100"
                          onClick={async () => {
                            close();
                            handleSelectRule(rule);
                          }}
                        >
                          {rule.name}
                        </li>
                      )}
                    </Fragment>
                  ))}
                </ul>
              </div>
            )}
          </Popover>
        )}
      </div>
    </div>
  );
}

OfferLinesRuleSelector.displayName = "OfferLinesRuleSelector";
export { OfferLinesRuleSelector };
