import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export function PageError({ title, description }: { title: string; description: string }) {
  return (
    <div className="h-screen py-8 sm:pb-18 sm:pt-20">
      <div className="mx-auto flex h-full max-w-7xl flex-col items-center justify-center px-4 sm:flex-row sm:px-6 md:px-8">
        <div className="flex flex-col gap-4 rounded-lg bg-white p-12 shadow-md">
          <div className="flex items-center gap-4">
            <ExclamationTriangleIcon className="h-16 w-16 text-orange-500" />
            <h1 className="m-0">{title}</h1>
          </div>
          <div className="whitespace-pre-line">{description}</div>
        </div>
      </div>
    </div>
  );
}
/*

 */
