import { API_KEY_NAME } from "./config";

/**
 * @version 1.0.0 Skip the secure part of the cookie when devevloping locally
 * @version 1.0.1 Add the secure part of the cookie when devevloping locally due to phase out: https://developers.google.com/privacy-sandbox/3pcd/prepare/prepare-for-phaseout
 */
const COOKIE_POSTFIX = "SameSite=None; Secure; max-age=31536000; path=/;";

export interface SessionCookie {
  apiKey: string;
  language?: string;
  scope: string;
}

export function restoreApiKey() {
  const value = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${API_KEY_NAME}=`))
    ?.split("=")[1];
  if (value) {
    const sessionCookie: SessionCookie = JSON.parse(value);
    return sessionCookie;
  }
  return undefined;
}

export function saveCookie(cookie: SessionCookie) {
  if (!cookie.apiKey || !cookie.scope) {
    throw new Error("Cannot save an empty cookie");
  }
  const persistedData = { apiKey: cookie.apiKey, language: cookie.language, scope: cookie.apiKey };
  document.cookie = `${API_KEY_NAME}=${JSON.stringify(persistedData)}; ${COOKIE_POSTFIX}`;
}

export function deleteCookie() {
  document.cookie = `${API_KEY_NAME}=; ${COOKIE_POSTFIX}`;
}
