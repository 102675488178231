import { Invoice } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import Badge from "~/lib/ui/badge";

export function InvoiceBadge({ invoice }: { invoice: Invoice }) {
  const { t } = useTranslation();
  // Badge that shows "sent to X" or "draft" or "booked by X"
  const label = (() => {
    if (invoice.isDraft) {
      return t("common:draft");
    }
    return t("invoices:booked_by_x", {
      replace: {
        x: invoice.integration?.name ?? "Apacta",
      },
      defaultValue: "Booked by {{x}}",
    });
  })();
  return <Badge variant="green" label={label} />;
}
