import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ClassicFrame } from "~/lib/ui/helpers/classic-frame";
import { SkeletonLoader } from "~/lib/ui/skeleton";

export default function CompanySettingsPage() {
  const { t } = useTranslation();
  const [loading, setIsLoading] = useState(true);

  return (
    <div className="flex flex-col gap-8">
      {loading && <SkeletonLoader template="document" />}
      <ClassicFrame
        iframeURL="/companies/companies/edit"
        disableLocationUpdates
        hideTopbar
        onLoaded={() => setIsLoading(false)}
      />
    </div>
  );
}
