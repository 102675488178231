import FinancialOverview from "./_cmp/financial-overview";
import TaskOverview from "./_cmp/task-overview";

export default function FrontpageOverviewPage() {
  return (
    <div className="flex flex-col gap-8">
      <FinancialOverview />
      <TaskOverview />
    </div>
  );
}
