import { twMerge } from "tailwind-merge";
import { formatCurrency } from "~/lib/utils/number";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";

export function OrderLinesBuilderPricePart({
  line,
  editMode,
  parentId,
  width,
}: {
  line: Partial<OrderLine>;
  editMode: boolean;
  parentId?: string | null;
  width: string;
}) {
  const { updateLine } = useOrderLinesBuilder();
  return (
    <div style={{ width }} className={twMerge("flex-shrink-0 px-3 py-5 text-right text-zinc-900")}>
      {editMode && (!parentId || line.childOfferLines) && (
        <NumberFormatInput
          className="text-right text-sm"
          defaultValue={line.sellingPrice}
          onChange={(v) => updateLine(["sellingPrice"], { sellingPrice: v }, line.identifier)}
        />
      )}
      {!editMode && (
        <span>
          {line.sellingPrice ? formatCurrency(line.sellingPrice, { currency: "DKK" }) : null}
        </span>
      )}
    </div>
  );
}
