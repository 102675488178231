// First day of any year
const ARBITRARY_START_OF_YEAR_DATE = "2022-01-01";

// First day of any week when the first day of the week is Monday
const ARBITRARY_START_OF_WEEK_DATE = "2022-08-01";

const generateMonthNames = (language: string): Array<string> => {
  const m = [];
  const options: Intl.DateTimeFormatOptions = { month: "long" };

  for (let i = 0; i < 12; i++) {
    const d = new Date(ARBITRARY_START_OF_YEAR_DATE);
    d.setMonth(i);

    const mn = d.toLocaleDateString(language, options);

    m.push(mn.charAt(0).toUpperCase() + mn.slice(1));
  }
  return m;
};

const generateDayNames = (language: string): Array<string> => {
  const m = [];
  const options: Intl.DateTimeFormatOptions = { weekday: "narrow" };

  for (let i = 0; i < 7; i++) {
    const d = new Date(ARBITRARY_START_OF_WEEK_DATE);
    d.setDate(d.getDate() + i);

    const mn = d.toLocaleDateString(language, options);

    m.push(mn.charAt(0).toUpperCase() + mn.slice(1));
  }
  return m;
};

const monthNames = (language: string): Array<{ index: number; name: string }> => {
  const mNames = generateMonthNames(language);

  return [
    {
      index: 0,
      name: mNames[0],
    },
    {
      index: 1,
      name: mNames[1],
    },
    {
      index: 2,
      name: mNames[2],
    },
    {
      index: 3,
      name: mNames[3],
    },
    {
      index: 4,
      name: mNames[4],
    },
    {
      index: 5,
      name: mNames[5],
    },
    {
      index: 6,
      name: mNames[6],
    },
    {
      index: 7,
      name: mNames[7],
    },
    {
      index: 8,
      name: mNames[8],
    },
    {
      index: 9,
      name: mNames[9],
    },
    {
      index: 10,
      name: mNames[10],
    },
    {
      index: 11,
      name: mNames[11],
    },
  ];
};
const dayNames = (language: string): Array<{ index: number; name: string }> => {
  const dNames = generateDayNames(language);

  return [
    {
      index: 0,
      name: dNames[0],
    },
    {
      index: 1,
      name: dNames[1],
    },
    {
      index: 2,
      name: dNames[2],
    },
    {
      index: 3,
      name: dNames[3],
    },
    {
      index: 4,
      name: dNames[4],
    },
    {
      index: 5,
      name: dNames[5],
    },
    {
      index: 6,
      name: dNames[6],
    },
  ];
};

export { monthNames, dayNames };
