import { Layout, LayoutProps, RankedTester, rankWith, uiTypeIs } from "@jsonforms/core";
import { renderChildren } from "./renderChildren";
import { getLabelForControl } from "../getLabelForControl";

export const GroupRenderer = (props: LayoutProps) => {
  const layout = props.uischema as Layout;
  return (
    <div className="my-2">
      <h4>{getLabelForControl(props)}</h4>
      {renderChildren({
        schema: props.schema,
        path: props.path,
        enabled: props.enabled,
        elements: layout.elements,
        uischema: props.uischema,
      })}
    </div>
  );
};
export const GroupRendererTester: RankedTester = rankWith(100, uiTypeIs("Group"));
