import { Popover } from "~/lib/ui/popover/popover";
import { useTranslation } from "react-i18next";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import TextInput from "~/lib/ui/form-elements/text-input";
import { StandardOfferFileTypeEnum } from "@apacta/sdk";
import { Icon, Label } from "~/lib/ui";

export default function OfferViewTitleSection({
  formState,
  editMode,
  onTypeChange,
}: {
  formState: OfferFormState;
  editMode: boolean;
  onTypeChange: (type: StandardOfferFileTypeEnum) => Promise<void>;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4 text-2xl">
        <div className="flex flex-1 gap-4">
          <div>
            <Label>Type</Label>
            <Popover
              config={{ align: "start" }}
              disabled={!editMode}
              triggerRender={() => (
                <div className="flex h-12 items-center justify-between gap-6 rounded-md border border-gray-300 bg-white px-3 py-1 text-2xl text-gray-700 shadow-sm transition-colors duration-200 hover:border-hover hover:text-hover">
                  <span>{t(`offers:type.${formState.getValue("type")}`)}</span>
                  <Icon name="chevronDown" size="medium" />
                </div>
              )}
            >
              {(close) => (
                <div className="min-w-[10em] rounded-lg border bg-white p-2 text-base shadow-md">
                  <ul>
                    <li
                      className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
                      onClick={() => {
                        onTypeChange("quote");
                        close();
                      }}
                    >
                      {t("offers:type.quote")}
                    </li>
                    <li
                      className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
                      onClick={() => {
                        onTypeChange("estimate");
                        close();
                      }}
                    >
                      {t("offers:type.estimate")}
                    </li>
                  </ul>
                </div>
              )}
            </Popover>
          </div>
          <TextInput
            {...formState.register("title")}
            disabled={!editMode}
            label={t("offers:offer_name")}
            required={true}
            placeholder={t("common:enter", { text: t("offers:offer_name").toLowerCase() })}
            size="2xl"
            className="bg-white"
          />
        </div>
      </div>
    </div>
  );
}
