import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { DragEvent, ReactNode, useId, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { UploadButton } from "~/lib/ui/buttons/upload-button";

/**
 * A file upload area that supports drag and drop.
 * Note: This component does not handle the actual file upload.
 * Note: This component does not use `react-dnd` as it is not compatible with strict mode and file uploads.
 * @param onSelected - Callback when files are selected
 * @param className - Classname for the container
 * @param renderArea - Render function for the upload area
 * @param multiple - Allow multiple files to be selected
 * @param accept - Hint to browsers to only show files that are allowed for the current input
 */
export function DNDUpload({
  onSelected,
  className,
  renderArea = DefaultArea,
  multiple,
  accept,
}: {
  onSelected: (files: FileList) => Promise<void> | void;
  className?: string;
  renderArea?: ({
    onClick,
    isOver,
  }: {
    onClick: (files: FileList) => void;
    isOver: boolean;
    multiple?: boolean;
    accept?: string;
  }) => ReactNode;
  multiple?: boolean;
  accept?: string;
}) {
  const uploadId = useId();
  const uploadButtonRef = useRef<HTMLInputElement>(null);
  const [isOver, setIsOver] = useState<boolean>(false);

  const handleFileSelect = async (files: FileList) => {
    if (!files || files.length === 0) {
      return;
    }
    await handleFileUpload(files);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOver(false);
    const files = e.dataTransfer.files;
    if (!files || files.length === 0) {
      return;
    }

    await handleFileUpload(files);
  };

  const handleFileUpload = async (files: FileList) => {
    await onSelected(files);
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    setIsOver(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    setIsOver(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOver(true);
  };

  return (
    <>
      <div
        className={className}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {renderArea({ onClick: handleFileSelect, isOver, multiple, accept })}
      </div>
      <input
        ref={uploadButtonRef}
        accept={accept}
        multiple={multiple}
        id={uploadId}
        className="sr-only"
        type="file"
      />
    </>
  );
}

function DefaultArea({
  onClick,
  isOver,
  multiple,
  accept,
}: {
  onClick: (files: FileList) => void;
  isOver: boolean;
  multiple?: boolean;
  accept?: string;
}) {
  const { t } = useTranslation();
  return (
    <div
      className={twMerge(
        isOver ? "border-hover text-hover" : "border-shade-400 text-shade-400",
        "group flex h-72 items-center justify-center rounded-lg border-2 border-dashed transition-colors duration-300 ease-in-out"
      )}
    >
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-2">
          <div>
            <CloudArrowUpIcon
              className={twMerge(
                isOver ? "text-hover" : "text-primary",
                "transition-color h-24 w-24 duration-300 ease-in-out"
              )}
            />
          </div>
          <div>{t("ui:dnd.drag_drop_files_upload")}</div>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-24">
            <hr className="border-t-1 border-shade-400" />
          </div>
          {t("common:or").toUpperCase()}
          <div className="w-24">
            <hr className="border-t-1 border-shade-400" />
          </div>
        </div>
        <div>
          <UploadButton variant="primary" onSelected={onClick} multiple={multiple} accept={accept}>
            {t("ui:select_file", { count: 2 })}
          </UploadButton>
        </div>
      </div>
    </div>
  );
}
