import { IGetProject200ResponseData } from "@apacta/sdk";
import { BriefcaseIcon, BuildingOffice2Icon, UserIcon } from "@heroicons/react/24/outline";
import { linkToCustomer } from "~/lib/utils";
import { OptionalLink } from "~/lib/utils/routing/optional-link";

export function InlineProjectDetails({ project }: { project: IGetProject200ResponseData }) {
  return (
    <div className="mt-4 flex gap-x-4 text-xs">
      {project.streetName && (
        <div className="flex items-center gap-2">
          <BuildingOffice2Icon className="h-5 w-5" />
          <span>
            {project.streetName}, {project?.zipCode} {project?.cityName}
          </span>
        </div>
      )}

      {project.contact?.name && (
        <OptionalLink to={project.contactId ? linkToCustomer(project.contactId) : undefined}>
          <div className="flex items-center gap-2">
            <BriefcaseIcon className="h-5 w-5" />
            <span>{project.contact?.name}</span>
          </div>
        </OptionalLink>
      )}
      {project.contactPerson?.name && (
        <>
          <div className="flex items-center gap-2">
            <UserIcon className="h-5 w-5" />
            <span>{project.contactPerson.name}</span>
          </div>
        </>
      )}
    </div>
  );
}
