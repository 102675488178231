import { Suspense } from "react";
import {
  ArrayLayoutProps,
  composePaths,
  findUISchema,
  rankWith,
  isObjectArray,
  and,
  or,
} from "@jsonforms/core";
import { Button, Label, getIcon } from "~/lib/ui";
import { JsonFormsDispatch } from "@jsonforms/react";
import { range } from "~/lib/utils/utils";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { childWithAncestor } from "./childWithAncestor";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { isEntityOf } from "./testers";
import { useAPI } from "~/lib/api";
import { Popover } from "~/lib/ui/popover/popover";
import { DNDUpload } from "~/lib/ui/d-n-d-upload";
import { useToasts } from "~/lib/toast/use-toasts";

// Custom List renderer for Products
export function PhotoLayoutRenderer(props: ArrayLayoutProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const toast = useToasts();
  const {
    addItem,
    data,
    uischemas,
    schema,
    path,
    uischema,
    rootSchema,
    moveDown,
    moveUp,
    removeItems,
  } = props;

  const dataArray = range(data);

  async function handleAdd(f: FileList) {
    const fa = Array.from(f);
    const res = await api.iFormUploadFiles({
      files: fa,
    });
    if (!res.success) {
      toast.showTemplate("OPERATION_FAILED");
      return;
    }
    res.data.forEach((file) => {
      const item = {
        // id, url, mime, comment
        id: file.id,
        url: file.url,
        mime: file.mime,
        comment: undefined,
      };
      addItem(path, item)();
    });
  }

  return (
    <div>
      <Label>{props.label}</Label>
      {dataArray.length === 0 && (
        <DNDUpload
          accept="image/*"
          onSelected={async (files) => {
            await handleAdd(files);
          }}
          multiple={true}
        />
      )}
      {dataArray.map((i) => {
        const childPath = composePaths(path, `${i}`);
        const listItemUiSchema = findUISchema(
          uischemas ?? [],
          schema,
          uischema.scope,
          path,
          "HorizontalLayout",
          uischema,
          rootSchema
        );

        return (
          <Fragment key={childPath}>
            <Suspense fallback={<SkeletonLoader template="box" className="h-16" />}>
              <div className="flex w-full flex-row items-center justify-between gap-4 hover:bg-shade-50">
                <JsonFormsDispatch
                  uischema={childWithAncestor(listItemUiSchema, uischema)}
                  schema={schema}
                  path={composePaths(path, `${i}`)}
                  enabled={props.enabled}
                />
                <Button onClick={removeItems?.(path, [i])} Icon={getIcon("delete")} size="small" />
              </div>
            </Suspense>
          </Fragment>
        );
      })}
      {dataArray.length > 0 && (
        <div className="mt-4">
          <Popover
            triggerRender={() => (
              <Button Icon={getIcon("add")} variant="secondary" size="small">
                {t("common:photo", { count: 2, defaultValue: "Photos" })}
              </Button>
            )}
          >
            {(onClose) => (
              <div className="rounded-md border bg-white p-4 shadow-md">
                <DNDUpload
                  accept="image/*"
                  onSelected={async (files) => {
                    await handleAdd(files);
                    onClose();
                  }}
                  multiple={true}
                />
              </div>
            )}
          </Popover>
        </div>
      )}
    </div>
  );
}

export const photoListTester = rankWith(
  4,
  and(
    isObjectArray,
    or(isEntityOf("photo_without_gps"), isEntityOf("photo_with_gps"), isEntityOf("photo_signature"))
  )
);
