import * as Tooltip from "@radix-ui/react-tooltip";
import { ReactNode, useState } from "react";
import { NavItem } from "./navigation-item.type";
import Badge from "~/lib/ui/badge";
import { twMerge } from "tailwind-merge";
import { useSidebarContext } from "../../../sidebar-context";

/**
 * Wraps Navigation Items with a Tooltip when the sidebar is collapsed. Otherwise renders directly.
 */
export function NavItemWrapper({
  children,
  item,
  triggerAsChild = true,
}: {
  children: ReactNode;
  item?: NavItem;
  triggerAsChild: boolean;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { sidebarCollapsed, sidebarType } = useSidebarContext();

  const showTooltip = sidebarCollapsed && sidebarType === "laptop";

  function handleOpenChange(open: boolean) {
    setIsOpen(open);
  }

  if (!showTooltip) return children;

  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root open={isOpen} onOpenChange={handleOpenChange}>
        <Tooltip.Trigger asChild={triggerAsChild}>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="left"
            sideOffset={-5}
            asChild
            className="z-navigation-menu-popover"
          >
            <div className="flex h-11 flex-row items-center rounded-r-lg bg-hover p-2 text-white">
              {item?.name}
              {item?.badge ? (
                <Badge
                  className={twMerge("ml-4", item.badge.className)}
                  size="sm"
                  label={item.badge.label}
                />
              ) : null}
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
