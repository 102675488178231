import { ControlProps, isStringControl, or, rankWith } from "@jsonforms/core";
import { LabelInput } from "~/lib/ui";
import { isControlEntity } from "./testers";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { useTranslation } from "react-i18next";

export function TextControlRenderer(props: ControlProps) {
  const { t } = useTranslation();
  return (
    <LabelInput
      type="text"
      label={getLabelForControl(props)}
      className="w-full min-w-[0.8rem]"
      required={props.required}
      defaultValue={props.data}
      placeholder={
        props.uischema.options?.["placeholder"] ??
        t("common:write_here_placeholder", "Write here...")
      }
      error={props.errors}
      onChange={(e) => {
        props.handleChange(props.path, e.currentTarget.value);
      }}
    />
  );
}
export const TextControlRendererTester = rankWith(
  2,
  or(isStringControl, isControlEntity("small_text"))
);
