import { and, composePaths, ControlProps, or, rankWith, uiTypeIs } from "@jsonforms/core";
import { isDecendantOf } from "./testers";
import { useTranslation } from "react-i18next";
import { MediaPreviewLink } from "~/lib/ui/media/";
import { LabelInput } from "~/lib/ui";
import { capitalize } from "~/lib/utils/string";

export function PhotoRendererHorizontal(props: ControlProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-4">
      <div className="w-48">
        <MediaPreviewLink
          mimeType={props.data.mime}
          href={props.data.url}
          title={props.data.comment}
        />
      </div>
      <div className="flex-1">
        <LabelInput
          label={capitalize(t("common:comment"))}
          value={props.data?.comment ?? ""}
          placeholder={t("common:write_here_placeholder")}
          onChange={(e) =>
            props.handleChange(composePaths(props.path, "comment"), e.currentTarget.value)
          }
        />
      </div>
    </div>
  );
}

export const supportedEntityTypes = [
  isDecendantOf("photo_without_gps"),
  isDecendantOf("photo_with_gps"),
  isDecendantOf("photo_signature"),
];

export const photoTester = rankWith(
  3,
  and(uiTypeIs("HorizontalLayout"), or(...supportedEntityTypes))
);
