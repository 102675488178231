import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { InvoiceActivityLogItem } from "./activity-log-content-item";
import { Icon } from "~/lib/ui";
import { CACHE_INVOICES } from "..";

/**
 * Trigger for Invoice ActivityLog. Shows the latest event.
 * @returns
 */
export function InvoiceActivityLogDetailTrigger(props: { invoiceId: string }) {
  const api = useAPI();
  const { t } = useTranslation();
  const dataQ = useQuery({
    queryFn: () => api.getInvoiceActivityLog({ invoiceId: props.invoiceId }),
    queryKey: [CACHE_INVOICES, props.invoiceId, "activity-log"],
  });

  const lastActivity = (() => {
    if (!dataQ.data?.data || dataQ.data.data.length === 0) return;
    return dataQ.data.data[0];
  })();

  return (
    <div className="flex w-full flex-col items-start">
      <h3>{t("common:activity_log")}</h3>
      <div className="flex w-full items-center justify-between rounded-md bg-white p-4">
        <div className="flex w-full flex-col items-start ">
          {dataQ.isLoading ? (
            <p>{t("common:loading")}...</p>
          ) : (
            <div>{lastActivity ? <InvoiceActivityLogItem item={lastActivity} /> : null}</div>
          )}
        </div>
        {true && (
          <div className="flex w-24 grow items-center justify-end gap-2 text-sm">
            <div>{t("common:show_all")}</div>
            <div>
              {false ? (
                <Icon name="chevronUp" className="h-auto w-4" />
              ) : (
                <Icon name="chevronDown" className="h-auto w-4" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
