import { CVRLookup } from "@apacta/sdk";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "../api";
import { useDebouncedValue } from "../debounce/use-debounce";
import { AutoCompleteInput } from "./autocomplete";

// TODO: Make less dangerous to use. The value/defaultValue stuff is brittle atm.
export function CVRInput({
  onChange,
  onSelect,
  initialValue,
  value,
  label,
  placeholder,
  error,
}: {
  label?: string;
  onChange?: (val: string) => void;
  onSelect?: (item: CVRLookup) => void;
  initialValue?: string;
  value?: string;
  placeholder?: string;
  error?: string;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [debouncedValue, { setValue, isPending }] = useDebouncedValue(value || initialValue || "");
  const q = useQuery({
    queryKey: ["cvrLookup", debouncedValue],
    queryFn: ({}) => api.cvrSearchByName({ companyName: debouncedValue }),
    enabled: !!debouncedValue,
  });

  return (
    <div>
      <AutoCompleteInput
        label={label}
        items={q.data?.data ?? []}
        loading={q.isLoading || isPending}
        defaultValue={value ? undefined : initialValue}
        valueFn={(item) => item.vat?.toString() || ""}
        error={error}
        name="cvr"
        value={value}
        displayFn={(item) => {
          return (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row gap-x-4">
                <span className="font-semibold">{item.name}</span>
                <span className="font-gray-500">{`${item.address}, ${item.zipcode}`}</span>
              </div>
              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {item.vat}
              </span>
            </div>
          );
        }}
        placeholder={placeholder ?? t("ui:cvr_input.placeholder")}
        onSelect={(i) => {
          onSelect?.(i);
        }}
        onChange={(val) => {
          setValue(val);
          onChange?.(val);
        }}
      />
    </div>
  );
}
