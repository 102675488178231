import { OfferLine } from "@apacta/sdk";
import { calculateChildLinesCost } from "~/lib/ui/order-lines/lib/calculations";
import { OrderLine, OrderLineType } from "~/lib/ui/order-lines/lib/types";
import { randomUUID } from "~/lib/uuid/random-uuid";

/**
 * Transformer method used to convert {OfferLine} into {MutableOfferLine}
 * Supports childOfferLines
 * @param item - OfferLine to transform
 * @param pos - Position in the list of lines
 */
export const offerLineToOrderLineTransformer = (
  item: OfferLine,
  pos: number
): Partial<OrderLine> => {
  const lineTypeConverter = (): OrderLineType => {
    if (item.type === "user") {
      return "hours";
    }
    if (item.type === "text") {
      return "text";
    }
    if (item.type === "normal" && item.childOfferLines?.length) {
      return "bundle";
    } else if (item.type === "normal" && item.expenseLineId) {
      return "expense_line";
    } else if (item.type === "normal" && item.vendorProductId) {
      return "vendor_product";
    }

    return "product";
  };

  return {
    ...item,
    type: lineTypeConverter(),
    isInvalid: false,
    unit: item.productUnit ?? undefined,
    identifier: item.id ?? randomUUID(),
    position: pos ?? 0,
    discountType: item.discountType || "fixed",
    discount: (item.discountType === "fixed" ? item.discountAmount : item.discountPercent) || 0,
    costPrice: item.childOfferLines?.length
      ? calculateChildLinesCost(offerLinesToOrderLinesTransformer(item.childOfferLines)) ?? 0
      : item.costPrice ?? 0,
    childOfferLines: item.childOfferLines?.length
      ? offerLinesToOrderLinesTransformer(item.childOfferLines)
      : undefined,
  };
};

/**
 * Utility method used to convert {OfferLine[]} into {MutableOfferLine[]}
 * Supports childOfferLines
 * @param {OfferLine[]} lines - OfferLines to transform
 */
export const offerLinesToOrderLinesTransformer = (
  lines: Array<OfferLine>
): Array<Partial<OrderLine>> => {
  return lines.map((line, idx) => {
    return offerLineToOrderLineTransformer(line, idx);
  });
};

/**
 * Utility method used to convert {MutableOfferLine} into {OfferLine}
 * Supports childOfferLines
 * @param {Partial<OrderLine>} line - Order line to transform
 * @param {Object} customParams - Additional parameters to append to the offer line
 */
const orderLineToOfferLineTransformer = (
  line: Partial<OrderLine>,
  customParams?: { [key: string]: unknown }
): OfferLine => {
  const offerLine = line as OfferLine;

  const lineTypeConverter = (): OfferLine["type"] => {
    if (line.type === "hours") {
      return "user";
    }
    if (line.type === "text") {
      return "text";
    }

    return "normal";
  };

  return {
    ...offerLine,
    id: line.id ? line.id : "",
    type: lineTypeConverter(),
    productUnit: line.unit ?? null,
    placement: Number(line.position ?? 0) + 1,
    discountType: line.discountType || "fixed",
    discountPercent: line.discountType === "percent" ? line.discount : undefined,
    discountAmount: line.discountType === "fixed" ? line.discount : undefined,
    childOfferLines: line.childOfferLines?.length
      ? orderLinesToOfferLinesTransformer(line.childOfferLines, customParams)
      : undefined,
    ...customParams,
  };
};

/**
 * Utility method used to convert {MutableOfferLine[]} into {OfferLine[]}
 * Supports childOfferLines
 * @param {Partial<OrderLine>[]} lines - Lines to transform
 * @param customParams - Additional parameters to append to the offer line
 */
export const orderLinesToOfferLinesTransformer = (
  lines: Array<Partial<OrderLine>>,
  customParams?: { [key: string]: unknown }
): Array<OfferLine> => {
  return lines.map((line) => {
    return orderLineToOfferLineTransformer(line, customParams);
  });
};
