import { HTMLProps, Ref, forwardRef, useId } from "react";
import { Label } from "../label";
import { twMerge } from "tailwind-merge";
import { Select } from "./select";

type LabeledSelectProps = HTMLProps<HTMLSelectElement> & {
  label?: string;
  labelClassName?: string;
  error?: string;
};

/**
 * A combination of a label and an input field. Use this if you want to add a label to an input field.
 */
export const LabeledSelect = forwardRef(function LabeledSelectInner(
  props: LabeledSelectProps,
  inputRef: Ref<HTMLSelectElement>
) {
  const id = useId();
  const { label, labelClassName, required, error, ref: _, ...restProps } = props;

  return (
    <div>
      {label && (
        <Label htmlFor={id} required={required} className={labelClassName}>
          {label}
        </Label>
      )}
      <Select
        id={id}
        ref={inputRef}
        {...restProps}
        className={twMerge(error && "border-error", props.className)}
      />
      {error ? <p className="mt-1 text-left text-sm text-red-600">{props.error}</p> : null}
    </div>
  );
});
