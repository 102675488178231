import { useTranslation } from "react-i18next";
import { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { useSearchParams } from "react-router-dom";
import Controls from "./_cmp/controls/controls";
import { DndProvider } from "react-dnd";
import { DragLayerImplementation } from "~/pages/planning/_cmp/drag/drag-layer";
import { Spinner } from "~/lib/ui/spinner";
import { TouchBackend } from "react-dnd-touch-backend";
import PlanningProvider, { PlanningContext } from "~/lib/planning/planning-context";
import { usePreferences } from "~/lib/preferences/use-preferences";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import UnplannedPanel from "~/pages/planning/_cmp/crud/unplanned-panel";
import { CardProps } from "~/pages/planning/_cmp/draggable-card";

export default function PlannerPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [draggedItem, setDraggedItem] = useState<CardProps | undefined>();

  const getCurrentDate = (): Date => {
    return searchParams.get("date") ? new Date(searchParams.get("date") as string) : new Date();
  };

  const [selectedDate, setSelectedDate] = useState<Date>(getCurrentDate());

  const [{ planningView: view, planningSideBarOpen }, setPreferences] = usePreferences();

  const pageTitle = usePageTitle();

  useEffect(() => {
    const translationString =
      view === "day" ? "navigation:planning.title" : `navigation:planning.${view}.title`;
    pageTitle.set(t(translationString));
  }, [view]);

  useEffect(() => {
    if (searchParams.has("date")) {
      try {
        setSelectedDate(new Date(searchParams.get("date") as string));
      } catch (e) {
        console.warn("Supplied date could not be parsed as date", searchParams.get("date"));
      }
    } else {
      setSelectedDate(new Date());
    }
  }, [searchParams]);

  const handleExpandContractSidePanel = (expanded: boolean): void => {
    setPreferences("planningSideBarOpen", expanded);
  };

  const { t } = useTranslation();
  const handleSetDate = (d: Date) => {
    setSelectedDate(d);
    setSearchParams((prevState) => {
      const date = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d
        .getDate()
        .toString()
        .padStart(2, "0")}`; // YYYY-MM-DD
      return { ...prevState, date };
    });
  };

  return (
    <DndProvider
      backend={TouchBackend}
      options={{ enableMouseEvents: true, ignoreContextMenu: true, delayTouchStart: 100 }}
    >
      <DragLayerImplementation
        onDragging={(v: boolean, item: CardProps) => {
          setIsDragging(v);
          setDraggedItem(item);
        }}
      />
      <PlanningProvider selectedDate={selectedDate} view={view}>
        <PlanningContext.Consumer>
          {({ isLoadingUsers, viewDates, selectedDate: date, selectedCard, setSelectedCard }) => (
            <>
              <div className="flex h-full flex-col overflow-hidden pt-6">
                <Controls
                  selectedDate={date}
                  planningView={view}
                  shownDates={viewDates}
                  onDateChange={handleSetDate}
                  onShowUnplanned={() => handleExpandContractSidePanel(!planningSideBarOpen)}
                  onViewChange={(newView) => setPreferences("planningView", newView)}
                />
                <div className="flex flex-grow flex-col items-stretch self-stretch overflow-y-auto overflow-x-hidden">
                  <Suspense
                    fallback={
                      <div className="flex flex-grow flex-col items-center justify-center overflow-hidden">
                        <PageSpinner />
                      </div>
                    }
                  >
                    {isLoadingUsers && (
                      <div className="flex flex-grow flex-col items-center  justify-center gap-4 overflow-hidden">
                        <Spinner />
                      </div>
                    )}
                    {!isLoadingUsers && (
                      <Outlet
                        context={{
                          view,
                          isDragging,
                          sidePanelExpanded: planningSideBarOpen,
                          selectedCard,
                          setSelectedCard,
                        }}
                      />
                    )}
                  </Suspense>
                </div>
              </div>
              <UnplannedPanel
                open={planningSideBarOpen}
                isDragging={isDragging}
                draggedItem={draggedItem}
                onClose={() => handleExpandContractSidePanel(false)}
              />
            </>
          )}
        </PlanningContext.Consumer>
      </PlanningProvider>
    </DndProvider>
  );
}
