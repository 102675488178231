import { Vendor } from "@apacta/sdk";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useState } from "react";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { useTranslation } from "react-i18next";
import { Popover } from "~/lib/ui/popover/popover";
import { PillBadge } from "~/lib/ui/pill-badge";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "~/lib/ui/form-elements/text-input";
import { twMerge } from "tailwind-merge";
import { FilterList } from "~/lib/ui/form-elements/filter-list";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";

export default function VendorProductFilter({
  selectedVendor,
  selectedTab,
  onSelect,
}: {
  selectedVendor?: Vendor;
  selectedTab: TabHeaderItem;
  onSelect: (vendor: Vendor | undefined) => void;
}) {
  const [vendorSearchInput, setVendorSearchInput] = useState<string>("");
  const [vendorSearchQuery, setVendorSearchQuery] = useState<string>("");

  const api = useAPI();
  const { t } = useTranslation();

  const vendorQuery = useQuery({
    queryKey: ["vendorList", vendorSearchQuery, selectedTab.id],
    queryFn: () =>
      api.getVendors({
        q: vendorSearchQuery,
        withExpense: selectedTab.id === "expense_line",
        withPriceFile: selectedTab.id === "vendor_product",
      }),
    placeholderData: keepPreviousData,
  });

  useDebounce(
    () => {
      setVendorSearchQuery(vendorSearchInput);
    },
    [vendorSearchInput],
    500
  );

  return (
    <div className="flex">
      <Popover
        triggerRender={() => (
          <div className="flex">
            <PillBadge
              label={t("common:vendor", { count: 1 })}
              isLoading={false}
              value={<span>{selectedVendor?.name ?? t("common:all_nonspecific")}</span>}
              action={selectedVendor ? <XMarkIcon className="h-3 w-3" /> : undefined}
              onAction={(e) => {
                e.stopPropagation();
                if (selectedVendor) {
                  onSelect(undefined);
                }
              }}
              className="outline-none hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover"
            />
          </div>
        )}
        config={{ side: "bottom" }}
        onOpenChange={(isOpen) => !isOpen && setVendorSearchInput("")}
      >
        {(closeSelect) => (
          <div className="flex h-96 min-w-[18em] flex-shrink-0 flex-col gap-2 overflow-hidden rounded-lg border bg-white p-2 shadow-md">
            <FilterList
              isLoading={vendorQuery.isFetching}
              filterRender={() => (
                <div className="flex">
                  <TextInput
                    id="vendor-search"
                    label=""
                    placeholder={`${t("common:search")} ${t("common:vendor", {
                      count: 2,
                    }).toLowerCase()}`}
                    onChange={(value) => setVendorSearchInput(value)}
                  />
                </div>
              )}
              itemRender={({ item, index, isSelected, triggerSelect }) => (
                <div
                  key={`user-${item.id}`}
                  className={twMerge(
                    "flex cursor-pointer items-center justify-between rounded-md px-2 py-2 hover:bg-gray-100"
                  )}
                  onClick={() => {
                    onSelect(item);
                    closeSelect();
                  }}
                >
                  <div className="flex items-center gap-3">
                    <div className="h-6 w-6">
                      <img src={item.imageUrl} className="object-contain" />
                    </div>
                    <span>{item.name}</span>
                  </div>
                  <div className="ml-4">
                    <CheckIcon className={twMerge("h-5 w-5", !isSelected && "invisible")} />
                  </div>
                </div>
              )}
              items={vendorQuery.data?.data ? vendorQuery.data.data : []}
              initialSelected={selectedVendor ? [selectedVendor] : []}
            />
          </div>
        )}
      </Popover>
    </div>
  );
}
