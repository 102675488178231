import { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ContentLoader from "react-content-loader";
import { ControlPanelApiOfferStatusResponse } from "@apacta/sdk";
import { useOfferStatus } from "~/lib/offers/use-offer-status";

export default function OfferStatusLabel({
  readonly = false,
  selectedStatusId,
  onChange,
}: {
  readonly?: boolean;
  selectedStatusId?: string;
  onChange?: () => void;
}) {
  const { offerStatus, offerStatuses, offerStatusLoading, offerStatusLabel } =
    useOfferStatus(selectedStatusId);

  const [open, setOpen] = useState<boolean>(false);

  const greenStyle = "bg-green-50 text-green-700";
  const yellowStyle = "bg-yellow-50 text-yellow-700";
  const redStyle = "bg-red-50 text-red-700";
  const grayStyle = "bg-gray-50 text-gray-700";

  const selected = offerStatus;

  const getStyle = (item?: ControlPanelApiOfferStatusResponse): string => {
    if (!item || !item.identifier) return grayStyle;
    if (["accepted"].includes(item.identifier)) {
      return greenStyle;
    } else if (["rejected", "expired", "deleted"].includes(item.identifier)) {
      return redStyle;
    } else if (["draft", "sent", "sent_and_seen"].includes(item.identifier)) {
      return yellowStyle;
    } else {
      return grayStyle;
    }
  };

  return (
    <>
      {readonly ? (
        <>
          {!offerStatusLoading && selected ? (
            <span
              className={twMerge(
                "inline-flex items-center rounded-md border px-2 py-1 text-xs font-medium",
                getStyle(selected)
              )}
            >
              <>
                <span>{offerStatusLabel(selected)}</span>
              </>
            </span>
          ) : (
            <ContentLoader className={twMerge("h-6 w-32")}>
              <rect x={0} y={0} width="80vw" height="100vh"></rect>
            </ContentLoader>
          )}
        </>
      ) : (
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger className={twMerge("-ml-px hover:z-10")}>
            {!offerStatusLoading && selected ? (
              <span
                className={twMerge(
                  "inline-flex items-center rounded-md border px-2 py-1 text-xs font-medium",
                  getStyle(selected)
                )}
              >
                <>
                  <span>{offerStatusLabel(selected)}</span>
                  <span>
                    <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                  </span>
                </>
              </span>
            ) : (
              <ContentLoader className={twMerge("h-6 w-32")}>
                <rect x={0} y={0} width="80vw" height="100vh"></rect>
              </ContentLoader>
            )}
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="start">
              <div className="min-w-[10rem] rounded-lg bg-white p-2 shadow">
                <ul>
                  {offerStatuses?.map((v, i) => (
                    <li
                      key={`status-${i}`}
                      className="flex cursor-pointer gap-2 rounded-lg p-2 hover:bg-tertiary hover:text-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("Not used currently");
                      }}
                    >
                      <div
                        className={twMerge(
                          "flex h-6 w-6 items-center justify-center rounded-full bg-opacity-100 p-1.5",
                          getStyle(v)
                        )}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 23 23"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="11.5" cy="11.5" r="11.5" />
                        </svg>
                      </div>
                      <span>{offerStatusLabel(v)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
    </>
  );
}
