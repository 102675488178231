import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { getIcon } from "~/lib/ui";
import { Suspense } from "react";
import { Outlet } from "react-router";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useMount } from "~/lib/lifecycle-helpers";

export default function Index() {
  const pageTitle = usePageTitle();
  const { t, i18n } = useTranslation();

  useMount(() => {
    pageTitle.set(t("common:frontpage"));
  });

  return (
    <PageLayout title={t("common:frontpage")}>
      <PageTabs
        tabs={[
          {
            id: "overview",
            label: t("common:overview"),
            Icon: getIcon("chart"),
            to: "",
          },
          {
            id: "feed",
            label: t("frontpage:activity_feed"),
            Icon: getIcon("feed"),
            to: "feed",
          },
        ]}
      />
      <Suspense>
        <Outlet />
      </Suspense>
    </PageLayout>
  );
}
