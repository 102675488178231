import { Button, Icon } from "~/lib/ui";
import Tooltip from "~/lib/ui/tooltip";
import { useNavigate } from "react-router";
import { ProjectEntity } from "~/pages/projects/_cmp/data-components/employee-access";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { CACHE_PROJECTS } from "~/pages/projects";
import { useTranslation } from "react-i18next";

// https://docs.google.com/spreadsheets/d/1DaU5HocJ17vBs6OvRrGy5rzk3AlNcHrmV-vlvO4n8gM/edit?gid=471143296#gid=471143296
const AVG_CONTRIBUTION_RATIO = 30;
const AVG_DISTRIBUTION_RATIO_PRODUCT = 50;
const AVG_DISTRIBUTION_RATIO_HOURS = 50;

export default function ZeroCostWarning({
  project,
  onAutomaticFix,
  showAutomaticButton = false,
  showManualButton = false,
}: {
  project: ProjectEntity;
  onAutomaticFix?: ({ productCost, hoursCost }: { productCost: number; hoursCost: number }) => void;
  showAutomaticButton?: boolean;
  showManualButton?: boolean;
}) {
  const api = useAPI();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleAutomaticFix = async () => {
    // https://docs.google.com/spreadsheets/d/1DaU5HocJ17vBs6OvRrGy5rzk3AlNcHrmV-vlvO4n8gM/edit?gid=471143296#gid=471143296
    const totalSalesPrice = project.totalSalesPrice ?? 0;
    if (totalSalesPrice === 0) {
      console.warn("Total sales price is zero, cannot calculate cost price");
      return;
    }

    const productsTotalCostPrice =
      totalSalesPrice *
      ((100 - AVG_CONTRIBUTION_RATIO) / 100) *
      (AVG_DISTRIBUTION_RATIO_PRODUCT / 100);
    const workingHoursTotalCostPrice =
      totalSalesPrice *
      ((100 - AVG_CONTRIBUTION_RATIO) / 100) *
      (AVG_DISTRIBUTION_RATIO_HOURS / 100);

    await updateMutation.mutateAsync({
      productsTotalCostPrice,
      workingHoursTotalCostPrice,
    });

    onAutomaticFix?.({
      productCost: productsTotalCostPrice,
      hoursCost: workingHoursTotalCostPrice,
    });
  };

  const updateMutation = useMutation({
    mutationFn: async ({
      productsTotalCostPrice,
      workingHoursTotalCostPrice,
    }: {
      productsTotalCostPrice: number;
      workingHoursTotalCostPrice: number;
    }) =>
      api.iEditProject({
        projectId: project.id,
        iCreateProjectRequest: {
          ...project,
          productsTotalCostPrice,
          workingHoursTotalCostPrice,
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS],
      });
    },
  });

  return (
    <div className="flex flex-col gap-2 rounded-lg border border-yellow-300 bg-yellow-50 p-6">
      <div className="flex items-start gap-4">
        <div className="rounded-full bg-yellow-500 p-1.5 text-white">
          <Icon name="warningTriangle" className="h-6 w-6" />
        </div>
        <div className="flex flex-col text-sm">
          <div className="text-base font-semibold">
            {t("projects:zero_cost_warning_banner.title")}
          </div>
          <div className="whitespace-pre">{t("projects:zero_cost_warning_banner.description")}</div>

          {(showAutomaticButton || showManualButton) && (
            <div className="flex gap-4 pt-4">
              {showManualButton && (
                <Button variant="primary" size="small" onClick={() => navigate("data")}>
                  {t("projects:zero_cost_warning_banner.manual_fix")}
                </Button>
              )}

              {showAutomaticButton && (
                <Tooltip
                  delay={500}
                  trigger={
                    <Button
                      onClick={handleAutomaticFix}
                      variant="secondary"
                      size="small"
                      className="flex items-center gap-2"
                    >
                      <span>{t("projects:zero_cost_warning_banner.automatic_fix")}</span>
                      <Icon name="tooltip" className="h-5 w-5" />
                    </Button>
                  }
                >
                  {t("projects:zero_cost_warning_banner.automatic_fix_tooltip")}
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
