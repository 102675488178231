import { PageLayout } from "~/lib/ui/page-layout";
import { getDatesInPeriod, getDateWithoutTime } from "~/lib/utils/date/date-utils";
import { useLocale } from "~/lib/utils/date";
import { twMerge } from "tailwind-merge";

type TempEmployee = {
  id: string;
  employee: string;
  tasks: Array<TempTask>;
};

type TempTask = {
  id: string;
  date: Date;
  task: string;
  hours?: string;
  timeFrom?: string;
  timeTo?: string;
  label?: string;
};

const data: Array<TempEmployee> = [
  {
    id: "1",
    employee: "John Doe",
    tasks: [
      {
        id: "1",
        date: new Date("2024-04-16"),
        task: "Very long taskname that does not fit",
        hours: "4,5",
        label: "1",
      },
      {
        id: "2",
        date: new Date(),
        task: "Task 2",
        hours: "4,5",
        label: "1",
      },
      {
        id: "3",
        date: new Date(),
        task: "Task 2",
        hours: "4,5",
        label: "2",
      },
      {
        id: "4",
        date: new Date(),
        task: "Task 2",
        hours: "4,5",
        label: "3",
      },
      {
        id: "5",
        date: new Date("2024-04-18"),
        task: "Task 2",
        hours: "4,5",
      },
    ],
  },
  {
    id: "2",
    employee: "Darlene Johnson",
    tasks: [
      {
        id: "1",
        date: new Date(),
        timeFrom: "08:00",
        timeTo: "12:00",
        task: "Task 3",
        hours: "4",
        label: "1",
      },
      {
        id: "2",
        date: new Date(),
        task: "Task 4",
        hours: "4",
      },
      {
        id: "3",
        date: new Date("2024-04-18"),
        task: "Task 4",
        hours: "4",
        label: "3",
      },
      {
        id: "4",
        date: new Date("2024-04-18"),
        task: "Task 4",
        hours: "4",
      },
    ],
  },
];

export default function PlanningCalendar() {
  const dates = getDatesInPeriod(new Date(), "week");
  const { format, dayName } = useLocale();

  const getLabelColor = (task: TempTask) => {
    switch (task.label) {
      case "1":
        return "bg-orange-600";
      case "2":
        return "bg-teal-600";
      case "3":
        return "bg-purple-600";
      default:
        return "bg-shade-600";
    }
  };

  return (
    <PageLayout title="Planning layout">
      <div className="flex flex-col gap-12">
        <div className="flex w-full flex-col divide-y rounded-lg border bg-white shadow-sm">
          <div className="flex w-full divide-x">
            {dates.map((date) => (
              <div
                key={date.toString()}
                className="flex grow basis-1 flex-col px-2 py-2 text-center"
              >
                <div className="font-semibold">{dayName(date, { capitalizeFirst: true })}</div>
                <div className="text-sm">
                  {format(date, { shortMonth: true, excludeTime: true })}
                </div>
              </div>
            ))}
          </div>
          {data.map((employee) => (
            <div key={employee.id} className="flex w-full divide-x">
              {dates.map((date) => {
                const tasks = employee.tasks.filter(
                  (task) =>
                    getDateWithoutTime(task.date).getTime() === getDateWithoutTime(date).getTime()
                );
                return (
                  <div
                    key={`${employee.id}-${date}`}
                    className="flex grow basis-1 flex-col gap-1 px-2 py-2 text-center"
                  >
                    {tasks.map((task) => (
                      <div
                        key={task.id}
                        title={task.task}
                        className="flex h-8 shrink-0 cursor-pointer items-center gap-2 overflow-hidden px-2 text-xs hover:bg-shade-100"
                      >
                        <div
                          className={twMerge("h-4 w-4 shrink-0 rounded-full", getLabelColor(task))}
                        ></div>
                        <div className="flex w-full justify-between">
                          <div className="line-clamp-1 text-left">{task.task}</div>
                          {!!task.hours ? (
                            <div className="text-xs text-shade-400">{task.hours}h</div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="flex w-full flex-col divide-y rounded-lg border bg-white shadow-sm">
          <div className="flex w-full divide-x">
            {dates.map((date) => (
              <div
                key={date.toString()}
                className="flex grow basis-1 flex-col px-2 py-2 text-center"
              >
                <div className="font-semibold">{dayName(date, { capitalizeFirst: true })}</div>
                <div className="text-sm">
                  {format(date, { shortMonth: true, excludeTime: true })}
                </div>
              </div>
            ))}
          </div>
          {data.map((employee) => (
            <div key={employee.id} className="flex w-full divide-x">
              {dates.map((date) => {
                const tasks = employee.tasks.filter(
                  (task) =>
                    getDateWithoutTime(task.date).getTime() === getDateWithoutTime(date).getTime()
                );
                return (
                  <div
                    key={`${employee.id}-${date}`}
                    className="flex grow basis-1 flex-col gap-1 px-2 py-2 text-center"
                  >
                    {tasks.map((task) => (
                      <div
                        key={task.id}
                        title={task.task}
                        className={twMerge(
                          "flex h-8 cursor-pointer items-center justify-between gap-2 overflow-hidden px-2 text-sm text-white hover:bg-opacity-70",
                          getLabelColor(task)
                        )}
                      >
                        <div className="line-clamp-1 text-left">{task.task}</div>
                        {!!task.hours ? (
                          <div className="shrink-0 text-xs text-white/80">{task.hours}h</div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="flex w-full flex-col divide-y rounded-lg border bg-white shadow-sm">
          <div className="flex w-full divide-x">
            {dates.map((date) => (
              <div
                key={date.toString()}
                className="flex grow basis-1 flex-col px-2 py-2 text-center"
              >
                <div className="font-semibold">{dayName(date, { capitalizeFirst: true })}</div>
                <div className="text-sm">
                  {format(date, { shortMonth: true, excludeTime: true })}
                </div>
              </div>
            ))}
          </div>
          {data.map((employee) => (
            <div key={employee.id} className="flex w-full divide-x">
              {dates.map((date) => {
                const tasks = employee.tasks.filter(
                  (task) =>
                    getDateWithoutTime(task.date).getTime() === getDateWithoutTime(date).getTime()
                );
                return (
                  <div
                    key={`${employee.id}-${date}`}
                    className="flex grow basis-1 flex-col gap-1 px-2 py-2 text-center"
                  >
                    {tasks.map((task) => (
                      <div
                        key={task.id}
                        title={task.task}
                        className={twMerge(
                          "flex h-12 cursor-pointer flex-col items-start justify-center overflow-hidden px-2 py-1 text-white hover:bg-opacity-70",
                          getLabelColor(task)
                        )}
                      >
                        <div className="line-clamp-1 text-left text-sm">{task.task}</div>
                        <div className="flex w-full justify-between">
                          {!!task.timeFrom && !!task.timeTo ? (
                            <div className="text-xs text-white/80">{`${task.timeFrom} - ${task.timeTo}`}</div>
                          ) : null}
                          {!!task.hours ? (
                            <div className="text-xs text-white/80">{task.hours}h</div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
}
