// Borrowed from https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str: string) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}
/**
 * Converts an object to Array with types intact.
 * @param arr
 * @returns
 */
export function toArray<RecordType>(arr: Record<string, RecordType>) {
  return Object.entries(arr).map(([, value]) => value);
}

export function fileListToArray(filelist?: FileList): Array<File> {
  const out: Array<File> = [];
  if (!filelist) return out;
  for (const file of filelist) {
    out.push(file);
  }
  return out;
}

export function printPercent(percent: number): string {
  const perc = Number.isNaN(percent) ? 0 : Math.round(percent);
  return `${perc}%`;
}

export function trimString(str: string, len: number): string {
  return str.length > len ? `${str.slice(0, len)}...` : str;
}

export function range(n: number) {
  return [...Array(n).keys()];
}

export function sleepAsync(ms: number = 1000) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Convert a Blob to a base64 string
export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function notNullOrUndefined<T>(val: T | null | undefined): val is T {
  return val !== null && val !== undefined;
}
