import { FormCardTimeRegistration } from "./variants/form-card-time-registration";
import { FormCardQa } from "./variants/form-card-qa";
import { FrontpageFeedDTO } from "@apacta/sdk";

export const FormCard = ({ data }: { data: FrontpageFeedDTO }) => {
  if (data.templateType === "time_registration") {
    return <FormCardTimeRegistration data={data} />;
  } else {
    return <FormCardQa data={data} />;
  }
};
