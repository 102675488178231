import {
  ExpandedState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowData,
  TableOptions,
  useReactTable as useReactTableLib,
  VisibilityState,
} from "@tanstack/react-table";
import { useDataTableState } from "./use-data-table-state";
import { useMemo } from "react";
import { merge } from "lodash";
import { PaginationDetails } from "@apacta/sdk";

type UseDataTableArgs<T extends RowData> = {
  mode?: "client" | "server";
  /** Loading is required so we don't forget to set it */
  isLoading: boolean;
  /** Row ID is important, so forcing it to be set.
   * Tanstack table defaults to index, and that messes up things like expansion
   * If unsure, use `getRowId: (row) => row.id` */
  getRowId: TableOptions<T>["getRowId"];
  /** Override skeleton rows. This should only be used for a good reason
   * @deprecated
   */
  skeletonRows?: number;
  data?: TableOptions<T>["data"];
  columns: TableOptions<T>["columns"];
  /** Used to work around pagination issues caused by backend */
  backendPagination?: PaginationDetails;
  pageCount?: TableOptions<T>["pageCount"];
  // There was pageSize here, but it's cleaner to only manage the tableState, inside of tableState.
  tableState?: ReturnType<typeof useDataTableState>;
  /** Special Apacta feature that only allows one expanded row at a time */
  singleRowExpansion?: boolean;
};
export const useDataTable = <T extends RowData>(
  args: UseDataTableArgs<T>,
  overrideTemplate?: Partial<TableOptions<T>>
) => {
  const mode = args.mode ?? "server";

  const data = args.data ?? [];

  const tableOptions = useMemo(() => {
    // Handle Backend Pagination quirks (like missing pageCount)
    const pageCount = (() => {
      if (args.pageCount) return args.pageCount; // If overriden, use that
      if (args.backendPagination) {
        const bp = args.backendPagination;
        if (bp?.pageCount) return bp?.pageCount; // If backend has pageCount, use that
        if (bp?.pageCount === undefined && bp?.currentPage !== undefined && bp?.hasNextPage) {
          return bp?.currentPage + 1; // No pageCount, but we know we have an extra page
        }
      }
    })();

    /**
     * Man oh man. Exclusive row expansion is a pain in the bleep.
     *
     * I wanted to implement this and retain the tanstack table row methods.
     * So this function checks if we're in singleRowExpansion mode, and if so, it will only expand the first row.
     *
     * This should work with all toggle methods in the table.
     */
    const expansionUpdaterFn: OnChangeFn<ExpandedState> = (updaterOrValue) => {
      if (!args.singleRowExpansion) {
        return args.tableState?.handleTableState("expanded")(updaterOrValue);
      }
      if (updaterOrValue === undefined) return;

      /** Single Row Expansion Logic */
      const currentExpansionState = args.tableState?.state.expanded ?? {};

      // We pass an empty object to get the next state without anything else expanded
      const nextFresh = typeof updaterOrValue === "function" ? updaterOrValue({}) : updaterOrValue;
      const nextWithExisting =
        typeof updaterOrValue === "function"
          ? updaterOrValue(currentExpansionState)
          : updaterOrValue;

      const existingSelection = Object.keys(currentExpansionState) as Array<
        keyof typeof currentExpansionState
      >;

      // Nothing previously selected, default behavior
      if (existingSelection.length === 0) {
        return args.tableState?.handleTableState("expanded")(nextWithExisting);
      }

      // This row is already expanded, so we collapse everything
      if (nextFresh[existingSelection[0]]) {
        return args.tableState?.handleTableState("expanded")({});
      }
      args.tableState?.handleTableState("expanded")(nextFresh);
    };

    let opts: TableOptions<T> = {
      data,
      pageCount: pageCount,
      columns: args.columns,
      getCoreRowModel: getCoreRowModel(),
      enableRowSelection: false,
      defaultColumn: {
        enableSorting: false,
        enableColumnFilter: false,
      },
      enableExpanding: false,
      enableGlobalFilter: false,
      getRowId: args.getRowId,
      meta: {
        isLoading: args.isLoading,
        singleRowExpansion: args.singleRowExpansion,
        skeletonRows: args.skeletonRows ?? args.tableState?.state.pagination.pageSize ?? 20,
      },
      state: {
        pagination: args.tableState?.state.pagination,
        sorting: args.tableState?.state.sorting,
        columnVisibility: args.tableState?.state.columnVisibility as VisibilityState,
        columnFilters: args.tableState?.state.columnFilters,
        globalFilter: args.tableState?.state.search,
        expanded: args.tableState?.state.expanded,
      },
      onPaginationChange: args.tableState?.handleTableState("pagination"),
      onSortingChange: args.tableState?.handleTableState("sorting"),
      onGlobalFilterChange: args.tableState?.handleTableState("search"),
      onColumnFiltersChange: args.tableState?.handleTableState("columnFilters"),
      onExpandedChange: expansionUpdaterFn,
      onColumnVisibilityChange: args.tableState?.handleTableState("columnVisibility"),
    };

    if (mode === "client") {
      opts.manualSorting = false;
      opts.manualFiltering = false;
      opts.manualPagination = false;
      opts.getPaginationRowModel = getPaginationRowModel();
      opts.getSortedRowModel = getSortedRowModel();
      opts.getFilteredRowModel = getFilteredRowModel();
    }

    if (mode === "server") {
      opts.manualSorting = true;
      opts.manualFiltering = true;
      opts.manualPagination = true;
      opts.manualExpanding = true;
    }
    if (overrideTemplate) {
      opts = merge(opts, overrideTemplate);
    }
    return opts;
  }, [args, overrideTemplate]);

  return useReactTableLib<T>(tableOptions);
};
