import { t } from "i18next";
import { useState } from "react";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { LabeledTextarea } from "~/lib/ui/form-elements/labeled-textarea";
import { WallPost } from "@apacta/sdk";

export function EditPostDialog({
  post,
  onClose,
  onEditPost,
}: {
  post: WallPost;
  onClose: () => void;
  onEditPost: (args: { message: string; postId: string }) => Promise<unknown>;
}) {
  const [postMessage, setPostMessage] = useState<string>(post.message ?? "");
  const nothingToSubmit = postMessage === "" || postMessage === post.message;

  return (
    <div className="flex flex-col">
      <h3 className="mb-2">{t("projects:feed.edit_a_post")}</h3>
      <LabeledTextarea
        required
        onChange={(e) => setPostMessage(e.currentTarget.value)}
        defaultValue={postMessage}
        className="mb-4 mt-2 block min-h-20 w-full rounded-md border-gray-300 shadow-sm focus:border-hover focus:outline-none focus:ring-transparent sm:text-sm"
      />

      <DialogFooter
        primary={{
          disabled: nothingToSubmit,
          label: t("common:save_changes"),
          onClick: async () => {
            await onEditPost({
              message: postMessage,
              postId: post.id as string,
            });
            onClose();
          },
        }}
        onClose={onClose}
      />
    </div>
  );
}
