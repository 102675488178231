import { InvoiceLine } from "@apacta/sdk";
import { OrderLine } from "~/lib/ui/order-lines/lib/types";
import { randomUUID } from "~/lib/uuid/random-uuid";

export function orderLineToInvoiceLine(
  orderLine: Partial<OrderLine>,
  invoiceId: string
): InvoiceLine {
  const invoiceLineType: InvoiceLine["type"] = (() => {
    if (orderLine.type === "product") return "normal";
    if (orderLine.type === "hours") return "user";
    if (orderLine.type === "text") return "text";
    return "normal";
  })();

  return {
    id: orderLine.id ?? orderLine.identifier ?? randomUUID(),
    productId: orderLine.productId ?? undefined,
    quantity: orderLine.quantity ?? 1,
    productUnit: orderLine.unit ?? undefined,
    sellingPrice: orderLine.sellingPrice,
    buyingPrice: orderLine.costPrice,
    discountPercent: orderLine.discount,
    description: orderLine.description,
    placement: orderLine.position ? orderLine.position + 1 : 0,
    name: orderLine.type === "text" ? orderLine.description : orderLine.name,
    type: invoiceLineType,
    invoiceId,
    expenseLineId: orderLine.expenseLineId ?? undefined,
    vendorProductId: orderLine.vendorProductId ?? undefined,
    usersPriceGroupId: orderLine.usersPriceGroupId ?? undefined,
    created: new Date(),
    modified: new Date(),
  };
}
