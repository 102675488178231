import z from "zod";

const TaskStatusEnum = z.enum(["to_do", "doing", "done"]);

export const taskFormStateSchema = {
  taskName: z.string().min(1),
  fromDate: z.date().nullish(),
  toDate: z.date().nullish(),
  includeWeekends: z.boolean(),
  startTime: z.object({ hour: z.number().nullish(), minute: z.number().nullish() }),
  duration: z.number().nullish(),
  repeat: z.enum(["daily", "weekly", "monthly", "yearly"]).optional(),
  interval: z.number().min(0).optional(),
  endTime: z.date().nullish(),
  projectId: z.string().nullish(),
  projectName: z.string().nullish(),
  labelId: z.string().nullish(),
  employeeIds: z.array(z.string()),
  description: z.string().nullish(),
  products: z.array(
    z.object({
      name: z.string().optional(),
      productId: z.string().optional(),
      vendorProductId: z.string().optional(),
      expenseLineId: z.string().optional(),
      amount: z.number(),
    })
  ),
  formTemplateIds: z.array(z.string()).nullish(),
  status: TaskStatusEnum,
};

export const defaultTaskFormState = {
  taskName: "",
  fromDate: null,
  toDate: null,
  includeWeekends: false,
  startTime: { hour: null, minute: null },
  duration: null,
  repeat: undefined,
  interval: undefined,
  endTime: undefined,
  projectId: null,
  projectName: null,
  labelId: null,
  employeeIds: [],
  description: "",
  products: [],
  formTemplateIds: [],
  status: TaskStatusEnum.parse("to_do"),
};
