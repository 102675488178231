import colors from "tailwindcss/colors";
import { ReactNode } from "react";
import { contrastColor, hexToRgb } from "~/lib/utils/colors";
import { twMerge } from "tailwind-merge";

export function BadgeLabel({
  backgroundColor = colors.gray[100],
  backgroundOpacity = 1,
  textColor = colors.zinc[700],
  className,
  children,
}: {
  backgroundColor?: string; // hex
  backgroundOpacity?: number;
  textColor?: string; // hex
  className?: string;
  children: ReactNode;
}) {
  const rgb = hexToRgb(backgroundColor);
  const textColorHex = contrastColor(textColor, backgroundColor);

  return (
    <span
      style={{
        backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${backgroundOpacity})`,
        color: textColorHex,
      }}
      className={twMerge(
        "inline-flex select-none items-center rounded-full",
        className ? className : "px-3 py-1 text-xs"
      )}
    >
      {children}
    </span>
  );
}
