import { Layout, LayoutProps, rankWith, uiTypeIs } from "@jsonforms/core";
import { renderChildren } from "./renderChildren";
import { Label } from "~/lib/ui";
import { getLabelForControl } from "../getLabelForControl";

export const HorizontalLayoutRenderer = (props: LayoutProps) => {
  const layout = props.uischema as Layout;
  const label = getLabelForControl(props);

  return (
    <div className="flex w-full flex-row flex-wrap justify-between gap-4">
      {label && <Label>{label}</Label>}
      {renderChildren({
        schema: props.schema,
        path: props.path,
        enabled: props.enabled,
        elements: layout.elements,
        uischema: props.uischema,
      })}
    </div>
  );
};
export const HorizontalLayoutTester = rankWith(2, uiTypeIs("HorizontalLayout"));
