import { Boundary } from "~/lib/ui/boundary";
import { CrashActions } from "../errors";

export default function ErrorPage() {
  return (
    <div>
      <h3>Error Tab</h3>
      <CrashActions key="tab" />
      <Boundary>
        <TheBox />
      </Boundary>
    </div>
  );
}

function TheBox() {
  return (
    <div className="mt-4 bg-green-100 p-4">
      <h4>Error Element</h4>
      <CrashActions key="box" />
    </div>
  );
}
