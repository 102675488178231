import { ControlPanelApiProjectViewOrIndexResponse } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { linkToProject } from "~/lib/utils";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { Icon } from "../../icons/icon";
import Tooltip from "../../tooltip";
import { ENTITY_SHOW_ICON } from "./settings";
import { twMerge } from "tailwind-merge";
import { AuthorizedLink } from "~/lib/utils/routing/authorized-link";

type ProjectCellProps =
  | {
      project?: ControlPanelApiProjectViewOrIndexResponse;
      primary?: boolean;
      projectName?: never;
      projectId?: never;
      description?: never;
    }
  | {
      project?: never;
      projectName?: string;
      projectId: string;
      primary?: never;
      description?: string;
    };
/**
 * Render a project with a link to the project page
 */
export function ProjectCell(props: ProjectCellProps) {
  const { t } = useTranslation();
  const { project, primary } = props;
  const projectName = project ? project.name : props.projectName ?? t("common:not_available");
  const projectId = project ? project.id : props.projectId;
  const description = project ? project.description : props.description;

  if (!projectId) {
    return t("common:not_available");
  }

  return (
    <span className="inline-flex w-full items-center justify-between">
      <AuthorizedLink to={linkToProject(projectId)}>
        {ENTITY_SHOW_ICON && <Icon name="project" size="small" className="mr-2 inline" />}
        <span className="break line-clamp-2 gap-4 text-wrap">{projectName}</span>
        {description && (
          <span
            className={twMerge(
              "mt-2 line-clamp-2 truncate text-wrap text-gray-400",
              primary && "line-clamp-4"
            )}
          >
            {description}
          </span>
        )}
      </AuthorizedLink>
      <span className="inline-flex gap-2">
        {primary && (
          <span>
            {project?.isRotten && (
              <Tooltip
                trigger={
                  <span>
                    <Icon name="warningTriangle" size="small" />
                  </span>
                }
              >
                {t("projects:rotten_project_warning")}
              </Tooltip>
            )}
          </span>
        )}
        {primary && (
          <OptionalLink to={linkToProject(projectId)} openInNewTab>
            <Icon name="externalLink" size="small" />
          </OptionalLink>
        )}
      </span>
    </span>
  );
}
