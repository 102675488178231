import { ControlProps, LayoutProps, getSubErrorsAt } from "@jsonforms/core";
import {
  ctxDispatchToControlProps,
  ctxToControlProps,
  ctxToLayoutProps,
  useJsonForms,
} from "@jsonforms/react";
import { ComponentType, memo } from "react";
import { LayoutWrapper } from "./LayoutWrapper";
import { DEV } from "~/lib/auth/config";
import { ControlWrapper } from "./ControlWrapper";
import { useTranslation } from "react-i18next";

// Decorates with control props and debug controls
export const withControlProps = (
  Component: ComponentType<ControlProps>,
  memoize: boolean = true
) => {
  const fn = function ControlWithWrapper(props: ControlProps) {
    const ctx = useJsonForms();

    const controlProps = {
      ...props,
      ...ctxToControlProps(ctx, props),
      ...ctxDispatchToControlProps(ctx.dispatch as any),
    };
    const errors = useSubErrorsForControl(controlProps);
    controlProps.errors = errors;

    if (!DEV) return <Component {...controlProps} />;

    return (
      <div>
        <ControlWrapper
          {...controlProps}
          render={(p) => <Component {...p} />}
          debugName={Component.displayName ?? "Control"}
        />
      </div>
    );
  };
  if (memoize) return memo(fn);
  return fn;
};

export const withLayoutProps = (Component: ComponentType<LayoutProps>, memoize: boolean = true) => {
  const fn = function WithLayoutWithWrapper(props: LayoutProps) {
    const ctx = useJsonForms();

    const layoutProps = {
      ...props,
      ...ctxToLayoutProps(ctx, props),
    };

    if (!DEV) return <Component {...layoutProps} />;

    return (
      <div>
        <LayoutWrapper
          {...layoutProps}
          layoutName={Component.displayName ?? "Layout"}
          render={(p) => <Component {...p} />}
        />
      </div>
    );
  };
  if (memoize) return memo(fn);
  return fn;
};

function useSubErrorsForControl(props: ControlProps): string {
  const { t } = useTranslation();
  const ctx = useJsonForms();
  // Fetches all child errors
  const subErrors = getSubErrorsAt(props.path, props.schema)({ jsonforms: { ...ctx } });
  if (props.errors) {
    // Parent error is present, only show to DEV
    if (!DEV) return t("forms:invalid_input", "Invalid input");
    return props.errors;
  }

  // No children errors present
  if (!subErrors || subErrors.length === 0) return "";

  // Show first child error for DEV, default error message for PROD
  if (DEV) {
    return subErrors[0].message ?? t("forms:invalid_input", "Invalid input");
  } else {
    return t("forms:invalid_input", "Invalid input");
  }
}
