import { t } from "i18next";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { captureMessage } from "~/lib/error-reporting";
import { PageLayout } from "~/lib/ui/page-layout";

export default function Index() {
  //We should never get here - but just in case...
  captureMessage("This user didn't get redirected to any appropriate frontpage");

  return (
    <PageLayout title={t("common:frontpage")}>
      <Suspense>
        <Outlet />
      </Suspense>
    </PageLayout>
  );
}
