import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { captureException, getLastEventId, sendFeedback } from ".";
import { useSession } from "../auth/session";
import { FeedbackPanel } from "./feedback-panel";
import { useMount } from "../lifecycle-helpers";
import { Button, getIcon } from "../ui";
import { DEV } from "../auth/config";
import { ResponseError } from "@apacta/sdk";
import { twMerge } from "tailwind-merge";

export function ErrorView({
  error,
  hideBackButton = false,
  hideHomeButton = false,
  className,
}: {
  error: Error;
  hideBackButton?: boolean;
  hideHomeButton?: boolean;
  className?: string;
}) {
  const { me } = useSession();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const eventId = getLastEventId();

  useMount(() => {
    captureException(error);
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  function handleFeedback(feedback: string) {
    if (!me) return;
    const { user } = me;
    if (!user.fullName || !user.email || !eventId) {
      console.warn("missing data for feedback");
      return;
    }
    sendFeedback({ name: user.fullName, email: user.email, feedback, eventId });
  }

  type ErrorDetails = {
    title: string;
    subtitle: string;
    showFeedback: boolean;
  };
  const details: ErrorDetails = (() => {
    // Return HTTP details if available
    if (error instanceof ResponseError) {
      switch (error.response.status) {
        case 404:
          return {
            title: t("errors:not_found.title", "Not found"),
            subtitle: t("errors:not_found.subtitle"),
            showFeedback: false,
          };
        case 403:
          return {
            title: t("errors:forbidden.title", "Forbidden"),
            subtitle: t("errors:forbidden.subtitle"),
            showFeedback: false,
          };
        case 401:
          return {
            title: t("errors:unauthorized.title", "Unauthorized"),
            subtitle: t("errors:unauthorized.subtitle"),
            showFeedback: false,
          };
        default:
          return {
            title: t("errors:unexpected.title"),
            subtitle: t("errors:unexpected.subtitle"),
            showFeedback: true,
          };
      }
    }
    // Otherwise return generic error
    if (!DEV)
      return {
        title: t("error.unexpected.title"),
        subtitle: t("error.unexpected.subtitle"),
        showFeedback: true,
      };

    // Only for developers
    return {
      title: t("error.unexpected.title"),
      subtitle: error.message,
      showFeedback: false,
    };
  })() satisfies ErrorDetails;

  return (
    <div className={twMerge("flex min-h-screen flex-col p-6", className)}>
      <div className="flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
        <div className="pt-4">
          <div>
            <h1 className="mb-2 mt-2 font-bold tracking-tight text-gray-900 sm:text-5xl">
              {details.title}
            </h1>
            <div className="text-shade-500">{details.subtitle}</div>
            {DEV && (
              <div className="mt-2 flex flex-col bg-red-100 p-4 text-base text-gray-500">
                <pre>{error.stack}</pre>
              </div>
            )}
            {details.showFeedback && (
              <div className="mt-2 flex flex-col text-base text-gray-500">
                <FeedbackPanel onFeedback={handleFeedback} />
                <p>&nbsp;</p>
                <p>
                  <>
                    {t("errors:unexpected.contact_support")}
                    &nbsp;
                    <strong>{t("common:customer_service_telephone")}</strong>
                  </>
                </p>
              </div>
            )}

            <div className="mt-6 flex flex-row gap-4">
              {!hideBackButton ? (
                <Button
                  variant="secondary"
                  Icon={getIcon("chevronLeft")}
                  onClick={() => handleGoBack()}
                >
                  {t("common:go-back")}
                </Button>
              ) : null}
              {!hideHomeButton ? (
                <Button variant="secondary" Icon={getIcon("home")} onClick={() => handleGoHome()}>
                  {t("common:go-home")}
                </Button>
              ) : null}
              <Button
                Icon={getIcon("reload")}
                variant="tertiary"
                onClick={() => window.location.reload()}
              >
                {t("common:reload")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
