import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import { withJsonFormsArrayLayoutProps, withJsonFormsLayoutProps } from "@jsonforms/react";
import { DateInputRenderer, DateInputTester } from "./DateInputRenderer";
import { TextControlRenderer, TextControlRendererTester } from "./TextControlRenderer";
import { TextAreaControl, TextAreaControlTester } from "./TextAreaControl";
import { LocationRenderer, LocationTester } from "./LocationRenderer";
import { DropdownRenderer, DropdownTester } from "./DropdownRenderer";
import { TimeInputRenderer, TimeInputTester } from "./TimeInputRenderer";
import { NumberRenderer, NumberRendererTester } from "./NumberRenderer";
import { LabelRenderer, LabelTester } from "./LabelRenderer";
import { CategorizationRenderer, CategorizationTester } from "./CategorizationRenderer";
import { CategoryRenderer, CategoryTester } from "./CategoryRenderer";
import { GroupRenderer, GroupRendererTester } from "./GroupRenderer";
import { FallbackControlRenderer, FallbackControlTester } from "./FallbackControlRenderer";
import { FallbackLayoutRenderer, LayoutFallbackTester } from "./FallbackLayoutRenderer";
import { HorizontalLayoutRenderer, HorizontalLayoutTester } from "./HorizontalLayoutRenderer";
import { VerticalLayoutRenderer, VerticalLayoutTester } from "./VerticalLayoutRenderer";
import { ArrayLayoutRenderer, arrayLayoutTester } from "./array-layout-renderer";
import { DurationRenderer, durationTester } from "./DurationRenderer";
import { ProductRenderer, productTester } from "./ProductRenderer";
import { objectControlTester, ObjectRenderer } from "./object-renderer";
import { enumControlTester, EnumRenderer } from "./enum-renderer";
import {
  DrivingDestinationEditRenderer,
  drivingDestinationEditTester,
} from "./DrivingDestinationEditRenderer";
import {
  DrivingDestinationViewRenderer,
  drivingDestinationViewTester,
} from "./DrivingDestinationViewRenderer";
import { SwitchRenderer, switchTester } from "./SwitchRenderer";
import { PhotoRendererHorizontal, photoTester } from "./PhotoRenderer";
import { SignatureRenderer, signatureTester } from "./SignatureRenderer";
import { ProductLayoutRenderer, productListTester } from "./product-layout-renderer";
import { QARenderer, qaTester } from "./QARenderer";
import { SpacerRenderer, spacerTester } from "./SpacerRenderer";
import { DeprecatedRenderer, deprecatedTester } from "./DeprecatedRenderer";
import {
  HorizontalLayoutTightRenderer,
  HorizontalLayoutTightTester,
} from "./HorizontalLayoutRendererTight";
import { withControlProps, withLayoutProps } from "./with-props";
import { CheckboxLayoutRenderer, checkboxLayoutTester } from "./CheckboxLayoutRenderer";
import { PhotoLayoutRenderer, photoListTester } from "./photo-layout-renderer";
import {
  ProductUnitRenderer,
  productUnitTester,
} from "~/lib/json-forms/renderers/ProductUnitRenderer";

export const renderers: Array<JsonFormsRendererRegistryEntry> = [
  {
    renderer: withLayoutProps(CategorizationRenderer, true),
    tester: CategorizationTester,
  },
  { renderer: withControlProps(DateInputRenderer, true), tester: DateInputTester },
  { renderer: withJsonFormsLayoutProps(CategoryRenderer, true), tester: CategoryTester },
  {
    renderer: withControlProps(FallbackControlRenderer, true),
    tester: FallbackControlTester,
  },
  {
    renderer: withLayoutProps(FallbackLayoutRenderer, true),
    tester: LayoutFallbackTester,
  },
  {
    renderer: withControlProps(TextControlRenderer, true),
    tester: TextControlRendererTester,
  },
  { renderer: withControlProps(TextAreaControl, true), tester: TextAreaControlTester },
  { renderer: withLayoutProps(GroupRenderer, true), tester: GroupRendererTester },
  { renderer: withControlProps(LocationRenderer, true), tester: LocationTester },
  { renderer: withControlProps(DropdownRenderer, true), tester: DropdownTester },
  { renderer: withControlProps(TimeInputRenderer, true), tester: TimeInputTester },
  { renderer: withControlProps(NumberRenderer, true), tester: NumberRendererTester },
  { renderer: withControlProps(LabelRenderer, true), tester: LabelTester },
  {
    renderer: withLayoutProps(VerticalLayoutRenderer, true),
    tester: VerticalLayoutTester,
  },
  {
    renderer: withLayoutProps(HorizontalLayoutRenderer, true),
    tester: HorizontalLayoutTester,
  },
  {
    renderer: withLayoutProps(HorizontalLayoutTightRenderer, true),
    tester: HorizontalLayoutTightTester,
  },
  { renderer: withJsonFormsArrayLayoutProps(ArrayLayoutRenderer, true), tester: arrayLayoutTester },
  {
    renderer: withJsonFormsArrayLayoutProps(ProductLayoutRenderer, true),
    tester: productListTester,
  },
  {
    renderer: withJsonFormsArrayLayoutProps(PhotoLayoutRenderer, true),
    tester: photoListTester,
  },

  { renderer: withControlProps(ProductRenderer, true), tester: productTester },
  { renderer: withControlProps(ProductUnitRenderer, true), tester: productUnitTester },
  { renderer: withControlProps(DurationRenderer, true), tester: durationTester },
  { renderer: withControlProps(ObjectRenderer, true), tester: objectControlTester },
  { renderer: withControlProps(SwitchRenderer, true), tester: switchTester },
  {
    renderer: withControlProps(DrivingDestinationViewRenderer, true),
    tester: drivingDestinationViewTester,
  },
  {
    renderer: withControlProps(EnumRenderer, true),
    tester: enumControlTester,
  },
  {
    renderer: withControlProps(DrivingDestinationEditRenderer, true),
    tester: drivingDestinationEditTester,
  },
  {
    renderer: withControlProps(PhotoRendererHorizontal, true),
    tester: photoTester,
  },
  {
    renderer: withControlProps(SignatureRenderer, true),
    tester: signatureTester,
  },
  {
    renderer: withControlProps(QARenderer, true),
    tester: qaTester,
  },
  { renderer: withControlProps(SpacerRenderer, true), tester: spacerTester },
  { renderer: withControlProps(DeprecatedRenderer, true), tester: deprecatedTester },
  {
    renderer: withControlProps(CheckboxLayoutRenderer, true),
    tester: checkboxLayoutTester,
  },
];
