/**
 * Array.prototype.filter() filter functions
 * @module lib/utils/filters
 * @description Filters for Array.prototype.filter()
 */

/**
 * Filters out null and undefined values
 * @param value<TValue> - value to check (default through filter)
 */
export function notNullOrUndefinedFilter<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * Filters out duplicate values
 * @type Array<TValue> - array to check for duplicates
 * @param value<TValue> - value to check (default through filter)
 * @param index<number> - index of value in array (default through filter)
 * @param array<Array<TValue>> - array to check for duplicates (default through filter)
 */
export function onlyUniqueFilter<TValue>(value: TValue, index: number, array: Array<TValue>) {
  return array.indexOf(value) === index;
}
