import { Input } from "~/lib/ui/form-elements";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";
import { Icon } from "~/lib/ui";
import { useEffect, useRef } from "react";

export function TaskNameSection({
  defaultTaskName,
  formState,
}: {
  defaultTaskName?: string;
  formState: TaskFormState;
}) {
  const { t } = useTranslation();
  const taskNameRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const tName = formState.getValue("projectName");
    if (taskNameRef.current && taskNameRef.current.value === "") {
      formState.setValues({ taskName: tName ?? "" });
      taskNameRef.current.value = tName ?? "";
    }
  }, [formState.getValue("projectId")]);

  return (
    <div className="flex items-center gap-2 text-sm">
      <div className="flex grow  items-center gap-3  ">
        <div
          title={t("planning:create_modal.task_name")}
          className="flex h-8 w-8 shrink-0 items-center justify-center"
        >
          <Icon name="projectSolid" size="small" />
        </div>
        <Input
          ref={taskNameRef}
          placeholder={t("common:enter", {
            text: t("planning:create_modal.task_name").toLowerCase(),
          })}
          defaultValue={defaultTaskName}
          onChange={(e) => formState.setValues({ taskName: e.currentTarget.value })}
          className="w-full cursor-pointer rounded-none border-none border-gray-300 hover:bg-shade-100 focus:border-b-2 focus:border-b-hover focus:border-l-gray-300 focus:border-r-gray-300 focus:border-t-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
        />
      </div>
    </div>
  );
}
