import { Location } from "react-router";

const enabledFeatures = ["/playground/"];

export function featureEnabled(location: Location): boolean {
  const locationPath = location.pathname;

  return enabledFeatures.some((fullPath: string) => {
    const pathParts = fullPath.split("/*");
    let regex = new RegExp(`^${pathParts[0]}$`);
    if (pathParts.length > 1) {
      regex = new RegExp(`^${pathParts[0]}(/.*)?$`);
    }

    return regex.test(locationPath);
  });
}
