import { HTMLProps, Ref, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export const Select = forwardRef(function Select(
  props: HTMLProps<HTMLSelectElement>,
  ref: Ref<HTMLSelectElement>
) {
  const { className, ...restProps } = props;
  return (
    <select
      ref={ref}
      {...restProps}
      className={twMerge(
        `h-10 rounded-md border border-gray-300 text-gray-700 transition-colors focus:border-hover focus:outline-none focus:ring-transparent`,
        className
      )}
    />
  );
});
