import { ControlProps, isDateControl, isDateTimeControl, or, rankWith } from "@jsonforms/core";
import { LabelInput } from "~/lib/ui";
import { getLabelForControl } from "~/lib/json-forms/getLabelForControl";
import { useDefaultFormValues } from "./useDefaultFormValues";

export function DateInputRenderer(props: ControlProps) {
  useDefaultFormValues(props);

  return (
    <LabelInput
      label={getLabelForControl(props)}
      type="date"
      defaultValue={props.data}
      onChange={(e) => props.handleChange(props.path, e.currentTarget.value)}
      className="max-w-xs md:max-w-fit"
      error={props.errors}
    />
  );
}
export const DateInputTester = rankWith(5, or(isDateControl, isDateTimeControl));
