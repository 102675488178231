import { Fragment, useCallback, useMemo } from "react";
import { isValidEmail, isValidPhone, isValidUrl } from "~/lib/utils/string/linkify/validation";
import { LinkifyProps } from "~/lib/utils/string/linkify/types";
import { linkifyWatchers } from "~/lib/utils/string/linkify/content";

export default function Linkify({ children }: LinkifyProps) {
  const words = useMemo(() => children?.split(" "), [children]);
  const watchers = linkifyWatchers;
  const findWatcher = useCallback((word: string) => {
    // There isn't such short links, phones or urls
    if (word.length < 5) {
      return;
    }
    return watchers.find((watcher) => {
      return (
        (watcher.type === "link" && isValidUrl(word)) ||
        (watcher.type === "email" && isValidEmail(word)) ||
        (watcher.type === "phone" && isValidPhone(word))
      );
    });
  }, []);
  return (
    <>
      {words.map((word, index) => {
        const { render } = findWatcher(word) || {};
        const content = index + 1 === words?.length ? ` ${word}` : `${word} `;
        const key = `${index}_${word}`;

        return <Fragment key={key}>{render?.(content) ?? content}</Fragment>;
      })}
    </>
  );
}
