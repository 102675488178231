import { z } from "zod";

export const userSchema = {
  first_name: z.string().min(1),
  last_name: z.string().optional(),
  profile_image: z.string().optional().nullable(),
  email: z.string().email().optional(),
  password: z.string().optional(),
  mobile_country_code: z.string().max(8).optional(),
  mobile: z.string().max(32).optional(),
  address: z.string().optional(),
  // zip_code: dkZip(), Nice to have
  zip_code: z.string().optional(),
  city_name: z.string().optional(),
  language_id: z.string().uuid().min(1),
  erp_id: z.string().optional(),
  city_id: z.string().uuid().optional(),
  manual_price: z.boolean(),
  users_price_group_id: z.string().optional(),
  cost_price: z.number().nullish(),
  extra_price: z.number().nullish(),
  labels: z.array(z.string().uuid()).optional(),
};
