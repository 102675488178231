import { IGetProject200Response, ProjectCustomFieldAttribute } from "@apacta/sdk";
import { useFormState } from "~/lib/form-state";
import { projectFormState } from "~/pages/projects/[id]/data";
import { useTranslation } from "react-i18next";
import TextInput from "../../../../lib/ui/form-elements/text-input";
import { twMerge } from "tailwind-merge";
import { Label } from "~/lib/ui";

export default function CustomFieldsSection({
  project,
  formState,
}: {
  project: IGetProject200Response["data"];
  formState: ReturnType<typeof useFormState<typeof projectFormState>>;
}) {
  const { t } = useTranslation();

  // Company has no custom fields
  if (!project.customFieldAttributes?.length) {
    return null;
  }

  const updateCustomField = (value: string, field: ProjectCustomFieldAttribute) => {
    formState.setValues({
      customFields: {
        ...formState.getValues().customFields,
        [field.id]: value,
      },
    });
  };

  // We have no idea how many custom fields there are, so we need to map them dynamically
  // This also includes a bit of logic to determine padding and margin based on the index
  return (
    <div className="w-full">
      <div className="mb-2 text-gray-500">{t("projects:custom_fields")}</div>
      <div className="flex w-full flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
        <div className="flex grow flex-wrap">
          {project.customFieldAttributes?.map((field, index) => (
            <div
              key={`${field.id}-${index}`}
              className={twMerge("basis-1/2", index % 2 ? "pl-4" : "pr-4", index > 1 ? "mt-6" : "")}
            >
              <div className="flex justify-between">
                <Label>{field.name}</Label>
                <span className="text-sm text-shade-400">
                  {t(`projects:custom_fields_description.${field.accessType}`)}
                </span>
              </div>
              <TextInput
                defaultValue={formState.getValue("customFields")?.[field.id] ?? ""}
                onChange={(e) => updateCustomField(e, field)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
