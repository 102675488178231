import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { Suspense, useEffect, useRef } from "react";
import { calculateKPIDataPoints, calculateLinesTotal } from "~/lib/ui/order-lines/lib/calculations";
import { useTranslation } from "react-i18next";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";
import { useMount } from "~/lib/lifecycle-helpers";
import Switch from "~/lib/ui/switch";

export default function ManualPricingSection({
  formState,
  editMode,
}: {
  formState: OfferFormState;
  editMode: boolean;
}) {
  const { t } = useTranslation();
  const { orderLines, vat } = useOrderLinesBuilder();

  const salesPriceRef = useRef<HTMLInputElement | null>(null);
  const contributionRatioRef = useRef<HTMLInputElement | null>(null);
  const shouldOverwrite =
    formState.getValue("offerLinesRule") === "gather_offer_lines" ||
    formState.getValue("offerLinesRule") === "show_only_product_bundles";

  const calculateTotalSalesPrice = (): number => {
    const subAmount = calculateLinesTotal(orderLines, "subAmount", vat);
    if (shouldOverwrite && subAmount !== formState.getValue("manualTotalSalesPrice")) {
      return formState.getValue("manualTotalSalesPrice") ?? 0;
    }
    return Math.round(subAmount);
  };

  const calculateContributionRatio = (): number => {
    const contributionRatio =
      calculateKPIDataPoints(orderLines, calculateLinesTotal(orderLines, "subAmount", vat))
        .contributionRatio * 100;
    if (shouldOverwrite && contributionRatio !== formState.getValue("contributionRatioOverwrite")) {
      return formState.getValue("contributionRatioOverwrite") ?? 0;
    }
    return Number(Math.round(contributionRatio));
  };

  // Listen for changes in shouldSetManualPrice and update manual pricing accordingly
  useEffect(() => {
    if (formState.getValue("shouldSetManualPrice")) {
      updateManualPricing();
    } else {
      formState.setValues(
        {
          manualTotalSalesPrice: undefined,
          contributionRatioOverwrite: undefined,
        },
        true
      );
    }
  }, [formState.getValue("shouldSetManualPrice")]);

  const updateManualPricing = () => {
    if (!formState.getValue("shouldSetManualPrice")) return; // Do not update if manual pricing is not enabled
    const manualTotalSalesPrice = calculateTotalSalesPrice();
    const contributionRatioOverwrite = calculateContributionRatio();

    if (salesPriceRef.current) {
      salesPriceRef.current.value = manualTotalSalesPrice.toString();
    }

    if (contributionRatioRef.current) {
      contributionRatioRef.current.value = contributionRatioOverwrite.toString();
    }

    formState.setValues(
      {
        manualTotalSalesPrice,
        contributionRatioOverwrite,
      },
      true
    );
  };

  useMount(() => {
    updateManualPricing();
  });

  // If sales price is shown in the OrderLine builder, only set contribution ratio override
  // If sales price is NOT shown in the OrderLine builder, set sales price and contribution ratio
  useEffect(() => {
    if (!formState.getValue("shouldSetManualPrice")) return;
    const rule = formState.getValue("offerLinesRule");
    if (rule !== "gather_offer_lines" && rule !== "show_only_product_bundles") {
      updateManualPricing();
    } else {
      const contributionRatioOverwrite = Number(
        Math.round(
          calculateKPIDataPoints(orderLines, formState.getValue("manualTotalSalesPrice") ?? 0)
            .contributionRatio * 100
        )
      );

      if (contributionRatioRef.current) {
        contributionRatioRef.current.value = contributionRatioOverwrite.toString();
      }

      formState.setValues(
        {
          contributionRatioOverwrite,
        },
        true
      );
    }
  }, [orderLines]);

  const handleChangeContributionRatio = (v: number) => {
    if (!formState.getValue("shouldSetManualPrice")) return;
    const manualTotalSalesPrice = Math.round(
      calculateKPIDataPoints(orderLines, v).totalCost / (1 - v / 100)
    );
    const contributionRatioOverwrite = v;

    formState.setValues({
      manualTotalSalesPrice,
      contributionRatioOverwrite,
    });

    if (salesPriceRef.current) {
      salesPriceRef.current.value = manualTotalSalesPrice.toString();
    }
  };

  const handleChangeSalesPrice = (v: number) => {
    if (!formState.getValue("shouldSetManualPrice")) return;
    const manualTotalSalesPrice = v;
    const contributionRatioOverwrite = Math.round(
      calculateKPIDataPoints(orderLines, v).contributionRatio * 100
    );

    formState.setValues({
      manualTotalSalesPrice,
      contributionRatioOverwrite,
    });

    if (contributionRatioRef.current) {
      contributionRatioRef.current.value = contributionRatioOverwrite.toString();
    }
  };

  return (
    <Suspense>
      {orderLines && (
        <div className="flex flex-col items-end">
          <div className="flex w-fit flex-col items-end gap-4 border bg-yellow-50 p-4">
            <Switch
              onCheckedChange={(v) => formState.setValues({ shouldSetManualPrice: v })}
              defaultChecked={formState.getValue("shouldSetManualPrice")}
              label={t("products:manually_priced")}
            />
            <div className="flex w-full justify-end gap-8">
              <div className="w-1/2">
                <NumberFormatInput
                  ref={contributionRatioRef}
                  defaultValue={formState.getValue("contributionRatioOverwrite")}
                  disabled={!editMode || !formState.getValue("shouldSetManualPrice")}
                  label={t("common:contribution_ratio")}
                  onChange={(v) => handleChangeContributionRatio(Number(v))}
                  className="text-right"
                />
              </div>
              <div className="w-1/2">
                <NumberFormatInput
                  ref={salesPriceRef}
                  defaultValue={formState.getValue("manualTotalSalesPrice")}
                  disabled={!editMode || !formState.getValue("shouldSetManualPrice")}
                  label={t("finance:sales_price")}
                  onChange={(v) => handleChangeSalesPrice(Number(v))}
                  className="text-right"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Suspense>
  );
}
