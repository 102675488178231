import { User } from "@apacta/sdk";
import { generateBackgroundColor } from "~/lib/utils/colors";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";

export function EmployeeHeader({ user }: { user: User }) {
  return (
    <div
      className="relative flex h-16 select-none items-center gap-2 border border-t-4 bg-white px-1 text-sm"
      style={{ borderTopColor: generateBackgroundColor(user.fullName) }}
    >
      <div className="rounded-full border bg-white p-1 shadow-sm">
        <UserAvatar user={user} className="h-8 w-8" />
      </div>
      <div className="flex flex-col gap-1">
        <div title={user.fullName} className="line-clamp-1 pr-6 text-xl">
          {user.fullName}
        </div>
      </div>
    </div>
  );
}
